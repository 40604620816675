import { ISuccessResponse } from 'API/constants'
import {
  activeContract,
  createContract,
  deActiveContract,
  deleteContract,
  downloadContractFile,
  getContractDetail,
  getContractListAdmin,
  getContractListOfUser,
  showContractFile,
  updateContract,
} from 'API/contract'
import { IContractPayload } from 'API/contract/constant'
import { handleError } from 'API/error'
import { getPartners } from 'API/partner'
import { IPartnerListResponse } from 'API/partner/constants'
import { saveAs } from 'file-saver'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { getInQuery, toBase64, getContractDetailFormData, formatContractNumber } from 'utils'
import { EContractTypes, EYesNoOption } from 'constants/enum'
import { IPartner, IContract } from 'constants/schema'
import { IContractForm } from 'containers/Contract/Create/constants'
import RootStore from '../rootStore'

class AdminContractStore {
  rootStore: RootStore
  partnerList: IPartner[] = []
  contractList: IContract[] = []
  contractListOfUser: IContract[] = []
  currentContractContent
  contractDetail: IContract

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getPartnerList(partnerCategory: string[]): Promise<void> {
    try {
      const payload = { filter: { isDeleted: false, isArchived: false, category: getInQuery(partnerCategory) } }
      const response: ISuccessResponse<IPartnerListResponse> = await getPartners(payload)
      this.partnerList = response?.data?.partners
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'getPartnerList')
    }
  }

  public async createContract(data: IContractForm): Promise<IContract> {
    try {
      const payload: IContractPayload = {
        ...data,
        isCreateInternalDoc: get(data, 'isCreateInternalDoc', EYesNoOption.YES) === EYesNoOption.YES,
        number: formatContractNumber(data?.number, get(data, 'type.value', '')),
        partnerId: get(data, 'partnerId.value', ''),
        laborContractCategory: get(data, 'laborContractCategory.value', ''),
        laborContractTerm: get(data, 'laborContractTerm.value', ''),
        type: get(data, 'type.value', ''),
        IDIssuePlaceVI: data?.IDIssuePlaceVI?.value ?? '',
      }
      const contract: IContract = await createContract(payload)
      this.contractDetail = contract
      return contract
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'createContract')
      return {} as IContract
    }
  }

  public async getContractList(type: EContractTypes): Promise<void> {
    try {
      const { contractList } = await getContractListAdmin(type)
      this.contractList = contractList
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'getContractList')
    }
  }

  public async getContractListOfUser(userId: string): Promise<void> {
    try {
      const { contractList } = await getContractListOfUser(userId)
      this.contractListOfUser = contractList
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'getContractListOfUser')
    }
  }

  public async getContractDetail(contractId: string): Promise<void> {
    try {
      const { contractDetail } = await getContractDetail(contractId)
      if (contractDetail) {
        const formattedContract: IContract = getContractDetailFormData(contractDetail)
        this.contractDetail = formattedContract
      }
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'getContractDetail')
    }
  }

  public async updateContract(contractId: string, data: IContractPayload): Promise<void> {
    try {
      const payload: IContractPayload = {
        ...omit(data, 'partnerData'),
        partnerId: get(data, 'partnerId.value', ''),
        laborContractCategory: get(data, 'laborContractCategory.value', ''),
        laborContractTerm: get(data, 'laborContractTerm.value', ''),
        type: get(data, 'type.value', ''),
      }
      const { message } = await updateContract(payload, contractId)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'updateContract')
    }
  }

  public async deleteContract(id: string): Promise<void> {
    try {
      const { message } = await deleteContract(id)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'deleteContract')
    }
  }

  public async activeContract(id: string): Promise<void> {
    try {
      const { message } = await activeContract(id)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'activeContract')
    }
  }

  public async deactiveContract(id: string): Promise<void> {
    try {
      const { message } = await deActiveContract(id)
      toast.success(message)
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'deactiveContract')
    }
  }

  public async downloadContractFile(id: string, fileName: string): Promise<void> {
    try {
      const acceptType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      const contractFile: BlobPart = await downloadContractFile(id, fileName, acceptType)
      const result: string = (await toBase64(new Blob([contractFile], { type: acceptType }))) as string
      saveAs(result, fileName)
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'downloadContractFile')
    }
  }

  public async showContractFile(id: string, fileName: string): Promise<void> {
    try {
      const acceptType = 'application/pdf'
      const contractFile: BlobPart = await showContractFile(id, fileName, acceptType)
      const result: string = (await toBase64(new Blob([contractFile], { type: acceptType }))) as string
      this.currentContractContent = result
    } catch (error) {
      handleError(error, 'src/store/admin/contractStore.ts', 'showContractFile')
    }
  }
}

export default AdminContractStore
