import { Text } from '@chakra-ui/react'
import { textGrey500 } from 'theme/globalStyles'
interface ITagItem {
  value?: string
  className?: string
  handleTextColor?: (value: string) => string
}
const TagItem = (props: ITagItem) => {
  const { value, className, handleTextColor } = props
  const isTextColor: boolean = typeof handleTextColor === 'function'

  return (
    <Text
      className={className}
      border={`1px solid ${textGrey500}`}
      borderColor={isTextColor ? handleTextColor(value ?? '') : undefined}
      borderRadius={'6px'}
      margin={{ lg: '0px', md: '2px', sm: '2px' }}
      padding={'4px 12px'}
      color={isTextColor ? handleTextColor(value ?? '') : undefined}>
      {value}
    </Text>
  )
}

export default TagItem
