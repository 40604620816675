import { ECommitTimeGroupBy, ECommitTimeTypeOfFilter } from 'constants/enum'

export const COMMIT_TIME_BASE_URL: string = '/commit-time'

export interface ICommitTimeRequestStatus {
  value: number
  label: string
}

export interface ICommitTimeRequestData {
  _id: string
  commitTime: number
  developerName: string
  project: string
  projectName: string
  reason: string
  rejectedReason: string
  status: ICommitTimeRequestStatus
  createdAt?: Date
}

export interface ICommitTimeRequestList {
  commitTimeRequestList: ICommitTimeRequestData[]
}

export interface ICommitTimeFilterPayload {
  typeOfFilter?: ECommitTimeTypeOfFilter
  project?: string
  user?: string
  groupBy?: ECommitTimeGroupBy
}

export interface ICommitTimeReportsResponse {
  commitTimeReportList: ICommitTimeReportResponse[] | null
}

export interface ICommitTimeReportResponse {
  period?: string
  _id?: {
    startDate: string
    endDate: string
  }
  data: IFormattedCommitTimeReport[]
}

export interface IFormattedCommitTimeReport {
  commitTime: number
  project?: string
  status?: string
  timeToSendReport?: string
  projectName?: string
  developerName?: string
}

export interface ICommitTimeRequestsAdminResponse {
  commitTimeRequestList: ICommitTimeRequestData[]
}

export interface ICommitTimeRequestRejectPayload {
  commitTimeRequestId: string
  rejectedReason?: string
}

export interface ICommitTimeListAdminResponse {
  commitTimeListData: ICommitTimeListAdmin
}

export interface ICommitTimeListAdmin {
  commitTimeOfPerProject: ICommitTimeData[]
  commitTimeOfAllProject: ICommitTimeData[]
  commitTimeOfGroupBy: ICommitTimeOfGroupBy[]
}

export interface ICommitTimeData {
  _id?: string
  projectName: string
  developerName?: string
  commitTime: number
  project: string
  isAll?: boolean
  isActive: boolean
  updatedBy: string
  isAdminUpdated?: boolean
  developer?: string
}

export interface ICommitTimeOfGroupBy {
  groupByTitle: string
  commitTimeDataOfGroup: {
    commitTimeDataOfGroupPerProject: ICommitTimeData[]
    commitTimeDataOfGroupAllProject: ICommitTimeData[]
  }
}

export interface ICommitTimeList {
  commitTimeList: ICommitTimeRequestData[]
}

export interface ICommitTimeSendRequestChange {
  commitTimeId: string
  isAll: boolean
  newCommitValue: number
  project: string
  reason: string
}
