import React, { useState, useEffect } from 'react'
import UnderConstruction from 'containers/UnderConstruction'

const Profile = () => {

  return (
    <>
      <UnderConstruction  />
    </>
  )
}
export default Profile
