import { ITableHeader } from 'components/Table'
import moment from 'moment'
import * as Yup from 'yup'
import { IProject, IPartner, ICreatableOption } from 'constants/schema'

export interface IRowActionItem {
  name: string
  customClassNames?: string[]
  handler: () => void
}
export function getHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'PARNTER',
      accessor: 'partnerName',
    },
    {
      Header: 'BILL NUMBER',
      accessor: 'billNumber',
    },
    {
      Header: 'PUBLISHED DATE',
      accessor: 'publishedDate',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export const today = moment().format('YYYY-MM-DD')
export const initialValues = {}
export const validation = Yup.object().shape({
  year: Yup.number().min(1900).max(2100),
  month: Yup.number().min(1).max(12),
})
export interface Props {
  history: any
  match: any
  handleCloseConfirmDialog?: () => void
  handleOpenSnackbar?: ({ variant, message }) => void
}

export interface IOption {
  label: string
  value: string
}

export interface IProjectFilter extends Omit<IProject, 'partnerId' | 'countryId' | 'technology' | 'user'> {
  partnerId?: string
  countryId?: string[]
  technology?: string[]
  user?: string[]
}
export interface States {
  projects: IProject[]
  count: number
  filter?: IProjectFilter
  partners?: IPartner[]
  doctypes?: ICreatableOption[]
  open?: boolean
  exportNow?: boolean
}

export function getHourlyProjectHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'DEVELOPER',
      accessor: 'developer',
    },
    {
      Header: 'PROJECT',
      accessor: 'project',
    },
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
    },
    {
      Header: 'STARTED DATE',
      accessor: 'startedDate',
    },
    {
      Header: 'ENDED DATE',
      accessor: 'endedDate',
    },
    {
      Header: 'QTY',
      accessor: 'quantity',
    },
    {
      Header: 'HOURLY RATE',
      accessor: 'hourlyRate',
    },
    {
      Header: 'TOTAL PRICE',
      accessor: 'totalPrice',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}
