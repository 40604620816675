import { Text, VStack, SimpleGrid, Container } from '@chakra-ui/react'
import LabelForm from 'componentsCharka/LabelForm'
import { observer } from 'mobx-react'

const DetailPartnerForm = (props) => {
  const { methods } = props
  const { getValues } = methods
  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack spacing={{ lg: 4, md: 4, sm: 2 }} alignItems={'initial'} margin={{ base: 6, lg: 6 }} height="full">
        <Text fontWeight={600}>Partner Detail</Text>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <LabelForm name={`number`} label="Partner Number" readonly={true} isHighligh />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <LabelForm name={'fullName'} label="Full Name" readonly={true} isHighligh />
          <LabelForm name={'address'} label="Address" readonly={true} isHighligh />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <LabelForm name={'type'} label="Partner Type" readonly={true} />
          <LabelForm name={'category'} label="Partner Category" readonly={true} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <LabelForm name={'email'} label="Email" readonly={true} />
          <LabelForm name={'website'} label="Website" readonly={true} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <LabelForm name={'taxCode'} label="Tax Code" type="date" readonly={true} />
          <LabelForm name={'billingAddress'} label="Billing Address" readonly={true} />
        </SimpleGrid>
      </VStack>
    </Container>
  )
}
export default observer(DetailPartnerForm)
