import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'formik'

import Input from '../../../../../components/Input'
import RadioButton from '../../../../../components/RadioButton'
import ToggleEditSaveDeleteInline from '../../../../../components/ToggleEditSaveDeleteInline'
import DatePicker from '../../../../../components/DatePicker'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
  menu: {
    width: 200,
  },
})

class Information extends React.Component {
  render() {
    const {
      classes,
      viewMode,
      handleToggleActiveEdit,
      cancelForm,
      handleDelete,
      handleSubmit,
      dirty,
      isSubmitting,
      errors,
      values,
    } = this.props
    let activeEdit
    switch (viewMode) {
      case 'show':
        activeEdit = false
        break
      case 'edit':
        activeEdit = true
        break
      default:
        return null
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="box box-default">
            <div className="box-header">
              Information
              <ToggleEditSaveDeleteInline
                title="Descriptions"
                viewMode={viewMode}
                cancelClickHandler={() => cancelForm(values._id)}
                editClickHandler={() => handleToggleActiveEdit && handleToggleActiveEdit(values._id)}
                deleteClickHandler={() => handleDelete(values)}
                saveDisabled={isSubmitting || !dirty}
              />
            </div>
            <div className="box-divider" />
            <div className={`box-body`}>
              <div className="container-fluid">
                <div className="fields company-info">
                  <div className="field">
                    <Field
                      name="title"
                      label="Title*"
                      className={classes.textField}
                      margin="normal"
                      component={Input}
                      disabled={!activeEdit}
                    />
                  </div>
                  <div className="field">
                    <Field
                      name="publishedDate"
                      label="Published Date*"
                      type="date"
                      className={classes.textField}
                      margin="normal"
                      component={DatePicker}
                      disabled={!activeEdit}
                    />
                  </div>
                  <div className="field radio-button">
                    <Field
                      name="published"
                      label="Published"
                      className={classes.textField}
                      margin="normal"
                      component={RadioButton}
                      disabled={!activeEdit}
                    />
                  </div>
                </div>
                <div className="fields">
                  <div className="field">
                    <Field
                      name="content"
                      label="Content*"
                      multiline
                      row={5}
                      className={classes.textField}
                      margin="normal"
                      component={Input}
                      disabled={!activeEdit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className="error-message">{errors['form']}</span>
      </form>
    )
  }
}

Information.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Information)
