import axios from 'axios'
import ErrorHandler from './errorHandler'
import { EventName } from '../constants'
import { AppConfig } from 'constants/index'

const getHeader = function () {
  const isRememberMe = JSON.parse(window.localStorage.getItem('isRememberMe'))
  const rToken = isRememberMe ? window.localStorage.getItem('rToken') : window.sessionStorage.getItem('rToken')
  return {
    Authorization: 'Bearer ' + rToken,
  }
}

const getUploadHeader = function () {
  const isRememberMe = JSON.parse(window.localStorage.getItem('isRememberMe'))
  const rToken = isRememberMe ? window.localStorage.getItem('rToken') : window.sessionStorage.getItem('rToken')
  return {
    Authorization: `Bearer ${rToken}`,
    'content-type': 'application/x-www-form-urlencoded',
  }
}

const getDownloadHeader = function (dataType) {
  const isRememberMe = JSON.parse(window.localStorage.getItem('isRememberMe'))
  const rToken = isRememberMe ? window.localStorage.getItem('rToken') : window.sessionStorage.getItem('rToken')
  return {
    Authorization: `Bearer ${rToken}`,
    Accept: `application/${dataType}`,
  }
}

const closeLoading = function () {
  document.dispatchEvent(new CustomEvent(EventName.closeLoading, { detail: { isOpen: false } }))
}
const openLoading = function () {
  document.dispatchEvent(new CustomEvent(EventName.openLoading, { detail: { isOpen: true } }))
}
const helper = {
  get: async function (options, cb, isLoading = false) {
    const backendUrl = AppConfig.API_URL
    const { url } = options
    const apiUrl = `${backendUrl}${url}`
    if (isLoading) {
      openLoading()
    }
    const headers = getHeader()
    axios
      .get(apiUrl , { headers })
      .then((res) => {
        closeLoading()
        cb({
          status: res.status,
          data: res.data.data,
        })
      })
      .catch(function (error) {
        closeLoading()
        // handle error
        ErrorHandler(error.response)
      })
  },
  getWithLongPolling: async function (options, cb, isLoading = true) {
    const backendUrl = AppConfig.API_URL
    const { url } = options
    const apiUrl = `${backendUrl}${url}`
    if (isLoading) {
      openLoading()
    }
    const headers = getHeader()
    axios
      .get(apiUrl, { headers })
      .then((res) => {
        closeLoading()
        cb(res)
      })
      .catch(function (error) {
        closeLoading()
        // handle error
        ErrorHandler(error.response)
      })
  },
  post: async function (options, cb, isLoading = true) {
    const backendUrl = AppConfig.API_URL
    console.log('backendUrl', backendUrl)
    const { url } = options
    const apiUrl = `${backendUrl}${url}`
    if (isLoading) {
      openLoading()
    }
    if (isLoading) {
      openLoading()
    }
    const headers = getHeader()
    return axios
      .post(apiUrl, options.data, { headers })
      .then((res) => {
        closeLoading()
        const result = {
          status: res.status,
          data: res.data.data,
        }
        cb && cb(result)
        return result
      })
      .catch(function (error) {
        closeLoading()
        // handle error
        ErrorHandler(error.response)
      })
  },
  upload: async function (options, cb) {
    const backendUrl = AppConfig.API_URL
    const { url } = options
    const apiUrl = `${backendUrl}${url}`
    openLoading()
    const uploadHeaders = getUploadHeader()
    axios
      .post(apiUrl, options.data, { headers: { ...uploadHeaders } })
      .then((res) => {
        closeLoading()
        cb({
          status: res.status,
          data: res.data.data,
        })
      })
      .catch(function (error) {
        closeLoading()
        // handle error
        ErrorHandler(error.response)
      })
  },
  downloadFile: async function (options, cb) {
    const backendUrl = AppConfig.API_URL
    const { url } = options
    const apiUrl = `${backendUrl}${url}`
    const downloadHeaders = getDownloadHeader(options.dataType)
    axios
      .get(apiUrl, {
        responseType: 'arraybuffer',
        headers: { ...downloadHeaders },
      })
      .then((res) => {
        cb(res)
      })
      .catch(function (error) {
        // handle error
        ErrorHandler(error.response)
      })
  },
  uploadDataAnddownloadFile: async function (options, cb) {
    const backendUrl = AppConfig.API_URL
    const { url } = options
    const apiUrl = `${backendUrl}${url}`
    const downloadHeaders = getDownloadHeader(options.dataType)
    axios
      .post(apiUrl, options.data, {
        responseType: 'arraybuffer',
        headers: { ...downloadHeaders },
      })
      .then((res) => {
        cb(res)
      })
      .catch(function (error) {
        // handle error
        ErrorHandler(error.response)
      })
  },
}
export async function callLoginAPI(values) {
  const backendUrl = AppConfig.API_URL
  return axios.post(`${backendUrl}/auth/login`, values)
}

export default helper
