import { chakra, Box, Text } from '@chakra-ui/react'

export const IconWithTextWrapper = chakra(Box, {
  baseStyle: () => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '16px',
    '> span': {
      width: 'max-content !important',
    },
  }),
})

export const Label = chakra(Text, {
  baseStyle: () => ({
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '16px',
  }),
})
