import { IPolicy } from 'constants/schema'

export const POLICY_BASE_URL: string = '/policy'

export interface IPolicyListResponse {
  policies: IPolicy[]
}

export interface ICreatePolicyResponse {
  triggerNotifyFrontend: boolean
}
