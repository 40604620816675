import React, { useEffect } from 'react'
import { HStack, VStack, Container, Text, Button } from '@chakra-ui/react'
import { handleError } from 'API/error'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { RoutesConstants } from 'constants/index'
import { IProject } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import CreateProjectForm from '../components/CreateProjectForm'
import LogoProject from '../components/LogoProject'
import SettingProject from '../components/SettingProject'
import styles from '../ProjectsListAdmin/index.module.scss'

const ProjectCreate = () => {
  const { adminProjectStore } = useStores()
  const { partnerList, usersList, technologiesList, countriesList, currencyList, projectTypes } = adminProjectStore
  const methods = useForm()
  const history = useHistory()
  const { handleSubmit, reset, setValue } = methods
  const partnerCategory: string[] = ['owner', 'client']

  window.localStorage.removeItem('pageTitle')
  window.localStorage.setItem('pageTitle', 'Projects')
  useEffect(() => {
    adminProjectStore.getPartnerList(partnerCategory)
    adminProjectStore.getTechnologyList()
    adminProjectStore.getProjectTypes()
    adminProjectStore.getCurrencyList()
    adminProjectStore.getCountryList()
  }, [])

  function resetForm() {
    reset()
    history.push(RoutesConstants.PROJECTS_ADMIN_LIST)
  }
  async function submitHandler(data: IProject) {
    try {
      await adminProjectStore.createProject(data)
      history.push(RoutesConstants.PROJECTS_ADMIN_LIST)
    } catch (error) {
      handleError(error, 'src/containers/ProjectsNew/ProjectCreate/index.tsx', 'submitHandler')
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => submitHandler(data))}>
        <VStack>
          <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
            <HStack margin={{ base: 6, lg: 6 }} height="full" background="white" justifyContent="space-between">
              <Text fontSize="lg" color="gray.700" margin={0}>
                New Project
              </Text>
              <HStack>
                <Button
                  colorScheme="blue"
                  variant="outline"
                  isFullWidth
                  type="button"
                  onClick={resetForm}
                  _hover={{
                    cursor: 'pointer',
                  }}>
                  Cancel
                </Button>
                <Button
                  bg={'#4D5DFB'}
                  minWidth={'none'}
                  isFullWidth
                  className={styles.createButton}
                  variant="solid"
                  type="submit">
                  Save
                </Button>
              </HStack>
            </HStack>
          </Container>

          <HStack width="full" justifyContent="space-between" alignItems="flex-start">
            <VStack width="100%">
              <CreateProjectForm
                partnerList={partnerList}
                projectTypes={projectTypes}
                technologyList={technologiesList}
                currencyList={currencyList}
                countryList={countriesList}
                methods={methods}
              />
            </VStack>
            <VStack width="300px" height="100%">
              <LogoProject
                type="logo"
                name="logo"
                afterUploadHandler={(value) => {
                  setValue('logo', value)
                }}
              />
              <SettingProject />
            </VStack>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  )
}

export default observer(ProjectCreate)
