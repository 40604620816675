import React, { useEffect, useState } from 'react'
import { Text, VStack, SimpleGrid, Container, Button, HStack } from '@chakra-ui/react'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import FormInput from 'componentsCharka/FormInput'
import Helper from 'Helper'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useStores } from 'utils/hooks/useStores'
import ClientBillItemList from '../ClientBillItemList/index'
import styles from './index.module.scss'

const CreateClientBillForm = (props) => {
  const {
    partnerList,
    projectsList,
    methods,
    userList,
    deleteNewRowOfClientBillItemHandler,
    addNewRowOfClientBillItemHandler,
    clientBillData,
  } = props

  const { watch, getValues } = methods
  const history = useHistory()
  const partnerSelect = watch('partnerId')
  const [projectsOptions, setProjectsOptions] = useState([])
  const partnersOptions = Helper.createOptionsOfReactSelectFromDB(partnerList)
  const projectsInBillItem: [] = watch('includedProjects') !== undefined ? watch('includedProjects') : []
  const defaultValueOfPublishedDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
  const { adminClientBillStore } = useStores()
  const [previousBillNumber, setPreviousBillNumber] = useState('')

  const { clientBillList, totalBill } = adminClientBillStore
  useEffect(() => {
    adminClientBillStore.getClientBillList(true, history, '')
  }, [])
  function generateDefaultData() {
    const allValuesOfForm = getValues()
    adminClientBillStore.getDefaultDataOfTable(allValuesOfForm)
  }
  function updateDefaultData(field: string, index: number) {
    const allValuesOfForm = getValues()
    adminClientBillStore.updateDefaultDataOfTable(field, index, allValuesOfForm)
  }
  useEffect(() => {
    var currentBill
    const projectsListOfPartner = projectsList?.filter((project) => {
      return project.partnerId === partnerSelect?.value
    })

    const billsOfPartner = clientBillList?.filter((bill) => {
      return bill.partnerId === partnerSelect?.value
    })

    if (billsOfPartner?.length > 0) {
      currentBill = clientBillList?.reduce((r, a) => {
        return r.publishedDate > a.publishedDate ? r : a
      })

      setPreviousBillNumber(currentBill === null ? '' : currentBill?.billNumber)
    } else {
      setPreviousBillNumber('')
    }

    setProjectsOptions(Helper.createOptionsOfReactSelectFromDB(projectsListOfPartner))
  }, [partnerSelect])
  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack background="white" padding={{ base: 6, lg: 6 }} borderRadius="8px">
        <Text alignSelf={'flex-start'} className={styles.headerBox}>
          General Information
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
          <ChakraInputDropdown
            name="partnerId"
            label="Belongs To Partner *"
            optionsData={partnersOptions}
            placeHolder={'- Select Partner -'}
          />
          <FormInput
            name="publishedDate"
            label="Published Date *"
            type="date"
            defaultValue={defaultValueOfPublishedDate}
          />
          <FormInput
            name="billNumber"
            label="Bill Number *"
            isRequired
            placeholder="Enter Bill Number"
            helperText={`Previous bill number: ${previousBillNumber}`}
            helperTextShow
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
          <ChakraInputDropdown isMulti name="includedProjects" label="Projects" optionsData={projectsOptions} />
        </SimpleGrid>
        <HStack>
          <Button
            bg={'#009900'}
            minWidth={'none'}
            isFullWidth
            variant="solid"
            onClick={generateDefaultData}
            type="button">
            Generate Default Table
          </Button>
        </HStack>

        <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
          Bill Detail Information
        </Text>
        <ClientBillItemList
          isEdit={true}
          projectsInBillItem={projectsInBillItem}
          userList={userList}
          deleteRowHandler={deleteNewRowOfClientBillItemHandler}
          updateDefaultDataHandler={updateDefaultData}
        />
        <p className={styles.totalBill}>
          Total: <span>{totalBill}</span>
        </p>
        <Button
          colorScheme="blue"
          variant="outline"
          type="button"
          onClick={addNewRowOfClientBillItemHandler}
          _hover={{
            cursor: 'pointer',
          }}>
          Add New Row
        </Button>
      </VStack>
    </Container>
  )
}
export default CreateClientBillForm
