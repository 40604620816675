export const defaultExcludedList = [
  'createdBy',
  'createdAt',
  'updatedBy',
  'updatedAt',
  '__v',
  'partner',
  'partners',
  'projects',
  'projectsOfSupervisor',
  'id',
  'actions',
  'partnerId',
  'password',
  'updatedBy',
  'createdBy',
  'logo',
]
