import React from 'react'
import { useMediaQuery, chakra, Tr, Td, useDisclosure } from '@chakra-ui/react'
import { ITableHeader } from 'components/Table'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import ResponsiveTable from 'componentsCharka/ResponsiveTable'
import TableWithInputCell from 'componentsCharka/Table/TableWithInputCell'
import get from 'lodash/get'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { getValidArray, checkValidArray } from 'utils'
import { RoutesConstants, Messages } from 'constants/index'
import styles from '../../../index.module.scss'
import { getHourlyProjectHeaderList } from '../constants'
import EditProjectRatingModal from '../EditProjectRatingModal'

interface IHourlyProjectRatingProps {
  name: string
  isEdit?: boolean
}
const HourlyProjectRating = (props: IHourlyProjectRatingProps) => {
  const { name, isEdit } = props
  const history = useHistory()
  const nameOfData: string = `${name}.hourlyRatingPrices`
  const headerGroupData: ITableHeader[] = getHourlyProjectHeaderList()
  const { control, register } = useFormContext()
  const [isTablet] = useMediaQuery('(max-width: 45em)')
  const { fields } = useFieldArray({
    control,
    name: nameOfData, // unique name for your Field Array
  })
  const { isOpen, onClose, onOpen } = useDisclosure()

  function goToProjectDetail(projectId: string): void {
    if (projectId) {
      const pathname = generatePath(RoutesConstants.PROJECT_ADMIN_DETAIL, { id: projectId })
      history.push(pathname)
    }
  }

  return (
    <chakra.form width="full">
      {!isTablet ? (
        <React.Fragment>
          {!fields?.length && <EmptyContentBox text={Messages.noData} />}
          {checkValidArray(fields) && (
            <TableWithInputCell headersGroup={headerGroupData}>
              {getValidArray(fields).map((field, index) => {
                const itemId: string = get(field, 'project.value', '')
                return (
                  <Tr key={field.id}>
                    <Td className="can-hover" onClick={() => goToProjectDetail(itemId)}>
                      <input
                        className={`${styles.projectRatingInputField} can-hover`}
                        {...register(`${nameOfData}.${index}.project.label`)}
                        disabled={true}
                      />
                    </Td>
                    <Td>
                      <input
                        className={styles.projectRatingInputField}
                        key={field.id} // important to include key with field's id
                        {...register(`${nameOfData}.${index}.hourlyRate`)}
                        disabled={!isEdit}
                      />
                    </Td>
                    <Td>
                      <input
                        className={styles.projectRatingInputField}
                        key={field.id} // important to include key with field's id
                        {...register(`${nameOfData}.${index}.clientRate`)}
                        disabled={!isEdit}
                      />
                    </Td>
                    <Td>
                      <input
                        className={styles.projectRatingInputField}
                        type="date"
                        key={field.id} // important to include key with field's id
                        {...register(`${nameOfData}.${index}.applyDate`)}
                        disabled={!isEdit}
                      />
                    </Td>
                    {/* *INFO: This will be integrated later */}
                    {/* <Td>
                      <EditButton onClick={onOpen} />
                    </Td> */}
                  </Tr>
                )
              })}
            </TableWithInputCell>
          )}
        </React.Fragment>
      ) : (
        <ResponsiveTable headersGroup={headerGroupData}>
          {!fields?.length && <EmptyContentBox text={Messages.noData} />}
          {getValidArray(fields).map((field, index) => {
            return (
              <Tr key={field.id}>
                <Td aria-label="Project">
                  <input
                    className={styles.projectRatingInputField}
                    {...register(`${nameOfData}.${index}.project.label`)}
                    disabled={!isEdit}
                  />
                </Td>
                <Td aria-label="Hourly rate" bg={'white'}>
                  <input
                    className={styles.projectRatingInputField}
                    key={field.id} // important to include key with field's id
                    {...register(`${nameOfData}.${index}.hourlyRate`)}
                    disabled={!isEdit}
                  />
                </Td>
                <Td aria-label="Client rate" bg={'white'}>
                  <input
                    className={styles.projectRatingInputField}
                    key={field.id} // important to include key with field's id
                    {...register(`${nameOfData}.${index}.clientRate`)}
                    disabled={!isEdit}
                  />
                </Td>
                <Td aria-label="Apply date">
                  <input
                    className={styles.projectRatingInputField}
                    type="date"
                    key={field.id} // important to include key with field's id
                    {...register(`${nameOfData}.${index}.applyDate`)}
                    disabled={!isEdit}
                  />
                </Td>
                {/* *INFO: This will be integrated later */}
                {/* <Td aria-label="Apply date">
                  <EditButton onClick={onOpen} />
                </Td> */}
              </Tr>
            )
          })}
        </ResponsiveTable>
      )}
      {isOpen && <EditProjectRatingModal isOpen={isOpen} onClose={onClose} />}
    </chakra.form>
  )
}

export default HourlyProjectRating
