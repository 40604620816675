import api from 'API'
import { handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import { IPolicy } from 'constants/schema'
import { POLICY_BASE_URL, IPolicyListResponse, ICreatePolicyResponse } from './constants'

export async function getPolicyListAdmin(): Promise<IPolicyListResponse> {
  try {
    const response: AxiosResponse = await api.get(`${POLICY_BASE_URL}/list-admin`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/policy', 'getPolicyListAdmin')
    return {} as IPolicyListResponse
  }
}

export async function updatePolicy(payload: IPolicy, policyId: string): Promise<void> {
  try {
    await api.post(`${POLICY_BASE_URL}/update/${policyId}`, payload)
  } catch (error) {
    handleError(error as Error, 'API/policy', 'updatePolicy')
  }
}

export async function deletePolicy(policyId: string): Promise<void> {
  try {
    await api.post(`${POLICY_BASE_URL}/delete/${policyId}`)
  } catch (error) {
    handleError(error as Error, 'API/policy', 'deletePolicy')
  }
}

export async function getPolicyList(): Promise<IPolicyListResponse> {
  try {
    const response: AxiosResponse = await api.get(`${POLICY_BASE_URL}/list`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/policy', 'getPolicyList')
    return {} as IPolicyListResponse
  }
}

export async function createPolicy(payload: IPolicy): Promise<ICreatePolicyResponse> {
  try {
    const url: string = `${POLICY_BASE_URL}/create`
    const response: AxiosResponse = await api.post(url, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/policy', 'createPolicy')
    return {} as ICreatePolicyResponse
  }
}
