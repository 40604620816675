import { FormControl, FormLabel } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { PhoneInputField } from './phoneNumberInput.styles'

export interface IPhoneNumberInputProps {
  name: string
  label: string
}

// TODO: Upgrade this component when add dial code to phone number in the future
const PhoneNumberInput = (props: IPhoneNumberInputProps) => {
  const { name, label } = props
  const { register } = useFormContext()
  // *INFO: Maybe use later when add dial code to phone number
  // const formattedPhoneNumber: string | undefined = useWatch({ name, control })
  // const phoneNumber: string = formattedPhoneNumber?.split(' ')[1]?.replace(/-/g, '') ?? ''
  // const countryCode: string = phoneNumber?.split(' ')[0] ?? ''
  // const [country, setCountry] = useState<string>(countries[0].dialCode)
  // const [code, setCode] = useState<string>()

  // useEffect(() => {
  //   setCode(countries.find((country: ICountry) => country.dialCode === countryCode)?.code ?? '')
  // }, [])

  // useEffect(() => {
  //   if (country !== '' || phoneNumber !== '') {
  //     setValue(name, `(${country}) ${phoneNumber}`)
  //   }
  // }, [country, phoneNumber])

  // function onCountryClick(codeNum: string): void {
  //   const parsedNumber = new AsYouType().input(`(${country}) ${phoneNumber}`)
  //   setCode(countries.find((country: ICountry) => country.dialCode === codeNum)?.code ?? '')
  //   setCountry(codeNum)
  //   setValue(name, parsedNumber)
  // }

  // function onPhoneNumberChange(): void {
  //   const parsedNumber = new AsYouType().input(`(${country}) ${phoneNumber}`)
  //   setValue(name, parsedNumber)
  // }

  return (
    <FormControl>
      {label && (
        <FormLabel color="gray.700" fontWeight={400}>
          {label}
        </FormLabel>
      )}
      <PhoneInputField {...register(name)} />
      {/* // *INFO: Maybe use later when add dial code to phone number */}
      {/* <InputGroup height={10}>
        <InputLeftElement width="6rem">
          <Menu closeOnSelect={true} autoSelect={false} computePositionOnMount>
            {({ isOpen }) => (
              <>
                <CountrySelectButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon color="black" />}>
                  {true ? <Flag code={'VN'} style={{ height: '24px', width: '32px' }} /> : <PhoneIcon />}
                </CountrySelectButton>
                <CountryList>
                  {countries.map((state: ICountry) => (
                    <CountryItem key={state.name} onClick={() => onCountryClick(state.dialCode)}>
                      <Flag code={state.code} style={{ width: '20px' }} />
                      <CountryName>{`${state.name}`}</CountryName>
                      <CountryDialCode>{`(${state.dialCode})`}</CountryDialCode>
                    </CountryItem>
                  ))}
                </CountryList>
              </>
            )}
          </Menu>
        </InputLeftElement>
      </InputGroup> */}
    </FormControl>
  )
}

export default PhoneNumberInput
