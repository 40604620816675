import AdminClientBillStore from './admin/clientBillStore'
import AdminContractStore from './admin/contractStore'
import AdminDiagramStore from './admin/diagramStore'
import AdminPartnerStore from './admin/partnerStore'
import AdminPaymentStore from './admin/paymentStore'
import AdminProjectStore from './admin/projectStore'
import AdminUserStore from './admin/userStore'
import AuthStore from './authstore'
import LayoutStore from './layoutStore'
import UserContractStore from './user/contractStore'
import UserPaymentStore from './user/paymentStore'
import UserProfileStore from './user/profileStore'
import UserProjectStore from './user/projectStore'
import UserTimesheetStore from './user/timesheetStore'

class RootStore {
  // ADMIN stores
  adminPaymentStore: AdminPaymentStore
  adminUserStore: AdminUserStore
  adminProjectStore: AdminProjectStore
  adminClientBillStore: AdminClientBillStore
  adminContractStore: AdminContractStore
  adminPartnerStore: AdminPartnerStore
  adminDiagramStore: AdminDiagramStore
  // USER stores
  userPaymentStore: UserPaymentStore
  userProfileStore: UserProfileStore
  userProjectStore: UserProjectStore
  userContractStore: UserContractStore
  userTimesheetStore: UserTimesheetStore
  // Common stores
  authStore: AuthStore
  layoutStore: LayoutStore

  constructor() {
    // ADMIN stores
    this.adminPaymentStore = new AdminPaymentStore(this)
    this.adminUserStore = new AdminUserStore(this)
    this.adminProjectStore = new AdminProjectStore(this)
    this.adminClientBillStore = new AdminClientBillStore(this)
    this.adminContractStore = new AdminContractStore(this)
    this.adminPartnerStore = new AdminPartnerStore(this)
    this.adminDiagramStore = new AdminDiagramStore(this)
    // USER stores
    this.userPaymentStore = new UserPaymentStore(this)
    this.userProfileStore = new UserProfileStore(this)
    this.userProjectStore = new UserProjectStore(this)
    this.userContractStore = new UserContractStore(this)
    this.userTimesheetStore = new UserTimesheetStore(this)
    // Common stores
    this.authStore = new AuthStore(this)
    this.layoutStore = new LayoutStore(this)
  }
}

export default RootStore

export const rootStore = new RootStore()
