import { ESettingType } from 'API/setting/constants'
import camelCase from 'lodash/camelCase'
import { Messages } from '../../../constants'
import { ESettingActionType } from './constants'

export function getSettingActionMessage(action: ESettingActionType, type: ESettingType): string {
  const defaultType: string = type.replace(/-/g, ' ')
  const messageName = `${action} ${defaultType} Success`
  const messageKey: string = camelCase(messageName)
  return Messages[messageKey]
}
