import apiV2 from 'API/apiV2'
import { ISuccessResponse, IResponseWithMessage } from 'API/constants'
import { getErrorMessage, handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import { EContractTypes } from 'constants/enum'
import { IContract } from 'constants/schema'
import { CONTRACT_BASE_URL, IContractListResponse, IContractPayload, IContractDetailResponse } from './constant'

export async function litsOfContract(): Promise<ISuccessResponse<IContractListResponse>> {
  try {
    return apiV2.get(`${CONTRACT_BASE_URL}/list`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getContractListOfUser(userId: string): Promise<IContractListResponse> {
  try {
    const response: AxiosResponse = await apiV2.get(`${CONTRACT_BASE_URL}/list-of-agiven-user/${userId}`)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function deleteContract(contractId: string): Promise<IResponseWithMessage> {
  try {
    const response: AxiosResponse = await apiV2.get(`${CONTRACT_BASE_URL}/delete/${contractId}`)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function activeContract(contractId: string): Promise<IResponseWithMessage> {
  try {
    const response: AxiosResponse = await apiV2.get(`${CONTRACT_BASE_URL}/active/${contractId}`)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function deActiveContract(contractId: string): Promise<IResponseWithMessage> {
  try {
    const response: AxiosResponse = await apiV2.get(`${CONTRACT_BASE_URL}/deactive/${contractId}`)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function downloadContractFile(
  contractId: string,
  filename: string,
  acceptType: string
): Promise<BlobPart> {
  try {
    const url = `${CONTRACT_BASE_URL}/download-file-from-admin/${contractId}/${filename}`
    const response: AxiosResponse = await apiV2.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: acceptType,
      },
    })
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function showContractFile(contractId: string, filename: string, acceptType: string): Promise<BlobPart> {
  try {
    const url = `${CONTRACT_BASE_URL}/show-file-from-admin/${contractId}/${filename}`
    const response: AxiosResponse = await apiV2.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: acceptType,
      },
    })
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getContractListAdmin(type: EContractTypes): Promise<IContractListResponse> {
  try {
    const response: AxiosResponse = await apiV2.get(`${CONTRACT_BASE_URL}/list-of-admin/${type}`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'APIV2/contract', 'getContractListAdmin')
    return {} as IContractListResponse
  }
}

export async function createContract(payload: IContractPayload): Promise<IContract> {
  try {
    const response: AxiosResponse = await apiV2.post(`${CONTRACT_BASE_URL}/create`, payload)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getContractDetail(contractId: string): Promise<IContractDetailResponse> {
  try {
    const response: AxiosResponse = await apiV2.get(`${CONTRACT_BASE_URL}/detail-of-admin/${contractId}`)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function updateContract(payload: IContractPayload, contractId: string): Promise<IResponseWithMessage> {
  try {
    const response: AxiosResponse = await apiV2.post(`${CONTRACT_BASE_URL}/update/${contractId}`, payload)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function publishContract(
  contractId: string,
  payload: Pick<IContract, 'fileName' | 'partnerId'>
): Promise<void> {
  try {
    await apiV2.post(`${CONTRACT_BASE_URL}/${contractId}/publish`, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
