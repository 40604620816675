import React from 'react'
import MultiRef from 'react-multi-ref'
import * as Yup from 'yup'

import FormModel from '../../../components/FormModel'
import FeedbackDetail from './components/FeedbackDetail'
import { AppConfig, Messages, EventName } from '../../../constants'
import { getUserListAdmin, getUserList } from 'API/user'
import { getFeedbackListAdmin, updateFeedback, updateAdminIsRead, deleteFeedback } from 'API/feedback'
import './index.scss'
import { handleError } from 'API/error'

const validationSchema = Yup.object().shape({
  receiver: Yup.array().required(Messages.fieldRequired),
  question: Yup.string()
    .required(Messages.fieldRequired)
    .max(AppConfig.LIMIT_CHARACTERS_OF_STRING, Messages.exceedCharacterOfString),
  name: Yup.string().required(Messages.fieldRequired),
})
class FeedbackListAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      feedbacks: [],
      selectedFeedback: null,
      admins: [],
      users: null,
    }
  }
  componentDidMount() {
    this.getListAdminsHandler()
    this.getListUsersHandler()
    this.getListFeedbackHandler()
  }
  getListUsersHandler = async () => {
    try {
      const response = await getUserList()
      this.setState({
        users: response?.data?.users,
      })
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackListAdmin/index.js', 'getListUsersHandler')
    }
  }
  getListAdminsHandler = async () => {
    try {
      const response = await getUserListAdmin()
      this.setState({
        admins: response?.admins,
      })
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackListAdmin/index.js', 'getListAdminsHandler')
    }
  }

  _actRefs = new MultiRef()

  getListFeedbackHandler = async () => {
    try {
      const response = await getFeedbackListAdmin()
      this.setState({
        feedbacks: response?.feedbacks,
      })
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackListAdmin/index.js', 'getListFeedbackHandler')
    }
  }

  handleToggleActiveEdit = (id) => {
    this.setState({
      selectedFeedback: id,
    })
  }

  editFeedbackHandler = async (values) => {
    try {
      const feedbackID = values._id
      await updateFeedback(feedbackID, values)
      this.props.handleOpenSnackbar({ variant: 'success', message: Messages.updateFeedbackSuccess })
      this.handleToggleActiveEdit(null)
      this.getListFeedbackHandler()
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackListAdmin/index.js', 'editFeedbackHandler')
    }
  }

  switchIsReadHandler = async (event, id) => {
    try {
      const currentIsReadValue = event.target.checked
      const feedbackID = id
      await updateAdminIsRead(feedbackID, { isRead: currentIsReadValue })
      this.getListFeedbackHandler()
      this.props.getUnreadFeedbackNumber()
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackListAdmin/index.js', 'switchIsReadHandler')
    }
  }

  handleClickOKOfDialogConfirm = async (id, type = null) => {
    try {
      await deleteFeedback(id)
      this.props.handleCloseConfirmDialogYesNo()
      this.props.handleOpenSnackbar({ variant: 'success', message: Messages.deleteFeedbackSuccess })
      this.getListFeedbackHandler()
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackListAdmin/index.js', 'handleClickOKOfDialogConfirm')
    }
  }
  callOpenDialogConfirm = (data) => {
    document.dispatchEvent(
      new CustomEvent(EventName.openDeleteFeedbackConfirmDialogYesNo, {
        detail: {
          isOpen: true,
          OKClickHandler: this.handleClickOKOfDialogConfirm,
          item: data,
        },
      })
    )
  }
  deleteFeedbackHandler = (feedback) => {
    this.callOpenDialogConfirm(feedback)
  }
  resetFormWhenPressCancel = (id) => {
    this._actRefs.map.get(id).resetForm()
    this.setState({
      selectedFeedback: null,
    })
  }
  render() {
    const { feedbacks, selectedFeedback, admins, users } = this.state
    return (
      <div className="container feedback-list-page">
        <div>
          <div className="feedback-list">
            {feedbacks &&
              feedbacks.map((item) => {
                const viewModeFeedbackDetail = selectedFeedback === item._id ? 'edit' : 'show'
                return (
                  <div className="project-detail-sub" key={item._id}>
                    <FormModel
                      refComponent={this._actRefs.ref(item._id)}
                      initialValues={item}
                      handleSubmit={this.editFeedbackHandler}
                      schema={validationSchema}
                      component={
                        <FeedbackDetail
                          admins={admins}
                          users={users}
                          viewMode={viewModeFeedbackDetail}
                          cancelForm={this.resetFormWhenPressCancel}
                          handleToggleActiveEdit={this.handleToggleActiveEdit}
                          handleDelete={this.deleteFeedbackHandler}
                          switchIsReadHandler={this.switchIsReadHandler}
                          switchButtonLabel="Is Read"
                        />
                      }
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}

export default FeedbackListAdmin
