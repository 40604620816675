import React from 'react'
import FormModel from '../../../../components/FormModel/index'
import ApiHelper from '../../../../Helper/api'
import CommitTimeReportTable from '../../components/CommitTimeReportTable'
import FilterForm from '../../components/FilterForm/FilterFormOfCommitTimeReport'
import { initialValues, validationFilterUserSchema, validationFilterProjectSchema } from './constants'
import { getProjectList } from 'API/project'
import { getUserList } from 'API/user'
import { handleError } from 'API/error'
import { getCommitTimeReportsAdmin } from 'API/commit-time'

class CommitTimeReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      projects: [],
      selectedUser: undefined,
      commitTimeReportList: [],
      validationSchema: validationFilterUserSchema,
      selectedTypeOfFiler: undefined,
      selectedProject: undefined,
      isShowProjectList: false,
      isShowUserList: false,
    }
  }
  componentDidMount() {
    this.getListUsersHandler()
    this.getListProjectsHandler()
    window.sessionStorage.setItem('subPageTitle', 'Report')
    window.dispatchEvent(new Event('storage'))
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem('subPageTitle')
    this.setState = () => {
      return
    }
  }

  selectedTypeOfFilerHandler = (value) => {
    let validationSchema
    let isShowProjectList = false
    let isShowUserList = false
    switch (value) {
      case 'filter by project': {
        isShowProjectList = true
        validationSchema = validationFilterProjectSchema
        break
      }
      case 'filter by user': {
        isShowUserList = true
        validationSchema = validationFilterUserSchema

        break
      }
      default: {
        return null
      }
    }
    this.setState({
      isShowProjectList,
      isShowUserList,
      validationSchema,
    })
  }

  getListProjectsHandler = async () => {
    try {
      const response = await getProjectList()
      this.setState({
        projects: response.data.projects,
      })
    } catch (error) {
      handleError(
        error,
        'src/containers/CommitTime/CommitTimeAdmin/CommitTimeReport/index.js',
        'getListProjectsHandler'
      )
    }
  }
  getListUsersHandler = async () => {
    try {
      const response = await getUserList()
      this.setState({
        users: response.data.users,
      })
    } catch (error) {
      handleError(error, 'src/containers/CommitTime/CommitTimeAdmin/CommitTimeReport/index.js', 'getListUsersHandler')
    }
  }

  filterSubmitHandler = async (values) => {
    try {
      const { user, project } = values
      const payload = {
        ...values,
        user: user.value,
        project: project.value,
      }
      const { commitTimeReportList } = await getCommitTimeReportsAdmin(payload)
      this.setState({ commitTimeReportList })
    } catch (error) {
      handleError(error, 'src/containers/CommitTime/CommitTimeAdmin/CommitTimeReport/index.js', 'filterSubmitHandler')
    }
  }

  render() {
    const { users, commitTimeReportList, projects, validationSchema, isShowProjectList, isShowUserList } = this.state
    return (
      <div className="timesheet-page container">
        <div>
          <FormModel
            initialValues={initialValues}
            schema={validationSchema}
            handleSubmit={this.filterSubmitHandler}
            component={
              <FilterForm
                users={users}
                projects={projects}
                selectedTypeOfFilerHandler={this.selectedTypeOfFilerHandler}
                isShowProjectList={isShowProjectList}
                isShowUserList={isShowUserList}
                {...this.props}
              />
            }
          />
        </div>
        <div className="timesheet-content">
          {commitTimeReportList && commitTimeReportList.length > 0 && (
            <CommitTimeReportTable data={commitTimeReportList} />
          )}
        </div>
      </div>
    )
  }
}

export default CommitTimeReport
