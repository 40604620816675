import React from 'react'

import ApiHelper from '../../../../Helper/api'
import { Messages } from '../../../../constants'
import CommitTimeRequestTable from '../../components/CommitTimeRequestTable'
import RejectRequestDialog from '../../components/RejectRequestDialog'
import { getCommitTimeRequestListAdmin, rejectCommitTimeRequest, approveCommitTimeRequest } from 'API/commit-time'
import { handleError } from 'API/error'
import { toast } from 'react-toastify'

class CommitTimeRequestAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commitTimeRequestList: [],
      isOpenRejectRequestDialog: false,
      selectedCommitTimeRequest: undefined,
    }
  }
  componentDidMount() {
    this.getCommitTimeRequestList()
    window.sessionStorage.setItem('subPageTitle', 'Request')
    window.dispatchEvent(new Event('storage'))
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem('subPageTitle')
    this.setState = () => {
      return
    }
  }

  getCommitTimeRequestList = async () => {
    try {
      const { commitTimeRequestList } = await getCommitTimeRequestListAdmin()
      this.setState({ commitTimeRequestList })
    } catch (error) {
      handleError(
        error,
        'src/containers/CommitTime/CommitTimeAdmin/CommitTimeRequest/index.js',
        'getCommitTimeRequestList'
      )
    }
  }

  closeRejectRequestDialog = () => {
    this.setState({
      isOpenRejectRequestDialog: false,
    })
  }

  OKClickHandlerOfRejectRequestDialog = async (data) => {
    try {
      const { commitTimeRequestList } = await rejectCommitTimeRequest(data)
      this.setState({ commitTimeRequestList })
      this.closeRejectRequestDialog()
      toast.success(Messages.rejectRequestChangeCommitTimeSuccessfully)
    } catch (error) {
      toast.error('Reject request failed')
      handleError(
        error,
        'src/containers/CommitTime/CommitTimeAdmin/CommitTimeRequest/index.js',
        'OKClickHandlerOfRejectRequestDialog'
      )
    }
  }

  rejectRequestHandler = (commitTimeRequestId) => {
    const { commitTimeRequestList } = this.state
    const selectedCommitTimeRequest = commitTimeRequestList.find((item) => {
      return item._id === commitTimeRequestId
    })
    this.setState({
      isOpenRejectRequestDialog: true,
      selectedCommitTimeRequest,
    })
  }

  approvedRequestHandler = async (commitTimeRequestId) => {
    try {
      const { commitTimeRequestList } = await approveCommitTimeRequest(commitTimeRequestId)
      this.setState({ commitTimeRequestList })
      toast.success(Messages.approveRequestChangeCommitTimeSuccessfully)
    } catch (error) {
      toast.error('Approve request failed')
      handleError(
        error,
        'src/containers/CommitTime/CommitTimeAdmin/CommitTimeRequest/index.js',
        'approvedRequestHandler'
      )
    }
  }

  render() {
    const { commitTimeRequestList, isOpenRejectRequestDialog, selectedCommitTimeRequest } = this.state
    return (
      <div className="timesheet-page container">
        <div className="timesheet-content">
          <div className="container-fluid">
            <div className="box box-default">
              <div className="box-header">Commit Time Request List</div>
              <div className="box-divider" />
              <div className={`box-body`}>
                {commitTimeRequestList?.length > 0 && (
                  <CommitTimeRequestTable
                    data={commitTimeRequestList}
                    rejectHandler={this.rejectRequestHandler}
                    approvedHandler={this.approvedRequestHandler}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <RejectRequestDialog
          isOpen={isOpenRejectRequestDialog}
          closeHandler={this.closeRejectRequestDialog}
          OKClickHandler={this.OKClickHandlerOfRejectRequestDialog}
          item={selectedCommitTimeRequest}
        />
      </div>
    )
  }
}

export default CommitTimeRequestAdmin
