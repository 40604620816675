import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'

const styles = {
  colorSwitchBase: {
    '&$colorChecked': {
      color: green[400],
      '& + $colorBar': {
        backgroundColor: green[400],
      },
    },
    '&$colorDisabled': {
      cursor: 'not-allowed',
    },
  },
  colorBar: {},
  colorChecked: {},
  colorDisabled: {},
}

class CustomizeRadioButton extends React.PureComponent {
  render() {
    const {
      field,
      label,
      classes,
      disabled,
      form: { touched, errors },
      labelPlacement,
    } = this.props

    return (
      <FormControlLabel
        label={label}
        className="radio_label"
        labelPlacement={labelPlacement}
        control={
          <Switch
            error={touched[field?.name] && errors[field?.name] ? 'true' : 'false'}
            id={field?.name}
            value={field?.value || false}
            checked={field?.value || false}
            onChange={disabled ? null : field?.onChange}
            classes={{
              switchBase: classes.colorSwitchBase,
              checked: classes.colorChecked,
              bar: classes.colorBar,
              disabled: classes.colorDisabled,
            }}
          />
        }
      />
    )
  }
}

CustomizeRadioButton.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  labelPlacement: PropTypes.string,
}

export default withStyles(styles)(CustomizeRadioButton)
