import { FormControl, InputGroup, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import { GroupBase, CreatableSelect } from 'chakra-react-select'
import { useFormContext, Controller } from 'react-hook-form'
import { borderColor, boxShadow } from 'theme/globalStyles'
import { IOption } from '../types'
import { IChakraInputDropdownProps } from '../types'

const SingleSelect = (props: IChakraInputDropdownProps) => {
  const {
    name,
    label,
    optionsData,
    isDisabled,
    placeHolder,
    isRequire,
    widthCustom,
    isClearable = true,
    isDisabledClearIndicator,
    isCreatable,
    isFocused,
  } = props
  const { control } = useFormContext()


  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: isRequire ? 'This field is required.' : false }}
      render={({ field: { onChange, name, ref, value }, fieldState: { error } }) => (
        <FormControl width="full" isInvalid={!!error} id={name}>
          {label && (
            <FormLabel fontWeight={'nomal'} mb={2} color="gray.700">
              {label}
            </FormLabel>
          )}
          <InputGroup
            borderRadius="6px"
            width={{
              sm: widthCustom ? widthCustom : '100px',
              md: widthCustom ? widthCustom : 'full',
            }}
            bg="white">
            <CreatableSelect<IOption, true, GroupBase<IOption>>
              name={name}
              ref={ref}
              placeholder={placeHolder}
              isDisabled={isDisabled}
              colorScheme="gray.700"
              value={value}
              options={optionsData}
              isValidNewOption={(value) => isCreatable && !!value}
              closeMenuOnSelect={true}
              size="md"
              isClearable={isClearable}
              onChange={onChange}
              chakraStyles={{
                container: (provided: Record<string, unknown>) => ({
                  ...provided,
                  width: 'full',
                }),
                control: (provided: Record<string, unknown>) => ({
                  ...provided,
                }),
                dropdownIndicator: (provided: Record<string, unknown>) => ({
                  ...provided,
                  bg: 'transparent',
                  px: 2,
                  cursor: 'pointer',
                  color: 'gray.700',
                }),
                indicatorSeparator: (provided: Record<string, unknown>) => ({
                  ...provided,
                  display: 'none',
                }),
                clearIndicator: (provided: Record<string, unknown>) => ({
                  ...provided,
                  color: 'gray.700',
                  background: 'transparent',
                  cursor: 'pointer',
                  display: isDisabledClearIndicator ? 'none' : 'flex',
                }),
                menu: (provided: Record<string, unknown>) => ({
                  ...provided,
                  border: `1px solid ${borderColor}`,
                  boxShadow: boxShadow,
                }),
                option: (provided: Record<string, unknown>) => ({
                  ...provided,
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                }),
              }}
            />
          </InputGroup>
          <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  )
}

export default SingleSelect
