import { EProjectType } from 'enums/developerOnProject'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { IDeveloperOnProject } from 'types/developerOnProject'
import { IOption, IProjectsInfo } from 'types/user'
import { getValidArray } from 'utils'
import { EUserStatusLabel } from 'constants/enum'
import { IUser } from 'constants/schema'

export function getUserStatusLabel(user: IUser): string {
  if (user?.isArchived) {
    return EUserStatusLabel.ARCHIVED
  }

  if (user?.isDeleted) {
    return EUserStatusLabel.DELETED
  }

  return ''
}

export function generateUserOptions(users: IUser[]): IOption[] {
  const sortedUsers: IUser[] = orderBy(getValidArray(users), ['isDeleted', 'isArchived'], ['asc', 'asc'])

  return getValidArray(sortedUsers).map<IOption>((user: IUser) => {
    const fullName: string = user?.fullName ?? ''
    const statusLabel: string = getUserStatusLabel(user)

    return {
      value: user?._id,
      label: `${fullName}${statusLabel && ` (${statusLabel}) `}`,
    }
  })
}

export function getUserLabel(user: IUser): string {
  const statusLabel: string = getUserStatusLabel(user)
  const fullName: string = user?.fullName ?? ''

  return `${fullName}${statusLabel && ` (${statusLabel}) `}`
}

export function getProjectsIdsByProjectType(
  developerOnProjects: IDeveloperOnProject[],
  projectType?: EProjectType
): string[] {
  let filteredProjects: IDeveloperOnProject[] = []

  if (projectType) {
    filteredProjects = getValidArray(developerOnProjects).filter((item: IDeveloperOnProject) => {
      return item?.projectType === projectType
    })
  } else {
    filteredProjects = developerOnProjects
  }

  return map(filteredProjects, 'project') ?? []
}

export function getSupervisorProjectIds(developerOnProjects: IDeveloperOnProject[]): string[] {
  const filteredDeveloperOnProjects: IDeveloperOnProject[] = getValidArray(developerOnProjects).filter(
    (item: IDeveloperOnProject) => {
      return item?.isSupervisor
    }
  )
  return map(filteredDeveloperOnProjects, 'project') ?? []
}

export function getProjectsInfo(user: IUser): IProjectsInfo {
  const developerOnProjectsData: IDeveloperOnProject[] = user?.developerOnProjectsData ?? []

  return {
    projects: getProjectsIdsByProjectType(developerOnProjectsData),
    externalProjects: getProjectsIdsByProjectType(developerOnProjectsData, EProjectType.EXTERNAL_PROJECT),
    extraProjects: getProjectsIdsByProjectType(developerOnProjectsData, EProjectType.EXTRA_PROJECT),
    supervisorProjects: getSupervisorProjectIds(developerOnProjectsData),
  }
}
