import { Box, MenuItem, Text } from '@chakra-ui/react'
import { backgroundGrey100 } from 'theme/globalStyles'
interface IDropdownSelectionProps {
  onClick: () => void
  label: string | number
  isSelected?: boolean
  width?: number
  minWidth?: number
  maxWidth?: number | string
  isTable?: boolean
  color?: string
  borderColor?: string
  hasIcon?: boolean
}

const DropdownSelection = (props: IDropdownSelectionProps) => {
  const {
    onClick,
    label,
    isSelected,
    width,
    minWidth,
    maxWidth,
    isTable,
    color = 'red',
    borderColor = 'blue',
    hasIcon,
  } = props
  return (
    <MenuItem
      padding="8px 16px"
      height="40px"
      width={width ?? { base: '400px', lg: '100%' }}
      minWidth={minWidth}
      maxWidth={maxWidth}
      onClick={onClick}
      //*INFO: teal.500 with opacity: 0.15
      _hover={{ background: backgroundGrey100 }}
      background={isSelected ? backgroundGrey100 : 'none'}
      paddingLeft={isTable ? { base: '45px', md: '16px' } : 4}>
      {hasIcon && (
        <Box
          width={3}
          height={3}
          borderRadius="50%"
          background={color}
          border="1px solid"
          borderColor={borderColor}
          margin={4}></Box>
      )}
      <Text color="gray.700" fontSize="md" lineHeight={6} margin={0}>
        {label}
      </Text>
    </MenuItem>
  )
}

export default DropdownSelection
