import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'

export function getPageTitle(path: string): string {
  const pathElements: string[] = `${path ?? ''}`.split('/')
  const pagePath: string = pathElements[1]
  const pageTitle: string = startCase(camelCase(pagePath.replace('-', ' ')))

  return pageTitle
}
