import { ECreatableOptionScope, ECreatableOptionTypes } from 'constants/enum'
import { ICreatableOption } from 'constants/schema'

export const CREATABLE_OPTION_BASE_URL: string = '/creatable-option'

export interface ICreatableOptionFilter {
  scope?: ECreatableOptionScope
  type?: ECreatableOptionTypes
}

export interface ICreatableOptionListWithFilterRequest {
  filter: ICreatableOptionFilter
}

export interface ICreatableOptionListWithFilterResponse {
  creatableOptions: ICreatableOption[]
}
