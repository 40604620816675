import { Button, Stack, HStack, Text, VStack, SimpleGrid } from '@chakra-ui/react'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import FormInput from 'componentsCharka/FormInput'
import GroupRadio from 'componentsCharka/GroupRadio'
import Helper from 'Helper'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { getValidArray } from 'utils'
import { UserRole } from 'constants/defaultOptionList'
import { ECreatableOptionTypes, EPaymentCategoryOfUser, EYesNoOptionWithCapitalization } from 'constants/enum'
import { RoutesConstants, Messages } from 'constants/index'
import { ICreatableOption } from 'constants/schema'
import { ICreateUserFormProps, isSupervisor, isActive, isDowloadCV } from './constants'
import styles from './index.module.scss'

const CreateUserForm = (props: ICreateUserFormProps) => {
  const { projectList, partnerList, submitHandler, creatableOptionList } = props
  const title: string = 'Add New User'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))
  const methods = useForm()
  const history = useHistory()
  const { handleSubmit, watch, reset } = methods
  const options = getValidArray(creatableOptionList)
  const titleOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.TITLE_OF_USER)
  )
  const levelOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.LEVEL_OF_USER)
  )
  const paymentCategoryOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER)
  )
  const partnersOptions = Helper.createOptionsOfReactSelectFromDB(partnerList)
  const projectsOptions = Helper.createOptionsOfReactSelectFromDB(projectList)
  const extraProjects: [] = watch('projects') !== undefined ? watch('projects') : []
  const extraProjectsOptions = Helper.createOptionsOfSelectFromDropdown(extraProjects)
  const externalProjects: [] = watch('projects') !== undefined ? watch('projects') : []
  const externalProjectsOptions = Helper.createOptionsOfSelectFromDropdown(externalProjects)
  const checkIsSupervisor = watch('isSupervisor')
  const paymentCategoryOfUser = watch('paymentCategoryId')
  const isFreelancerToo =
    paymentCategoryOfUser !== undefined
      ? paymentCategoryOfUser.label === EPaymentCategoryOfUser.MIXED_PAYROLL_FREELANCER_FEE
      : false
  const isReceivedPayroll =
    paymentCategoryOfUser !== undefined
      ? paymentCategoryOfUser.label === EPaymentCategoryOfUser.PAYROLL ||
        paymentCategoryOfUser.label === EPaymentCategoryOfUser.MIXED_PAYROLL_FREELANCER_FEE
      : false
  const resetForm = () => {
    reset()
    history.push(RoutesConstants.USER_LIST)
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => submitHandler(data))}>
        <VStack p={6} paddingInline={{ base: 6, lg: 6 }} paddingStart={{ base: '27px' }} spacing="30px">
          <Stack
            direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'column' }}
            className={styles.formHeaderSection}
            padding={{ base: 6, lg: 6 }}>
            <Text fontSize={'lg'} color="gray.700" margin={0}>
              New User
            </Text>
            <HStack>
              <Button
                colorScheme="blue"
                variant="outline"
                isFullWidth
                type="button"
                onClick={resetForm}
                _hover={{
                  cursor: 'pointer',
                }}>
                Cancel
              </Button>
              <Button bg={'#4D5DFB'} className={styles.saveButton} isFullWidth variant="solid" type="submit">
                Save
              </Button>
            </HStack>
          </Stack>
          <VStack
            background="white"
            padding={{ base: 6, lg: 6 }}
            width="full"
            borderRadius="8px"
            marginTop="24px !important">
            <Text alignSelf={'flex-start'} fontWeight={500}>
              User Profile Detail
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 3 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="fullName" label="Full Name*" isRequired />
              <FormInput name="email" label="Email*" isRequired />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap={{ base: 6, lg: 8 }} width="full">
              <ChakraInputDropdown
                placeHolder="-Select Partner-"
                name="partnerId"
                label="Partner"
                optionsData={partnersOptions}
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 3 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="phone" label="Phone Number" type="tel" />
              <FormInput name="dateOfBirth" label="Birth Date" type="date" />
              <FormInput name="joinDate" label="Join Date" type="date" />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, sm: 1, md: 3, lg: 2, xl: 3 }} gap={{ base: 6, lg: 8 }} width="full">
              <GroupRadio name="role" data={UserRole} label="Role" defaultValue="user" />
              <ChakraInputDropdown
                placeHolder="-Select Title-"
                name="titleId"
                label="Title *"
                isRequire
                optionsData={titleOptions}
              />
              <ChakraInputDropdown
                placeHolder="-Select Level-"
                name="levelId"
                label="Level *"
                isRequire
                optionsData={levelOptions}
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
              <ChakraInputDropdown isMulti name="projects" label="Projects" optionsData={projectsOptions} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <ChakraInputDropdown
                name="paymentCategoryId"
                label="Category of Payment*"
                isRequire
                optionsData={paymentCategoryOptions}
              />
              <ChakraInputDropdown
                isMulti
                name="extraProjects"
                isDisabled={!isFreelancerToo}
                label="Extra Projects"
                optionsData={extraProjectsOptions}
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="defaultRating" label="Default Rate*" isRequired />
              <FormInput name="fixedSalary" label="Net Salary (VND)*" disabled={!isReceivedPayroll} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <GroupRadio name="isSupervisor" data={isSupervisor} label="Is Supervisor" defaultValue="No" />
              <ChakraInputDropdown
                isDisabled={!(checkIsSupervisor === EYesNoOptionWithCapitalization.YES)}
                isMulti
                name="projectsOfSupervisor"
                label="Projects Of Supervisor"
                optionsData={extraProjectsOptions}
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
              <ChakraInputDropdown
                isMulti
                name="externalProjects"
                label="External Projects"
                optionsData={externalProjectsOptions}
                helperText={Messages.externalProjectNote}
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <GroupRadio name="allowToDownloadCV" data={isDowloadCV} label="Is Dowload CV" defaultValue="Yes" />
              <GroupRadio name="isActive" data={isActive} label="Is Active" defaultValue="Yes" />
            </SimpleGrid>
          </VStack>
        </VStack>
      </form>
    </FormProvider>
  )
}
export default CreateUserForm
