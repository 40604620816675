import { useEffect } from 'react'
import {
  Button,
  SimpleGrid,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  HStack,
  Box,
} from '@chakra-ui/react'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import get from 'lodash/get'
import { FormProvider, useForm } from 'react-hook-form'

export interface IFilterForm {
  openModalFilterForm?: boolean
  setOpenFilterForm: (status: boolean) => void
  partnersList?
  filterSubmit?
}

const FilterForm = (props: IFilterForm) => {
  const { openModalFilterForm, setOpenFilterForm, partnersList, filterSubmit } = props

  const methods = useForm()
  const { register, handleSubmit, reset } = methods
  const filterValuesOfProjects = window.localStorage.getItem('filterValuesOfProjects')
  const filterValuesOfProjectsJSON = JSON.parse(filterValuesOfProjects)
  const partnerId = get(filterValuesOfProjectsJSON, 'selectedPartnerId', [])

  function closeModal(): void {
    setOpenFilterForm(false)
  }
  useEffect(() => {
    reset({
      partnerId: partnerId,
    })
  }, [])
  return (
    <HStack spacing={4} alignSelf="flex-start" mb={6} display={{ base: 'none', md: 'flex' }}>
      <Drawer isOpen={openModalFilterForm} placement="right" onClose={closeModal} size={'md'}>
        <DrawerOverlay />
        <FormProvider {...methods}>
          <form className="full-form" onSubmit={handleSubmit((data) => filterSubmit(data))}>
            <DrawerContent>
              <DrawerCloseButton onClick={closeModal} />
              <DrawerHeader>Filter</DrawerHeader>
              <div className="box-divider" />
              <DrawerBody>
                <SimpleGrid width="full" marginBottom={5}>
                  <ChakraInputDropdown isMulti name="partnerId" label="Belong To Partner" optionsData={partnersList} />
                </SimpleGrid>
              </DrawerBody>
              <div className="box-divider" />
              <DrawerFooter justifyContent={'space-between'}>
                <Button colorScheme="blue" variant="ghost" mr={3}>
                  Reset
                </Button>
                <Box>
                  <Button variant="outline" mr={3} onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button colorScheme="blue" type="submit">
                    Apply Filter
                  </Button>
                </Box>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </FormProvider>
      </Drawer>
    </HStack>
  )
}

export default FilterForm
