import React from 'react'
import './style.scss'

import LogoURL from '../../assets/images/logo/logo.svg'
import UserCV from './UserCV'
import { AppConfig } from '../../constants'

class ShareCV extends React.Component {
  render() {
    const { params } = this.props.match
    const fileId = params.fileId
    const userId = params.userId
    let enabledDownload = false
    const enabledShareCV = params.codeShare
    if (enabledShareCV === AppConfig.ENABLED_DOWNLOAD_CV) {
      enabledDownload = true
    }
    return (
      <div className="share-cv-page">
        <div className="header">
          <img src={LogoURL} alt="company-logo-mini" />
          <span>Avengers</span>
        </div>
        <div className="body">
          <div className="cv-content">
            <UserCV
              // cvFileName='FILE-CV-5c9cec5030a1b92192af7c1d-1566637769160.pdf'
              cvFileName={fileId}
              userId={userId}
              enabledShareCV={enabledDownload}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ShareCV
