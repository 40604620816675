import { StyledButton } from './button.style'

interface IActionButtonProps {
  title: string
  type?: 'button' | 'submit'
  onClick?: () => void
  buttonStyles?: Record<string, unknown>
}

const ActionButton = (props: IActionButtonProps) => {
  const { title, type, onClick, buttonStyles } = props
  return (
    <StyledButton type={type} onClick={onClick} {...buttonStyles}>
      {title}
    </StyledButton>
  )
}

export default ActionButton
