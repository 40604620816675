import React from 'react'
import QueueAnim from 'rc-queue-anim'

import ProjectItem from './components/ProjectItem'
import { getProjectList } from 'API/project/index'
import { handleError } from 'API/error'

import './index.scss'

class ProjectsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      project: [],
    }
  }
  componentDidMount() {
    this.getListProjectsHandler()
  }
  componentWillUnmount() {
    this.setState = () => {
      return
    }
  }
  getListProjectsHandler = async () => {
    try {
      const response = await getProjectList()
      this.setState({
        projects: response.data.projects,
      })
    } catch (error) {
      handleError(error, 'src/containers/ProjectsNew/ProjectList/index.js', 'getListProjectsHandler')
    }
  }
  render() {
    const { projects } = this.state
    return (
      <div className="container products-list-page">
        <div>
          <div className="project-list">
            <QueueAnim type="bottom" className="ui-animate">
              <div className="container">
                <div className="items">
                  {projects && projects.map((item) => <ProjectItem key={item._id} data={item} />)}
                </div>
              </div>
            </QueueAnim>
          </div>
        </div>
      </div>
    )
  }
}

export default ProjectsList
