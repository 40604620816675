import React, { useState } from 'react'
import { VStack, HStack } from '@chakra-ui/layout'
import { backgroundPrimary } from 'theme/globalStyles'
import { TabButton } from '../projectRating.styles'
import FixedProjectTable from './components/FixedProjectTable'
import HourlyProjectTable from './components/HourlyProjectTable'

interface IMobileProjectRatingTableProps {
  name: string
  isEdit: boolean
}

const MobileProjectRatingTable = (props: IMobileProjectRatingTableProps) => {
  const { name, isEdit } = props
  const [isHourlyTab, setIsHourlyTab] = useState<boolean>(true)

  function changeHourlyTab(): void {
    setIsHourlyTab(true)
  }

  function changeFixedTab(): void {
    setIsHourlyTab(false)
  }

  return (
    <VStack>
      <HStack width="full" paddingX={0}>
        <TabButton
          color={isHourlyTab ? 'white' : 'gray.500'}
          backgroundColor={isHourlyTab ? `${backgroundPrimary} !important` : 'white'}
          onClick={changeHourlyTab}>
          Hourly Project
        </TabButton>
        <TabButton
          color={isHourlyTab ? 'gray.500' : 'white'}
          backgroundColor={isHourlyTab ? 'white' : `${backgroundPrimary} !important`}
          onClick={changeFixedTab}>
          Fixed Project
        </TabButton>
      </HStack>
      {isHourlyTab && <HourlyProjectTable isEdit={isEdit} name={`${name}.hourlyProjectRatings.hourlyRatingPrices`} />}
      {!isHourlyTab && <FixedProjectTable isEdit={isEdit} name={`${name}.fixedProjectRatings.fixedRatingPrices`} />}
    </VStack>
  )
}

export default MobileProjectRatingTable
