import React from 'react'
import { FaEnvelope } from 'react-icons/fa'

import Helper from '../../../Helper'
import { getPolicyList } from 'API/policy'
import { handleError } from 'API/error'

class PolicyList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      policies: [],
    }
  }
  componentDidMount() {
    this.getListPolicyHandler()
  }
  getListPolicyHandler = async () => {
    try {
      const { policies } = await getPolicyList()
      this.setState({ policies })
    } catch (error) {
      handleError(error, 'src/containers/Policy/PolicyList/index.js', 'getListPolicyHandler')
    }
  }
  render() {
    const { policies } = this.state
    const filterredInfos = Helper.filterEvent(policies)
    return (
      <div className="infos-page">
        <div className="infos-content">
          <div className="timeline-container">
            {Array.isArray(filterredInfos) &&
              filterredInfos.map((info, index) => (
                <div key={index}>
                  <div className="timeline-label">
                    <span>{info.period}</span>
                  </div>
                  {eventList(info.data)}
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}

function eventList(events) {
  return (
    Array.isArray(events) &&
    events.map((event) => (
      <div key={event._id} className="timeline-item">
        <span className="icon-sub">
          <FaEnvelope className="icon" />
        </span>
        <div className="timeline-content">
          <span className="time">{Helper.getFormattedDate(event.publishedDate)}</span>
          <h3 className="timeline-header">{event.title}</h3>
          <div className="timeline-body">{event.content}</div>
        </div>
      </div>
    ))
  )
}

export default PolicyList
