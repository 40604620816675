import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'

const styles = (theme) => ({
  input: {
    color: 'rgba(0,0,0, 0.87)',
    '&$disabled': {
      cursor: 'not-allowed',
    },
  },
  disabled: {},
})
class PasswordInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'password',
      showPassword: false,
    }
  }
  handleClickToogleShowHidePassword = () => {
    const { showPassword } = this.state
    const newTriggerShowPassword = !showPassword
    this.setState({
      type: newTriggerShowPassword ? 'text' : 'password',
      showPassword: newTriggerShowPassword,
    })
  }
  render() {
    const {
      onChange,
      classes,
      placeholder = '',
      label,
      multiline,
      row,
      name,
      field,
      disabled,
      required,
      form: { touched, errors },
      rows,
      ...rest
    } = this.props
    const { type } = this.state
    return (
      <div className="input-field">
        <TextField
          error={Boolean(touched[field.name] && errors[field.name])}
          label={label}
          className="input"
          InputProps={{
            classes: {
              input: classes.input,
              disabled: classes.disabled,
            },
            inputProps: {
              step: rest.step || '1',
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility" onClick={this.handleClickToogleShowHidePassword}>
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          id={field.name}
          value={field.value || ''}
          onChange={onChange || field.onChange}
          onKeyPress={rest.onKeyPress}
          onBlur={rest.onBlur || field.onBlur}
          maxLength={100}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          fullWidth
          multiline={multiline}
          row={row}
        />
        <span className="error-message">{touched[field.name] && errors[field.name]}</span>
      </div>
    )
  }
}

export default withStyles(styles)(PasswordInput)
