import { getValidArray, checkValidArray } from 'utils'
import { IOption } from 'constants/common'

export function findOptionByValue(options: IOption[], value?: string): IOption | undefined {
  return getValidArray(options).find((option: IOption) => option.value === value)
}

export function getValueContainerLabel(options: IOption[]): string {
  if (!checkValidArray(options)) {
    return ''
  }
  if (options?.length === 1) {
    return `${options[0].label}`
  }
  if (options?.length === 2) {
    return `${options[0].label}, ${options[1].label}`
  }
  return `${options?.[0].label}, ${options?.[1].label}, +${options?.length - 2} others`
}
