import { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import CreatableSelect from 'react-select/creatable'

export default class CreatableSingle extends Component {
  handleChange = (newValue) => {
    const {
      form: { setFieldValue },
      field,
    } = this.props
    setFieldValue(field.name, newValue)
  }
  render() {
    const {
      label,
      field,
      options = [],
      disabled,
      form: { errors, touched },
      isMulti = false,
      isClearable = true,
      placeholder = 'Select...',
      styles,
    } = this.props
    return (
      <FormControl className="select-field">
        <label className="label-autocomplete-select">{label}</label>
        <CreatableSelect
          isClearable={isClearable}
          isMulti={isMulti}
          className="creatable-select"
          onChange={this.handleChange}
          options={options}
          value={field.value}
          placeholder={placeholder}
          isDisabled={disabled}
          styles={styles}
        />
        <span className="error-message">{touched[field.name] && errors[field.name]}</span>
      </FormControl>
    )
  }
}
