import React from 'react'
import { Messages } from '../../../../../constants'
import Helper from '../../../../../Helper'

class EditCommitTimeDialogContent extends React.Component {
  render() {
    const { data, inputValueChangeHandler } = this.props
    let currentCommitTime = Helper.getFormattedNumber(data.commitTime, 1)
    if (currentCommitTime === '-') {
      currentCommitTime = 0
    }
    return (
      <div className="confirm-dialog">
        <p className="warning-message">{Messages.editCommitTimeDialogWarning}</p>
        <p className="confirm-message">
          {Messages.editCommitTimeDialogContentPart1}{' '}
          <span className="highlight">{currentCommitTime} (hours per week)</span>
        </p>
        <div className="confirm-delete-again">
          <p className="guide-message">{Messages.editCommitTimeDialogContentPart2}</p>
          <div className="field">
            <label>Value</label>
            <input className="delete-input-field" type="number" onChange={(event) => inputValueChangeHandler(event)} />
          </div>
        </div>
      </div>
    )
  }
}

export default EditCommitTimeDialogContent
