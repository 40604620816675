import { useEffect, useState } from 'react'
import { handleError } from 'API/error'
import { isFeatureEnabled } from 'API/featureFlag'
import { EFeatureFlags } from 'constants/enum'

function useFeatureFlag(name: EFeatureFlags): { isEnabled?: boolean } {
  const flagName: string = `${process.env.REACT_APP_ENVIRONMENT}_${name}`
  const [isEnabled, setIsEnabled] = useState<boolean>()

  async function fetchFeatureFlag(): Promise<void> {
    try {
      const response: boolean = await isFeatureEnabled(flagName)
      setIsEnabled(response)
    } catch (error) {
      handleError(error, 'src/utils/hooks/useFeatureFlag.ts', 'useFeatureFlag')
    }
  }

  useEffect(() => {
    if (name) {
      fetchFeatureFlag()
    }
  }, [name])

  return { isEnabled }
}

export default useFeatureFlag
