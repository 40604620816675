import { ReactNode } from 'react'
import { VStack } from '@chakra-ui/react'
import NoDataText from 'componentsCharka/NoDataText'
import { useFormContext, useWatch } from 'react-hook-form'
import { FieldValue, FieldTitle } from './formFieldWithLabel.styles'
import { generateValidValue } from './utils'

export interface IFormFieldWithLabelProps {
  name: string
  label: string
  isEdit?: boolean
  isHighlighted?: boolean
  children?: ReactNode
}

const FormFieldWithLabel = (props: IFormFieldWithLabelProps) => {
  const { name, label, isEdit, isHighlighted, children } = props
  const { control } = useFormContext()
  const fieldValue = useWatch({ name, control })
  const value: string | number | undefined = generateValidValue(fieldValue)

  return (
    <VStack alignItems="start">
      {!isEdit && <FieldTitle>{label}</FieldTitle>}
      {children ? (
        children
      ) : value ? (
        <FieldValue color={isHighlighted ? 'blue.500' : 'gray.700'}>{value}</FieldValue>
      ) : (
        <NoDataText />
      )}
    </VStack>
  )
}

export default FormFieldWithLabel
