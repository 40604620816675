import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'formik'

import * as Yup from 'yup'

import Helper from '../../../Helper'
import { Messages, AppConfig, RoutesConstants } from '../../../constants'
import FormModel from '../../../components/FormModel'
import Input from '../../../components/Input'
import RadioButton from '../../../components/RadioButton'
import MultipleSelect from '../../../components/Select/MultipleSelect'
import { getUserListAdmin } from 'API/user'
import { createFeedback } from 'API/feedback'

import './index.scss'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
  menu: {
    width: 200,
  },
})

const FeedbackCreateForm = (props) => {
  const { classes, dirty, isSubmitting, handleSubmit, admins } = props
  const adminsOptions = Helper.createOptionsOfSelectFromDB(admins)
  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="box box-default">
          <div className="box-header">Create New Feedback</div>
          <div className="box-divider" />
          <div className={`box-body`}>
            <div className="container-fluid">
              <div className="fields">
                <div className="field">
                  <Field
                    id="name"
                    name="name"
                    label="Name*"
                    className={classes.textField}
                    margin="normal"
                    component={Input}
                  />
                </div>
                <div className="field">
                  <Field
                    id="receiver"
                    name="receiver"
                    label="Receiver*"
                    className={classes.textField + '98width'}
                    margin="normal"
                    options={adminsOptions}
                    component={MultipleSelect}
                    data={admins}
                  />
                </div>
                <div className="field radio-button">
                  <Field
                    id="anonymous"
                    name="anonymous"
                    label="Anonymous"
                    className={classes.textField}
                    margin="normal"
                    component={RadioButton}
                  />
                </div>
              </div>
              <div className="fields">
                <div className="field">
                  <Field
                    id="question"
                    name="question"
                    label="Question*"
                    className={classes.textField}
                    multiline
                    row={3}
                    margin="normal"
                    component={Input}
                  />
                </div>
              </div>
              <div className="options-sub">
                <button
                  className="create-new-policyrmation-button primary-button"
                  type="submit"
                  disabled={!dirty && isSubmitting}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const initialValues = {
  anonymous: false,
  receiver: [],
  question: '',
  name: '',
}

const validationSchema = Yup.object().shape({
  receiver: Yup.array().required(Messages.fieldRequired),
  question: Yup.string()
    .required(Messages.fieldRequired)
    .max(AppConfig.LIMIT_CHARACTERS_OF_STRING, Messages.exceedCharacterOfString),
  name: Yup.string().required(Messages.fieldRequired),
})
class FeedbackCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      admins: [],
    }
  }
  componentDidMount() {
    this.getListAdminsHandler()
  }
  getListAdminsHandler = async () => {
    try {
      const response = await getUserListAdmin()
      this.setState({
        admins: response?.admins,
      })
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackCreate/index.js', 'getListAdminsHandler')
    }
  }
  submitHandler = async (values) => {
    try {
      if (values.anonymous) {
        values.name = 'Anonymous'
      }
      const { history } = this.props
      await createFeedback(values)
      history.push(RoutesConstants.FEEDBACK)
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackCreate/index.js', 'submitHandler')
    }
  }
  render() {
    const { admins } = this.state
    return (
      <div className="policy-create-page">
        <FormModel
          initialValues={initialValues}
          schema={validationSchema}
          handleSubmit={this.submitHandler}
          component={<FeedbackCreateForm admins={admins} {...this.props} />}
        />
      </div>
    )
  }
}

FeedbackCreate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FeedbackCreate)
