import { useState } from 'react'
import { Flex, Box, VStack } from '@chakra-ui/react'
import LoginBackground from 'components/LoginBackground'
import { TitleLogin } from 'components/TitleLogin'
import ForgotPasswordForm from './components/ForgotPasswordForm'

const ForgotPassword = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  return (
    <Flex h="100vh" direction={{ base: 'column', md: 'row' }}>
      <LoginBackground />
      <Flex flex={1} align="center" justify="center" bg="white" px="6">
        <VStack spacing="6" w="full" maxW="400px">
          <TitleLogin
            title="Forgot Password"
            subtitle={
              isSubmitted
                ? 'Please check your email and follow the instruction to reset your password'
                : 'Please submit your email and we will send you a link to reset your password '
            }
          />
          {!isSubmitted && (
            <Box w="full">
              <ForgotPasswordForm setIsSubmitted={setIsSubmitted} />
            </Box>
          )}
        </VStack>
      </Flex>
    </Flex>
  )
}

export default ForgotPassword
