import React from 'react'
import Helper from '../../../../Helper'

class SummarizeTable extends React.Component {
  render() {
    const { data } = this.props
    return (
      <div className="table-content">
        <table className="report-table">
          <thead>
            <tr>
              <th>Commit time total (Hours/week)</th>
              <th>Of All Projects</th>
              <th>Of Specific Project</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Helper.getFormattedNumber(data.commitTimeTotal, 1)}</td>
              <td>{Helper.getFormattedNumber(data.commitTimeOfAllProject, 1)}</td>
              <td>{Helper.getFormattedNumber(data.commitTimeOfSpecificProject, 1)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default SummarizeTable
