import { handleError } from 'API/error'
import { filterPaymentsUser, editPaymentUser, sendRequest } from 'API/payment'
import { IFilterPaymentsPayload } from 'API/payment/constants'
import { makeAutoObservable } from 'mobx'
import { IPaymentList } from 'types/payment'
import { IProject } from 'types/project'
import RootStore from '../rootStore'

class UserPaymentStore {
  rootStore: RootStore
  projects: IProject[]
  payments: IPaymentList[]

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async filterPayments(payload: IFilterPaymentsPayload): Promise<void> {
    try {
      const { paymentsList } = await filterPaymentsUser(payload)
      this.payments = paymentsList
    } catch (error) {
      handleError(error, 'src/store/user/paymentStore.ts', 'filterPaymentsUser')
    }
  }

  public async editPayment(id: string, field: string, currentValue): Promise<void> {
    try {
      const { paymentsList } = await editPaymentUser({
        id,
        field,
        value: !currentValue,
      })
      this.payments = paymentsList
    } catch (error) {
      handleError(error, 'src/store/user/paymentStore.ts', 'editPaymentUser')
    }
  }

  public async sendRequest(id: string, reason: string): Promise<void> {
    try {
      await sendRequest({ id, reason })
    } catch (error) {
      handleError(error, 'src/store/user/paymentStore.ts', 'sendRequest')
    }
  }
}

export default UserPaymentStore
