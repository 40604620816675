import { useEffect } from 'react'
import { Text, VStack, SimpleGrid, Container } from '@chakra-ui/react'
import CheckboxFieldWithLabel from 'componentsCharka/CheckboxFieldWithLabel'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import FormInput from 'componentsCharka/FormInput'
import GroupRadio from 'componentsCharka/GroupRadio'
import Helper from 'Helper'
import { observer } from 'mobx-react'
import { PaymentCategories } from 'constants/defaultOptionList'
import styles from './index.module.scss'

const ProjectEditForm = (props) => {
  const { userList, partnerList, projectTypes, countryList, technologyList, currencyList, methods } = props
  const title: string = 'Project Detail'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))
  const partnersOptions = Helper.createOptionsOfReactSelectFromDB(partnerList)
  const userListOptions = Helper.createOptionsOfReactSelectFromDB(userList)
  const projectTypesOptions = Helper.createOptionsOfReactSelectFromDB(projectTypes)
  const countryListOptions = Helper.createOptionsOfReactSelectFromDB(countryList)
  const technologyListOptions = Helper.createOptionsOfReactSelectFromDB(technologyList)
  const currencyOptions = Helper.createOptionsOfReactSelectFromDB(currencyList)
  const { watch, setValue } = methods

  const isCheckUntilNow = watch('untilNow')

  useEffect(() => {
    if (isCheckUntilNow) {
      setValue('endedDate', null)
    }
  }, [isCheckUntilNow])
  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack
        background="white"
        padding={{ base: 6, lg: 6 }}
        // spacing={{ base: 6, lg: 8 }}
        // width="full"
        borderRadius="8px">
        <Text alignSelf={'flex-start'} className={styles.headerBox}>
          General Information
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="name" label="Project Name" isRequired placeholder="Enter Project name" />
          <FormInput name="companyName" label="Company Name (Optional)" placeholder="Enter Company name" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput
            name="companyWebsite"
            label="Company Website (Optional)"
            isWebsite
            placeholder="example.com"
            type="tel"
          />
          <FormInput name="companyPhone" label="Company Phone Number (Optional)" type="tel" />
          <ChakraInputDropdown
            name="country"
            label="Country"
            placeHolder="- Select country -"
            optionsData={countryListOptions}
            isRequire
          />
          {/* <ChakraInputDropdown name="city" label="City (Optional)" placeHolder="- Select City -" optionsData={[]} /> */}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="address" label="Address Detail (Optional)" placeholder="Enter Address detail" />
        </SimpleGrid>
        <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
          Customer Information
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="customer" label="Customer Name" placeholder="Enter Customer name" isRequired />
          <FormInput name="customerEmail" label="Customer Email (Optional)" placeholder="Enter Customer email" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="customerPhone" label="Customer Phone Number (Optional)" />
        </SimpleGrid>
        <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
          Payment
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <GroupRadio name={'paymentCategory'} isEdit data={PaymentCategories} label="Category of Payment" />
          <ChakraInputDropdown
            name="currency"
            label="Currency"
            placeHolder={'- Select Currency - '}
            optionsData={currencyOptions}
            isRequire
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="price" label="Price" placeholder="Enter Price" />
          <FormInput name="paymentPeriod" label="Payment Period (days)" placeholder="Enter Payment Period" isRequired />
        </SimpleGrid>
        <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
          Working Information
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="startedDate" label="Start Date" type="date" isRequired />
          {!isCheckUntilNow && <FormInput name="endedDate" label="End Date (Optional)" type="date" />}
          <CheckboxFieldWithLabel name="untilNow" label="Until Now" />
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2 }}
          spacingY="10px"
          gap={{ base: 6, lg: 8 }}
          width="full"></SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput
            name="commitedHours"
            label="Commited Hours (hrs/week) (Optional)"
            placeholder="Enter Commited hours"
          />
          <ChakraInputDropdown
            // isDisabled={!isSupervisor}
            isMulti
            name="technology"
            label="Technology (Optional)"
            optionsData={technologyListOptions}
            placeHolder={'- Select Technology -'}
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="neededDeveloperNumber" label="Needed Developer Number (Optional)" />
          <ChakraInputDropdown
            // isDisabled={!isSupervisor}
            isMulti
            name="userId"
            label="Working Developers (Optional)"
            optionsData={userListOptions}
            isDisabled={true}
            placeHolder={'- Select User -'}
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <ChakraInputDropdown
            // isDisabled={!isSupervisor}
            name="partnerId"
            label="Belongs To Partner"
            optionsData={partnersOptions}
            placeHolder={'- Select Partner -'}
            isRequire
          />
          <ChakraInputDropdown
            // isDisabled={!isSupervisor}
            name="type"
            label="Project Type (Optional)"
            optionsData={projectTypesOptions}
            placeHolder={'- Select Project Type -'}
          />
        </SimpleGrid>
      </VStack>
    </Container>
  )
}
export default observer(ProjectEditForm)
