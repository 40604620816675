import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'

export interface IConfirmDeleteModal {
  data?: any
  isOpen?: boolean
  title?: string
  content?: string
  closeHandler?
  OKClickHandler?: (id: string) => void
}

const ConfirmDeleteModal = (props: IConfirmDeleteModal) => {
  const { isOpen, title, content, closeHandler, OKClickHandler, data } = props
  const [isCorrectName, setCorrectName] = useState<boolean>(false)

  useEffect(() => {
    setCorrectName(false)
  }, [isOpen])

  function inputOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value
    setCorrectName(inputValue === data.shortName ? true : false)
  }
  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered>
      <ModalOverlay />
      <ModalContent maxHeight="278px">
        <ModalHeader>Delete {title}</ModalHeader>
        <ModalCloseButton color={'gray'} background="none" />
        <ModalBody paddingY={0}>
          <Text marginY={0}>Are you sure you want to delete this {title}? You can’t undo this action afterwards.</Text>
          <FormControl>
            <FormLabel fontWeight={'normal'} marginTop={4}>
              Please type <b>{data?.shortName}</b> in the <b>{content}</b> to confirm
            </FormLabel>
            <Input autoFocus={true} placeholder={content} onChange={inputOnChange} boxSizing="border-box" />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button background={'white'} variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button onClick={() => OKClickHandler(data._id)} colorScheme="red" disabled={isCorrectName ? false : true}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmDeleteModal
