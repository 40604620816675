import axios, { AxiosResponse } from 'axios'
import errorHandler from 'Helper/errorHandler'
import { IFeatureFlagData } from 'types/featureFlag'
import { AppConfig } from 'constants/index'

export async function isFeatureEnabled(featureName: string): Promise<boolean> {
  try {
    const response: AxiosResponse = await axios.get(`${AppConfig.FEATURE_FLAG_API_URL}${featureName}`, {
      headers: {
        Authorization: `Bearer ${AppConfig.FEATURE_FLAG_API_ACCESS_TOKEN}`,
      },
    })
    const responseData: IFeatureFlagData = response?.data
    return responseData?.active
  } catch (error) {
    errorHandler(error)
    throw error
  }
}
