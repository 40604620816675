import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import LogoURL from '../assets/images/logo/logo.svg'
import { Months, AppConfig, RoutesConstants, Messages } from '../constants'
import { activities } from '../constants/activityInfos'
import activityRoutes from '../constants/activityRoutes'
import { getValidArray } from 'utils'
import { generatePath } from 'react-router-dom'

const helper = {
  getClassOfFixedProjectRatingColumn: function (item) {
    const remunerationPerHours = item.remuneration / item.workingTime
    const fixedProjectRating = item.fixedProjectRating
    let result = 'normal'
    if (remunerationPerHours > fixedProjectRating) {
      result = 'error'
    }
    return result
  },
  generateImageURL: function (url, type) {
    if (url !== 'undefined' && url !== undefined) {
      if (AppConfig.API_URL.includes('localhost')) {
        return this.generateImagePath(url)
      }
      return `/images/${url}`
    }
    switch (type) {
      case 'logo':
        return '/assets/images/no-image-available.jpg'
      case 'avatar':
        return '/assets/images/no-image-avatar.png'
      default:
        return ''
    }
  },
  generateImagePath: function (url) {
    if (url) {
      return `${AppConfig.IMAGES_STORAGE_URL}/${url}`
    }
  },
  getFormattedDate: function (currentDate) {
    if (currentDate) {
      return moment(currentDate).format('MM/DD/YYYY')
    }
    const date = moment().format('YYYY-MM-DD')
    return date
  },
  getFormattedDateWith_DDMMYYYY: function (currentDate) {
    return currentDate ? moment(currentDate).format('DD/MM/YYYY') : ''
  },
  getFormattedDateWith_YYYYMMDD: function (currentDate) {
    if (currentDate) {
      return moment(currentDate).format('YYYY-MM-DD')
    }
    const date = moment().format('YYYY-MM-DD')
    return date
  },
  getDecimalFromTime(time) {
    return time
      .split(':')
      .map(function (value) {
        return parseInt(value, 10)
      })
      .reduce((previousValue, currentValue, index) => previousValue + currentValue / Math.pow(60, index))
  },
  getFormattedDateInEnglish: function (currentDate) {
    if (currentDate) {
      return moment(currentDate).format('MMM Do YYYY ')
    }
    return '-'
  },
  getFormattedDateTimeInEnglish: function (currentDate) {
    if (currentDate) {
      return moment(currentDate).format('MMM Do YYYY HH:mm')
    }
    return '-'
  },
  getFormattedNumber: function (number, character = 3) {
    let format
    switch (character) {
      case 0: {
        format = '0,0'
        break
      }
      case 1: {
        format = '0,0.0'
        break
      }
      case 2: {
        format = '0,0.00'
        break
      }
      case 3: {
        format = '0,0.000'
        break
      }
      case 4: {
        format = '0,0.0000'
        break
      }
      default:
        return null
    }
    if (number) {
      return numeral(number).format(format)
    }
    return '-'
  },
  getFormattedNumberOfChartData: function (number, character = 3) {
    let format
    switch (character) {
      case 0: {
        format = '0,0'
        break
      }
      case 1: {
        format = '0,0.0'
        break
      }
      case 2: {
        format = '0,0.00'
        break
      }
      case 3: {
        format = '0,0.000'
        break
      }
      default:
        return null
    }
    if (number) {
      return numeral(number).format(format)
    }
    return 0
  },
  getFormattedSalary: function (number) {
    if (number) {
      return numeral(number).format('0,0')
    }
    return '-'
  },
  getValue: function (number) {
    if (number) {
      return numeral(number).value()
    }
    return 0
  },
  getFormattedDateTime: function (currentDate) {
    if (currentDate) {
      return moment(currentDate).format('MMM Do YYYY HH:mm')
    }
    const date = moment().format('YYYY-MM-DD')
    return date
  },
  getFormattedRateExchange: function (rateExchange, currency) {
    if (rateExchange) {
      return `${rateExchange} VND/${currency}`
    }
    return '-/-'
  },
  createOptionsOfSelect: function (dataArray) {
    let result = []
    dataArray.forEach((item) => {
      result.push({
        value: item,
        label: item,
        text: item,
      })
    })
    return result
  },
  createOptionsOfSelectFromDB: function (dataArray) {
    if (!dataArray) {
      return []
    }
    let result = []
    dataArray.forEach((item) => {
      result.push({
        value: item._id,
        text: item.value || item.name || item.fullName,
      })
    })
    return result
  },
  createOptionsOfSelectFromDropdown: function (dataArray) {
    if (!dataArray) {
      return []
    }
    let result = []
    dataArray.forEach((item) => {
      result.push({
        value: item.value || item._id,
        label: item.label || item.name || item.fullName,
      })
    })
    return result
  },
  getValueFromOption: function (dataArray) {
    return _.map(getValidArray(dataArray), 'value')
  },
  createOptionsOfOptionsFromDB: function (dataArray) {
    if (!dataArray) {
      return []
    }
    let result = []
    dataArray.forEach((item) => {
      result.push({
        value: item.label,
        label: item.label,
        text: item.label,
      })
    })
    return result
  },
  createOptionsOfReactSelectFromDB: function (dataArray) {
    if (!dataArray) {
      return []
    }
    let result = []
    dataArray.forEach((item) => {
      result.push({
        value: item._id,
        label: item.value || item.name || item.fullName,
      })
    })
    return result
  },
  getItemFromOptionData: function (neededItemId, dataArray) {
    if (!dataArray) {
      return {}
    }
    return dataArray.find((item) => item.value === neededItemId)
  },
  createAsyncOptionsOfSelect: function (dataArray) {
    let result = []
    dataArray.forEach((item) => {
      result.push({
        value: item,
        text: item,
      })
    })
    return { options: result }
  },
  createAsyncOptionsOfSelectFromDB: function (dataArray) {
    if (!dataArray) {
      return []
    }
    let result = []
    dataArray.forEach((item) => {
      result.push({
        value: item._id,
        text: item.value || item.name || item.fullName,
      })
    })
    return { options: result }
  },
  createOptionsOfCheckboxFromDB: function (selectedData, data) {
    if (!selectedData) {
      return []
    }
    let result = []
    selectedData.forEach((item) => {
      const neededData = data.find((itemData) => {
        return itemData._id === item
      })
      if (neededData) {
        result.push({
          value: neededData._id,
          text: neededData.name,
        })
      }
    })
    return result
  },
  createTagsOptionsOfSelectFromDB: function (dataArray) {
    if (!dataArray) {
      return []
    }
    let result = []
    dataArray.forEach((item) => {
      result.push({
        value: item._id,
        label: item.value,
      })
    })
    return result
  },

  beforeLogoutHandler: function () {
    const isRememberMe = JSON.parse(window.localStorage.getItem('isRememberMe'))
    setTimeout(function () {
      if (!isRememberMe) {
        window.localStorage.clear()
        window.sessionStorage.clear()
      }
      window.localStorage.removeItem('userId')
      window.localStorage.removeItem('userRole')
      window.localStorage.removeItem('fullName')
      window.localStorage.removeItem('aToken')
      window.localStorage.removeItem('rToken')
      window.localStorage.removeItem('title')
      window.localStorage.removeItem('avatar')
      window.localStorage.removeItem('email')
      window.localStorage.removeItem('isRememberMe')
      window.location.reload()
    }, 300)
  },
  filterEvent: function (data) {
    const addedMonthYearData = data.map((event) => {
      event.monthYear = moment(event.publishedDate).format('MM-YYYY')
      const month = moment(event.publishedDate).month() + 1
      const monthString = Months.find((element) => {
        return element.key === month
      }).value
      const year = moment(event.publishedDate).year()
      event.monthYearString = `${monthString} ${year}`
      return event
    })
    const filteredData = _.groupBy(addedMonthYearData, 'monthYearString')
    const result = Object.keys(filteredData).map((key) => {
      return {
        period: key,
        data: filteredData[key],
      }
    })
    return result
  },
  checkAccessFE: function (aToken, rToken) {
    if (!aToken && !rToken) {
      return false
    }
    const aTokenArray = aToken.split(' ')
    let isValid = true
    if (Array.isArray(aTokenArray) && aTokenArray[0] !== rToken) {
      isValid = false
    }
    if (Array.isArray(aTokenArray) && aTokenArray[2] !== AppConfig.DEFAULT_TOKEN) {
      isValid = false
    }
    return isValid
  },
  parsedItemTimeSheet: function (items, editHandler) {
    return items.map((item) => {
      try {
        if (!(item.start_time instanceof moment)) {
          item.start_time = moment(item.start_time)
        }
        if (!(item.end_time instanceof moment)) {
          item.end_time = moment(item.end_time)
        }
        item.itemTouchSendsClick = true
        item.itemProps = {
          onDoubleClick: () => editHandler(item.id),
        }
        return item
      } catch (error) {
        throw new Error(`${error} in parsedTime function`, 'components/TimeSheetList/index.js')
      }
    })
  },
  getDateOfFilter: function (dateData) {
    const formattedDate = moment(new Date(dateData)).format('YYYY-MM')
    return formattedDate
  },
  getTitleOfTheScheduleItem: function (dateData) {
    const formattedDate = moment(new Date(dateData)).format('DD')
    const day = moment(dateData).day()
    let nameOfDay
    switch (day) {
      case 0: {
        nameOfDay = 'Sun'
        break
      }
      case 1: {
        nameOfDay = 'Mon'
        break
      }
      case 2: {
        nameOfDay = 'Tue'
        break
      }
      case 3: {
        nameOfDay = 'Wed'
        break
      }
      case 4: {
        nameOfDay = 'Thu'
        break
      }
      case 5: {
        nameOfDay = 'Fri'
        break
      }
      case 6: {
        nameOfDay = 'Sat'
        break
      }
      default: {
        break
      }
    }
    let fullDate = `${nameOfDay} (${formattedDate})`
    return fullDate
  },
  replaceDatOfWorkingSchedule: function (thisWeekData, lastWeekData) {
    const replacedData = thisWeekData.map((thisWeekItem) => {
      const dayOfThisWeek = moment(thisWeekItem.date).day()
      const neededLastweekItem = lastWeekData.find((lastWeekItem) => {
        const dayOfLastWeek = moment(lastWeekItem.date).day()
        return dayOfLastWeek === dayOfThisWeek
      })
      if (neededLastweekItem) {
        thisWeekItem.time = neededLastweekItem.time
      }
      return thisWeekItem
    })
    return replacedData
  },
  reduceDataOfRevenueChart: function (rawData) {
    const formattedData = rawData.map((data) => {
      return {
        date: data._id.startTime,
        salary: this.getFormattedNumber(data.salaryTotal, 0),
        revenue: this.getFormattedNumber(data.revenueTotal, 0),
        profit: this.getFormattedNumberOfChartData(data.revenueTotal - data.salaryTotal, 0),
      }
    })
    return formattedData
  },
  reduceDataOfChart: function (rawData, period, date, developer) {
    let initialData = []
    const startDayOfPeriod = moment(date).startOf(period)
    const endDayOfPeriod = moment(date).endOf(period)
    const duration = Math.ceil(moment.duration(endDayOfPeriod.diff(startDayOfPeriod)).asDays())
    switch (period) {
      case 'week': {
        for (let day = 0; day < 7; day++) {
          let currentDay = moment(startDayOfPeriod.add(day, 'days'))
          initialData.push({
            date: currentDay,
            totalTime: 0,
          })
        }
        break
      }
      case 'month': {
        for (let day = 1; day <= duration; day++) {
          let currentDay
          if (period === 'week') {
            day = startDayOfPeriod.daysInMonth()
          }
          currentDay = moment(date).date(day).format('YYYY-MM-DD')
          initialData.push({
            date: currentDay,
            totalTime: 0,
          })
        }
        break
      }
      default:
        return null
    }
    const processedData = initialData.map((item) => {
      const newData = rawData.find((data) => {
        return data.date === item.date
      })
      item.developerLevel = developer.level
      item.developer = developer.name
      item.formattedTotalTime = `0 hours 0 minutes`
      if (newData) {
        const minutesTotal = newData.totalTime * 60
        const hours = Math.floor(minutesTotal / 60)
        const theRestOfMinutes = minutesTotal - hours * 60
        let theFormattedRestOfMinutes = 0
        if (theRestOfMinutes > 0) {
          theFormattedRestOfMinutes = this.getFormattedNumber(theRestOfMinutes, 0)
        }
        item.totalTime = newData.totalTime
        item.formattedTotalTime = `${hours} hours ${theFormattedRestOfMinutes} minutes`
        item.tasksName = newData.taskName.join(', ').slice(0, 60) + '...'
        item.totalSalary = newData.totalSalary
        item.totalRevenue = newData.totalRevenue
        item.totalProfit = newData.totalProfit
      }
      return item
    })
    return processedData
  },
  getFormattedTaskNameList: function (taskNames) {
    if (taskNames && taskNames.length) {
      return taskNames.join('<br />')
    }
    return '-'
  },
  formattedExportHeader: function (paymentCategory, headers, isShowPrice) {
    let newHeaders = headers
    if (paymentCategory === 'Fixed Price') {
      // replace Hourly Rate by Remuneration
      newHeaders = headers.map((header) => {
        if (header.value === 'defaultRating') {
          header = { label: 'Remuneration', value: 'remuneration' }
        }
        return header
      })
    } else {
      // replace Remuneration by Hourly Rate
      newHeaders = headers.map((header) => {
        if (header.value === 'remuneration') {
          header = { label: 'Hourly Rate', value: 'defaultRating' }
        }
        return header
      })
    }
    if (!isShowPrice) {
      const removeList = ['remuneration', 'rateExchange', 'defaultRating', 'salary', 'totalSalary']
      newHeaders = headers.filter((header) => !removeList.some((item) => item === header.value))
    }
    return newHeaders
  },
  formattedExportData: function (data, isShowPrice, anonymous) {
    let workingTimeOfFresher = 0,
      workingTimeOfJunior = 0,
      workingTimeOfSenior = 0,
      workingTimeOfLeader = 0
    const formattedData = data.map((item) => {
      const paymentCategory = item.paymentCategory
      const itemDataArray = item.data
      let totalSalary = 0
      let totalWorkingTime = 0
      const formattedItemData = itemDataArray.map((itemData) => {
        const salaryValue = this.getValue(itemData.salary)
        totalSalary += salaryValue
        const totalWorkingValue = this.getValue(itemData.timeTotal)
        totalWorkingTime += totalWorkingValue
        let neededItem = {
          taskName: itemData.taskName,
          description: itemData.description,
          startTime: this.getFormattedDateTimeInEnglish(itemData.startTime),
          endTime: this.getFormattedDateTimeInEnglish(itemData.endTime),
          timeTotal: this.getFormattedNumber(itemData.timeTotal),
        }
        if (isShowPrice) {
          if (paymentCategory === 'Fixed Price') {
            neededItem['remuneration'] = itemData.remuneration
          } else {
            neededItem['defaultRating'] = itemData.defaultRating
          }
          neededItem.rateExchange = itemData.rateExchange
          neededItem.salary = itemData.salary
        }
        return neededItem
      })
      if (isShowPrice) {
        formattedItemData.push({
          totalSalary: totalSalary,
        })
      }
      formattedItemData.push({
        totalWorkingTime: totalWorkingTime,
      })
      switch (item.level) {
        case 'fresher': {
          workingTimeOfFresher += totalWorkingTime
          break
        }
        case 'junior': {
          workingTimeOfJunior += totalWorkingTime
          break
        }
        case 'senior': {
          workingTimeOfSenior += totalWorkingTime
          break
        }
        case 'lead': {
          workingTimeOfLeader += totalWorkingTime
          break
        }

        default: {
          break
        }
      }

      return {
        developer: item?.developer?.fullName,
        project: item.project,
        data: formattedItemData,
      }
    })
    if (!anonymous) {
      formattedData.push({
        type: 'totalSheet',
        data: [
          {
            fresher: workingTimeOfFresher,
            junior: workingTimeOfJunior,
            senior: workingTimeOfSenior,
            lead: workingTimeOfLeader,
          },
        ],
      })
    }

    return formattedData
  },
  getDateFromNow: function (date) {
    return moment(date).fromNow()
  },
  addItemType: function (route, itemId) {
    return generatePath(route, { id: itemId })
  },
  getRoutesOfActivity: function (code, role, itemId) {
    const currentActivityRoutes = activityRoutes.find((item) => item.keys.includes(code))
    const event = currentActivityRoutes.event
    switch (event) {
      case 'timesheet': {
        return role === 'admin'
          ? this.addItemType(RoutesConstants.TIMESHEET_ADMIN, itemId)
          : RoutesConstants.V2_TIMESHEET
      }
      case 'user': {
        return role === 'admin' ? this.addItemType(RoutesConstants.USER_DETAIL, itemId) : null
      }
      case 'project': {
        return role === 'admin'
          ? this.addItemType(RoutesConstants.PROJECT_ADMIN_DETAIL, itemId)
          : this.addItemType(RoutesConstants.PROJECT_DETAIL, itemId)
      }
      case 'feedback': {
        return role === 'admin' ? RoutesConstants.FEEDBACK_ADMIN : RoutesConstants.FEEDBACK
      }
      case 'policy': {
        return role === 'admin' ? RoutesConstants.POLICIES_ADMIN : RoutesConstants.POLICIES
      }
      case 'information': {
        return role === 'admin' ? RoutesConstants.INFOS_ADMIN : RoutesConstants.INFOS
      }
      case 'setting': {
        return role === 'admin' ? RoutesConstants.SETTINGS : null
      }
      default:
        return {}
    }
  },
  formattedNofiticationData: function (data, role) {
    return data.map((item) => {
      let content
      const activityInfo = activities[item.type]
      let attributeContent = `notificationContentOf_${role}`
      switch (item.objectType) {
        case 'timesheet': {
          content = activityInfo[attributeContent]
          break
        }
        case 'user': {
          content = activityInfo['notificationContentOf_broadCast']
          break
        }
        case 'project': {
          content = activityInfo['notificationContentOf_broadCast']
          break
        }
        case 'information': {
          content = activityInfo['notificationContentOf_broadCast']
          break
        }
        case 'policy': {
          content = activityInfo['notificationContentOf_broadCast']
          break
        }
        case 'setting': {
          content = activityInfo['notificationContentOf_admin']
          break
        }
        case 'feedback': {
          content = activityInfo[attributeContent]
          break
        }
        default:
          return null
      }
      return {
        _id: item._id,
        image: item.image,
        name: item.name,
        itemId: item.itemId,
        content: content,
        createdAt: item.createdAt,
        objectType: item.objectType,
        isRead: item.isRead,
      }
    })
  },
  showNotificationOnBrowser: function (message) {
    if (!('Notification' in window)) {
      return
    }
    let fullTitle = Messages.notifyBrowser.title
    let fullBody = Messages.notifyBrowser.updateTimesheetBody
    let options = {
      icon: LogoURL,
      body: fullBody,
    }
    // Let's check whether notification permissions have already been granted
    if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      new Notification(fullTitle, options)
    }
    // Otherwise, we need to ask the user for permission
    if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          new Notification(fullTitle, options)
        }
      })
    }
  },
  showBankingInfo: function (bankName, bankAccount) {
    if (bankName && bankAccount) {
      return `${bankAccount}-${bankName}`
    }
    return '--'
  },
  showEndDateOfProjectHistory: function (data) {
    if (data.untilNow) {
      return 'In progress'
    }
    return moment(data.endDate).format('MMM Do YYYY ')
  },
  renderRateListInShowMode: function (data, item) {
    const hourlyRatingPriceData = item
    const pastedDatas = data.pastedData
    const currentPastedData = pastedDatas.find((pastedData) => {
      return pastedData.project === item.project
    })
    if (currentPastedData) {
      return `${hourlyRatingPriceData.hourlyRate} (${currentPastedData.hourlyRate})`
    }
    return `${hourlyRatingPriceData.hourlyRate}`
  },
  renderFixedRateListInShowMode: function (data, item) {
    const hourlyRatingPriceData = item
    const pastedDatas = data.pastedData
    const currentPastedData = pastedDatas.find((pastedData) => {
      return pastedData.project === item.project
    })
    if (currentPastedData) {
      return `${hourlyRatingPriceData.fixedRate} (${currentPastedData.fixedRate})`
    }
    return `${hourlyRatingPriceData.fixedRate}`
  },
  generateGuid: function () {
    var result, i, j
    result = ''
    for (j = 0; j < 32; j++) {
      if (j === 8 || j === 12 || j === 16 || j === 20) result = result + '-'
      i = Math.floor(Math.random() * 16)
        .toString(16)
        .toUpperCase()
      result = result + i
    }
    return result
  },
  sortCommitTimeList: function (data) {
    return data.sort((a, b) => {
      if (a.isAll) {
        return -1
      }
      return 0
    })
  },

  convertStringToNumber: function (text) {
    if (text) {
      return Number(`${text}`.replace(/,/g, ''))
    }
    return 0
  },
  getFormattedSummarizeDataOfCommitTime: function (rawData) {
    const { commitTimeOfPerProject, commitTimeOfAllProject, commitTimeOfGroupBy } = rawData
    const { commitTimeDataOfGroupAllProject, commitTimeDataOfGroupPerProject } = commitTimeOfGroupBy
    let commitTimeOfAllProjectValue = 0
    let commitTimeOfSpecificProjectValue = 0
    let commitTimeOfAllProjectOfGroupValue = 0
    let commitTimeOfSpecificProjectOfGroupValue = 0
    if (Array.isArray(commitTimeOfPerProject) && commitTimeOfPerProject.length > 0) {
      commitTimeOfSpecificProjectValue = commitTimeOfPerProject.reduce((accumulator, item) => {
        if (item.isActive) {
          accumulator += item.commitTime
        }
        return accumulator
      }, 0)
    }
    if (Array.isArray(commitTimeOfAllProject) && commitTimeOfAllProject.length > 0) {
      commitTimeOfAllProjectValue = commitTimeOfAllProject.reduce((accumulator, item) => {
        if (item.isActive) {
          accumulator += item.commitTime
        }
        return accumulator
      }, 0)
    }
    if (Array.isArray(commitTimeDataOfGroupAllProject) && commitTimeDataOfGroupAllProject.length > 0) {
      commitTimeOfAllProjectOfGroupValue = commitTimeDataOfGroupAllProject.reduce((accumulator, item) => {
        if (item.isActive) {
          accumulator += item.commitTime
        }
        return accumulator
      }, 0)
    }
    if (Array.isArray(commitTimeDataOfGroupPerProject) && commitTimeDataOfGroupPerProject.length > 0) {
      commitTimeOfSpecificProjectOfGroupValue = commitTimeDataOfGroupPerProject.reduce((accumulator, item) => {
        if (item.isActive) {
          accumulator += item.commitTime
        }
        return accumulator
      }, 0)
    }
    const commitTimeOfAll = commitTimeOfAllProjectValue + commitTimeOfAllProjectOfGroupValue
    const commitTimeOfPer = commitTimeOfSpecificProjectValue + commitTimeOfSpecificProjectOfGroupValue
    const commitTimeTotal = commitTimeOfAll + commitTimeOfPer
    return {
      commitTimeTotal,
      commitTimeOfAllProject: commitTimeOfAll,
      commitTimeOfSpecificProject: commitTimeOfPer,
    }
  },
}

export default helper
