import { useEffect, useState } from 'react'
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import Snackbar from 'components/Snackbar'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'
import { ILoginForm } from 'types/user'
import { minLaptopSize } from 'constants/common'
import LoginBackground from '../../components/LoginBackground'
import { RoutesConstants } from '../../constants'
import { useStores } from '../../utils/hooks/useStores'
import LoginForm from './components/LoginForm'
import styles from './login.module.scss'

const LoginPage = () => {
  const [snackbarData, updateSnackbar] = useState({
    isSnackbarOpen: false,
    variant: undefined,
    message: null,
  })
  const { authStore } = useStores()
  const { userProfile } = authStore
  const history = useHistory()
  const location = useLocation()
  const [isDesktop] = useMediaQuery(minLaptopSize)

  async function onSubmit(data: ILoginForm): Promise<void> {
    try {
      await authStore.login(data)
    } catch (error) {
      updateSnackbar({
        isSnackbarOpen: true,
        variant: 'error',
        message: get(error, ['response', 'data', 'message'], 'Unexpected error!'),
      })
    }
  }

  function handleLoggedIn() {
    if (userProfile?.role === 'admin') {
      const locationState = location?.state as { from?: string; search?: string }
      const from = locationState?.from
      const search = locationState?.search
      const nextRoute = from && search ? `${from}${search}` : RoutesConstants.DASHBOARD
      history.push(nextRoute)
    } else {
      history.push(RoutesConstants.V2_TIMESHEET)
    }
  }

  useEffect(() => {
    userProfile && handleLoggedIn()
  }, [userProfile])

  return (
    <>
      <Flex h="100vh" overflow={'hidden'} direction={{ base: 'column', md: 'row' }}>
        {isDesktop && <LoginBackground />}
        <Box flex="1" bg={{ base: 'white', md: 'gray.50' }} px="6">
          <Box maxW="xl" mx="auto" mt="50vh" transform="translateY(-50%)">
            <Box>
              <Text mt="3" fontSize={'4xl'} className={styles.titleSignIn}>
                Sign in to continue
              </Text>
            </Box>
            <Box maxW={400} mx="auto">
              <LoginForm onSubmit={onSubmit} />
            </Box>
          </Box>
        </Box>
      </Flex>
      <Snackbar
        open={snackbarData?.isSnackbarOpen}
        variant={snackbarData?.variant}
        message={snackbarData?.message}
        handleClose={() => {
          updateSnackbar({ ...snackbarData, isSnackbarOpen: false })
        }}
      />
    </>
  )
}

export default observer(LoginPage)
