import api from 'API'
import { handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import { ESettingType, creatableOptionTypes, ISettingData, ISettingList, CREATABLE_OPTION_BASE_URL } from './constants'

export async function getSettingListData(): Promise<ISettingList> {
  try {
    const response: AxiosResponse = await api.get(`/projects/settings/list`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/setting', 'getSettingListData')
    return {} as ISettingList
  }
}

export async function createSettingData(payload: ISettingData, type: ESettingType): Promise<void> {
  try {
    const url: string = creatableOptionTypes.includes(type)
      ? `${CREATABLE_OPTION_BASE_URL}/create?type=${type}`
      : `/${type}/create`
    await api.post(url, payload)
  } catch (error) {
    handleError(error as Error, 'API/setting', 'createSettingData')
  }
}

export async function updateSettingData(payload: ISettingData, type: ESettingType): Promise<void> {
  try {
    const url: string = creatableOptionTypes.includes(type) ? `${CREATABLE_OPTION_BASE_URL}/update` : `/${type}/update`
    await api.post(url, payload)
  } catch (error) {
    handleError(error as Error, 'API/setting', 'updateSettingData')
  }
}

export async function deleteSettingData(id: string, type: ESettingType): Promise<void> {
  try {
    const url: string = creatableOptionTypes.includes(type)
      ? `${CREATABLE_OPTION_BASE_URL}/delete/${id}`
      : `/${type}/delete/${id}`
    await api.get(url)
  } catch (error) {
    handleError(error as Error, 'API/setting', 'deleteSettingData')
  }
}
