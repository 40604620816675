import { IInQuery } from 'API/constants'
import { IPartner } from 'constants/schema'

export const PARTNER_BASE_URL: string = '/partners'

export interface IPartnerListRequest {
  filter: {
    isDeleted?: boolean
    isArchived?: boolean
    category?: IInQuery
  }
}

export interface IPartnerListResponse {
  partners: IPartner[]
}

export interface IPartnerFilter {
  category: string[]
}

export interface IPartnerListPaginationRequest {
  currentPage: number
  perPage: number
  filter: IPartnerFilter
}

export interface IPartnerListPagination extends IPartnerListResponse {
  count: number
}

export interface ICreatePartnerMessages {
  createUser?: string
  createPartner?: string
}

export interface ICreatePartnerResponse {
  messages: ICreatePartnerMessages
  userId?: string
}

export interface IUpdatePartnerResponse {
  messages: string
}

export interface IPartnerDetailResponse {
  partner: IPartner
}
