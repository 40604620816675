import React from 'react'
import { SimpleGrid, Text } from '@chakra-ui/react'
import FormInput from 'componentsCharka/FormInput'
import styles from '../../../contract.module.scss'

const VNInformation = () => {
  return (
    <React.Fragment>
      <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
        Vietnamese Information
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="fullNameVI" label="Họ và tên" />
        <FormInput name="genderVI" label="Giới tính" />
        <FormInput name="nationalityVI" label="Quốc tịch" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="addressVI" label="Địa chỉ thường trú" />
        <FormInput
          name="publishedDateInfoVI"
          label="Thời gian và địa điểm ký văn bản"
          helperText="VD: TP. Hồ Chí Minh, ngày 2 tháng 3 năm 2022"
        />
        <FormInput name="placeOfBirthVI" label="Nơi sinh" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 4, lg: 4 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="IDIssuePlaceVI" label="Nơi cấp CCCD/CMND" />
        <FormInput name="educationLevelVI" label="Trình độ văn hóa" />
        <FormInput name="majorVI" label="Chuyên ngành" />
        <FormInput name="jobTitleVI" label="Chức vụ" />
      </SimpleGrid>
    </React.Fragment>
  )
}

export default VNInformation
