import { ReactNode } from 'react'
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Textarea,
} from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'
import styles from './index.module.scss'

interface IFormInputFieldProps {
  name: string
  type?: string
  placeholder?: string
  isRequired?: boolean
  children?: ReactNode
  helperText?: string
  helperTextShow?: boolean
  rightAddonText?: string
  readonly?: boolean
  disabled?: boolean
  isWebsite?: boolean
  defaultValue?
}

const FormInputField = (props: IFormInputFieldProps) => {
  const { name, type, placeholder, isRequired, readonly, rightAddonText, disabled, isWebsite, defaultValue } = props
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext()
  let pattern: { value: RegExp; message: string } | undefined
  switch (name) {
    case 'email':
      pattern = { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i, message: 'Please enter a valid email' }
      break
    default:
      pattern = undefined
  }
  const disabledProps = disabled
    ? {
        disabled: true,
        background: 'gray.100',
        opacity: '0.7 !important',
        color: 'gray.700',
        variant: 'filled',
      }
    : {}
  return type !== 'number' ? (
    <InputGroup width={{ lg: '100%', md: '90%', sm: '90%' }}>
      {isWebsite && <InputLeftAddon children="https://" />}
      {type !== 'textarea' ? (
        <Input
          className={styles.inputField}
          type={type}
          defaultValue={defaultValue}
          autoComplete={name}
          placeholder={placeholder}
          isReadOnly={readonly}
          {...disabledProps}
          focusBorderColor={errors?.name ? 'red.500' : 'gray.200'}
          {...register(name, {
            required: isRequired ? `This field is required` : false,
            pattern,
          })}
          color="gray.700"
        />
      ) : (
        <Textarea
          resize={'none'}
          paddingLeft={'20px'}
          readOnly={readonly}
          {...disabledProps}
          {...register(name)}
          fontSize="16px"
          width={'90%'}
          focusBorderColor={errors?.name ? 'red.500' : 'gray.200'}
        />
      )}
      {rightAddonText && <InputRightAddon paddingY="1px" children={rightAddonText} />}
    </InputGroup>
  ) : (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <NumberInput {...field}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      )}
    />
  )
}

export default FormInputField
