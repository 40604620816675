import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { getProjectDetailOfUser } from 'API/project'
import { IProjectDetailOfUserResponse } from 'API/project/constants'
import get from 'lodash/get'
import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'
import { makeAutoObservable } from 'mobx'
import { getInQuery, getParsedProjectValueBeforeRenderFE } from 'utils'
import { IProject } from 'constants/schema'
import ApiHelper from '../../Helper/api'
import RootStore from '../rootStore'

class UserProjectStore {
  rootStore: RootStore
  currentPage: number = 1
  count: number = 0
  projectsList: IProject[] = []
  projectDetail

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public getProjectList(isReset?: boolean, history?: any, filter?: any) {
    const currentPage: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
    const truthyFilter = {
      ...pickBy(filter, identity),
      partnerId: getInQuery(filter.partnerId),
      userId: getInQuery(filter.userId),
      country: getInQuery(filter.country),
      technology: getInQuery(filter.technology),
      isActive: filter.isActive ?? true,
      isArchived: filter.isArchived ?? false,
    }
    truthyFilter?.partnerId === null && delete truthyFilter?.partnerId
    truthyFilter?.userId === null && delete truthyFilter?.userId
    truthyFilter?.country === null && delete truthyFilter?.country
    truthyFilter?.technology === null && delete truthyFilter?.technology
    ApiHelper.post(
      {
        url: `/projects/list`,
        data: { currentPage, perPage: 8, filter: truthyFilter },
      },
      (response) => {
        this.projectsList = response.data.projects
        this.count = response.data.count
      }
    )
  }
  public async getProjectDetailOfUser(projectId: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectDetailOfUserResponse> = await getProjectDetailOfUser(projectId)
      const currentProjectData: IProject = response?.data?.project
      const parsedProjectValue = getParsedProjectValueBeforeRenderFE(currentProjectData, false)
      this.projectDetail = parsedProjectValue
    } catch (error) {
      handleError(error, 'src/store/user/projectStore.ts', 'getProjectDetailOfUser')
    }
  }
}

export default UserProjectStore
