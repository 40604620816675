export interface IOption<T = string> {
  label: T
  value: string
  text?: string
}

export const maxMobileSize = '(max-width: 576px)'
export const maxTabletSize = '(max-width: 768px)'
export const laptopSize = '(max-width: 1200px)'
export const minLaptopSize = '(min-width: 1200px)'
