import { useState, useEffect } from 'react'
import { VStack, SimpleGrid, Container, Divider } from '@chakra-ui/react'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import FormInput from 'componentsCharka/FormInput'
import GroupRadio from 'componentsCharka/GroupRadio'
import { IOption } from 'constants/common'
import { EPartnerCategory, EPartnerType, EYesNoOption } from 'constants/enum'

export interface ICreatePartnerFormProps {
  partnersType
  patnerCategoryOptions: IOption[]
  titleOptions: IOption[]
  levelOptions: IOption[]
  paymentCategoryOptions: IOption[]
}

const CreatePartnerForm = (props) => {
  const { partnersType, patnerCategoryOptions, titleOptions, levelOptions, paymentCategoryOptions, methods } = props
  const { watch } = methods

  const title: string = 'New Partner'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))
  const createUserOption = ['yes', 'no']
  const type = watch('type')
  const category = watch('category')
  const isCreateUser = watch('isCreateUser')
  const [toggleCreateUser, setToggleCreateUser] = useState<boolean>(false)
  const [toggleAddFieldsOfUser, setToggleAddFieldsOfUser] = useState<boolean>(false)
  // const [disableDropdow, setDisableDropdow] = useState<boolean>(false)

  useEffect(() => {
    if (
      type === EPartnerType.PERSONAL &&
      (category?.value === EPartnerCategory.FREELANCER || category?.value === EPartnerCategory.EMPLOYEE)
    ) {
      setToggleCreateUser(true)
    } else {
      setToggleCreateUser(false)
    }
  }, [type, category?.value])

  useEffect(() => {
    setToggleAddFieldsOfUser(isCreateUser === EYesNoOption.NO || isCreateUser === undefined ? false : true)
  }, [isCreateUser])
  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack background="white" padding={{ base: 6, lg: 6 }} borderRadius="8px">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="fullName" label="Full Name" isRequired />
          <FormInput name="address" label="Address" isRequired />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <GroupRadio
            name={'type'}
            data={partnersType}
            defaultValue={EPartnerType.ORGANIZATIONS}
            label="Partner Type"
          />
          <ChakraInputDropdown
            name="category"
            label="Partner Category"
            placeHolder="- Select partner category -"
            optionsData={patnerCategoryOptions}
            isRequire
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="phone" label="Phone Number (Optional)" />
          <FormInput
            name="email"
            isRequired={toggleCreateUser}
            label={`Email ${toggleCreateUser ? '' : '(Optional)'}`}
          />
          <FormInput name="website" isWebsite label="Website (Optional)" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="taxCode" label="Tax Code (Optional)" />
          <FormInput name="billingAddress" label="Billing Address (Optional)" />
        </SimpleGrid>
        {toggleCreateUser && (
          <>
            <Divider color={'gray/200'} />
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <GroupRadio name={'isCreateUser'} data={createUserOption} defaultValue={'no'} label="Create user" />
            </SimpleGrid>
          </>
        )}
        {toggleAddFieldsOfUser && (
          <>
            <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
              <ChakraInputDropdown
                name="titleId"
                label="Title"
                placeHolder="- Select Title -"
                optionsData={titleOptions}
                isRequire
              />
              <ChakraInputDropdown
                name="levelId"
                label="Level"
                placeHolder="- Select Level -"
                optionsData={levelOptions}
                isRequire
              />
              <ChakraInputDropdown
                name="paymentCategoryId"
                label="Partner Category *"
                placeHolder="-Select Payment category-"
                optionsData={paymentCategoryOptions}
                isRequire
              />
            </SimpleGrid>
          </>
        )}
      </VStack>
    </Container>
  )
}
export default CreatePartnerForm
