import React from 'react'
import { Box, VStack, Image, Text } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { EFeatureBlockOfAccounting } from 'constants/enum'
import { RoutesConstants } from 'constants/index'
import expenseURL from '../../../../assets/images/svg/expenses.svg'
import incomeURL from '../../../../assets/images/svg/income.svg'
import styles from '../../index.module.scss'
interface IFeatureBlock {
  name: string
}

const FeatureBlock = (props: IFeatureBlock) => {
  const { name } = props
  const history = useHistory()

  let icon
  let label
  let colorBg
  let router
  switch (name) {
    case EFeatureBlockOfAccounting.EXPENSE:
      icon = expenseURL
      label = EFeatureBlockOfAccounting.EXPENSE
      colorBg = '#ED8936'
      router = RoutesConstants.ACCOUNTING_EXPENSE_LIST
      break
    case EFeatureBlockOfAccounting.INCOME:
      icon = incomeURL
      label = EFeatureBlockOfAccounting.INCOME
      colorBg = '#4D5DFB'
      router = RoutesConstants.ACCOUNTING_INCOME_LIST
      break
    default:
      icon = <React.Fragment></React.Fragment>
      label = ''
      break
  }
  function clickHandler(nextRoute: string) {
    history.push(nextRoute)
  }
  return (
    <Box
      width="150px"
      height="150px"
      borderWidth="1px"
      borderRadius="lg"
      borderStyle="solid"
      overflow="hidden"
      borderColor={colorBg}
      textColor="white"
      className={styles.blockHover}>
      <VStack textAlign="center" padding="20px" onClick={() => clickHandler(router)}>
        <Image src={icon} width="60px" />
        <Box mt="1" fontWeight="semibold" as="h3" lineHeight="tight" noOfLines={1}>
          <Text color={colorBg}>{label}</Text>
        </Box>
      </VStack>
    </Box>
  )
}

export default FeatureBlock
