import * as React from 'react'
import FormModel from '../../../../components/FormModel'
import FormDetail from '../components/FormDetail'
import Item from '../components/Item'
import { IInternalDocumentType } from '../constants'
const InternalDocumentType = (props: IInternalDocumentType): JSX.Element => {
  const {
    handleSubmit,
    getDetailInternalDocumentType,
    internalDocumentTypes,
    internalDocumentTypeDetail,
    deleteInternalDocumentType,
    viewMode,
    cancelEditHandler,
    validationSchema,
  } = props
  return (
    <div className="container-fluid setting-item">
      <div className="box box-default">
        <div className="box-header">Internal Document Type</div>
        <div className="box-divider" />
        <div className={'box-body'}>
          <div className="container-fluid">
            <div>
              <FormModel
                initialValues={internalDocumentTypeDetail}
                handleSubmit={handleSubmit}
                schema={validationSchema}
                component={<FormDetail cancelEditHandler={cancelEditHandler} viewMode={viewMode} {...props} />}
              />
            </div>
            <div className="setting-list-wrapper">
              {internalDocumentTypes &&
                internalDocumentTypes.map((item, index) => (
                  <Item
                    key={item._id}
                    data={item}
                    index={index}
                    editClickHandler={() => getDetailInternalDocumentType(item._id)}
                    deleteClickHandler={() => deleteInternalDocumentType(item, 'doctype')}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default InternalDocumentType
