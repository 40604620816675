import { chakra, Input, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'

export const PhoneInputField = chakra(Input, {
  baseStyle: () => ({
    type: 'tel',
    boxSizing: 'border-box',
  }),
})

export const CountrySelectButton = chakra(MenuButton, {
  baseStyle: () => ({
    boxShadow: 'none !important',
    border: 'none',
    paddingY: 2,
    paddingX: 4,
    background: 'transparent',
    _hover: {
      background: 'transparent',
      cursor: 'pointer',
    },
    _active: { background: 'transparent' },
  }),
})

export const CountryName = chakra(Text, {
  baseStyle: () => ({
    fontWeight: '400',
    color: 'gray.700',
    paddingLeft: 2,
    fontSize: 'md',
  }),
})

export const CountryDialCode = chakra(Text, {
  baseStyle: () => ({
    fontWeight: '400',
    color: 'gray.500',
    paddingLeft: 2,
    fontSize: 'sm',
  }),
})

export const CountryItem = chakra(MenuItem, {
  baseStyle: () => ({
    padding: '8px 16px',
    _hover: {
      background: '#E0EFEF',
    },
  }),
})

export const CountryList = chakra(MenuList, {
  baseStyle: () => ({
    zIndex: 1001,
  }),
})
