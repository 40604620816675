import React from 'react'
import { Button, ButtonProps, LightMode } from '@chakra-ui/react'
import { ModalFlexForm } from './buttonFormModal.styles'
import styles from './buttonForm.module.scss'

interface IButtonFormProps extends ButtonProps {
  label: string
}

const ButtonForm = React.forwardRef<HTMLButtonElement, IButtonFormProps>((props, ref) => {
  const { label, ...rest } = props

  return (
    <ModalFlexForm>
      <LightMode>
        <Button
          ref={ref}
          mb={{ base: '4', md: '0' }}
          w="full"
          type="submit"
          size="lg"
          fontSize="md"
          className={styles.buttonForm}
          {...rest}>
          {label}
        </Button>
      </LightMode>
    </ModalFlexForm>
  )
})

ButtonForm.displayName = 'ButtonForm'

export default ButtonForm
