import { Avatar, FormControl, FormLabel, HStack, Tooltip, Wrap, WrapItem, SimpleGrid } from '@chakra-ui/react'
import Helper from 'Helper'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { IUser } from 'types/user'
import { getValidArray } from 'utils'
import { RoutesConstants } from 'constants/index'

export interface IAvatarDevGroup {
  name?: string
  data?: IUser[]
  label?: string
}

const AvatarDevGroup = (props: IAvatarDevGroup) => {
  const { name, label, data } = props
  const history = useHistory()
  const actionDetailHandler = (userID: string) =>
    history.push(generatePath(RoutesConstants.USER_DETAIL, { id: userID }))

  return (
    <FormControl id={name} width="100%">
      <HStack alignItems={'flex-start'} justifyContent={'space-between'}>
        <FormLabel fontWeight={'nomal'} color="gray.700">
          {label}
        </FormLabel>
        <FormLabel fontWeight={'normal'} color="blue.500">
          {getValidArray(data).length === 0 ? '' : getValidArray(data).length + ' developers'}
        </FormLabel>
      </HStack>
      <HStack justifyContent="space-between">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
          <Wrap>
            {getValidArray(data).map((item, index) => (
              <WrapItem>
                <Tooltip label={item.fullName}>
                  <Avatar
                    onClick={() => actionDetailHandler(item._id)}
                    height={'24px'}
                    width={'24px'}
                    key={index}
                    src={Helper.generateImageURL(item.avatar, 'avatar')}
                    _hover={{
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              </WrapItem>
            ))}
          </Wrap>
        </SimpleGrid>
      </HStack>
    </FormControl>
  )
}

export default AvatarDevGroup
