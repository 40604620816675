import React from 'react'
import * as Yup from 'yup'

import Technology from './Technology'
import ProjectType from './ProjectType'
import Currency from './Currency'
import Country from './Country'
import RateExchange from './RateExchange'
import PushNotification from './PushNotification'
import { Messages, EventName } from '../../../constants'
import ConfirmDialogContent from './components/ConfirmDialogContent'
import InternalDocumentType from './InteralDocumentType'
import Title from './Title'
import Level from './Level'
import capitalize from 'lodash/capitalize'
import { getSettingListData, createSettingData, updateSettingData, deleteSettingData } from 'API/setting'
import { handleError } from 'API/error'
import { getSettingActionMessage } from './utils.ts'
import { ESettingActionType, EViewModeForm } from './constants'
import { ESettingType } from 'API/setting/constants'

const validationSchema = Yup.object().shape({
  value: Yup.string().required(Messages.fieldRequired),
})
class ProjectSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      technologies: [],
      projectTypes: [],
      currencies: [],
      countries: [],
      rateExchanges: [],
      internalDocumentTypes: [],
      titles: [],
      levels: [],
      viewModeOfTechnologyForm: 'create',
      choosedTechnologyDetail: null,
      viewModeOfProjectTypeForm: 'create',
      choosedProjectTypeDetail: null,
      viewModeOfCurrencyForm: 'create',
      choosedCurrencyDetail: null,
      viewModeOfCountryForm: 'create',
      choosedCountryDetail: null,
      viewModeOfRateExchangeForm: 'create',
      choosedRateExchangeDetail: null,
      viewModeOfInternalDocumentTypeForm: 'create',
      chooseInternalDocumentTypeDetail: null,
      viewModeOfTitleForm: 'create',
      chooseTitleDetail: null,
      viewModeOfLevelForm: 'create',
      chooseLevelDetail: null,
    }
  }
  componentDidMount() {
    this.getListSetting()
    window.sessionStorage.setItem('subPageTitle', 'Project')
    window.dispatchEvent(new Event('storage'))
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem('subPageTitle')
    this.setState = () => {
      return
    }
  }
  getListSetting = async () => {
    try {
      const response = await getSettingListData()
      this.setState(response)
    } catch (error) {
      handleError(error, 'src/containers/Settings/ProjectsSettings', 'getListSetting')
    }
  }
  createSettingHandler = async (values, type) => {
    try {
      const message = getSettingActionMessage(ESettingActionType.CREATE, type)
      await createSettingData(values, type)
      this.props.handleOpenSnackbar({ variant: 'success', message })
      await this.getListSetting()
    } catch (error) {
      handleError(error, 'src/containers/Settings/ProjectsSettings', 'createSettingHandler')
    }
  }

  updateSettingHandler = async (values, type) => {
    try {
      const message = getSettingActionMessage(ESettingActionType.UPDATE, type)
      await updateSettingData(values, type)
      this.props.handleOpenSnackbar({ variant: 'success', message })
      await this.getListSetting()
      this.setState({
        [`viewModeOf${capitalize(type)}Form`]: 'create',
      })
    } catch (error) {
      handleError(error, 'src/containers/Settings/ProjectsSettings', 'updateSettingHandler')
    }
  }

  cancelEditSettingHandler = (type) => {
    this.setState({
      [`viewModeOf${type}Form`]: 'create',
    })
  }

  callOpenDialogConfirm = (data, type) => {
    document.dispatchEvent(
      new CustomEvent(EventName.openDeleteProjectSettingsConfirmDialog, {
        detail: {
          title: Messages.deleteDialogTitle,
          isOpen: true,
          content: (
            <ConfirmDialogContent
              data={data}
              inputDeleteDialogChangeHandler={this.inputDeleteProjectSettingsNameChangeHandler}
              type={type}
            />
          ),
          OKClickHandler: this.deleteSettingHandler,
          item: data,
          itemType: type,
        },
      })
    )
  }
  inputDeleteProjectSettingsNameChangeHandler = (event, projectSettingName) => {
    const typingProjectSettingName = event.target.value
    if (typingProjectSettingName === projectSettingName) {
      document.dispatchEvent(
        new CustomEvent(EventName.updateDeleteProjectSettingsConfirmDialog, {
          detail: {
            isCorrectName: true,
          },
        })
      )
    } else {
      document.dispatchEvent(
        new CustomEvent(EventName.updateDeleteProjectSettingsConfirmDialog, {
          detail: {
            isCorrectName: false,
          },
        })
      )
    }
  }

  clickDeleteButtonHandler = (setting, type) => {
    this.callOpenDialogConfirm(setting, type)
  }

  deleteSettingHandler = async (id, type) => {
    try {
      const message = getSettingActionMessage(ESettingActionType.DELETE, type)
      await deleteSettingData(id, type)
      this.props.handleCloseConfirmDialog()
      this.props.handleOpenSnackbar({ variant: 'success', message })
      await this.getListSetting()
    } catch (error) {
      handleError(error, 'src/containers/Settings/ProjectsSettings', 'deleteSettingHandler')
    }
  }
  /* Settings - Technology */
  getDetailTechnology = (id) => {
    const { technologies } = this.state
    const currentTechnology = technologies.find((item) => item._id === id)
    this.setState({
      viewModeOfTechnologyForm: EViewModeForm.EDIT,
      choosedTechnologyDetail: currentTechnology,
    })
  }

  /* Settings - Project Type */
  getDetailProjectType = (id) => {
    const { projectTypes } = this.state
    const currentProjectType = projectTypes.find((item) => item._id === id)
    this.setState({
      viewModeOfProjectTypeForm: EViewModeForm.EDIT,
      choosedProjectTypeDetail: currentProjectType,
    })
  }

  /* Settings - Currency */
  getDetailCurrency = (id) => {
    const { currencies } = this.state
    const currentCurrency = currencies.find((item) => item._id === id)
    this.setState({
      viewModeOfCurrencyForm: EViewModeForm.EDIT,
      choosedCurrencyDetail: currentCurrency,
    })
  }

  /* Settings - Country */
  getDetailCountry = (id) => {
    const { countries } = this.state
    const currentCountry = countries.find((item) => item._id === id)
    this.setState({
      viewModeOfCountryForm: EViewModeForm.EDIT,
      choosedCountryDetail: currentCountry,
    })
  }

  getDetailRateExchange = (id) => {
    const { rateExchanges } = this.state
    const currentRateExchange = rateExchanges.find((item) => item._id === id)
    this.setState({
      viewModeOfRateExchangeForm: EViewModeForm.EDIT,
      choosedRateExchangeDetail: currentRateExchange,
    })
  }
  /* Settings - Internal Document Type*/
  getDetailInternalDocumentType = (id) => {
    const { internalDocumentTypes } = this.state
    const currentInternalDocumentType = internalDocumentTypes.find((item) => item._id === id)
    this.setState({
      viewModeOfInternalDocumentTypeForm: EViewModeForm.EDIT,
      choosedInternalDocumentTypeDetail: currentInternalDocumentType,
    })
  }
  /* Settings User - Title*/
  getDetailTitle = (id) => {
    const { titles } = this.state
    const currentTitle = titles.find((item) => item._id === id)
    this.setState({
      viewModeOfTitleForm: EViewModeForm.EDIT,
      choosedTitleDetail: currentTitle,
    })
  }
  /* Settings User - Level*/
  getDetailLevel = (id) => {
    const { levels } = this.state
    const currentLevel = levels.find((item) => item._id === id)
    this.setState({
      viewModeOfLevelForm: EViewModeForm.EDIT,
      choosedLevelDetail: currentLevel,
    })
  }

  render() {
    const {
      technologies,
      projectTypes,
      currencies,
      countries,
      rateExchanges,
      internalDocumentTypes,
      titles,
      levels,
      viewModeOfTechnologyForm,
      choosedTechnologyDetail,
      viewModeOfProjectTypeForm,
      choosedProjectTypeDetail,
      viewModeOfCurrencyForm,
      choosedCurrencyDetail,
      viewModeOfCountryForm,
      choosedCountryDetail,
      viewModeOfRateExchangeForm,
      choosedRateExchangeDetail,
      viewModeOfInternalDocumentTypeForm,
      choosedInternalDocumentTypeDetail,
      viewModeOfTitleForm,
      choosedTitleDetail,
      viewModeOfLevelForm,
      choosedLevelDetail,
    } = this.state

    let technologyDetail
    let technologySubmitHandler
    if (viewModeOfTechnologyForm === EViewModeForm.CREATE) {
      technologyDetail = { value: '' }
      technologySubmitHandler = (values) => this.createSettingHandler(values, ESettingType.TECHNOLOGY)
    } else {
      technologyDetail = choosedTechnologyDetail
      technologySubmitHandler = (values) => this.updateSettingHandler(values, ESettingType.TECHNOLOGY)
    }

    let projectTypeDetail
    let projectTypeSubmitHandler
    if (viewModeOfProjectTypeForm === EViewModeForm.CREATE) {
      projectTypeDetail = { value: '' }
      projectTypeSubmitHandler = (values) => this.createSettingHandler(values, ESettingType.PROJECT_TYPE)
    } else {
      projectTypeDetail = choosedProjectTypeDetail
      projectTypeSubmitHandler = (values) => this.updateSettingHandler(values, ESettingType.PROJECT_TYPE)
    }

    let currencyDetail
    let currencySubmitHandler
    if (viewModeOfCurrencyForm === EViewModeForm.CREATE) {
      currencyDetail = { value: '' }
      currencySubmitHandler = (values) => this.createSettingHandler(values, ESettingType.CURRENCY)
    } else {
      currencyDetail = choosedCurrencyDetail
      currencySubmitHandler = (values) => this.updateSettingHandler(values, ESettingType.CURRENCY)
    }

    let countryDetail
    let countrySubmitHandler
    if (viewModeOfCountryForm === EViewModeForm.CREATE) {
      countryDetail = { value: '' }
      countrySubmitHandler = (values) => this.createSettingHandler(values, ESettingType.COUNTRY)
    } else {
      countryDetail = choosedCountryDetail
      countrySubmitHandler = (values) => this.updateSettingHandler(values, ESettingType.COUNTRY)
    }

    let rateExchangeDetail
    let rateExchangeSubmitHandler
    if (viewModeOfRateExchangeForm === EViewModeForm.CREATE) {
      rateExchangeDetail = { name: '', value: '' }
      rateExchangeSubmitHandler = (values) => this.createSettingHandler(values, ESettingType.RATE_EXCHANGE)
    } else {
      rateExchangeDetail = choosedRateExchangeDetail
      rateExchangeSubmitHandler = (values) => this.updateSettingHandler(values, ESettingType.RATE_EXCHANGE)
    }
    let internalDocumentTypeDetail
    let internalDocumentTypeSubmitHandler
    if (viewModeOfInternalDocumentTypeForm === EViewModeForm.CREATE) {
      internalDocumentTypeDetail = { value: '' }
      internalDocumentTypeSubmitHandler = (values) => this.createSettingHandler(values, ESettingType.DOCTYPE)
    } else {
      internalDocumentTypeDetail = choosedInternalDocumentTypeDetail
      internalDocumentTypeSubmitHandler = (values) => this.updateSettingHandler(values, ESettingType.DOCTYPE)
    }

    let titleUserDetail
    let titleUserSubmitHandler
    if (viewModeOfTitleForm === EViewModeForm.CREATE) {
      titleUserDetail = { value: '' }
      titleUserSubmitHandler = (values) => this.createSettingHandler(values, ESettingType.TITLE)
    } else {
      titleUserDetail = choosedTitleDetail
      titleUserSubmitHandler = (values) => this.updateSettingHandler(values, ESettingType.TITLE)
    }

    let levelUserDetail
    let levelUserSubmitHandler
    if (viewModeOfLevelForm === EViewModeForm.CREATE) {
      levelUserDetail = { value: '' }
      levelUserSubmitHandler = (values) => this.createSettingHandler(values, ESettingType.LEVEL)
    } else {
      levelUserDetail = choosedLevelDetail
      levelUserSubmitHandler = (values) => this.updateSettingHandler(values, ESettingType.LEVEL)
    }

    return (
      <div className="project-settings-page">
        <div className="group">
          <Technology
            technologies={technologies}
            technologyDetail={technologyDetail}
            handleSubmit={technologySubmitHandler}
            getDetailTechnology={this.getDetailTechnology}
            deleteTechnology={this.clickDeleteButtonHandler}
            viewMode={viewModeOfTechnologyForm}
            cancelEditHandler={() => this.cancelEditSettingHandler('Technology')}
            validationSchema={validationSchema}
          />
          <ProjectType
            projectTypes={projectTypes}
            projectTypeDetail={projectTypeDetail}
            handleSubmit={projectTypeSubmitHandler}
            getDetailProjectType={this.getDetailProjectType}
            deleteProjectType={this.clickDeleteButtonHandler}
            viewMode={viewModeOfProjectTypeForm}
            cancelEditHandler={() => this.cancelEditSettingHandler('ProjectType')}
            validationSchema={validationSchema}
          />
        </div>
        <div className="group">
          <Currency
            currencies={currencies}
            currencyDetail={currencyDetail}
            handleSubmit={currencySubmitHandler}
            getDetailCurrency={this.getDetailCurrency}
            deleteCurrency={this.clickDeleteButtonHandler}
            viewMode={viewModeOfCurrencyForm}
            cancelEditHandler={() => this.cancelEditSettingHandler('Currency')}
            validationSchema={validationSchema}
          />
          <Country
            countries={countries}
            countryDetail={countryDetail}
            handleSubmit={countrySubmitHandler}
            getDetailCountry={this.getDetailCountry}
            deleteCountry={this.clickDeleteButtonHandler}
            viewMode={viewModeOfCountryForm}
            cancelEditHandler={() => this.cancelEditSettingHandler('Country')}
            validationSchema={validationSchema}
          />
        </div>
        <div className="group">
          <RateExchange
            currencies={currencies}
            rateExchanges={rateExchanges}
            rateExchangeDetail={rateExchangeDetail}
            handleSubmit={rateExchangeSubmitHandler}
            getDetailRateExchange={this.getDetailRateExchange}
            deleteRateExchange={this.clickDeleteButtonHandler}
            viewMode={viewModeOfRateExchangeForm}
            cancelEditHandler={() => this.cancelEditSettingHandler('RateExchange')}
            validationSchema={validationSchema}
          />
          <InternalDocumentType
            internalDocumentTypes={internalDocumentTypes}
            internalDocumentTypeDetail={internalDocumentTypeDetail}
            handleSubmit={internalDocumentTypeSubmitHandler}
            getDetailInternalDocumentType={this.getDetailInternalDocumentType}
            deleteInternalDocumentType={this.clickDeleteButtonHandler}
            viewMode={viewModeOfInternalDocumentTypeForm}
            cancelEditHandler={() => this.cancelEditSettingHandler('InternalDocumentType')}
            validationSchema={validationSchema}
          />
        </div>
        <div className="group">
          <Title
            titles={titles}
            titleDetail={titleUserDetail}
            handleSubmit={titleUserSubmitHandler}
            getDetailTitle={this.getDetailTitle}
            deleteTitle={this.clickDeleteButtonHandler}
            viewMode={viewModeOfTitleForm}
            cancelEditHandler={() => this.cancelEditSettingHandler('Title')}
            validationSchema={validationSchema}
          />
          <Level
            levels={levels}
            levelDetail={levelUserDetail}
            handleSubmit={levelUserSubmitHandler}
            getDetailLevel={this.getDetailLevel}
            deleteLevel={this.clickDeleteButtonHandler}
            viewMode={viewModeOfLevelForm}
            cancelEditHandler={() => this.cancelEditSettingHandler('Level')}
            validationSchema={validationSchema}
          />
        </div>
        <div className="group">
          <PushNotification />
        </div>
      </div>
    )
  }
}

export default ProjectSettings
