import api from 'API'
import { handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import { PAYMENT_BASE_URL, IPaymentPayload, IFilterPaymentsPayload, IPaymentListResponse } from './constants'

export async function filterPaymentsAdmin(payload: IFilterPaymentsPayload): Promise<IPaymentListResponse> {
  try {
    const response: AxiosResponse = await api.post(`${PAYMENT_BASE_URL}/payment-admin/list`, payload)
    return response.data
  } catch (error) {
    handleError(error, 'API/payment', 'filterPaymentsAdmin')
    return {} as IPaymentListResponse
  }
}

export async function editRequestPayment(payload: IPaymentPayload): Promise<void> {
  try {
    await api.post(`${PAYMENT_BASE_URL}/payment-admin/edit-request`, payload)
  } catch (error) {
    handleError(error, 'API/payment', 'editRequestPayment')
  }
}

export async function editPaymentAdmin(payload: IPaymentPayload): Promise<void> {
  try {
    await api.post(`${PAYMENT_BASE_URL}/payment-admin/edit`, payload)
  } catch (error) {
    handleError(error, 'API/payment', 'editPaymentAdmin')
  }
}

export async function filterPaymentsUser(payload: IFilterPaymentsPayload): Promise<IPaymentListResponse> {
  try {
    const response: AxiosResponse = await api.post(`${PAYMENT_BASE_URL}/payment-user/list`, payload)
    return response.data
  } catch (error) {
    handleError(error, 'API/payment', 'filterPaymentsUser')
    return {} as IPaymentListResponse
  }
}

export async function editPaymentUser(payload: IPaymentPayload): Promise<IPaymentListResponse> {
  try {
    const response: AxiosResponse = await api.post(`${PAYMENT_BASE_URL}/payment-user/edit`, payload)
    return response.data
  } catch (error) {
    handleError(error, 'API/payment', 'editPaymentUser')
    return {} as IPaymentListResponse
  }
}

export async function sendRequest(payload: IPaymentPayload): Promise<void> {
  try {
    await api.post(`${PAYMENT_BASE_URL}/payment-user/send-request`, payload)
  } catch (error) {
    handleError(error, 'API/payment', 'sendRequest')
  }
}
