import React from 'react'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { green } from '@material-ui/core/colors'

import Helper from '../../../../Helper'

function formatRow(index) {
  let result = 'even'
  if (index % 2 === 0) {
    result = 'odd'
  }
  return result
}
class CommitTimeRequestTable extends React.Component {
  renderOptions = (item) => {
    const { approvedHandler, rejectHandler } = this.props
    let isRender = true
    const notRenderOptions = [1, 2, 4]
    if (notRenderOptions.includes(item.status.value)) {
      isRender = false
    }
    if (isRender) {
      return (
        <td className="options">
          <Tooltip title={'Approve'}>
            <IconButton data-cy={`Approve`} aria-label={'Approve'} onClick={() => approvedHandler(item._id)}>
              <Check style={{ color: green[500] }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Reject'}>
            <IconButton data-cy={`Reject`} aria-label={'Reject'} onClick={() => rejectHandler(item._id)}>
              <Close color="secondary" />
            </IconButton>
          </Tooltip>
        </td>
      )
    }
    return null
  }

  renderClassNameOfStatus = (value) => {
    let statusClassName = ''
    switch (value) {
      case 1: {
        statusClassName = 'approved'
        break
      }
      case 2: {
        statusClassName = 'rejected'
        break
      }
      default:
        break
    }
    return statusClassName
  }

  render() {
    const { data } = this.props
    return (
      <div className="table-content">
        <table className="report-table">
          <thead>
            <tr>
              <th>Developer</th>
              <th>Project</th>
              <th>Commit Time Per Week (Hours)</th>
              <th>Reason</th>
              <th>Status</th>
              <th>Rejected Reason</th>
              <th>Created At</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => (
                <tr key={item._id} className={formatRow(index)}>
                  <td>{item.developerName}</td>
                  <td>{item.projectName}</td>
                  <td>{Helper.getFormattedNumber(item.commitTime, 1)}</td>
                  <td>{item.reason}</td>
                  <td className={`commit-time-status ${this.renderClassNameOfStatus(item.status.value)}`}>
                    {item.status.label}
                  </td>
                  <td>{item.rejectedReason}</td>
                  <td>{Helper.getFormattedDateInEnglish(item.createdAt)}</td>
                  {this.renderOptions(item)}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default CommitTimeRequestTable
