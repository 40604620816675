import api from 'API'
import { ISuccessResponse } from 'API/constants'
import { getErrorMessage } from 'API/error'
import { CURRENCY_BASE_URL, ICurrencyListResponse } from './constants'

export async function getCurrencies(): Promise<ISuccessResponse<ICurrencyListResponse>> {
  try {
    return api.get(`${CURRENCY_BASE_URL}/list`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
