import { ITableHeader } from 'components/Table'
import { IOption } from 'constants/common'
import { EUserEducation, EYesNoOptionWithCapitalization } from 'constants/enum'
import { IUser, IPartner, ICreatableOption, IProject, IContract } from 'constants/schema'
export const isSupervisor = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]
export const isDowloadCV = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]
export const isActive = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]
export interface IRowActionItem {
  name: string
  customClassNames?: string[]
  handler: () => void
}
export interface Props {
  history: any
  match: any
  handleCloseConfirmDialog?: () => void
  handleOpenSnackbar?: ({ variant, message }) => void
}

export interface ILaborContractData extends Omit<IContract, 'isDeleted' | 'publishedDate'> {
  status: string
  isDeleted: string
  publishedDate: string
  actions: JSX.Element
  actionsTablet: IRowActionItem[]
}

export enum EUserDetailTitle {
  USER_PROFILE_DETAIL = 'User Profile Detail',
  PROJECT_RATINGS = 'Project Ratings',
  LABOR_CONTRACT = 'Labor Contract',
  USER_DATA = 'User Data',
  USER_PROFILE_PICTURE = 'User Profile Picture',
  CURRICULUMN_VITAE = 'Curriculumn Vitae',
}

export interface States {
  user: IUser
  partners?: IPartner[]
  projects?: IProject[]
  creatableOption?: ICreatableOption[]
}

export const headerListTabletTable = {
  fullNameVI: 'Full Name',
  number: 'Contract No',
  status: 'Status',
  isDeleted: 'Deleted',
  language: 'Languages',
  actionsTablet: '',
}

export function getHeaderLaborContractList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Full name',
      accessor: 'fullNameVI',
    },
    {
      Header: 'Contract No.',
      accessor: 'number',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Deleted',
      accessor: 'isDeleted',
    },
    {
      Header: 'Languages',
      accessor: 'language',
    },

    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export enum ETechnologyItem {
  NODE_JS = 'Node.js',
  REACT_JS = 'React.js',
  REACT_NATIVE = 'React Native',
  LOOPBACK_4 = 'Loopback 4',
  MONGODB = 'MongoDB',
}

// TODO: Create mock data for testing
export const fakeTechnologyData: IOption[] = [
  {
    value: '1',
    label: ETechnologyItem.NODE_JS,
  },
  {
    value: '2',
    label: ETechnologyItem.REACT_NATIVE,
  },
  {
    value: '3',
    label: ETechnologyItem.LOOPBACK_4,
  },
]

export interface IUserProfile {
  _id?: string
  partnerId?: string
  fullName?: string
  email?: string
  phone?: string
  gender?: string
  placeOfBirth?: string
  dateOfBirth?: string
  address?: string
  nationality: string
  IDNumber?: string
  IDIssueDate?: string
  IDIssuePlace?: string
  IDInformation?: string
  education?: EUserEducation
  major?: string
  joinDate?: string
  bankAccount?: string
  bankName?: string
  titleId?: string
  levelId?: string
  paymentCategoryId?: string
  role?: string
  isSupervisor?: string
  fixedSalary?: string
  defaultRating?: string
  projectsOfSupervisor?: IOption[]
  projects?: IOption[]
  extraProjects?: IOption[]
  externalProjects?: IOption[]
}
