import React from 'react'
import { Image } from '@chakra-ui/react'
import Helper from 'Helper'

interface IImageCellProps {
  avatar: string
}

const ImageCell = (props: IImageCellProps) => {
  const { avatar } = props

  return <Image height={'48px'} width="48px" src={Helper.generateImageURL(avatar, 'logo')} alt="project-logo" />
}

export default ImageCell
