import { chakra, Button } from '@chakra-ui/react'

export const TabButton = chakra(Button, {
  baseStyle: {
    width: 'full',
    height: 10,
    fontSize: 'md',
    fontWeight: 500,
    lineHeight: 6,
    paddingX: 4,
    paddingY: 2,
    borderRadius: '9999px',
    _focus: {
      boxShadow: 'none',
    },
  },
})
