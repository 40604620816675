import * as React from 'react'
import FormModel from '../../../../components/FormModel'
import FormDetail from '../../ProjectsSettings/components/FormDetail'
import Item from '../../ProjectsSettings/components/Item'
import { ILevel } from '../constants'

const Level = (props: ILevel): JSX.Element => {
  const {
    handleSubmit,
    getDetailLevel,
    levels,
    levelDetail,
    deleteLevel,
    viewMode,
    cancelEditHandler,
    validationSchema,
  } = props
  return (
    <div className="container-fluid setting-item">
      <div className="box box-default">
        <div className="box-header">Level</div>
        <div className="box-divider" />
        <div className={'box-body'}>
          <div className="container-fluid">
            <div>
              <FormModel
                initialValues={levelDetail}
                handleSubmit={handleSubmit}
                schema={validationSchema}
                component={<FormDetail cancelEditHandler={cancelEditHandler} viewMode={viewMode} {...props} />}
              />
            </div>
            <div className="setting-list-wrapper">
              {levels &&
                levels.map((item, index) => (
                  <Item
                    key={item._id}
                    data={item}
                    index={index}
                    editClickHandler={() => getDetailLevel(item._id)}
                    deleteClickHandler={() => deleteLevel(item, 'level')}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Level
