import React, { ReactNode } from 'react'
import { VStack, Text } from '@chakra-ui/react'
import NoDataText from 'componentsCharka/NoDataText'

interface IFormViewProps {
  label: string
  value?: string
  textColor?: string
  children?: ReactNode
}

const FormView = (props: IFormViewProps) => {
  const { label, value, textColor, children } = props

  return (
    <VStack
      width="full"
      spacing={2}
      alignItems="flex-start"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap">
      <Text fontSize="md" fontWeight={400} lineHeight={6} color="gray.500" margin={0}>
        {label}
      </Text>
      {children && children}
      {!children && value && (
        <Text fontSize="md" fontWeight={500} lineHeight={6} color={textColor ?? 'gray.700'}>
          {value}
        </Text>
      )}
      {!children && !value && <NoDataText />}
    </VStack>
  )
}

export default FormView
