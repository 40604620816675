import {
  Text,
  VStack,
  SimpleGrid,
  Container,
  Divider,
  Stack,
  FormControl,
  FormLabel,
  HStack,
  Link,
} from '@chakra-ui/react'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import FormInput from 'componentsCharka/FormInput'
import GroupRadio from 'componentsCharka/GroupRadio'
import Helper from 'Helper'
import { ICreatableOption } from 'types/creatableOption'
import { IProject } from 'types/project'
import { checkValidArray, getValidArray } from 'utils'
import { IOption } from 'constants/common'
import { UserRole, genderOptions, educationOptions, citizenIssuePlaceOptions } from 'constants/defaultOptionList'
import { ECreatableOptionTypes, EPaymentCategoryOfUser } from 'constants/enum'
import { Messages, RoutesConstants } from 'constants/index'
import { IPartner } from 'constants/schema'
import PasswordField from '../../../components/PasswordFiled'
import { isSupervisor, isActive, isDowloadCV } from '../../constant'

interface IProfileDetailProps {
  userId: string
  partnerList: IPartner[]
  projectList: IProject[]
  creatableOptionList: ICreatableOption[]
  name: string
  methods
  updateProjectRatingHandler
}

const EditUserForm = (props: IProfileDetailProps) => {
  const { userId, partnerList, projectList, creatableOptionList, methods, name, updateProjectRatingHandler } = props
  const { watch, getValues } = methods
  const diagramUrl: string = `${RoutesConstants.DIAGRAM}?userId=${userId}`

  const options = getValidArray(creatableOptionList)
  const titleOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.TITLE_OF_USER)
  )
  const levelOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.LEVEL_OF_USER)
  )
  const paymentCategoryOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER)
  )
  const paymentCategoryOfUser = watch(`${name}.paymentCategoryId`)
  const partnersOptions = Helper.createOptionsOfReactSelectFromDB(partnerList)
  const projectsOptions = Helper.createOptionsOfReactSelectFromDB(projectList)
  const userProjects: IOption[] = watch(`${name}.projects`) ?? []
  const extraProjectsOptions: IOption[] = Helper.createOptionsOfSelectFromDropdown(userProjects)
  const externalProjectsOptions: IOption[] = Helper.createOptionsOfSelectFromDropdown(userProjects)

  const disableSupervisor = watch(`${name}.isSupervisor`)
  const isFreelancerToo =
    paymentCategoryOfUser !== undefined
      ? paymentCategoryOfUser?.label === EPaymentCategoryOfUser.MIXED_PAYROLL_FREELANCER_FEE
      : false
  const isReceivedPayroll =
    paymentCategoryOfUser !== undefined
      ? paymentCategoryOfUser?.label === EPaymentCategoryOfUser.PAYROLL ||
        paymentCategoryOfUser?.label === EPaymentCategoryOfUser.MIXED_PAYROLL_FREELANCER_FEE
      : false

  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack alignItems={'initial'} margin={{ base: 6, lg: 6 }} marginTop={{ base: 0, lg: 0 }} height="full" gap={6}>
        <Text fontWeight={500} marginBottom={0} lineHeight={7} fontSize={'18px'}>
          User Profile Detail
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <Stack padding={{ sm: '10px', lg: 0 }}>
            <ChakraInputDropdown name={`${name}.partnerId`} label="Partner" optionsData={partnersOptions} />
          </Stack>
          <FormInput name={`${name}.fullName`} label="Full Name" isRequired />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <FormInput name={`${name}.email`} label="Email" isRequired />
          <FormInput name={`${name}.phone`} label="Phone Number" type="tel" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <Stack padding={{ sm: '10px', lg: 0 }}>
            <PasswordField name={`${name}.resetPassword`} label="Reset Password" />
          </Stack>
        </SimpleGrid>
        <Divider color="gray/200" />
        <SimpleGrid width="full" columns={2} gap={{ base: 6, lg: 8 }}>
          <ChakraInputDropdown
            name={`${name}.currentGender`}
            label="Gender"
            optionsData={genderOptions}
            isDisabledClearIndicator
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name={`${name}.placeOfBirth`} label="Place of Birth" />
          <FormInput name={`${name}.dateOfBirth`} label="Date of Birth" type="date" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name={`${name}.address`} label="Permanent Address" />
          <FormInput name={`${name}.nationality`} label="Nationality" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name={`${name}.IDNumber`} label="ID Number" />
          <FormInput name={`${name}.IDIssueDate`} label="ID Issue Date" type="date" />
          <ChakraInputDropdown
            placeHolder="- Select ID Issue Place -"
            name={`${name}.IDIssuePlace`}
            label="ID Issue Place"
            optionsData={citizenIssuePlaceOptions}
            isDisabledClearIndicator
            isCreatable
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <Stack padding={{ sm: '10px', lg: 0 }}>
            <ChakraInputDropdown
              name={`${name}.education`}
              label="Education"
              optionsData={educationOptions}
              isDisabledClearIndicator
            />
          </Stack>
          <FormInput name={`${name}.major`} label="Major" />
        </SimpleGrid>
        <Divider color="gray/200" />
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <FormInput name={`${name}.joinDate`} label="Join Date" type="date" />
          <GroupRadio name={`${name}.role`} isEdit data={UserRole} label="Role" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <FormInput name={`${name}.bankName`} label="Bank Name" type="textarea" />
          <FormInput name={`${name}.bankAccount`} label="Bank Account" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <ChakraInputDropdown name={`${name}.titleId`} label="Title *" optionsData={titleOptions} isRequire />
          <ChakraInputDropdown name={`${name}.levelId`} label="Level *" optionsData={levelOptions} isRequire />
        </SimpleGrid>
        {/* <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          TODO: update real data for technology later
          <ChakraInputDropdown
            isMulti
            name={`${name}.technologyId`}
            label="Technology*"
            optionsData={fakeTechnologyData}
            placeHolder="Select Technology"
          />
        </SimpleGrid> */}
        <SimpleGrid width="full" columns={{ base: 1, md: 2 }} gap={{ base: 6, lg: 8 }}>
          <FormControl>
            <HStack justifyContent="space-between" align="flex-start">
              <FormLabel color="gray.700" fontWeight={400}>
                Project
              </FormLabel>
              {checkValidArray(userProjects) && (
                <Link color="blue.600" fontWeight={400} target="_blank" href={diagramUrl}>
                  Edit Assignment
                </Link>
              )}
            </HStack>
            <ChakraInputDropdown
              isMulti
              name={`${name}.projects`}
              optionsData={projectsOptions}
              selectHandler={updateProjectRatingHandler}
            />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <ChakraInputDropdown
            name={`${name}.paymentCategoryId`}
            label="Payment Category*"
            optionsData={paymentCategoryOptions}
            isRequire
          />
          <ChakraInputDropdown
            isMulti
            isDisabled={!isFreelancerToo}
            name={`${name}.extraProjects`}
            label="Extra Projects"
            optionsData={extraProjectsOptions}
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name={`${name}.defaultRating`} label="Default Rate" isRequired />
          <FormInput name={`${name}.fixedSalary`} label="Net Salary (VND)" disabled={!isReceivedPayroll} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <GroupRadio name={`${name}.isSupervisor`} isEdit data={isSupervisor} label="Is Supervisor" />
          <ChakraInputDropdown
            isDisabled={disableSupervisor?.toLowerCase() === 'yes' ? false : true}
            isMulti
            name={`${name}.projectsOfSupervisor`}
            label="Projects Of Supervisor"
            optionsData={extraProjectsOptions}
          />
        </SimpleGrid>
        {/* <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          TODO: update real data for technology later
          <ChakraInputDropdown
            isMulti
            name={`${name}.technologyId`}
            label="Technology*"
            optionsData={fakeTechnologyData}
            placeHolder="Select Technology"
          />
        </SimpleGrid> */}
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, md: 8, lg: 8 }}>
          <ChakraInputDropdown
            isMulti
            name={`${name}.externalProjects`}
            label="External Projects"
            optionsData={externalProjectsOptions}
            helperText={Messages.externalProjectNote}
          />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <GroupRadio name={`${name}.allowToDownloadCV`} isEdit data={isDowloadCV} label="Is Download CV ?" />
          <GroupRadio name={`${name}.isActive`} isEdit data={isActive} label="Is Active ?" />
        </SimpleGrid>
      </VStack>
    </Container>
  )
}
export default EditUserForm
