import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { generatePath } from 'react-router'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Helper from '../../../../../Helper'
import { RoutesConstants } from '../../../../../constants'

import '../../index.scss'

const styles = {
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
  },
  redButton: {
    color: 'white',
    background: '#DB5461',
    minHeight: '31px',
    fontSize: '90%',
    marginTop: '10px',
    marginRight: '-10px',
    padding: '4px 8px',
    minWdth: '64px',
  },
  blueButton: {
    color: '#00B9AE',
    background: 'white',
    minHeight: '31px',
    fontSize: '90%',
    fontWeight: 'bold',
    margin: '-25px 0px 0px auto',
  },
}

function generateDateOfProject(item) {
  if (item.untilNow) {
    return `${Helper.getFormattedDate(item.startedDate)} - Now`
  } else {
    return `${Helper.getFormattedDate(item.startedDate)} - ${Helper.getFormattedDate(item.endedDate)}`
  }
}

function SimpleMediaCard(props) {
  const { classes, data, key } = props
  const pathname = generatePath(RoutesConstants.PROJECT_DETAIL, { id: data._id })
  return (
    <div className="cardright item" key={key}>
      <Card className="item-content">
        <CardMedia className={classes.media} image={Helper.generateImageURL(data.logo, 'logo')} title={data.name} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h3" className="cardtitle">
            <Link className="to-parameters-link" to={pathname}>
              {data.name}
            </Link>
          </Typography>
          <Typography component="p" className="cardp">
            {generateDateOfProject(data)}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

SimpleMediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SimpleMediaCard)
