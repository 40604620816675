import { Avatar, FormControl, FormLabel, HStack, Tooltip, SimpleGrid, Wrap, WrapItem } from '@chakra-ui/react'
import Helper from 'Helper'
import { IUser } from 'types/user'
import { getValidArray } from 'utils'

export interface IAvatarDevGroup {
  name?: string
  data?: IUser[]
  label?: string
}

const AvatarDevGroup = (props: IAvatarDevGroup) => {
  const { name, label, data } = props
  return (
    <FormControl id={name} width="100%">
      <HStack alignItems={'flex-start'} justifyContent={'space-between'}>
        <FormLabel fontWeight={'nomal'} mb={2} color="gray.700">
          {label}
        </FormLabel>
        <FormLabel fontWeight={'normal'} color="blue.500">
          {getValidArray(data).length === 0 ? '' : getValidArray(data).length + ' developers'}
        </FormLabel>
      </HStack>
      <HStack justifyContent="space-between">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
          <Wrap>
            {getValidArray(data).map((item, index) => (
              <WrapItem>
                <Tooltip label={item.fullName}>
                  <Avatar
                    height={'24px'}
                    width={'24px'}
                    key={index}
                    src={Helper.generateImageURL(item.avatar, 'avatar')}
                    _hover={{
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              </WrapItem>
            ))}
          </Wrap>
        </SimpleGrid>
      </HStack>
    </FormControl>
  )
}

export default AvatarDevGroup
