import { Box } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { ERoleOfUser } from 'constants/enum'
import { ReactComponent as Dashboard } from '../../../../../../assets/images/svg/dashboard.svg'
import { ReactComponent as Diagram } from '../../../../../../assets/images/svg/diagram.svg'
import { ReactComponent as InternalDoc } from '../../../../../../assets/images/svg/internal-doc.svg'
import { ReactComponent as Partner } from '../../../../../../assets/images/svg/partner.svg'
import { ReactComponent as Settings } from '../../../../../../assets/images/svg/settings.svg'
import { ReactComponent as User } from '../../../../../../assets/images/svg/user.svg'
import { RoutesConstants } from '../../../../../../constants'
import { ESidebarHeaderTitle, ESidebarMenuContent, IRouteName } from '../../constant'
import { getRouteName } from '../../utils'
interface ISubmenuProps {
  getClassName?: (name: string) => string
}

const SubmenuAdmin = (props: ISubmenuProps) => {
  const { getClassName } = props
  const role: string = window.localStorage.getItem('userRole')
  const isAdmin: boolean = role === ERoleOfUser.ADMIN
  const route: IRouteName = getRouteName(isAdmin)
  return (
    <>
      <Box className="sidebar-header">{ESidebarHeaderTitle.MANAGEMENT}</Box>
      <Box className={getClassName('partners')}>
        <Link to={route.partner}>
          <Partner className="icon" /> {ESidebarMenuContent.PARTNER}
        </Link>
      </Box>
      <Box className={getClassName('users')}>
        <Link to={route.userRoute}>
          <User className="icon" /> {ESidebarMenuContent.USERS}
        </Link>
      </Box>
      <Box className={getClassName('settings')}>
        <Link to={RoutesConstants.SETTINGS}>
          <Settings className="icon" /> {ESidebarMenuContent.SETTINGS}
        </Link>
      </Box>
    </>
  )
}

export default SubmenuAdmin
