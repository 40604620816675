import { chakra, Text, SimpleGrid, VStack, HStack } from '@chakra-ui/react'

export const SectionHeader = chakra(Text, {
  baseStyle: () => ({
    fontWeight: 'bold',
    margin: 0,
    fontSize: { base: '16px', md: '18px' },
    alignSelf: 'flex-start',
  }),
})

export const SectionBody = chakra(VStack, {
  baseStyle: () => ({
    alignItems: 'flex-start',
    width: '100%',
  }),
})

export const SectionWrapper = chakra(VStack, {
  baseStyle: () => ({
    boxSizing: 'border-box',
    padding: { base: 4 },
    width: '100%',
    background: {
      base: 'white',
      md: 'none',
    },
  }),
})

export const GridLayout = chakra(SimpleGrid, {
  baseStyle: () => ({
    columns: { base: 1, md: 2 },
    gap: { base: 4, lg: 8 },
    width: 'full',
    padding: { base: 0 },
  }),
})

export const ContentWrapper = chakra(VStack, {
  baseStyle: () => ({
    spacing: { base: '12px' },
    width: '100%',
  }),
})

export const FormHeaderTitle = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'lg',
    color: 'gray.700',
    margin: 0,
    lineHeight: 7,
    fontWeight: 500,
  }),
})

export const FormHeaderWrapper = chakra(HStack, {
  baseStyle: () => ({
    margin: { base: '12px 0px', md: 6 },
    paddingX: { base: 4 },
    height: 'full',
    justifyContent: 'space-between',
    width: 'full',
    boxSizing: 'border-box',
  }),
})
