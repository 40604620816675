import { IInQuery } from 'API/constants'
import { IOption } from 'constants/common'
import { ICreatableOption, IInternalDocument, IPartner } from 'constants/schema'

export const INTERNAL_DOCUMENT_BASE_URL = '/internal-documents'

export interface IInternalDocumentFilter {
  partnerId?: IInQuery
  typeId?: IInQuery
  scope?: IInQuery
  month?: number
  year?: number
}

export interface IInternalDocumentsPayload {
  currentPage?: number
  perPage?: number
  filter?: IInternalDocumentFilter
}

export interface IInternalDocuments {
  count?: number
  internalDocuments?: IInternalDocument[]
}

export interface IInternalDocumentDetail {
  internalDocument?: IFormattedInternalDocument
}

export interface IFormattedInternalDocument extends Omit<IInternalDocument, 'partnerId' | 'typeId'> {
  partnerId?: string | IOption
  typeId?: string | IOption
  partner?: IPartner[]
  typeData?: ICreatableOption[]
}
