import { FormControl, FormLabel, Stack, Radio, RadioGroup } from '@chakra-ui/react'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react'
import { useFormContext, useWatch } from 'react-hook-form'
import { getValidArray } from 'utils'

export interface IGroupRadio {
  name: string
  label?: any
  isEdit?: boolean
  data?: string[]
  defaultValue?: string
}

const GroupRadio = (props: IGroupRadio) => {
  const { name, label, isEdit, data, defaultValue } = props
  const { register, control } = useFormContext()
  const currentValue: string = useWatch({ control, name })

  return (
    <FormControl id={name} width="full">
      <FormLabel fontWeight={'nomal'} mb={2} color="gray.700">
        {label}
      </FormLabel>
      <RadioGroup defaultValue={defaultValue} value={currentValue}>
        <Stack
          spacing={{ xl: '8em', lg: '4em', md: '2em', sm: '1em' }}
          direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'column' }}>
          {getValidArray(data).map((item, index) => (
            <Radio {...register(name)} key={index} value={item} colorScheme="blue">
              {capitalize(item)}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  )
}

export default observer(GroupRadio)
