import { IInQuery } from 'API/constants'
import { IProject } from 'types/project'
import { IProject as IProjectSchema } from 'constants/schema'

export const PROJECT_BASE_URL: string = '/projects'

export interface IProjectsOfUserResponse {
  projects: IProject[]
}

export interface IProjectDetailOfUserResponse {
  project: IProject
}

export interface IProjectFilter {
  isActive?: boolean
  isArchived?: boolean
  name?: {
    $regex: string
    $options: string
  }
  userId?: IInQuery
  country?: IInQuery
  partnerId?: IInQuery
  technology?: IInQuery
}

export interface IProjectListWithPaginationRequest {
  currentPage: number
  perPage: number
  filter: IProjectFilter
}

export interface IProjectListWithPaginationResponse {
  count: number
  projects: IProjectSchema[]
}

export interface IProjectMessageResponse {
  message: string
}

export interface IProjectDetailResponse {
  project?: IProject
  message?: string
}

export interface IProjectListResponse {
  projects: IProject[]
}
