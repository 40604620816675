import React, { useEffect } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react'
import { updateFixedProjectRating } from 'API/projectRating'
import get from 'lodash/get'
import moment from 'moment'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { backgroundGrey200 } from 'theme/globalStyles'
import { IFixedProjectRating } from 'constants/schema'
import { SaveButton } from 'containers/UserNew/UserDetail/userDetail.styles'
import DatePicker from 'containers/v2/TimeSheet/TimeSheetUser/components/DatePicker'
import { useStores } from 'utils/hooks/useStores'
import FormView from '../../../../../../FormView'

interface IFixedProjectEditProps {
  data: IFixedProjectRating
  projectRatings: IFixedProjectRating[]
  isOpen: boolean
  onClose: () => void
}

const FixedProjectEdit = (props: IFixedProjectEditProps) => {
  const { data, projectRatings, isOpen, onClose } = props
  const { adminUserStore } = useStores()
  const { userDetail } = adminUserStore
  const methods = useForm<IFixedProjectRating>()
  const { control, getValues, setValue, register, reset } = methods

  function pickApplyDate(date: Date): void {
    setValue('applyDate', moment(date).format('YYYY-MM-DD'))
  }

  async function onSubmit(): Promise<void> {
    try {
      const updatedData: IFixedProjectRating = getValues()
      const fixedRatingPrices: IFixedProjectRating[] = projectRatings.map((projectRating: IFixedProjectRating) => {
        if (projectRating?._id === updatedData?._id) {
          return {
            ...updatedData,
            fixedRate: Number(updatedData?.fixedRate),
          }
        }
        return projectRating
      })
      await Promise.all([
        updateFixedProjectRating(userDetail?.profileDetailData?._id, { fixedRatingPrices }),
        adminUserStore.getUserDetail(userDetail?.profileDetailData?._id, true),
      ])
      toast.success('Update Project Rating Successfully')
      onClose()
    } catch (error) {
      toast.error('Update Project Rating Failed')
    }
  }

  useEffect(() => {
    reset(data)
  }, [data])

  return (
    <Drawer placement="bottom" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius={6}>
        <DrawerHeader fontSize="lg" fontWeight={500} lineHeight={7} padding={4}>
          Edit Project Rating
        </DrawerHeader>
        <DrawerCloseButton cursor="pointer" color="gray" background="none" />
        <DrawerBody padding="20px 16px" borderY={`1px solid ${backgroundGrey200}`}>
          <FormProvider {...methods}>
            <form>
              <VStack spacing={6} alignItems="flex-start">
                <FormView label="User">
                  <Input
                    value={userDetail?.profileDetailData?.fullName}
                    readOnly
                    required
                    background="gray.100"
                    focusBorderColor="none"
                    border={0}
                    boxSizing="border-box"
                  />
                </FormView>
                <FormView label="Project">
                  <Input
                    value={get(data, 'project.label')}
                    readOnly
                    required
                    background="gray.100"
                    focusBorderColor="none"
                    border={0}
                    boxSizing="border-box"
                  />
                </FormView>
                <FormControl id="fixedRate">
                  <FormLabel fontWeight={400} color="gray.500">
                    Fixed Rate
                  </FormLabel>
                  <Input defaultValue={data?.fixedRate} {...register('fixedRate')} boxSizing="border-box" />
                </FormControl>
                <FormControl id="applyDate">
                  <FormLabel fontWeight={400} color="gray.500">
                    Apply Date
                  </FormLabel>
                  <Controller
                    name="applyDate"
                    control={control}
                    render={({ field: { value } }) => (
                      <DatePicker selectedDate={new Date(value)} onChange={pickApplyDate} placement="top" />
                    )}
                  />
                </FormControl>
              </VStack>
            </form>
          </FormProvider>
        </DrawerBody>
        <DrawerFooter padding={4}>
          <SaveButton onClick={onSubmit}>Save</SaveButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default FixedProjectEdit
