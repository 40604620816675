import { ChangeEvent, useRef, useState, useEffect } from 'react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { VStack, Image, Text, Container, Button, Input, HStack } from '@chakra-ui/react'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import Helper from 'Helper'
import { useForm, useFormContext } from 'react-hook-form'
import { FaFileUpload } from 'react-icons/fa'
import ApiHelper from '../../../../Helper/api'
import styles from './index.module.scss'
interface ILogoProjectProps {
  type?: string
  name?: string
  afterUploadHandler?
  isDetail?: boolean
}

const LogoProject = (props: ILogoProjectProps) => {
  const { getValues } = useFormContext()
  const avatarRef = useRef<HTMLInputElement | null>(null)
  const [selectedImage, selectImage] = useState<File | null>(null)
  const [preview, setPreview] = useState('')
  const [logoURL, setLogoURL] = useState(null)
  const { type, name, afterUploadHandler, isDetail } = props

  const logoURLForm = getValues(name)
  const fullLogoURL = Helper.generateImageURL(logoURLForm, 'logo')

  const { register } = useForm()

  function handleChooseLogo(): void {
    avatarRef.current?.click()
  }

  function handleClearLogo(): void {
    setPreview('')
  }

  function handleChangeAvatar(event: ChangeEvent<HTMLInputElement>): void {
    if (!!event?.target?.files?.[0]) {
      !!event?.target?.files?.[0] && selectImage(event?.target?.files?.[0])
    }

    const file = event.target.files[0]

    const formData = new FormData()
    formData.append('myImage', file)
    ApiHelper.upload(
      {
        url: `/upload/images/${type}`,
        data: formData,
      },
      (response) => {
        afterUploadHandler(response.data.fileName)
      }
    )
  }

  useEffect(() => {
    setLogoURL(fullLogoURL)
  }, [fullLogoURL])

  useEffect(() => {
    if (!selectedImage) {
      setPreview('')
      return
    }

    const objectUrl = URL.createObjectURL(selectedImage)
    setPreview(objectUrl)

    //* INFO: disable unnecessary eslint rule eslint-disable-next-line consistent-return
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedImage])

  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack className={styles.logoSection}>
        <Text alignSelf={'flex-start'} className={`${styles.headerBox}`} fontSize={{ base: '18px' }} paddingBottom={2}>
          Logo
        </Text>
        {fullLogoURL ? (
          <Image src={preview.length === 0 ? logoURL : preview} alt="logo project" className={styles.avatarImage} />
        ) : (
          <EmptyContentBox text="No Photo" />
        )}
        {!isDetail && (
          <HStack>
            <Button background={'blackAlpha.600'} onClick={handleChooseLogo}>
              <FaFileUpload fontSize={'16px'} color={'gray.700'} />
            </Button>
            <Input
              {...register('file')}
              type="file"
              ref={avatarRef}
              accept={'image/*'}
              display="none"
              onChange={handleChangeAvatar}
            />
            {fullLogoURL && (
              <Button background={'blackAlpha.600'} onClick={handleClearLogo}>
                <SmallCloseIcon fontSize={'16px'} />
              </Button>
            )}
          </HStack>
        )}
      </VStack>
    </Container>
  )
}

export default LogoProject
