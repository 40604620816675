import * as React from 'react'
import { FormControl, FormLabel, Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import styles from './styles.module.scss'

interface InputFieldProps extends InputProps {
  label: string
  name?: string
  errors?: any
  isPassword?: boolean
}

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const { label, name, errors, isPassword, ...rest } = props
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  function handleClickShowPassword(): void {
    setShowPassword(!showPassword)
  }

  function getInputType(): string {
    if (isPassword) {
      return showPassword ? 'text' : 'password'
    }
    return 'text'
  }

  return (
    <FormControl>
      <FormLabel className={styles.labelForm}>{label}</FormLabel>
      <InputGroup size="md">
        <Input
          ref={ref}
          w="calc(100% - 34px)"
          name={name}
          size="lg"
          fontSize="md"
          type={getInputType()}
          className={styles.inputField}
          {...rest}
        />
        {isPassword && (
          <InputRightElement mr={'10px'}>
            <span className={styles.showPassword} onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </span>
          </InputRightElement>
        )}
      </InputGroup>
      <ErrorMessage
        errors={errors || {}}
        name={name}
        render={({ message }) => <p className={styles.errorMessage}>{message}</p>}
      />
    </FormControl>
  )
})

InputField.displayName = 'InputField'

export default InputField
