import { chakra, HStack } from '@chakra-ui/react'

export const OptionWrapper = chakra(HStack, {
  baseStyle: () => ({
    paddingLeft: 4,
    boxSizing: 'border-box',
    paddingY: 2.5,
    height: 10,
    justifyContent: 'space-between',
    _hover: {
      cursor: 'pointer',
      background: 'gray.200',
    },
  }),
})
