import { Textarea as CkTextarea } from '@chakra-ui/react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { BaseStyle } from 'types/common'
import { chakraShouldForwardProp } from 'utils'
import { ITextAreaProps } from './index'

type TextAreaUnionProps = ITextAreaProps & UseFormRegisterReturn & BaseStyle

export const TextArea = chakraShouldForwardProp<TextAreaUnionProps>(CkTextarea, (props: BaseStyle) => {
  const { isHighligh, isReadonly, disabled } = props
  const disabledProps = disabled
    ? {
        disabled: true,
        background: 'white',
        color: 'gray.700',
      }
    : {}
  return {
    ...disabledProps,
    resize: 'none',
    padding: 0,
    fontSize: 'md',
    color: isHighligh ? 'blue.500' : 'gray.700',
    fontWeight: 500,
    width: '90%',
    border: isReadonly ? 'none' : '1px solid black',
    minHeight: 0,
    height: '25px',
    '&:hover': {
      bg: 'white',
    },
  }
})
