import { FormLabel } from '@material-ui/core'
import { Ace } from 'ace-builds'
import cx from 'classnames'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/webpack-resolver'
import styles from './jsEditor.module.scss'

interface IJSEditorProps {
  name?: string
  value?: string
  className?: string
  isDisable?: boolean
  fontSize?: number
  label?: string
  onChange?: (value: string, event?: any) => void
  errorMessage?: string
  placeholder?: string
  debounceChangePeriod?: number
  onValidate?: (errors: Ace.Annotation[]) => void
}

const JSEditor = (props: IJSEditorProps) => {
  const {
    name,
    value,
    className,
    isDisable,
    fontSize = 14,
    label,
    onChange,
    errorMessage,
    placeholder,
    onValidate,
  } = props

  return (
    <div className={cx({ [styles.disabled]: isDisable }, 'input-field')}>
      <FormLabel className={styles.inputLabel}>{label}</FormLabel>
      <AceEditor
        name={name}
        readOnly={isDisable}
        className={cx(className, styles.container)}
        placeholder={placeholder}
        mode="javascript"
        theme="xcode"
        fontSize={fontSize}
        showPrintMargin
        showGutter
        highlightActiveLine
        onChange={onChange}
        value={value}
        onValidate={onValidate}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
      <FormLabel className="error-message">{errorMessage}</FormLabel>
    </div>
  )
}

export default JSEditor
