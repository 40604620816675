const activityRoutes = [
  { event: 'timesheet', keys: ['01010', '02011', '03011'] },
  { event: 'user', keys: ['04100', '05100', '06100'] },
  { event: 'project', keys: ['07100', '08100', '09100'] },
  { event: 'feedback', keys: ['10010', '11010', '12011'] },
  { event: 'policy', keys: ['13100', '14100', '15100'] },
  { event: 'information', keys: ['16100', '17100', '18100'] },
  { event: 'setting', keys: ['19010', '20010', '21010'] },
]

export default activityRoutes
