import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import errorHandler from 'Helper/errorHandler'
import get from 'lodash/get'
import { AppConfig } from 'constants/index'
import { EAuthStorageKey, IHeader } from './constants'

const api: AxiosInstance = axios.create({
  baseURL: AppConfig.API_URL,
})

api.interceptors.request.use(function (config: AxiosRequestConfig) {
  const authHeader: IHeader = getAuthHeader()
  config.headers = {
    ...authHeader,
  }
  return config
})

// *INFO: handle after response base on apiHelper 'src/Helper/api.js'
api.interceptors.response.use(
  function (response: AxiosResponse) {
    return {
      status: response?.status,
      data: get(response?.data, 'data', response?.data),
    }
  },
  function (error: AxiosError) {
    errorHandler(error)
    throw error
  }
)

function getAuthHeader(): IHeader {
  const isRememberMe: boolean = JSON.parse(window.localStorage.getItem(EAuthStorageKey.IS_REMEMBER_ME))

  const requestToken: string = isRememberMe
    ? window.localStorage.getItem(EAuthStorageKey.REQUEST_TOKEN)
    : window.sessionStorage.getItem(EAuthStorageKey.REQUEST_TOKEN)

  return {
    Authorization: 'Bearer ' + requestToken,
  }
}

export function getUploadHeader(): IHeader {
  const authHeader: IHeader = getAuthHeader()
  return {
    ...authHeader,
    'Content-Type': 'application/x-www-form-urlencoded',
  }
}

export default api
