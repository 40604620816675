export interface IFormLabelProps {
  name: string
  label: string
  isEdit: boolean
  isHighlighted?: boolean
}

export interface IFormInputProps {
  name: string
  label: string
  placeholder?: string
  isRequired?: boolean
}

export interface IFormSectionBaseProps {
  isEditing: boolean
}

export interface ILogoAndSettingSectionProps extends IFormSectionBaseProps {
  type?: string
  name?: string
}

export enum ESectionHeaderValues {
  GENERAL_INFORMATION = 'General Information',
  CUSTOMER_INFORMATION = 'Customer Information',
  PAYMENT = 'Payment',
  WORKING_INFORMATION = 'Working Information',
  LOGO = 'Logo',
  SETTINGS = 'Settings',
}
