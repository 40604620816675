import FormFieldWithLabel from 'componentsCharka/FormFieldWithLabel'
import PhoneNumberInput from 'componentsCharka/PhoneNumberInputWithDialCode'
import { IFormSectionBaseProps, ESectionHeaderValues } from '../../constants'
import { SectionWrapper, SectionHeader, GridLayout, SectionBody } from '../../projectDetailAdmin.styles'
import { getFormInputProps, getFormLabelProps } from '../../utils'
import FormInput from '../FormInput'

const CustomerInformationSection = (props: IFormSectionBaseProps) => {
  const { isEditing } = props

  return (
    <SectionWrapper spacing={{ base: 4 }}>
      <SectionHeader>{ESectionHeaderValues.CUSTOMER_INFORMATION}</SectionHeader>
      <SectionBody spacing={{ base: isEditing ? 6 : 4 }}>
        <GridLayout gap={{ base: !isEditing ? 4 : 6 }}>
          <FormFieldWithLabel {...getFormLabelProps('customer', 'Customer Name', isEditing)}>
            {isEditing && (
              <FormInput {...getFormInputProps('customer', 'Customer Name', 'Enter Customer name', true)} />
            )}
          </FormFieldWithLabel>
          <FormFieldWithLabel {...getFormLabelProps('customerEmail', 'Customer Email', isEditing, true)}>
            {isEditing && (
              <FormInput {...getFormInputProps('customerEmail', 'Customer Email (Optional)', 'Enter Customer email')} />
            )}
          </FormFieldWithLabel>
          <FormFieldWithLabel {...getFormLabelProps('customerPhone', 'Customer Phone Number', isEditing, true)}>
            {isEditing && (
              <PhoneNumberInput {...getFormInputProps('customerPhone', 'Customer Phone Number (Optional)')} />
            )}
          </FormFieldWithLabel>
        </GridLayout>
      </SectionBody>
    </SectionWrapper>
  )
}

export default CustomerInformationSection
