import { ReactComponent as ArchiveIcon } from '../../../../assets/images/svg/archive-fill.svg'
import { ReactComponent as ActiveIcon } from '../../../../assets/images/svg/collapse.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/svg/delete-bin-fill.svg'
import { ReactComponent as EditIcon } from '../../../../assets/images/svg/edit-fill.svg'
import { ReactComponent as DeactiveIcon } from '../../../../assets/images/svg/error-warning-fill.svg'
import { ReactComponent as EyeIcon } from '../../../../assets/images/svg/eye-fill-gray.svg'
import { ReactComponent as DashboardIcon } from '../../../../assets/images/svg/dashboard.svg'

interface IIconWithNameProps {
  iconName: string
}

const IconWithName = (props: IIconWithNameProps) => {
  const { iconName } = props

  return (
    <>
      {iconName.toLowerCase().includes('archive') && <ArchiveIcon style={{ marginRight: '8px' }} />}
      {iconName.includes('Delete') && <DeleteIcon style={{ marginRight: '8px' }} />}
      {iconName.includes('Edit') && <EditIcon width="20px" height="20px" style={{ marginRight: '8px' }} />}
      {iconName.includes('detail') && <EyeIcon width="20px" height="20px" style={{ marginRight: '8px' }} />}
      {iconName.includes('Deactivate') && <DeactiveIcon style={{ marginRight: '8px' }} />}
      {iconName.includes('Activate') && <ActiveIcon style={{ marginRight: '8px' }} />}
      {iconName.includes('Dashboard') && <DashboardIcon width="20px" height="20px" style={{ marginRight: '8px' }} />}
    </>
  )
}

export default IconWithName
