const CONSTANT = {
  CREATE_TIMESHEET: '01010',
  EDIT_TIMESHEET: '02011',
  DELETE_TIMESHEET: '03011',

  CREATE_USER: '04100',
  EDIT_USER: '05100',
  DELETE_USER: '06100',

  CREATE_PROJECT: '07100',
  EDIT_PROJECT: '08100',
  DELETE_PROJECT: '09100',

  CREATE_FEEDBACK: '10010',
  EDIT_FEEDBACK: '11010',
  REPLY_FEEDBACK: '12011',

  CREATE_POLICY: '13100',
  EDIT_POLICY: '14100',
  DELETE_POLICY: '15100',

  CREATE_INFORMATION: '16100',
  EDIT_INFORMATION: '17100',
  DELETE_INFORMATION: '18100',

  CREATE_SETTING: '19010',
  EDIT_SETTING: '20010',
  DELETE_SETTING: '21010',
}

const activities = {
  [CONSTANT.CREATE_TIMESHEET]: {
    notificationContentOf_broadCast: '',
    notificationContentOf_admin: ' has been input a timesheet',
    notificationContentOf_user: 'A your timesheet has been input by ',
    type: 'timesheet',
  },
  [CONSTANT.EDIT_TIMESHEET]: {
    notificationContentOf_broadCast: '',
    notificationContentOf_admin: ' has been updated a timesheet',
    notificationContentOf_user: 'one of your timesheet has been updated by ',
    type: 'timesheet',
  },
  [CONSTANT.DELETE_TIMESHEET]: {
    notificationContentOf_broadCast: '',
    notificationContentOf_admin: ' has been deleted a timesheet',
    notificationContentOf_user: 'one of your timesheet has been deleted by ',
    type: 'timesheet',
  },
  [CONSTANT.CREATE_USER]: {
    content: '{name} has been added',
    notificationContentOf_broadCast: ' has been added to our network, click here to check it out',
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'user',
  },
  [CONSTANT.EDIT_USER]: {
    content: '{name} has been edited',
    notificationContentOf_broadCast: ' has been updated',
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'user',
  },
  [CONSTANT.DELETE_USER]: {
    content: '{name} has been removed',
    notificationContentOf_broadCast: ' has been removed from our network, what a pitty!',
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'user',
  },
  [CONSTANT.CREATE_PROJECT]: {
    content: '{name} has been created',
    notificationContentOf_broadCast: "  has been added let's take a look",
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'project',
  },
  [CONSTANT.EDIT_PROJECT]: {
    content: '{name} has been edited',
    notificationContentOf_broadCast: ' has been updated',
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'project',
  },
  [CONSTANT.DELETE_PROJECT]: {
    content: '{name} has been removed',
    notificationContentOf_broadCast: '  has been removed, what a pitty!',
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'project',
  },
  [CONSTANT.CREATE_INFORMATION]: {
    content: '{name} has been created',
    notificationContentOf_broadCast: "Hey there, don't be an ancient guy!!!!",
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'information',
  },
  [CONSTANT.EDIT_INFORMATION]: {
    content: '{name} has been edited',
    notificationContentOf_broadCast: 'Ohhh, the information was updated, check it out!',
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'information',
  },
  [CONSTANT.DELETE_INFORMATION]: {
    content: '{name} has been removed',
    notificationContentOf_broadCast: 'Opps, something was deleted',
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'information',
  },
  [CONSTANT.CREATE_POLICY]: {
    content: '{name} has been created',
    notificationContentOf_broadCast: "Hi everyone, there is a policy published, don't miss it",
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'policy',
  },
  [CONSTANT.EDIT_POLICY]: {
    content: '{name} has been edited',
    notificationContentOf_broadCast: 'Hi everyone, a policy has been updated, please take a look',
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'policy',
  },
  [CONSTANT.DELETE_POLICY]: {
    content: '{name} has been removed',
    notificationContentOf_broadCast: 'Phew.... another policy has been removed',
    notificationContentOf_admin: '',
    notificationContentOf_user: '',
    type: 'policy',
  },
  [CONSTANT.CREATE_SETTING]: {
    content: '{name} has been created',
    notificationContentOf_broadCast: '',
    notificationContentOf_admin: 'Setting is configured successfully',
    notificationContentOf_user: '',
    type: 'policy',
  },
  [CONSTANT.EDIT_SETTING]: {
    content: '{name} has been edited',
    notificationContentOf_broadCast: '',
    notificationContentOf_admin: 'Setting is updated',
    notificationContentOf_user: '',
    type: 'policy',
  },
  [CONSTANT.DELETE_SETTING]: {
    content: '{name} has been removed',
    notificationContentOf_broadCast: '',
    notificationContentOf_admin: 'Setting has something removed',
    notificationContentOf_user: '',
    type: 'policy',
  },
  [CONSTANT.CREATE_FEEDBACK]: {
    content: '{name} has been created',
    notificationContentOf_broadCast: '',
    notificationContentOf_admin: 'Hey, you have a feedback',
    notificationContentOf_user: '',
    type: 'feedback',
  },
  [CONSTANT.EDIT_FEEDBACK]: {
    content: '{name} has been edited',
    notificationContentOf_broadCast: '',
    notificationContentOf_admin: 'Hey, a feedback has been updated',
    notificationContentOf_user: '',
    type: 'feedback',
  },
  [CONSTANT.REPLY_FEEDBACK]: {
    content: '{name} has been removed',
    notificationContentOf_broadCast: '',
    notificationContentOf_admin: 'A feedback has been resolved',
    notificationContentOf_user: 'A feedback has been resolved',
    type: 'feedback',
  },
}

export { activities }
