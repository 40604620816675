import { HStack, Button, Box, useMediaQuery } from '@chakra-ui/react'
import MoreDropBox from 'components/Table/components/MoreDropBox'
import ActionButton from 'componentsCharka/ActionButton'
import { textGrey700, borderColor, submitButtonBackground, backgroundSecondary } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'
import { ReactComponent as Edit } from '../../../../../../assets/images/svg/edit-white.svg'
import { IRowActionItem } from '../../../constants'

export interface IActionButtonGroupProps {
  isEditing: boolean
  actions: IRowActionItem[]
  switchToEditForm: () => void
  switchToDetailForm: () => void
}

const ActionButtonGroup = (props: IActionButtonGroupProps) => {
  const { isEditing, actions, switchToEditForm, switchToDetailForm } = props
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)

  return (
    <HStack>
      {!isEditing ? (
        <>
          <Button
            fontWeight="500"
            background={backgroundSecondary}
            variant="solid"
            onClick={switchToEditForm}
            leftIcon={<Edit />}
            width={10}
            height={10}
            iconSpacing={0}
            _hover={{
              cursor: 'pointer',
              background: backgroundSecondary,
            }}
          />
          <Box background="white" height={10} borderRadius={6} display="flex" alignItems="center">
            <MoreDropBox actions={actions} />
          </Box>
        </>
      ) : (
        <>
          {!isMobile && (
            <ActionButton
              title="Cancel"
              buttonStyles={{
                color: textGrey700,
                background: 'white',
                border: `1px solid ${borderColor}`,
              }}
              onClick={switchToDetailForm}
            />
          )}
          <ActionButton
            title="Save"
            type="submit"
            buttonStyles={{
              color: 'white',
              background: submitButtonBackground,
              border: `1px solid ${borderColor}`,
              borderRadius: '6px',
              _hover: {
                background: submitButtonBackground,
              },
            }}
          />
        </>
      )}
    </HStack>
  )
}

export default ActionButtonGroup
