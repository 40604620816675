import { useMediaQuery } from '@chakra-ui/react'
import { maxMobileSize } from 'constants/common'
import ProjectDetailAdminMobile from '../V2/ProjectDetailAdminMobile'
import ProjectDetailAdminDesktop from './components/ProjectDetailAdminDesktop'

const ProjectAdminDetail = () => {
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  return <>{isMobile ? <ProjectDetailAdminMobile /> : <ProjectDetailAdminDesktop />}</>
}

export default ProjectAdminDetail
