import { ITableHeader } from 'components/Table'

export function getHourlyProjectHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'PROJECT',
      accessor: 'project',
    },
    {
      Header: 'HOURLY RATE',
      accessor: 'hourlyRate',
    },
    {
      Header: 'CLIENT RATE',
      accessor: 'clientRate',
    },
    {
      Header: 'APPLY DATE',
      accessor: 'applyDate',
    },
  ]

  return headers
}

export function getFixedProjectHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'PROJECT',
      accessor: 'project',
    },
    {
      Header: 'FIXED RATE',
      accessor: 'fixed',
    },
    {
      Header: 'APPLY DATE',
      accessor: 'applyDate',
    },
  ]

  return headers
}
