import { FormControl, FormLabel, HStack, Link, VStack } from '@chakra-ui/react'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import GroupRadioButton from 'componentsCharka/GroupRadioButton'
import Helper from 'Helper'
import { useFormContext, useWatch } from 'react-hook-form'
import { IProject } from 'types/project'
import { checkValidArray, getValidArray } from 'utils'
import { IOption } from 'constants/common'
import { citizenIssuePlaceOptions, educationOptions, genderOptions, UserRole } from 'constants/defaultOptionList'
import { ECreatableOptionTypes, EPaymentCategoryOfUser, EYesNoOption } from 'constants/enum'
import { RoutesConstants } from 'constants/index'
import { ICreatableOption } from 'constants/schema'
import { IPartner } from 'constants/schema'
import FormInput from 'containers/Diagram/components/FormInput'
import PasswordField from 'containers/UserNew/components/PasswordFiled'
import { EUserDetailTitle, isSupervisor } from 'containers/UserNew/UserDetail/constant'
import Section from '../../../Section'

interface IEditFormMobileProps {
  name: string
  userId: string
  partnerList: IPartner[]
  projectList: IProject[]
  creatableOptionList: ICreatableOption[]
  handleUpdateProjectRating: (newProjects: IOption[]) => void
}

const EditFormMobile = (props: IEditFormMobileProps) => {
  const { name, userId, partnerList, projectList, creatableOptionList, handleUpdateProjectRating } = props
  const { control } = useFormContext()
  const diagramUrl: string = `${RoutesConstants.DIAGRAM}?userId=${userId}`

  const options: ICreatableOption[] = getValidArray(creatableOptionList)
  const titleOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.TITLE_OF_USER)
  )
  const levelOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.LEVEL_OF_USER)
  )
  const paymentCategoryOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER)
  )
  const partnersOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(partnerList)
  const projectsOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(projectList)

  const userProjects: IProject[] = useWatch({ control, name: `${name}.projects` }) ?? []
  const extraProjectsOptions: IOption[] = Helper.createOptionsOfSelectFromDropdown(userProjects)
  const externalProjectsOptions: IOption[] = Helper.createOptionsOfSelectFromDropdown(userProjects)

  const paymentCategoryOfUser: IOption = useWatch({ control, name: `${name}.paymentCategoryId` })
  const disableSupervisor: string = useWatch({ control, name: `${name}.isSupervisor` })
  const isFreelancerToo: boolean =
    paymentCategoryOfUser !== undefined
      ? paymentCategoryOfUser?.label === EPaymentCategoryOfUser.MIXED_PAYROLL_FREELANCER_FEE
      : false
  const isReceivedPayroll: boolean =
    paymentCategoryOfUser !== undefined
      ? paymentCategoryOfUser?.label === EPaymentCategoryOfUser.PAYROLL ||
        paymentCategoryOfUser?.label === EPaymentCategoryOfUser.MIXED_PAYROLL_FREELANCER_FEE
      : false

  return (
    <Section title={EUserDetailTitle.USER_PROFILE_DETAIL}>
      <VStack spacing={6}>
        <ChakraInputDropdown
          widthCustom="full"
          name={`${name}.partnerId`}
          label="Partner"
          optionsData={partnersOptions}
        />
        <FormInput name={`${name}.fullName`} label="Full Name" isRequired />
        <FormInput name={`${name}.email`} label="Email" isRequired />
        <PasswordField name={`${name}.resetPassword`} label="Reset Password (Optional)" />
        <FormInput name={`${name}.phone`} label="Phone Number (Optional)" type="tel" />
        <FormInput name={`${name}.bankName`} label="Bank Name (Optional)" />
        <FormInput name={`${name}.bankAccount`} label="Bank Account (Optional)" />
        <ChakraInputDropdown
          name={`${name}.currentGender`}
          label="Gender"
          optionsData={genderOptions}
          widthCustom="full"
          isDisabledClearIndicator
        />
        <FormInput name={`${name}.placeOfBirth`} label="Place of Birth (Optional)" />
        <FormInput name={`${name}.dateOfBirth`} label="Date of Birth (Optional)" type="date" />
        <FormInput name={`${name}.address`} label="Permanent Address (Optional)" />
        <FormInput name={`${name}.nationality`} label="Nationality (Optional)" />
        <FormInput name={`${name}.IDNumber`} label="ID Number (Optional)" />
        <FormInput name={`${name}.IDIssueDate`} label="ID Issue Date (Optional)" type="date" />
        <ChakraInputDropdown
          widthCustom="full"
          placeHolder="- Select ID Issue Place -"
          name={`${name}.IDIssuePlace`}
          label="ID Issue Place (Optional)"
          optionsData={citizenIssuePlaceOptions}
          isDisabledClearIndicator
          isCreatable
        />
        <ChakraInputDropdown
          widthCustom="full"
          name={`${name}.education`}
          label="Education (Optional)"
          optionsData={educationOptions}
          isDisabledClearIndicator
        />
        <FormInput name={`${name}.major`} label="Major (Optional)" />
        <FormInput name={`${name}.joinDate`} label="Join Date" type="date" />
        <GroupRadioButton
          name={`${name}.role`}
          label="Role"
          options={UserRole}
          stackProps={{
            direction: 'row',
            spacing: 100,
          }}
        />
        <ChakraInputDropdown
          widthCustom="full"
          name={`${name}.titleId`}
          label="Title"
          optionsData={titleOptions}
          isRequire
        />
        <ChakraInputDropdown
          widthCustom="full"
          name={`${name}.levelId`}
          label="Level"
          optionsData={levelOptions}
          isRequire
        />
        <ChakraInputDropdown
          widthCustom="full"
          name={`${name}.paymentCategoryId`}
          label="Payment Category"
          optionsData={paymentCategoryOptions}
          isRequire
        />
        <FormInput name={`${name}.defaultRating`} label="Default Rate" isRequired />
        <FormInput name={`${name}.fixedSalary`} label="Net Salary (VND)" isDisabled={!isReceivedPayroll} />
        <GroupRadioButton
          name={`${name}.isSupervisor`}
          label="Is Supervisor"
          options={isSupervisor}
          stackProps={{
            direction: 'row',
            spacing: 100,
          }}
        />
        <ChakraInputDropdown
          widthCustom="full"
          isMulti
          name={`${name}.projectsOfSupervisor`}
          label="Projects Of Supervisor"
          optionsData={extraProjectsOptions}
          isDisabled={!(disableSupervisor?.toLowerCase() === EYesNoOption.YES)}
        />
        {/* TODO: mock data, integrate later */}
        {/* <ChakraInputDropdown
        widthCustom="full"
        isMulti
        name={`${name}.technologyId`}
        label="Technology"
        optionsData={fakeTechnologyData}
        placeHolder="Select Technology"
      /> */}
        <FormControl>
          <HStack justifyContent="space-between" align="flex-start">
            <FormLabel color="gray.700" fontWeight={400}>
              Project
            </FormLabel>
            {checkValidArray(userProjects) && (
              <Link color="blue.600" fontWeight={400} target="_blank" href={diagramUrl}>
                Edit Assignment
              </Link>
            )}
          </HStack>
          <ChakraInputDropdown
            isMulti
            name={`${name}.projects`}
            optionsData={projectsOptions}
            selectHandler={handleUpdateProjectRating}
          />
        </FormControl>
        <ChakraInputDropdown
          widthCustom="full"
          isMulti
          isDisabled={!isFreelancerToo}
          name={`${name}.extraProjects`}
          label="Extra Projects"
          optionsData={extraProjectsOptions}
        />
        <ChakraInputDropdown
          widthCustom="full"
          isMulti
          name={`${name}.externalProjects`}
          label="External Projects"
          optionsData={externalProjectsOptions}
        />
      </VStack>
    </Section>
  )
}

export default EditFormMobile
