import cx from 'classnames'

export interface IIconProps {
  iconName: string
  size?: number
  width?: string | number
  height?: string | number
  alt?: string
  onClick?: () => void
  className?: string
}

const Icon = (props: IIconProps) => {
  const { iconName, size, alt, width, height, className, onClick } = props
  const defaultSize: number = 20
  const iconSize: number = size ? size : defaultSize

  return (
    <img
      src={`/assets/icons/${iconName}`}
      alt={alt}
      width={width || iconSize}
      height={height || iconSize}
      className={cx(className, { [`is-clickable`]: onClick })}
      onClick={onClick}
    />
  )
}

export default Icon
