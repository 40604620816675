import { forwardRef } from 'react'
import { CalendarIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import ReactDatePicker from 'react-datepicker'
import './styles.scss'

const customDateInput = ({ value, onClick, onChange, style }: any, ref: any) => {
  const borderColor = style?.borderColor ?? '#3182ce'
  return (
    <InputGroup onClick={onClick} cursor="pointer">
      <Input
        autoComplete="off"
        value={value}
        ref={ref}
        style={style}
        onChange={onChange}
        _focus={{
          border: `1px solid ${borderColor}`,
          boxShadow: `0 0 0 1px ${borderColor} !important`,
          zIndex: 2,
          borderRadius: '8px',
        }}
        readOnly={true}
      />
      <InputRightElement color="gray.500" children={icon} />
    </InputGroup>
  )
}
customDateInput.displayName = 'DateInput'

const CustomInput = forwardRef(customDateInput)

const icon = <CalendarIcon fontSize="sm" />

interface IDatePickerProps {
  isClearable?: boolean
  onChange: (date: Date) => any
  selectedDate: Date | undefined
  showPopperArrow?: boolean
  placement?: string
  style?: Record<string, unknown>
}

const DatePicker = (props: IDatePickerProps) => {
  const { isClearable, onChange, selectedDate, showPopperArrow, placement, style } = props

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={onChange}
      isClearable={isClearable}
      showPopperArrow={showPopperArrow}
      className="react-datepicker"
      dateFormat="dd/MM/yyyy"
      popperPlacement={placement}
      customInput={<CustomInput style={style} />}
    />
  )
}

export default DatePicker
