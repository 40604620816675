import React, { useState } from 'react'
import { TableContainer, Table, Thead, Tbody, Tr, Td } from '@chakra-ui/react'
import { ReactComponent as InformationIcon } from 'assets/images/svg/information-white.svg'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import { getValidArray, checkValidArray } from 'utils'
import { Messages } from 'constants/index'
import { ILaborContractData } from 'containers/UserNew/UserDetail/constant'
import { InformationButton, MobileTag, MobileTableTh, MobileTableTd } from '../../../../userDetail.styles'
import { getTagBackground, getTagColor } from '../../utils'
import ContractDetail from '../ContractDetail'

interface IMobileContractTableProps {
  contractList: ILaborContractData[]
}

const MobileContractTable = (props: IMobileContractTableProps) => {
  const { contractList } = props
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false)
  const [selectedDetail, setSelectedDetail] = useState<number>(null)

  function handleShowDetail(index: number): void {
    setSelectedDetail(index)
    setIsShowDetail(!isShowDetail)
  }

  function handleCloseDetail(): void {
    setIsShowDetail(!isShowDetail)
  }

  return (
    <TableContainer>
      {checkValidArray(contractList) ? (
        <Table variant="striped" size="sm" overflowX="hidden">
          <Thead>
            <Tr height={10}>
              <MobileTableTh>STARTED DATE</MobileTableTh>
              <MobileTableTh>CATEGORY</MobileTableTh>
            </Tr>
          </Thead>
          <Tbody>
            {getValidArray(contractList).map((contract: ILaborContractData, index: number) => (
              <Tr key={contract?._id}>
                <MobileTableTd>{contract?.publishedDate}</MobileTableTd>
                <MobileTableTd>
                  {contract?.laborContractCategory && (
                    <MobileTag
                      size="sm"
                      color={getTagColor(contract?.laborContractCategory)}
                      background={getTagBackground(contract?.laborContractCategory)}>
                      {contract?.laborContractCategory}
                    </MobileTag>
                  )}
                </MobileTableTd>
                <Td width={14} padding={0}>
                  <InformationButton onClick={() => handleShowDetail(index)}>
                    <InformationIcon />
                  </InformationButton>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <EmptyContentBox text={Messages.noData} />
      )}
      <ContractDetail contract={contractList[selectedDetail]} isOpen={isShowDetail} handleOnClose={handleCloseDetail} />
    </TableContainer>
  )
}

export default MobileContractTable
