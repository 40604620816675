import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

import { Messages, AppConfig } from '../../../../constants/index'
import SendRequestDialogContent from './SendRequestDialogContent'

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions)

class CustomizedDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidRequestForm: true,
      newCommitValue: undefined,
      reason: '',
      isShowErrorMessage: false,
      errorMessage: '',
    }
  }

  checkValidationForm = () => {
    const { item } = this.props
    const { newCommitValue, reason } = this.state
    let isValid = true
    let errorMessage = ''
    let isShowErrorMessage = false
    const theLengthOfReason = reason.length
    const allowedMinCharacters = AppConfig.MIN_CHARACTERS_OF_REQUEST_COMMIT_TIME_REASON
    const allowedMaxCharacters = AppConfig.MAX_CHARACTERS_OF_REQUEST_COMMIT_TIME_REASON
    if (theLengthOfReason < allowedMinCharacters || theLengthOfReason > allowedMaxCharacters) {
      isValid = false
      isShowErrorMessage = true
      errorMessage += Messages.invalidNewCommitTimeReason + '. '
    }
    if (
      Number(newCommitValue) < 1 ||
      Number(newCommitValue) === item.commitTime ||
      Number(newCommitValue) > AppConfig.MAX_TIME_SEND_REQUEST_COMMIT_TIME ||
      isNaN(newCommitValue)
    ) {
      isValid = false
      isShowErrorMessage = true
      errorMessage += Messages.invalidNewCommitTimeValue
    }
    this.setState({
      isValidRequestForm: isValid,
      errorMessage,
      isShowErrorMessage,
    })
    return isValid
  }

  inputNewCommitTimeValueChangeHandler = (event) => {
    const newCommitValue = event.target.value
    this.setState({
      newCommitValue,
    })
  }

  inputNewCommitTimeReasonChangeHandler = (event) => {
    const reason = event.target.value
    this.setState({
      reason,
    })
  }

  closeDialogHandler = () => {
    const { closeHandler } = this.props
    this.setState({
      isShowErrorMessage: false,
      errorMessage: '',
    })
    closeHandler()
  }

  submitHandler = () => {
    const { OKClickHandler, item } = this.props
    const { newCommitValue, reason } = this.state
    const isValid = this.checkValidationForm()
    if (isValid) {
      OKClickHandler({
        commitTimeId: item._id,
        project: item.project,
        isAll: item.isAll,
        newCommitValue,
        reason,
      })
    }
  }

  render() {
    const { isOpen, item } = this.props
    const { isShowErrorMessage, errorMessage } = this.state
    if (!item) return null
    const title = Messages.sendRequestDialogTitle

    const content = (
      <SendRequestDialogContent
        data={item}
        inputValueChangeHandler={this.inputNewCommitTimeValueChangeHandler}
        inputReasonChangeHandler={this.inputNewCommitTimeReasonChangeHandler}
        isShowErrorMessage={isShowErrorMessage}
        errorMessage={errorMessage}
      />
    )
    return (
      <div>
        <Dialog onClose={this.closeDialogHandler} aria-labelledby="customized-dialog-title" open={isOpen}>
          <DialogTitle id="customized-dialog-title">{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.closeDialogHandler}>
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={this.submitHandler}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default CustomizedDialog
