import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { Messages, AppConfig, RoutesConstants } from '../../../constants'
import FormModel from '../../../components/FormModel'
import Input from '../../../components/Input'
import RadioButton from '../../../components/RadioButton'
import DatePicker from '../../../components/DatePicker'

import './index.scss'
import { createPolicy } from 'API/policy'
import { handleError } from 'API/error'
import { toast } from 'react-toastify'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
  menu: {
    width: 200,
  },
})

const PolicyCreateForm = (props) => {
  const { classes, dirty, isSubmitting, handleSubmit } = props
  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="box box-default">
          <div className="box-header">Create New Policy</div>
          <div className="box-divider" />
          <div className={`box-body`}>
            <div className="container-fluid">
              <div className="fields company-policy">
                <div className="field">
                  <Field name="title" label="Title*" className={classes.textField} margin="normal" component={Input} />
                </div>
                <div className="field">
                  <Field
                    name="publishedDate"
                    label="Published Date*"
                    type="date"
                    className={classes.textField}
                    margin="normal"
                    component={DatePicker}
                  />
                </div>
                <div className="field radio-button">
                  <Field
                    name="published"
                    label="Published"
                    className={classes.textField}
                    margin="normal"
                    component={RadioButton}
                  />
                </div>
              </div>
              <div className="fields">
                <div className="field">
                  <Field
                    name="content"
                    label="Content*"
                    multiline
                    row={5}
                    className={classes.textField}
                    margin="normal"
                    component={Input}
                  />
                </div>
              </div>
              <div className="options-sub">
                <button
                  className="create-new-policyrmation-button primary-button"
                  type="submit"
                  disabled={!dirty && isSubmitting}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const date = moment().format('YYYY-MM-DD')
const initialValues = {
  title: '',
  content: '',
  publishedDate: date,
  published: false,
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required(Messages.fieldRequired),
  content: Yup.string()
    .required(Messages.fieldRequired)
    .max(AppConfig.LIMIT_CHARACTERS_OF_STRING, Messages.exceedCharacterOfString),
  publishedDate: Yup.date().required(Messages.fieldRequired),
})
class PolicyCreate extends React.Component {
  submitHandler = async (values) => {
    try {
      const { history } = this.props
      await createPolicy(values)
      history.push(RoutesConstants.POLICIES_ADMIN)
      toast.success(Messages.createPolicySuccess)
    } catch (error) {
      handleError(error, 'src/containers/Policy/PolicyCreate/index.js', 'submitHandler')
    }
  }
  render() {
    return (
      <div className="policy-create-page">
        <FormModel
          initialValues={initialValues}
          schema={validationSchema}
          handleSubmit={this.submitHandler}
          component={<PolicyCreateForm {...this.props} />}
        />
      </div>
    )
  }
}

PolicyCreate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PolicyCreate)
