import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react'

export interface IDeleteItemModal {
  data?: any
  isOpen?: boolean
  closeHandler?
  countDelete?: number
  OKClickHandler?: (id: string) => void
}

const DeleteItemModal = (props: IDeleteItemModal) => {
  const { isOpen, closeHandler, OKClickHandler, data, countDelete } = props

  function DeleteItem(data) {
    OKClickHandler(data)
    window.localStorage.setItem('deleteCountBillItem', JSON.stringify(countDelete + 1))
    closeHandler()
  }
  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Item Bill</ModalHeader>
        <ModalCloseButton color={'gray'} />
        <ModalBody>
          <Text>Are you sure you want to delete?</Text>
        </ModalBody>
        <ModalFooter>
          <Button background={'white'} variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button onClick={() => DeleteItem(data)} colorScheme="red">
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteItemModal
