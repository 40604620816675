import React, { useState } from 'react'
import { TableContainer, Table, Thead, Tbody, Tr, Td } from '@chakra-ui/react'
import { ReactComponent as InformationIcon } from 'assets/images/svg/information-white.svg'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import get from 'lodash/get'
import { useFormContext, useWatch } from 'react-hook-form'
import { getValidArray, checkValidArray } from 'utils'
import { Messages } from 'constants/index'
import { IHourlyProjectRating } from 'constants/schema'
import { InformationButton, MobileTableTh, MobileTableTd } from 'containers/UserNew/UserDetail/userDetail.styles'
import EditButton from '../../../components/EditButton'
import HourlyProjectDetail from './components/HourlyProjectDetail'
import HourlyProjectEdit from './components/HourlyProjectEdit'

interface IHourlyProjectTableProps {
  name: string
  isEdit: boolean
}

const HourlyProjectTable = (props: IHourlyProjectTableProps) => {
  const { name, isEdit } = props
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false)
  const [isShowEditForm, setIsShowEditForm] = useState<boolean>(false)
  const [selectedDetail, setSelectedDetail] = useState<IHourlyProjectRating>()
  const { control } = useFormContext()
  const fields: IHourlyProjectRating[] = useWatch({ control, name }) ?? []

  function handleShowDetail(data: IHourlyProjectRating): void {
    setSelectedDetail(data)
    setIsShowDetail(true)
  }

  function handleShowEditForm(data: IHourlyProjectRating): void {
    setSelectedDetail(data)
    setIsShowEditForm(true)
  }

  function handleCloseDetail(): void {
    setIsShowDetail(false)
  }

  function handleCloseEditForm(): void {
    setIsShowEditForm(false)
  }

  return (
    <TableContainer width="full">
      {checkValidArray(fields) ? (
        <Table variant="striped" size="sm" overflowX="hidden">
          <Thead>
            <Tr height={10}>
              <MobileTableTh>PROJECT</MobileTableTh>
              <MobileTableTh>HOURLY RATE</MobileTableTh>
            </Tr>
          </Thead>
          <Tbody>
            {getValidArray(fields).map((field: IHourlyProjectRating) => (
              <Tr key={field?._id}>
                <MobileTableTd>{get(field, 'project.label')}</MobileTableTd>
                <MobileTableTd>{field?.hourlyRate}</MobileTableTd>
                <Td width={14} padding={0}>
                  {isEdit ? (
                    <EditButton onClick={() => handleShowEditForm(field)} />
                  ) : (
                    <InformationButton onClick={() => handleShowDetail(field)}>
                      <InformationIcon />
                    </InformationButton>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <EmptyContentBox text={Messages.noData} />
      )}
      <HourlyProjectEdit
        data={selectedDetail}
        projectRatings={fields}
        isOpen={isShowEditForm}
        onClose={handleCloseEditForm}
      />
      <HourlyProjectDetail isOpen={isShowDetail} onClose={handleCloseDetail} data={selectedDetail} />
    </TableContainer>
  )
}

export default HourlyProjectTable
