import { useState } from 'react'
import { HStack } from '@chakra-ui/layout'
import { ChromePicker, ColorResult } from 'react-color'
import { useFormContext } from 'react-hook-form'
import { ColorDot, Label, Popover, Cover, Container } from './colorPicker.styles'

interface IColorPickerProps {
  value?: string
  name: string
  isEdit?: boolean
}

const FormColorPicker = (props: IColorPickerProps) => {
  const { name, isEdit, value: color } = props
  const { setValue } = useFormContext()
  const [showPicker, setShowPicker] = useState<boolean>(false)

  function onChangePicker(currentColor: ColorResult): void {
    if (currentColor) {
      setValue(name, currentColor?.hex)
    }
  }

  function onClickPicker(): void {
    if (!isEdit) {
      return
    }

    setShowPicker((previousShowPicker) => {
      return !previousShowPicker
    })
  }

  function onClickClosePicker(): void {
    setShowPicker(false)
  }

  if (!color) {
    return <></>
  }

  return (
    <Container cursor={isEdit ? 'pointer' : 'default'}>
      <HStack spacing={2} onClick={onClickPicker}>
        <ColorDot backgroundColor={color} />
        <Label>{color}</Label>
      </HStack>
      {showPicker && (
        <Popover>
          <Cover onClick={onClickClosePicker} />
          <ChromePicker color={color} onChange={onChangePicker} />
        </Popover>
      )}
    </Container>
  )
}

export default FormColorPicker
