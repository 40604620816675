import React from 'react'
import { EventName, Messages } from '../../../../constants'
import CommitTimeRequestTable from '../../components/CommitTimeRequestTableOfUser'
import { getCommitTimeRequestList, closeCommitTimeRequest } from 'API/commit-time'

class CommitTimeRequest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commitTimeRequestList: [],
    }
  }
  componentDidMount() {
    this.getCommitTimeRequestList()
    window.sessionStorage.setItem('subPageTitle', 'Request')
    window.dispatchEvent(new Event('storage'))
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem('subPageTitle')
    this.setState = () => {
      return
    }
  }

  getCommitTimeRequestList = async () => {
    try {
      const response = await getCommitTimeRequestList()
      this.setState({
        commitTimeRequestList: response?.commitTimeRequestList,
      })
    } catch (error) {
      handleError(error, 'src/containers/CommitTime/CommitTime/CommitTimeRequest', 'getCommitTimeRequestList')
    }
  }

  handleClickOKOfDialogConfirm = async (id) => {
    try {
      const response = await closeCommitTimeRequest(id)
      this.props.handleCloseConfirmDialogYesNo()
      this.props.handleOpenSnackbar({ variant: 'success', message: Messages.closeCommitTimeRequestSuccessfully })
      this.setState({
        commitTimeRequestList: response?.commitTimeRequestList,
      })
    } catch (error) {
      handleError(error, 'src/containers/CommitTime/CommitTime/CommitTimeRequest', 'handleClickOKOfDialogConfirm')
    }
  }

  callOpenDialogConfirm = (data) => {
    document.dispatchEvent(
      new CustomEvent(EventName.openCloseCommitTimeRequestDialogYesNo, {
        detail: {
          isOpen: true,
          OKClickHandler: this.handleClickOKOfDialogConfirm,
          item: data,
        },
      })
    )
  }

  closeCommitTimeRequest = (id) => {
    this.callOpenDialogConfirm(id)
  }

  render() {
    const { commitTimeRequestList } = this.state
    return (
      <div className="timesheet-page container">
        <div className="timesheet-content">
          <div className="container-fluid">
            <div className="box box-default">
              <div className="box-header">Commit Time Request List</div>
              <div className="box-divider" />
              <div className={`box-body`}>
                {commitTimeRequestList?.length > 0 && (
                  <CommitTimeRequestTable
                    data={commitTimeRequestList}
                    closeRequestHandler={this.closeCommitTimeRequest}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CommitTimeRequest
