import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { getProjectsOfUser } from 'API/project'
import { IProjectsOfUserResponse } from 'API/project/constants'
import { getFixedProjectRating, getHourlyProjectRating } from 'API/projectRating'
import { IFixedProjectRatingList, IHourlyProjectRatingList, IProjectRatings } from 'API/projectRating/constants'
import { changePassword, getDashboardUser, getUserProfile, updateUserData, updateUserProfile } from 'API/user'
import { IChangePasswordResponse, IUpdateUserProfileResponse, IUserProfileResponse } from 'API/user/constants'
import Helper from 'Helper'
import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { toast } from 'react-toastify'
import { ICreatableOption } from 'types/creatableOption'
import { IDashboardUser } from 'types/dashboard-user'
import { IPartner } from 'types/partner'
import { IProject } from 'types/project'
import { IFormattedProfileBeforeRenderFE, IFormChangePassword, IProfileDetailOfDetailForm, IUser } from 'types/user'
import { getParsedUserValueBeforeRenderFE, getParsedUserValueBeforeSendBE, getValidArray, parseJson } from 'utils'
import { EWorkingHourPeriods } from 'constants/enum'
import { IFixedProjectRating, IHourlyProjectRating } from 'constants/schema'
import { trackIdentify } from 'utils/tracking'
import { Messages } from '../../constants'
import ApiHelper from '../../Helper/api'
import RootStore from '../rootStore'

class UserProfileStore {
  rootStore: RootStore
  userList: IUser[]
  projectList: IProject[] = []
  partnerList: IPartner[] = []
  creatableOption: ICreatableOption[] = []
  profileDetail: IProfileDetailOfDetailForm
  projectRatings: IProjectRatings
  cvFileName: string
  dashboardData: IDashboardUser = {
    fullName: '',
    totalSalary: 0,
    totalWorkingTime: 0,
    lastTotalSalary: 0,
    lastTotalWorkingTime: 0,
    numberOfProjects: 0,
    lastNumberOfProjects: 0,
    commitTimes: [],
    timesheetData: [],
    projectWorkingTimePieData: [],
    performanceLineData: [],
    salaryBarData: [],
  }
  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async updateProfile(data: IProfileDetailOfDetailForm): Promise<void> {
    try {
      const parseValues: IUser = getParsedUserValueBeforeSendBE(data)
      if (!parseValues) {
        return
      }
      await updateUserProfile(parseValues)
      this.getProfileDetail()
      toast.success(Messages.updateProfileSuccess)
      if (window) {
        window.localStorage.setItem('userRole', String(data?.role))
        window.localStorage.setItem('email', data?.email)
        window.localStorage.setItem('fullName', data?.fullName)
        window.localStorage.setItem('title', data?.title?.value)
        window.localStorage.setItem('avatar', data?.avatar)
      }
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'updateProfile')
    }
  }

  public async getProjectList(cb?: Function): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectsOfUserResponse> = await getProjectsOfUser()
      this.projectList = response?.data?.projects
      if (typeof cb === 'function') cb()
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'getProjectList')
    }
  }

  public async getProfileDetail(): Promise<void> {
    try {
      const response: ISuccessResponse<IUserProfileResponse> = await getUserProfile()
      const currentUserData: IUser = response?.data?.user
      const currentUser: IFormattedProfileBeforeRenderFE = getParsedUserValueBeforeRenderFE(
        currentUserData,
        this.projectList,
        true
      )
      const formattedData: IProfileDetailOfDetailForm = {
        ...currentUser.profileDetailData,
        isSupervisor: currentUser.profileDetailData?.isSupervisor ? 'Yes' : 'No',
        joinDateUser: moment(currentUser.profileDetailData?.joinDate ?? '').format('DD/MM/YYYY'),
      }
      this.profileDetail = formattedData
      trackIdentify(currentUserData._id, currentUserData.email, currentUserData.fullName)
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'getProfileDetail')
    }
  }

  public getProjectRatings = async () => {
    const [hourlyProjectRatings, fixedProjectRatings] = await Promise.all([
      this.getHourlyRatePriceData(),
      this.getFixedRatePriceData(),
    ])
    this.projectRatings = {
      hourlyProjectRatings,
      fixedProjectRatings,
    }
  }

  private getHourlyRatePriceData = async () => {
    const hourlyRatingData: IHourlyProjectRatingList = await getHourlyProjectRating()
    const hourlyProjectRatingsData: IHourlyProjectRating[] = hourlyRatingData?.hourlyRatingPriceData?.hourlyRatingPrices
    const formattedHourlyProjectRatingsData: IHourlyProjectRating[] = getValidArray(hourlyProjectRatingsData).map(
      (hourlyProjectRatingData: IHourlyProjectRating) => {
        const projectData = this.projectList.find((item) => item._id === hourlyProjectRatingData.project)
        return {
          ...hourlyProjectRatingData,
          applyDate: Helper.getFormattedDateWith_YYYYMMDD(hourlyProjectRatingData?.applyDate),
          project: projectData ? { value: projectData._id, label: projectData.name } : {},
        }
      }
    )
    return {
      hourlyRatingPrices: formattedHourlyProjectRatingsData,
    }
  }
  private getFixedRatePriceData = async () => {
    const fixedRatingData: IFixedProjectRatingList = await getFixedProjectRating()
    const fixedProjectRatingsData: IFixedProjectRating[] = fixedRatingData?.fixedRatingPriceData?.fixedRatingPrices
    const formattedFixedProjectRatingsData: IFixedProjectRating[] = getValidArray(fixedProjectRatingsData).map(
      (fixedProjectRatingData: IFixedProjectRating) => {
        const projectData = this.projectList.find((item) => item._id === fixedProjectRatingData.project)
        return {
          ...fixedProjectRatingData,
          applyDate: Helper.getFormattedDateWith_YYYYMMDD(fixedProjectRatingData?.applyDate),
          project: projectData ? { value: projectData._id, label: projectData.name } : {},
        }
      }
    )
    return {
      fixedRatingPrices: formattedFixedProjectRatingsData,
    }
  }

  public async changePassword(data: IFormChangePassword): Promise<void> {
    try {
      const response: ISuccessResponse<IChangePasswordResponse> = await changePassword(data)
      const { messages, isSuccess } = response.data
      if (isSuccess) {
        toast.success(messages)
        setTimeout(() => {
          window.localStorage.clear()
          window.location.reload()
        }, 3000)
      } else {
        toast.error(messages)
      }
    } catch (error) {
      handleError(error, 'src/store/profileStore.ts', 'changePassword')
    }
  }

  public updateUserData = async (data: string) => {
    try {
      const dataObject: object = parseJson(data)
      const response: ISuccessResponse<IUpdateUserProfileResponse> = await updateUserData(dataObject)
      toast.success(response?.data?.messages)
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'updateUserData')
    }
  }
  public deleteCVNameByUserId = () => {
    ApiHelper.post({ url: `/upload/file/delete/cv` }, (response) => {
      const user = response.data.user
      this.cvFileName = user.cv
    })
  }

  public async fetchDashboardUser(period: EWorkingHourPeriods, date: string, userId: string): Promise<void> {
    try {
      const data: IDashboardUser = await getDashboardUser(period, date, userId)
      this.dashboardData = data
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'fetchDashboardUser')
    }
  }
}

export default UserProfileStore
