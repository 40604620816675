import { IOption } from './common'
import {
  EPaymentCategoryOfProject,
  ERoleOfUser,
  EContractTypes,
  ELaborContractCategories,
  ELaborContractTerm,
  EYesNoOption,
  EPartnerCategory,
  EUserEducation,
  EGender,
  EGenderVI,
} from './enum'

export const PaymentCategories = [EPaymentCategoryOfProject.HOURLY, EPaymentCategoryOfProject.FIXED_PRICE]
export const UserRole = [ERoleOfUser.ADMIN, ERoleOfUser.USER]
export const YesNoOption = [EYesNoOption.YES, EYesNoOption.NO]
export const ReportPeriods = ['day', 'week', 'month']
export const ReportPeriodsWithoutDay = ['week', 'month', 'year']
export const ReportPeriodsOnlyMonth = ['month']
export const WorkingScheduleFilter = ['day', 'week']
export const WorkingScheduleFilterOnlyDate = ['day']
export const NotificationId = 'notificationId'
export const ExportCSVFileHeaders = [
  { label: 'Task Name', value: 'taskName' },
  { label: 'Description', value: 'description' },
  { label: 'Start Time', value: 'startTime' },
  { label: 'End Time', value: 'endTime' },
  { label: 'Time Total', value: 'timeTotal' },
  { label: 'Rate Exchange', value: 'rateExchange' },
  { label: 'Hourly Rate', value: 'defaultRating' },
  { label: 'Salary (VND)', value: 'salary' },
  { label: 'Total Salary(VND)', value: 'totalSalary' },
  { label: 'Total Working Time(hours)', value: 'totalWorkingTime' },
]
export const ExportCSVFileHeadersOfTotalSheet = [
  { label: 'Time Of Fresher', value: 'fresher' },
  { label: 'Time Of Junior', value: 'junior' },
  { label: 'Time Of Senior', value: 'senior' },
  { label: 'Time Of Lead', value: 'lead' },
]
export const WorkingTimeOfSchedule = [
  '1:00',
  '2:00',
  '3:00',
  '4:00',
  '5:00',
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
]
export const MonthOfYear = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'Octorber', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
]

export const ChartList = [
  { label: 'Time total chart', value: 'timetotalChart' },
  { label: 'Revenue chart', value: 'revenueChart' },
  { label: 'Profit chart', value: 'profitChart' },
]
export const TypeOfFilterOfCommitTime = ['filter by project', 'filter by user', 'filter all']
export const TypeOfContract = [
  EContractTypes.FREELANCER_CONTRACT,
  EContractTypes.LABOR_CONTRACT,
  EContractTypes.CONFIDENTIALITY_AGREEMENT,
]
export const LaborContractCategory = [ELaborContractCategories.OFFICIAL, ELaborContractCategories.PROBATION]
export const LaborContractTerm = [ELaborContractTerm.DEFINITE_TERM, ELaborContractTerm.INDEFINITE_TERM]
export const TypeOfFilterOfHeadMap = ['filter by project', 'filter by user', 'filter all']
export const TypeOfFilterOfCommitTimeOfReport = ['filter by project', 'filter by user']
export const GroupByOfCommitTime = ['projects', 'users']
export const PartnerCategory = [
  EPartnerCategory.OWNER,
  EPartnerCategory.CLIENT,
  EPartnerCategory.SUPPLIER,
  EPartnerCategory.EMPLOYEE,
  EPartnerCategory.FREELANCER,
  EPartnerCategory.BANK,
]
export const PartnerCategoryOfBirthday = ['employee', 'freelancer']
export const PartnerType = ['organizations', 'personal']

export const educationOptions: IOption[] = [
  {
    value: EUserEducation.UNDERGRADUATE,
    label: EUserEducation.UNDERGRADUATE,
  },
  {
    value: EUserEducation.BACHELOR,
    label: EUserEducation.BACHELOR,
  },
  {
    value: EUserEducation.MASTER,
    label: EUserEducation.MASTER,
  },
  {
    value: EUserEducation.DOCTOR,
    label: EUserEducation.DOCTOR,
  },
  {
    value: EUserEducation.CERTIFICATE,
    label: EUserEducation.CERTIFICATE,
  },
]

export const genderOptions: IOption[] = [
  {
    label: EGender.MALE,
    value: EGender.MALE,
  },
  {
    label: EGender.FEMALE,
    value: EGender.FEMALE,
  },
]

export const genderVIOptions: IOption[] = [
  {
    label: EGenderVI.MALE,
    value: EGenderVI.MALE,
  },
  {
    label: EGenderVI.FEMALE,
    value: EGenderVI.FEMALE,
  },
]

export const citizenIssuePlaceOptions: IOption[] = [
  {
    value: 'Cục trưởng cục CS QLHC Về TTXH',
    label: 'Cục trưởng cục CS QLHC Về TTXH',
  },
]
