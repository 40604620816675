import { Text, VStack, SimpleGrid, Container } from '@chakra-ui/react'
import LabelForm from 'componentsCharka/LabelForm'
import WebLinkForm from 'componentsCharka/WebLinkForm'
import { IUser } from 'types/user'
import AvatarDevGroup from './components/avatarDevGroup'
import { getValidDevelopers } from './utils'
import styles from './index.module.scss'

const ProjectDetailForm = (props) => {
  const { methods } = props
  const { getValues } = methods
  const untilNow = getValues('untilNow')
  const listDeveloper = getValues('developerDetailOnProject')
  const validDevelopers: IUser[] = getValidDevelopers(listDeveloper)

  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack background="white" padding={{ base: 6, lg: 6 }} borderRadius="8px" gap={6}>
        <VStack padding={0} className={`${styles.groupInformation} ${styles.border}`} gap={6}>
          <Text alignSelf={'flex-start'} className={styles.headerBox} fontSize={{ base: '18px' }}>
            General Information
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="name" label="Project Name" readonly={true} />
            <LabelForm name="companyName" label="Company Name" readonly={true} />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <WebLinkForm name="companyWebsite" label="Company Website" isHighligh={true} />
            <LabelForm name="companyPhone" label="Company Phone Number" isHighligh readonly={true} />
            <LabelForm name="country" label="Country" readonly={true} />
            {/* <LabelForm name="city" label="City" readonly={true} /> */}
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="address" label="Address" readonly={true} />
          </SimpleGrid>
        </VStack>
        <VStack padding={0} className={`${styles.groupInformation} ${styles.border}`} gap={6}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox}`} fontSize={{ base: '18px' }}>
            Customer Information
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="customer" label="Customer Name" readonly={true} />
            <LabelForm name="customerEmail" label="Customer Email" isHighligh readonly={true} />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="customerPhone" label="Customer Phone Number" isHighligh readonly={true} />
          </SimpleGrid>
        </VStack>
        <VStack padding={0} className={`${styles.groupInformation} ${styles.border}`} gap={6}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox}`} fontSize={{ base: '18px' }}>
            Payment
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="paymentCategory" label="Category of Payment" readonly={true} />
            <LabelForm name="currency" label="Currency" readonly={true} />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="price" label="Price" readonly={true} />
            <LabelForm name="budgetProject" label="Budget" readonly={true} />
          </SimpleGrid>
        </VStack>
        <VStack padding={0} className={`${styles.groupInformation}`} gap={6}>
          <Text alignSelf={'flex-start'} className={`${styles.headerBox}`} fontSize={{ base: '18px' }}>
            Working Information
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="startedDate" label="Start Date" readonly={true} />
            {!untilNow && <LabelForm name="endedDate" label="End Date" readonly={true} />}
            {untilNow && <LabelForm name="untilNowProject" label="Until Now" readonly={true} />}
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="commitedHours" label="Commited Hours (hrs/week)" readonly={true} />
            <LabelForm name="technology" label="Technology" readonly={true} type="textarea" />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="neededDeveloperNumber" label="Needed Developer Number" readonly={true} />
            <AvatarDevGroup name="listDeveloper" data={validDevelopers} label="Working Developers" />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="partnerId" label="Belongs To Partner" readonly={true} />
            <LabelForm name="type" label="Project Type" isTagFormat readonly={true} />
          </SimpleGrid>
        </VStack>
      </VStack>
    </Container>
  )
}
export default ProjectDetailForm
