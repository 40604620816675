import api from 'API'
import { getErrorMessage } from 'API/error'
import { AxiosResponse } from 'axios'
import { IFeedBack } from 'constants/schema'
import { FEEDBACK_BASE_URL } from './constants'

export async function getFeedbackList(): Promise<IFeedBack[]> {
  try {
    const response: AxiosResponse = await api.get(`${FEEDBACK_BASE_URL}/list`)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function updateFeedback(feedbackId: string, feedback: IFeedBack): Promise<void> {
  try {
    return api.post(`${FEEDBACK_BASE_URL}/update/${feedbackId}`, feedback)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function createFeedback(feedback: IFeedBack): Promise<void> {
  try {
    return api.post(`${FEEDBACK_BASE_URL}/create`, feedback)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getFeedbackListAdmin(): Promise<IFeedBack[]> {
  try {
    const response: AxiosResponse = await api.get(`${FEEDBACK_BASE_URL}/list-admin`)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function updateAdminIsRead(feedbackId: string, payload: { isRead: boolean }): Promise<void> {
  try {
    return api.post(`${FEEDBACK_BASE_URL}/admin-update-isread/${feedbackId}`, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function deleteFeedback(feedbackId: string): Promise<void> {
  try {
    await api.post(`${FEEDBACK_BASE_URL}/delete/${feedbackId}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function countUnreadFeedback(): Promise<{ unReadFeedbackNumber: number }> {
  try {
    const response: AxiosResponse = await api.get(`${FEEDBACK_BASE_URL}/unread-list-admin`)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
