/* eslint-disable max-lines */
import { Component } from 'react'
import { Box } from '@chakra-ui/react'
import { ISuccessResponse } from 'API/constants'
import { getListCreatableOptions } from 'API/creatableOption'
import { ICreatableOptionListWithFilterResponse } from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import { getUserListWithPagination, updateUser } from 'API/user'
import { archivedUser, deleteUser } from 'API/user'
import { IUserFilterPagination, IUserListResponse, IUserListWithPaginationRequest } from 'API/user/constants'
import Table from 'components/Table'
import MoreDropBox from 'components/Table/components/MoreDropBox'
import ConfirmArchiveModal from 'componentsCharka/ConfirmArchiveModal'
import ConfirmDeleteModal from 'componentsCharka/ConfirmDeleteModal'
import Helper from 'Helper'
import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'
import difference from 'lodash/difference'
import get from 'lodash/get'
import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'
import set from 'lodash/set'
import { generatePath } from 'react-router'
import { toast } from 'react-toastify'
import { backgroundGrey200 } from 'theme/globalStyles'
import { getValidArray, getInQuery } from 'utils'
import { maxMobileSize } from 'constants/common'
import { ECreatableOptionScope } from 'constants/enum'
import { Messages, RoutesConstants, actionOnDataTable } from 'constants/index'
import { IUser } from 'constants/schema'
import FilterForm from '../components/FilterForm'
import HeaderSection from './components/HeaderSection'
import UserSummary from './components/UserSummary'
import { getHeaderList, IUserFilter, initialValues, IRowActionItem, Props, States, ERoleFilter } from './constant'
import styles from './userList.module.scss'

class UserList extends Component<Props, States> {
  constructor(props: Props) {
    super(props)
    this.state = {
      users: [],
      count: 0,
      open: false,
      projects: [],
      exportNow: false,
      filter: initialValues,
      openArchiveModal: false,
      openDeleteModal: false,
      openSummaryModal: false,
      selectedUser: null,
      isCorrectName: false,
    }
  }
  componentDidMount() {
    this.getListUsersHandler()
    this.getListOptionsHandler()
  }
  componentDidUpdate(_: Props, prevState: States) {
    if (prevState?.filter !== this.state?.filter) {
      this.getListUsersHandler(true)
    }
  }
  componentWillUnmount() {
    this.setState({})
  }
  filterSubmitHandler = (value: IUserFilter) => {
    const parseFilter: IUserFilter = {
      ...value,
      fullName: { $regex: value?.fullName ?? '', $options: 'i' },
      titleId: value.titleId,
      levelId: value.levelId,
      role: value.role,
      projects: get(value, 'projects.value', ''),
      paymentCategoryId: value.paymentCategoryId,
    }
    window.localStorage.setItem('filterValuesOfUsers', JSON.stringify(parseFilter))
    const titleId = Helper.getValueFromOption(value.titleId)
    const role = value.role
    const levelId = Helper.getValueFromOption(value.levelId)
    const paymentCategoryId = Helper.getValueFromOption(value.paymentCategoryId)
    this.setState({
      filter: { ...value, role: role, titleId: titleId, levelId: levelId, paymentCategoryId: paymentCategoryId },
    })
  }
  toggleOpen = () => this.setState({ open: !this.state.open })
  toggleOpenDeleteModal = () => this.setState({ openDeleteModal: !this.state.openDeleteModal })
  toggleOpenArchiveModal = () => this.setState({ openArchiveModal: !this.state.openArchiveModal })
  toggleOpenSummaryModal = () => this.setState({ openSummaryModal: !this.state.openSummaryModal })
  toggleOpenSummaryModalHandler = (user: IUser) => {
    this.setState({ selectedUser: user, openSummaryModal: !this.state.openSummaryModal })
  }
  handleCreate = () => this.props.history.push(RoutesConstants.USER_CREATE)
  toggleExport = () => this.setState({ exportNow: !this.state.exportNow })

  getListOptionsHandler = async (): Promise<void> => {
    try {
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptions({
        filter: { scope: ECreatableOptionScope.USER },
      })
      this.setState({ creatableOption: response?.data?.creatableOptions ?? [] })
    } catch (error) {
      handleError(error, 'src/containers/UserNew/UserList/index.tsx', 'getListOptionsHandler')
    }
  }

  getListUsersHandler = async (isReset?: boolean): Promise<void> => {
    try {
      const { filter } = this.state
      const currentPage: number = isReset ? 1 : Number(get(this.props, 'history.location.state.page') || '1')
      const { isActive, isDeleted, isArchived, titleId, levelId, paymentCategoryId } = filter
      const truthyFilter: IUserFilterPagination = {
        ...pickBy(filter, identity),
        titleId: getInQuery(titleId),
        levelId: getInQuery(levelId),
        paymentCategoryId: getInQuery(paymentCategoryId),
        isActive: filter?.isActive ?? true,
        isDeleted: filter?.isDeleted ?? false,
        isArchived: filter?.isArchived ?? false,
      }
      truthyFilter?.role === ERoleFilter.ALL && delete truthyFilter?.role
      truthyFilter?.titleId === null && delete truthyFilter?.titleId
      truthyFilter?.levelId === null && delete truthyFilter?.levelId
      truthyFilter?.paymentCategoryId === null && delete truthyFilter?.paymentCategoryId
      truthyFilter?.role?.length < 1 && delete truthyFilter?.role
      truthyFilter?.fullName?.length < 1 && delete truthyFilter?.fullName
      if (isDeleted || isArchived) {
        delete truthyFilter?.isActive
        isDeleted && delete truthyFilter?.isArchived
      }
      const payload: IUserListWithPaginationRequest = { currentPage, perPage: 10, filter: truthyFilter }
      const response: ISuccessResponse<IUserListResponse> = await getUserListWithPagination(payload)
      this.setState({ users: response?.data?.users, count: response?.data?.count })
    } catch (error) {
      handleError(error, 'src/containers/UserNew/UserList/index.tsx', 'getListUsersHandler')
    }
  }

  updateUser = async (userId: string, user: IUser): Promise<void> => {
    try {
      const projectIdArray: string[] = getValidArray(this.state.projects).map((project) => project._id)
      const projectsNotAdded: string[] = difference(projectIdArray, user.projects)
      await updateUser(userId, { updatedUserData: user, projectsNotAdded })
      this.getListUsersHandler()
      this.props.handleCloseConfirmDialog()
      const { isDeleted = false, isArchived = false } = user
      const { deleteUserSuccess, archiveUserSuccess, unarchiveUserSuccess } = Messages
      const message: string = isDeleted ? deleteUserSuccess : isArchived ? archiveUserSuccess : unarchiveUserSuccess
      toast.success(message)
      this.setState({ openDeleteModal: false })
    } catch (error) {
      handleError(error, 'src/containers/UserNew/UserList/index.tsx', 'updateUser')
    }
  }
  inputTargetUserNameHandler = (event, fullName: string) => {
    if (event.target.value === fullName) {
      this.setState({ isCorrectName: false })
    } else {
      this.setState({ isCorrectName: true })
    }
  }
  handleClickOKConfirmedDelete = (id: string) => {
    this.updateUser(id, { isDeleted: true, deletedAt: new Date() })
    deleteUser(id)
  }
  handleClickOKOfArchiveConfirm = (id: string) => {
    this.updateUser(id, { isArchived: true, archivedAt: new Date() })
    archivedUser(id)
    this.setState({ openArchiveModal: false })
  }
  handleClickOKOfUnarchiveConfirm = (id: string) => {
    this.updateUser(id, { isArchived: false })
    this.setState({ openArchiveModal: false })
  }
  callOpenDialogConfirm = (user: IUser, nameOfEvent: string) => {
    set(user, 'shortName', user?.fullName ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      this.setState({ selectedUser: user })
      this.toggleOpenDeleteModal()
    } else if (nameOfEvent === actionOnDataTable.EDIT) {
      // *TODO: need to implement edit user
    } else {
      this.setState({ selectedUser: user })
      this.toggleOpenArchiveModal()
    }
  }
  changePage = (page: number) => {
    const { filter } = this.state
    this.props.history.push({ pathname: RoutesConstants.USER, state: { page, filter } })
    this.getListUsersHandler()
  }
  render() {
    const {
      users,
      count,
      creatableOption,
      open,
      filter,
      exportNow,
      projects,
      openDeleteModal,
      openArchiveModal,
      openSummaryModal,
      selectedUser,
    } = this.state

    const { history } = this.props
    const isMobile: boolean = window.matchMedia(maxMobileSize).matches
    const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
    const pagination = {
      includePagination: true,
      pageIndex,
      pageSize: 10,
      tableLength: count,
      gotoPage: this.changePage,
    }

    const changeName = debounce((event: { target: { value: string } }) => {
      this.setState({ filter: { ...filter, fullName: { $regex: event?.target?.value ?? '', $options: 'i' } } })
    }, 1000)
    const dataInTable = getValidArray(users).map((user) => {
      const isDeleted: boolean = user?.isDeleted ?? false
      const isArchived: boolean = user?.isArchived ?? false
      const isActive: boolean = user?.isActive ?? false
      const pathname = generatePath(RoutesConstants.USER_DETAIL, { id: user?._id ?? '', isDeleted: isDeleted })
      const dashBoard = generatePath(RoutesConstants.USER_DASHBOARD_DETAIL, { id: user?._id ?? '' })
      const actionDetailHandler = () => history.push(pathname)
      const actions: IRowActionItem[] = []
      !isDeleted &&
        actions.push(
          // *TODO: Implement edit user later
          // {
          //   name: 'Edit User',
          //   handler: () => this.callOpenDialogConfirm(user, actionOnDataTable.EDIT),
          // },
          {
            name: 'View Dashboard',
            handler: () => history.push(dashBoard),
          },
          {
            name: !!isArchived ? 'Unarchive User' : 'Archive User',
            handler: () => this.callOpenDialogConfirm(user, actionOnDataTable.ARCHIVE),
          },
          {
            name: 'Delete User',
            customClassNames: ['isDanger'],
            handler: () => this.callOpenDialogConfirm(user, actionOnDataTable.DELETE),
          }
        )
      const partnerInfo: string = `${capitalize(get(user, 'partner[0].type', ''))} | ${capitalize(
        get(user, 'partner[0].category', '')
      )} `
      const titleId: string = `${capitalize(get(user, 'titleData[0].value', ''))}`
      let status = isDeleted ? 'Deleted' : isArchived ? 'Archived' : isActive ? 'Active' : 'Inactive'
      const actionsTablet: IRowActionItem[] = []
      !isDeleted &&
        actionsTablet.push(
          {
            name: 'Detail',
            handler: () => actionDetailHandler(),
          },
          {
            name: 'View Dashboard',
            handler: () => history.push(dashBoard),
          },
          {
            name: !!isArchived ? 'Unarchive' : 'Archive',
            handler: () => this.callOpenDialogConfirm(user, actionOnDataTable.ARCHIVE),
          }
        )
      return {
        ...user,
        partnerName: get(user, 'partner[0].fullName', ''),
        partnerInfo: partnerInfo.length > 4 ? partnerInfo : '',
        status,
        titleId,
        actions: (
          <MoreDropBox
            isDetail
            isSummary
            detailActionHandler={actionDetailHandler}
            summaryActionHandler={() => this.toggleOpenSummaryModalHandler(user)}
            actions={actions}
          />
        ),
        actionsTablet: actionsTablet,
      }
    })

    return (
      <Box>
        <HeaderSection
          handleCreate={this.handleCreate}
          toggleOpen={this.toggleOpen}
          toggleExport={this.toggleExport}
          changeName={changeName}
        />
        <Box
          className={styles.userContainer}
          background={isMobile ? 'none' : 'white'}
          paddingY={isMobile ? 2 : 4}
          paddingX={isMobile ? 0 : 4}
          borderRadius="6px"
          border={isMobile ? 'none' : `1px solid ${backgroundGrey200}`}>
          {open && (
            <FilterForm
              openModalFilterForm={this.state.open}
              setOpenFilterForm={this.toggleOpen}
              creatableOptionList={this.state.creatableOption}
              filterSubmit={this.filterSubmitHandler}
            />
          )}
          <Table
            headerList={getHeaderList(isMobile)}
            tableData={dataInTable}
            isStriped
            pagination={pagination}
            hasBottomHeader={!isMobile}
            exportNow={exportNow}
            toggleExport={this.toggleExport}
          />
          <ConfirmDeleteModal
            data={selectedUser}
            isOpen={openDeleteModal}
            closeHandler={this.toggleOpenDeleteModal}
            OKClickHandler={this.handleClickOKConfirmedDelete}
            title={'User'}
            content={'Full Name'}
          />
          <ConfirmArchiveModal
            data={selectedUser}
            isOpen={openArchiveModal}
            closeHandler={this.toggleOpenArchiveModal}
            OKClickHandler={
              selectedUser?.isArchived ? this.handleClickOKOfUnarchiveConfirm : this.handleClickOKOfArchiveConfirm
            }
            title={'User'}
            content={'Full Name'}
          />
          <UserSummary
            isOpen={openSummaryModal}
            handleCloseModal={this.toggleOpenSummaryModal}
            userData={selectedUser}
          />
        </Box>
      </Box>
    )
  }
}

export default UserList
