import { HStack } from '@chakra-ui/react'
import { EFeatureBlockOfAccounting } from 'constants/enum'
import FeatureBlock from './components/FeatureBlock'

const Accounting = () => {
  window.localStorage.setItem('deleteCountBillItem', '0')

  return (
    <HStack
      spacing={{ base: 6, lg: 8 }}
      alignItems={'flex-start'}
      backgroundColor="white"
      borderRadius="8px"
      p={{ base: 6, lg: 8 }}>
      <FeatureBlock name={EFeatureBlockOfAccounting.EXPENSE} />
      <FeatureBlock name={EFeatureBlockOfAccounting.INCOME} />
    </HStack>
  )
}

export default Accounting
