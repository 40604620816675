import React from 'react'
import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import { Box, Button, Flex, HStack, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { handleError } from 'API/error'
import { getPartnerListWithPagination, updatePartner } from 'API/partner'
import { IPartnerFilter, IPartnerListPagination } from 'API/partner/constants'
import MoreDropBox from 'components/Table/components/MoreDropBox'
import ConfirmArchiveModal from 'componentsCharka/ConfirmArchiveModal'
import ConfirmDeleteModal from 'componentsCharka/ConfirmDeleteModal'
import Table from 'componentsCharka/Table'
import Helper from 'Helper'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'
import set from 'lodash/set'
import { FiDownload, FiFilter } from 'react-icons/fi'
import { generatePath } from 'react-router'
import { backgroundBlueSecondary, backgroundGrey200 } from 'theme/globalStyles'
import { getValidArray } from 'utils'
import { IPartner } from 'constants/schema'
import { EventName, Messages, RoutesConstants, actionOnDataTable } from '../../../constants'
import FilterForm from '../FilterForm'
import { getHeaderList, IRowActionItem, Props, State, headerListTabletTable } from './constant'
import styles from './partnerList.module.scss'
class PartnerList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      partners: [],
      count: 0,
      open: false,
      exportNow: false,
      openArchiveModal: false,
      openDeleteModal: false,
      selectedPartner: null,
      isCorrectName: false,
    }
  }
  componentDidMount() {
    window.dispatchEvent(new Event('storage'))
    this.getListPartnersHandler()
  }
  componentDidUpdate(_: Props, prevState: State) {
    if (prevState?.filter !== this.state?.filter) {
      this.getListPartnersHandler(true)
    }
  }
  filterSubmitHandler = ({ category }) => {
    this.setState({
      filter: { ...this.state.filter, category: Helper.getValueFromOption(category) },
    })
  }
  toggleOpen = () => this.setState({ open: !this.state.open })
  toggleOpenDeleteModal = () => this.setState({ openDeleteModal: !this.state.openDeleteModal })
  toggleOpenArchiveModal = () => this.setState({ openArchiveModal: !this.state.openArchiveModal })
  handleCreate = () => {
    window.sessionStorage.setItem('subPageTitle', 'Create')
    window.dispatchEvent(new Event('storage'))
    this.props.history.push(RoutesConstants.PARTNER_NEW)
  }
  toggleExport = () => this.setState({ exportNow: !this.state.exportNow })

  getListPartnersHandler = async (isReset?: boolean) => {
    try {
      const { history } = this.props
      const { filter } = this.state
      const truthyFilter: IPartnerFilter = pickBy(filter, identity) as IPartnerFilter
      truthyFilter?.category?.length < 1 && delete truthyFilter?.category
      const currentPage: number = isReset ? 1 : Number(get(history, 'location.state.page', 1))
      const response: IPartnerListPagination = await getPartnerListWithPagination({
        currentPage,
        perPage: 10,
        filter: truthyFilter,
      })
      this.setState({
        partners: response?.partners,
        count: response?.count,
      })
    } catch (error) {
      handleError(error as Error, 'src/containers/Partner/PartnerList', 'getListPartnersHandler')
    }
  }

  updatePartner = async (partnerId: string, partner: IPartner) => {
    try {
      await updatePartner(partner, partnerId)
      this.getListPartnersHandler()
      this.props.handleCloseConfirmDialog()
      const { isDeleted = false, isArchived = false } = partner
      if (isDeleted) {
        this.props.handleOpenSnackbar({ variant: 'success', message: Messages.deletePartnerSuccess })
      } else if (isArchived) {
        this.props.handleOpenSnackbar({ variant: 'success', message: Messages.archivePartnerSuccess })
      } else {
        this.props.handleOpenSnackbar({ variant: 'success', message: Messages.unarchivePartnerSuccess })
      }
    } catch (error) {
      handleError(error as Error, 'src/containers/Partner/PartnerList', 'updatePartner')
    }
  }

  inputTargetPartnerNameHandler = (event, shortName) => {
    const typingName = event.target.value
    if (typingName === shortName) {
      document.dispatchEvent(
        new CustomEvent(EventName.updateDeletePartnerConfirmDialog, {
          detail: { isCorrectName: true },
        })
      )
    } else {
      document.dispatchEvent(
        new CustomEvent(EventName.updateDeletePartnerConfirmDialog, {
          detail: { isCorrectName: false },
        })
      )
    }
  }
  handleClickOKConfirmedDelete = (id: string) => {
    this.updatePartner(id, { isDeleted: true })
    this.setState({ openDeleteModal: false })
  }
  handleClickOKOfArchiveConfirm = (id: string) => {
    this.updatePartner(id, { isArchived: true })
    this.setState({ openArchiveModal: false })
  }
  handleClickOKOfUnarchiveConfirm = (id: string) => {
    this.updatePartner(id, { isArchived: false })
    this.setState({ openArchiveModal: false })
  }
  callOpenDialogConfirm = (partner: IPartner, nameOfEvent: string) => {
    let content: React.ReactNode
    const isArchived: boolean = partner?.isArchived ?? false
    set(partner, 'shortName', partner?.fullName ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      this.setState({ selectedPartner: partner })
      this.toggleOpenDeleteModal()
    } else {
      this.setState({ selectedPartner: partner })
      this.toggleOpenArchiveModal()
    }
  }
  render() {
    const { partners, count, filter, open, exportNow, openDeleteModal, openArchiveModal, selectedPartner } = this.state
    const { history } = this.props
    const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
    const pagination = {
      includePagination: true,
      pageIndex,
      pageSize: 10,
      tableLength: count,
      gotoPage: (page: number) => {
        history.push({ pathname: RoutesConstants.PARTNER, state: { page, filter } })
        this.getListPartnersHandler()
      },
    }
    const changeName = debounce((event: { target: { value: string } }) => {
      this.setState({ filter: { ...filter, fullName: { $regex: event?.target?.value ?? '', $options: 'i' } } })
    }, 1000)
    const dataInTable = getValidArray<IPartner>(partners).map((partner) => {
      const isDeleted: boolean = partner?.isDeleted ?? false
      const isArchived: boolean = partner?.isArchived ?? false
      const pathname = generatePath(RoutesConstants.PARTNER_DETAIL, { id: partner?._id, isDeleted: isDeleted })
      const actionDetailHandler = () => history.push(pathname)
      const actions: IRowActionItem[] = []
      if (!isDeleted && !partner?.isDefault) {
        actions.push(
          {
            name: !!isArchived ? 'Unarchive' : 'Archive',
            handler: () => this.callOpenDialogConfirm(partner, actionOnDataTable.ARCHIVE),
          },
          {
            name: 'Delete',
            customClassNames: ['isDanger'],
            handler: () => this.callOpenDialogConfirm(partner, actionOnDataTable.DELETE),
          }
        )
      }
      const actionsTablet: IRowActionItem[] = []
      !isDeleted &&
        actionsTablet.push(
          {
            name: 'Detail',
            handler: () => actionDetailHandler(),
          },
          {
            name: !!isArchived ? 'Unarchive' : 'Archive',
            handler: () => this.callOpenDialogConfirm(partner, actionOnDataTable.ARCHIVE),
          },
          {
            name: 'Delete',
            customClassNames: ['isDanger'],
            handler: () => this.callOpenDialogConfirm(partner, actionOnDataTable.DELETE),
          }
        )
      return {
        ...partner,
        status: isDeleted ? 'Deleted' : isArchived ? 'Archived' : 'Active',
        actions: <MoreDropBox isDetail={true} detailActionHandler={actionDetailHandler} actions={actions} />,
        actionsTablet: actionsTablet,
      }
    })
    return (
      <Box
        className={styles.partnerContainer}
        background="white"
        padding={6}
        borderRadius="6px"
        border={`1px solid ${backgroundGrey200}`}>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <HStack marginBottom={6} width={{ base: '100%', md: 'auto' }}>
            <InputGroup
              border={`1px solid ${backgroundGrey200}`}
              borderRadius="6px"
              width={{ base: '100%', lg: '540px' }}
              background="white">
              <InputLeftElement pointerEvents="none">
                <Search2Icon color="gray.400" />
              </InputLeftElement>
              <Input type="search" placeholder="Search Partner by name..." onChange={changeName} />
            </InputGroup>
            <Button
              cursor="pointer"
              paddingY="10px"
              leftIcon={<FiFilter />}
              variant="outline"
              background="white"
              onClick={this.toggleOpen}
              display={{ base: 'none', md: 'inline-flex' }}>
              Filter
            </Button>
          </HStack>
          <HStack marginBottom={6} width={{ base: '100%', md: 'auto' }} spacing={4}>
            <Button
              leftIcon={<FiFilter />}
              colorScheme="blue"
              variant="outline"
              onClick={this.toggleOpen}
              display={{ md: 'none' }}>
              Filter
            </Button>
            <Button
              cursor="pointer"
              leftIcon={<FiDownload />}
              onClick={this.toggleExport}
              background="white"
              marginLeft={{ md: '0 !important' }}
              variant="outline">
              Export
            </Button>
            <Button
              cursor="pointer"
              paddingY="10px"
              fontWeight={500}
              leftIcon={<AddIcon width={3} />}
              background={backgroundBlueSecondary}
              _hover={{
                background: backgroundBlueSecondary,
              }}
              onClick={this.handleCreate}>
              Add Partner
            </Button>
          </HStack>
        </Flex>
        {open && (
          <FilterForm
            openModalFilterForm={this.state.open}
            setOpenFilterForm={this.toggleOpen}
            filterSubmit={this.filterSubmitHandler}
            {...this.props}
          />
        )}
        <Table
          headerList={getHeaderList()}
          headerTabletList={headerListTabletTable}
          tableData={dataInTable}
          isStriped
          pagination={pagination}
          isShowPagination={true}
          exportNow={exportNow}
          toggleExport={this.toggleExport}
        />
        <ConfirmDeleteModal
          data={selectedPartner}
          isOpen={openDeleteModal}
          closeHandler={this.toggleOpenDeleteModal}
          OKClickHandler={this.handleClickOKConfirmedDelete}
          title={'Partner'}
          content={'Full Name'}
        />
        <ConfirmArchiveModal
          data={selectedPartner}
          isOpen={openArchiveModal}
          closeHandler={this.toggleOpenArchiveModal}
          OKClickHandler={
            selectedPartner?.isArchived ? this.handleClickOKOfUnarchiveConfirm : this.handleClickOKOfArchiveConfirm
          }
          title={'Partner'}
          content={'Full Name'}
        />
      </Box>
    )
  }
}

export default PartnerList
