import React, { useEffect, useState } from 'react'
import { Button, HStack, VStack, Container, Text } from '@chakra-ui/react'
import { IContractPayload } from 'API/contract/constant'
import { handleError } from 'API/error'
import Icon from 'componentsCharka/Icon'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { FormProvider, useForm, UseFormReturn, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IOption } from 'constants/common'
import { EContractLanguages, EContractTypes } from 'constants/enum'
import { EPartnerCategory } from 'constants/enum'
import { useStores } from 'utils/hooks/useStores'
import CommonInformation from './components/CommonInformation'
import ENInformation from './components/ENInformation'
import LaborContractDetail from './components/LaborContractDetail'
import VNInformation from './components/VNInformation'

const ContractDetail = () => {
  window.sessionStorage.setItem('subPageTitle', 'Contract Detail')
  window.dispatchEvent(new Event('storage'))
  const methods: UseFormReturn = useForm()
  const { handleSubmit, reset, control } = methods
  const params = useParams()
  const contractId: string = get(params, 'id', '')
  const chosenType: IOption | undefined = useWatch({ name: 'type', control })
  const { adminContractStore } = useStores()
  const { partnerList, contractDetail } = adminContractStore
  const [language, setLanguage] = useState<EContractLanguages>(EContractLanguages.VIETNAMESE)
  let showDetailLaborContract: boolean = false

  if (chosenType?.value === EContractTypes.LABOR_CONTRACT) {
    showDetailLaborContract = true
  }

  function changeLanguage(chosenLanguage: EContractLanguages): void {
    setLanguage(chosenLanguage)
  }

  function resetForm(): void {
    reset()
  }

  async function onSubmit(data: IContractPayload): Promise<void> {
    try {
      await adminContractStore.updateContract(contractId, { ...data, language: language })
    } catch (error) {
      toast.error('Update contract failed')
      handleError(error as Error, 'src/containers/Contract/Detail/index.tsx', 'onSubmit')
    }
  }

  useEffect(() => {
    adminContractStore.getPartnerList([EPartnerCategory.CLIENT, EPartnerCategory.EMPLOYEE, EPartnerCategory.FREELANCER])
    adminContractStore.getContractDetail(contractId)
  }, [])

  useEffect(() => {
    if (contractDetail) {
      setLanguage(contractDetail.language)
      reset(contractDetail)
    }
  }, [contractDetail])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => onSubmit(data))} className="full-form">
        <VStack>
          <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
            <HStack margin={{ base: 6, lg: 6 }} height="full" background="white" justifyContent="space-between">
              <Text fontSize="lg" color="gray.700" margin={0}>
                {contractDetail ? contractDetail.number : ''}
              </Text>
            </HStack>
            <HStack margin={{ base: 6, lg: 6 }} height="full" background="white" justifyContent="space-between">
              <HStack>
                <Button
                  colorScheme="blue"
                  variant={language === EContractLanguages.VIETNAMESE ? 'solid' : 'outline'}
                  type="button"
                  onClick={() => changeLanguage(EContractLanguages.VIETNAMESE)}>
                  <Icon iconName="vietnam-flag.svg" size={20} /> Vietnamese
                </Button>
                <Button
                  colorScheme="blue"
                  variant={language === EContractLanguages.BILINGUAL ? 'solid' : 'outline'}
                  type="button"
                  onClick={() => changeLanguage(EContractLanguages.BILINGUAL)}>
                  <Icon iconName="geography.svg" size={20} /> Bilingual
                </Button>
              </HStack>
              <HStack>
                <Button colorScheme="red" type="button" onClick={resetForm}>
                  Cancel
                </Button>
                <Button colorScheme="blue" variant="solid" type="submit">
                  Save
                </Button>
              </HStack>
            </HStack>
          </Container>
          <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
            <VStack padding={{ base: 6, lg: 6 }}>
              <CommonInformation partnerList={partnerList} />
              <VNInformation />
              {language === EContractLanguages.BILINGUAL && (
                <React.Fragment>
                  <ENInformation />
                </React.Fragment>
              )}
              {showDetailLaborContract && <LaborContractDetail />}
            </VStack>
          </Container>
        </VStack>
      </form>
    </FormProvider>
  )
}

export default observer(ContractDetail)
