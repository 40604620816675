import { Container, Button, HStack, Text, useMediaQuery, Box } from '@chakra-ui/react'
import { ReactComponent as Edit } from 'assets/images/svg/edit-white.svg'
import MoreDropBox from 'components/Table/components/MoreDropBox'
import { backgroundSecondary, boxShadow } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'
import { IRowActionItem } from '../../constant'
import { SaveButton, CancelButton } from '../../userDetail.styles'

interface IHeaderSectionProps {
  fullName: string
  isEdit: boolean
  handleEdit: () => void
  handleCancel: () => void
  actions: IRowActionItem[]
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const { fullName, isEdit, handleEdit, handleCancel, actions } = props
  const [isMobile] = useMediaQuery(maxMobileSize)

  return (
    <Container maxWidth="full" padding={0}>
      {isMobile ? (
        <HStack justify="space-between" margin={4}>
          <Text fontSize="lg" fontWeight={500} lineHeight={7} color="gray.700" margin={0}>
            {fullName}
          </Text>
          {!isEdit ? (
            <HStack>
              <Button
                width="40px"
                padding={0}
                background={backgroundSecondary}
                onClick={handleEdit}
                _hover={{
                  cursor: 'pointer',
                  background: backgroundSecondary,
                }}>
                <Edit />
              </Button>
              <Box background="white" height={10} borderRadius={6} display="flex" alignItems="center">
                <MoreDropBox actions={actions} />
              </Box>
            </HStack>
          ) : (
            <SaveButton type="submit">Save</SaveButton>
          )}
        </HStack>
      ) : (
        <HStack
          paddingX={6}
          paddingY={4}
          borderRadius={6}
          background="white"
          justifyContent="space-between"
          boxShadow={boxShadow}>
          <Text fontSize="lg" color="gray.700" margin={0} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {fullName}
          </Text>
          {!isEdit ? (
            <HStack>
              <Button
                fontWeight={500}
                lineHeight={6}
                leftIcon={<Edit />}
                background={backgroundSecondary}
                cursor="pointer"
                onClick={handleEdit}
                _focus={{ boxShadow: 'none' }}
                _hover={{ background: 'blue' }}>
                Edit User
              </Button>
              <MoreDropBox isBackground actions={actions} />
            </HStack>
          ) : (
            <HStack>
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
              <SaveButton type="submit">Save</SaveButton>
            </HStack>
          )}
        </HStack>
      )}
    </Container>
  )
}

export default HeaderSection
