import { Search2Icon } from '@chakra-ui/icons'
import { Flex, HStack, Input, InputGroup, InputLeftElement, useMediaQuery } from '@chakra-ui/react'
import ButtonWithIcon from 'componentsCharka/ButtonWithIcon'
import { backgroundBlueSecondary, backgroundGrey200 } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'
import { ThemeColor } from 'constants/theme'

interface IHeaderSectionProps {
  handleCreate: () => void
  toggleOpen: () => void
  toggleExport: () => void
  changeName: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const { handleCreate, toggleOpen, toggleExport, changeName } = props
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)

  return (
    <Flex justifyContent="space-between" flexWrap="wrap" paddingX={isMobile ? 4 : 0} paddingTop={isMobile ? 4 : 0}>
      <HStack marginBottom={6}>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          background="white"
          width={isMobile ? '160px' : '400px'}>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input
            type="search"
            placeholder={isMobile ? 'Search' : 'Search Users by name...'}
            onChange={changeName}
            border="none"
          />
        </InputGroup>
        <ButtonWithIcon
          label={!isMobile && 'Filter'}
          background="white"
          color="gray.700"
          iconName="filter.svg"
          size={isMobile ? 20 : 16}
          fontSize="md"
          lineHeight={6}
          fontWeight={500}
          onClick={toggleOpen}
        />
      </HStack>
      <HStack marginBottom={6}>
        <ButtonWithIcon
          label={!isMobile && 'Export'}
          background="white"
          color="gray.700"
          iconName="export.svg"
          size={isMobile ? 20 : 16}
          fontSize="md"
          lineHeight={6}
          fontWeight={500}
          onClick={toggleExport}
        />
        <ButtonWithIcon
          label={!isMobile && 'Create New User'}
          background={backgroundBlueSecondary}
          color={ThemeColor.white}
          iconName="add-white.svg"
          size={isMobile ? 20 : 16}
          fontSize="md"
          lineHeight={6}
          fontWeight={500}
          onClick={handleCreate}
        />
      </HStack>
    </Flex>
  )
}

export default HeaderSection
