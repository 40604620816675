import { IAuthUser } from 'types/user'

export const AUTH_BASE_URL: string = '/auth'

export interface ILoginResponse {
  user: IAuthUser
}

export interface IRefreshTokenResponse {
  user: IAuthUser
}
