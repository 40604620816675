import React from 'react'
import { FaInfo } from 'react-icons/fa'
import Helper from '../../../Helper'
import ApiHelper from '../../../Helper/api'
import { AppConfig } from '../../../constants'

class InformationList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      infos: [],
    }
  }
  componentDidMount() {
    this.getListInfoHandler()
  }
  getListInfoHandler = () => {
    ApiHelper.get(
      {
        url: `/informations/list`,
      },
      (response) => {
        this.setState({
          infos: response.data.informations,
        })
      }
    )
  }
  render() {
    const { infos } = this.state
    const filterredInfos = Helper.filterEvent(infos)
    return (
      <div className="infos-page">
        <div className="infos-content">
          <div className="timeline-container">
            {Array.isArray(filterredInfos) &&
              filterredInfos.map((info, index) => (
                <div key={index}>
                  <div className="timeline-label">
                    <span>{info.period}</span>
                  </div>
                  {eventList(info.data)}
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
}

function eventList(events) {
  return (
    Array.isArray(events) &&
    events.map((event) => (
      <div key={event._id} className="timeline-item">
        <span className="icon-sub">
          <FaInfo className="icon" />
        </span>
        <div className="timeline-content">
          <span className="time">{Helper.getFormattedDate(event.publishedDate)}</span>
          <h3 className="timeline-header">{event.title}</h3>
          <div className="timeline-body">{event.content}</div>
        </div>
      </div>
    ))
  )
}

export default InformationList
