import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  VStack,
  Wrap,
} from '@chakra-ui/react'
import TagItem from 'componentsCharka/InputTag/TagItem'
import { backgroundGrey200 } from 'theme/globalStyles'
import { checkValidArray } from 'utils'
import { IUser, IPartner, ICreatableOption } from 'constants/schema'
import { Information, Label, Flex } from './userSummary.styles'
import styles from './userSummary.module.scss'

interface IUserDetail extends IUser {
  partner?: IPartner[]
  title?: string
  titleData?: ICreatableOption[]
}
interface IUserSummaryProps {
  isOpen: boolean
  userData: IUserDetail
  handleCloseModal: () => void
}

const UserSummary = (props: IUserSummaryProps) => {
  const { isOpen, userData, handleCloseModal } = props
  const activeUserClassName: string = userData?.isActive ? 'Active' : ''

  return (
    <>
      <Drawer isOpen={isOpen} placement="bottom" onClose={handleCloseModal}>
        <DrawerOverlay />
        <DrawerContent maxHeight="80vh">
          <DrawerHeader borderBottom={`1px solid ${backgroundGrey200}`}>User Summary</DrawerHeader>
          <DrawerCloseButton color="gray" background="none" />
          <DrawerBody padding={4}>
            <VStack spacing={6} alignItems="flex-start">
              <Flex>
                <Label>Full Name</Label>
                <Information>{userData?.fullName ? userData?.fullName : 'N/A'}</Information>
              </Flex>
              <Flex>
                <Label>Role</Label>
                <Wrap>
                  <TagItem className={styles[userData?.role]} value={userData?.role}></TagItem>
                </Wrap>
              </Flex>
              <Flex>
                <Label>Email</Label>
                <Information>{userData?.email ? userData?.email : 'N/A'}</Information>
              </Flex>
              <Flex>
                <Label>Phone Number</Label>
                <Information>{userData?.phone ? userData?.phone : 'N/A'}</Information>
              </Flex>
              <Flex>
                <Label>Partner</Label>
                <Information color="blue.500">
                  {checkValidArray(userData?.partner) ? userData?.partner[0]?.fullName : 'N/A'}
                </Information>
              </Flex>
              <Flex>
                <Label>Title</Label>
                <Information>
                  {checkValidArray(userData?.titleData) ? userData?.titleData[0]?.value : 'N/A'}
                </Information>
              </Flex>
              <Flex>
                <Label>Status</Label>
                <Wrap>
                  <TagItem className={styles[activeUserClassName]} value={activeUserClassName}></TagItem>
                </Wrap>
              </Flex>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default UserSummary
