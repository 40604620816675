import { ISuccessResponse } from 'API/constants'
import { litsOfContract } from 'API/contract'
import { IContractListResponse } from 'API/contract/constant'
import { handleError } from 'API/error'
import ApiHelper from 'Helper/api'
import { makeAutoObservable } from 'mobx'
import { toBase64 } from 'utils'
import { IContract } from 'constants/schema'
import RootStore from '../rootStore'
class UserContractStore {
  rootStore: RootStore
  contractList: IContract[] = []
  currentContractContent

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getContractList(): Promise<void> {
    try {
      const response: ISuccessResponse<IContractListResponse> = await litsOfContract()
      const contractList: IContract[] = response.data.contractList
      this.contractList = contractList
    } catch (error) {
      handleError(error, 'src/store/user/contractStore.ts', 'getContractList')
    }
  }

  public showContractFile = async (id, fileName) => {
    ApiHelper.downloadFile(
      {
        dataType: 'pdf',
        url: `/contract/show-file-from-admin/${id}/${fileName}`,
      },
      async (response) => {
        const contractData = response.data
        const result = await toBase64(new Blob([contractData], { type: 'application/pdf' }))
        this.currentContractContent = result
      }
    )
  }
}

export default UserContractStore
