import { chakra, Text, Flex as CKFlex } from '@chakra-ui/react'

export const Label = chakra(Text, {
  baseStyle: () => ({
    fontWeight: 400,
    fontSize: 'md',
    lineHeight: 6,
    color: 'gray.500',
    margin: 0,
  }),
})

export const Information = chakra(Text, {
  baseStyle: () => ({
    fontWeight: 500,
    fontSize: 'md',
    lineHeight: 6,
    color: 'gray.700',
    margin: 0,
    marginTop: 2,
  }),
})

export const Flex = chakra(CKFlex, {
  baseStyle: () => ({
    flexDirection: 'column',
  }),
})
