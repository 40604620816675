import React from 'react'
import { Field } from 'formik'
import { withStyles } from '@material-ui/core/styles'

import Input from '../../../../../components/Input'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
})

class FormDetail extends React.Component {
  render() {
    const { classes, handleSubmit, dirty, isSubmitting, viewMode, cancelEditHandler } = this.props
    let buttonText = 'Edit'
    if (viewMode === 'create') {
      buttonText = 'Create'
    }
    return (
      <form onSubmit={handleSubmit} className="form-detail">
        <Field name="value" label="Value" className={classes.textField} margin="normal" component={Input} />
        <div className="options-sub">
          <button className="project-setting-button primary-button" type="submit" disabled={!dirty && isSubmitting}>
            {buttonText}
          </button>
          {viewMode === 'edit' && (
            <button className="project-setting-button secondary-button" onClick={cancelEditHandler}>
              Cancel
            </button>
          )}
        </div>
      </form>
    )
  }
}

export default withStyles(styles)(FormDetail)
