import { ReactNode } from 'react'
import { Table as CkTable, Thead, Tbody, Tr, Th, Container } from '@chakra-ui/react'
import styles from './index.module.scss'
interface IResponsiveTable {
  dataTable?
  headersGroup?
  children?: ReactNode
}

const ResponsiveTable = (props: IResponsiveTable) => {
  const { headersGroup, children, dataTable } = props
  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <CkTable width="full" className={styles.responsiveTable} variant="striped" colorScheme="gray" outline="none">
        <Thead display={dataTable?.length > 0 ? 'initial' : 'none'}>
          <Tr>
            {headersGroup?.map((header, index) => {
              return <Th key={index}>{header.Header}</Th>
            })}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </CkTable>
    </Container>
  )
}

export default ResponsiveTable
