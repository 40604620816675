import React, { useState } from 'react'
import { HStack, VStack } from '@chakra-ui/layout'
import { EContractTabs } from './constants'
import UnderConstruction from 'containers/UnderConstruction'

const Contract = () => {
  const [currentTab, setCurrentTab] = useState<EContractTabs>(EContractTabs.CONTRACT_CREATE)
  return (
    <HStack
      spacing={{ base: 6, lg: 8 }}
      // justify="space-between"
      alignItems={'flex-start'}
      backgroundColor="white"
      borderRadius="8px"
      p={{ base: 6, lg: 8 }}>
      <UnderConstruction />
    </HStack>
  )
}
export default Contract
