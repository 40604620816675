import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Button, HStack, MenuButton, Text } from '@chakra-ui/react'
import { backgroundGrey200 } from 'theme/globalStyles'
import { EPaymentCategoryOfProject } from 'constants/enum'

export interface IProjectItem {
  title: string
  value?: string | number
  borderColor?: string
  color?: string
  paymentCategory?: EPaymentCategoryOfProject
  budgetRemain?: number
  currencyName?: string
}

interface IDropdownButtonProps {
  item?: IProjectItem
  placeHolder?: string
  isOpen: boolean
  minWidth?: number
  width?: number | string
  isTable?: boolean
  hasIcon?: boolean
}

const DropdownButton = (props: IDropdownButtonProps) => {
  const { item, placeHolder, isOpen, minWidth, width, isTable, hasIcon } = props
  const itemTitle = item?.title ?? ''
  const itemColor = item?.color ?? ''
  const itemBorderColor = item?.borderColor ?? ''

  return (
    <MenuButton
      minWidth={minWidth || '200px'}
      width={width ?? { base: '400px', lg: '100%' }}
      height="10"
      isActive={isOpen}
      isTruncated
      fontSize="md"
      color="gray.700"
      border={`1px solid ${backgroundGrey200}`}
      borderRadius="0.375em"
      background="white"
      colorScheme="white"
      fontWeight="normal"
      as={Button}
      iconSpacing={5}
      textAlign={{ base: isTable ? 'center' : 'left', md: 'left' }}
      transition="all 0.1s"
      _focus={{ boxShadow: 'outline' }}
      cursor={'pointer'}
      rightIcon={<ChevronDownIcon height="5" width="5" />}>
      <HStack>
        {hasIcon && (
          <Box
            width={3}
            height={3}
            borderRadius="50%"
            background={itemColor}
            border="1px solid"
            borderColor={itemBorderColor}
            margin={2}></Box>
        )}
        <Text>{item?.value !== undefined ? itemTitle : placeHolder}</Text>
      </HStack>
    </MenuButton>
  )
}

export default DropdownButton
