import { chakra, Text as CKText } from '@chakra-ui/react'

export const Title = chakra(CKText, {
  baseStyle: () => ({
    fontWeight: 400,
    fontSize: 'md',
    lineHeight: 6,
    color: 'gray.500',
    margin: 0,
  }),
})

export const Text = chakra(CKText, {
  baseStyle: () => ({
    fontWeight: 500,
    fontSize: 'md',
    lineHeight: 6,
    color: 'gray.700',
    margin: 0,
    marginTop: 2,
  }),
})
