import * as React from 'react'
import FormModel from '../../../../components/FormModel'
import FormDetail from '../../ProjectsSettings/components/FormDetail'
import Item from '../../ProjectsSettings/components/Item'
import { ITitle } from '../constants'

const Title = (props: ITitle): JSX.Element => {
  const {
    handleSubmit,
    getDetailTitle,
    titles,
    titleDetail,
    deleteTitle,
    viewMode,
    cancelEditHandler,
    validationSchema,
  } = props
  return (
    <div className="container-fluid setting-item">
      <div className="box box-default">
        <div className="box-header">Title</div>
        <div className="box-divider" />
        <div className={'box-body'}>
          <div className="container-fluid">
            <div>
              <FormModel
                initialValues={titleDetail}
                handleSubmit={handleSubmit}
                schema={validationSchema}
                component={<FormDetail cancelEditHandler={cancelEditHandler} viewMode={viewMode} {...props} />}
              />
            </div>
            <div className="setting-list-wrapper">
              {titles &&
                titles.map((item, index) => (
                  <Item
                    key={item._id}
                    data={item}
                    index={index}
                    editClickHandler={() => getDetailTitle(item._id)}
                    deleteClickHandler={() => deleteTitle(item, 'title')}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Title
