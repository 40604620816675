import api from 'API'
import apiV2 from 'API/apiV2'
import { ISuccessResponse } from 'API/constants'
import { getErrorMessage, handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import { EWorkingHourPeriods } from 'constants/enum'
import { IDashboardUser } from 'types/dashboard-user'
import { IFormChangePassword, IUser } from 'types/user'
import {
  IBirthdayFilter,
  IBirthdayList,
  IChangePasswordResponse,
  ICreateUserRequest,
  IUpdateUserProfileResponse,
  IUpdateUserRequest,
  IUserListAdminResponse,
  IUserListResponse,
  IUserListWithPaginationRequest,
  IUserProfileResponse,
  USER_BASE_URL,
  USER_PROFILE_BASE_URL,
} from './constants'

export async function changePassword(payload: IFormChangePassword): Promise<ISuccessResponse<IChangePasswordResponse>> {
  try {
    return api.post(`${USER_PROFILE_BASE_URL}/change-password`, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getUserProfile(): Promise<ISuccessResponse<IUserProfileResponse>> {
  try {
    const url = `${USER_BASE_URL}/profile/detail`
    return apiV2.get(url)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

// *INFO: using "object" type because the API response empty object
export async function updateUserProfile(payload: IUser): Promise<ISuccessResponse<object>> {
  try {
    const url = `${USER_BASE_URL}/profile/update`
    return api.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getCvFile(cvFileName: string): Promise<ISuccessResponse<BlobPart>> {
  try {
    return api.get(`${USER_BASE_URL}/get-cv-file/${cvFileName}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    })
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function updateUserData(payload: object): Promise<ISuccessResponse<IUpdateUserProfileResponse>> {
  try {
    const url = `${USER_BASE_URL}/profile/update-user-data`
    return api.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getUserList(): Promise<ISuccessResponse<IUserListResponse>> {
  try {
    return api.get(`${USER_BASE_URL}/list`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getUserListWithPagination(
  payload?: IUserListWithPaginationRequest
): Promise<ISuccessResponse<IUserListResponse>> {
  try {
    return api.post(`${USER_BASE_URL}/list`, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function updateUser(userId: string, user: IUpdateUserRequest): Promise<void> {
  try {
    return api.post(`${USER_BASE_URL}/update/${userId}`, user)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function createUser(createUser: ICreateUserRequest): Promise<void> {
  try {
    const url = `${USER_BASE_URL}/create-user`
    const response: AxiosResponse = await apiV2.post(url, createUser)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function deleteUser(userId: string): Promise<void> {
  try {
    await api.post(`${USER_BASE_URL}/delete/${userId}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function archivedUser(userId: string): Promise<void> {
  try {
    await api.post(`${USER_BASE_URL}/archive/${userId}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function unArchivedUser(userId: string): Promise<void> {
  try {
    await api.post(`${USER_BASE_URL}/unarchive/${userId}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getUserDetail(userId: string): Promise<IUserProfileResponse> {
  try {
    const response: AxiosResponse = await api.get(`${USER_BASE_URL}/detail/${userId}`)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function updateUserDetail(userId: string, payload: IUpdateUserRequest): Promise<void> {
  try {
    await api.post(`${USER_BASE_URL}/update/${userId}`, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function updateUserProjectRating(userId: string, payload: IUpdateUserRequest): Promise<void> {
  try {
    await api.post(`${USER_BASE_URL}/update-project-ratings/${userId}`, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getUserListAdmin(): Promise<IUserListAdminResponse> {
  try {
    const response: AxiosResponse = await api.get(`${USER_BASE_URL}/list-admin`)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getBirthdayList(payload: IBirthdayFilter): Promise<IBirthdayList> {
  try {
    const url: string = `${USER_BASE_URL}/get-birthday-list`
    const response: AxiosResponse = await api.post(url, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/user', 'getBirthdayList')
    return {} as IBirthdayList
  }
}

export async function getIsSupervisor(): Promise<boolean> {
  try {
    const url: string = `${USER_PROFILE_BASE_URL}/is-supervisor`
    const response: AxiosResponse = await apiV2.get(url)
    return response?.data?.isSupervisor
  } catch (error) {
    handleError(error as Error, 'API/user', 'getIsSupervisor')
    return false
  }
}

export async function getDashboardUser(
  period: EWorkingHourPeriods,
  date: string,
  userId: string
): Promise<IDashboardUser> {
  try {
    const url: string = `${USER_BASE_URL}/dashboard?period=${period}&date=${date}&userId=${userId}`

    const response: AxiosResponse = await apiV2.get(url)
    return response?.data
  } catch (error) {
    handleError(error as Error, 'API/user', 'getIsSupervisor')
    return {
      fullName: '',
      totalSalary: 0,
      totalWorkingTime: 0,
      lastTotalSalary: 0,
      lastTotalWorkingTime: 0,
      numberOfProjects: 0,
      lastNumberOfProjects: 0,
      commitTimes: [],
      timesheetData: [],
      projectWorkingTimePieData: [],
      performanceLineData: [],
      salaryBarData: [],
    }
  }
}
