import React from 'react'
import { Messages } from '../../../../../constants'
import Helper from '../../../../../Helper'

class RejectRequestDialogContent extends React.Component {
  render() {
    const { data, inputReasonChangeHandler } = this.props
    let currentCommitTime = Helper.getFormattedNumber(data.commitTime, 1)
    if (currentCommitTime === '-') {
      currentCommitTime = 0
    }
    return (
      <div className="confirm-dialog">
        <p className="warning-message">{Messages.rejectRequestDialogWarning}</p>
        <p className="confirm-message">
          {Messages.rejectRequestDialogContentPart1}{' '}
          <span className="highlight">{currentCommitTime} (hours per week)</span>
        </p>
        <div className="confirm-delete-again">
          <p className="guide-message">{Messages.rejectRequestDialogContentPart2}</p>
          <div className="field">
            <label>Reason</label>
            <input className="delete-input-field" onChange={(event) => inputReasonChangeHandler(event)} />
          </div>
        </div>
      </div>
    )
  }
}

export default RejectRequestDialogContent
