import { useEffect } from 'react'
import { handleError } from 'API/error'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import FormFieldWithLabel from 'componentsCharka/FormFieldWithLabel'
import GroupRadioButton from 'componentsCharka/GroupRadioButton'
import Helper from 'Helper'
import startCase from 'lodash/startCase'
import { useWatch, useFormContext } from 'react-hook-form'
import { IOption } from 'constants/common'
import { PaymentCategories } from 'constants/defaultOptionList'
import { EPaymentCategoryOfProject } from 'constants/enum'
import { useStores } from 'utils/hooks/useStores'
import { IFormSectionBaseProps, ESectionHeaderValues } from '../../constants'
import { SectionWrapper, SectionHeader, GridLayout, SectionBody } from '../../projectDetailAdmin.styles'
import { getFormInputProps, getFormLabelProps } from '../../utils'
import FormInput from '../FormInput'

const PaymentSection = (props: IFormSectionBaseProps) => {
  const { isEditing } = props
  const { adminProjectStore } = useStores()
  const { currencyList } = adminProjectStore
  const { control } = useFormContext()
  const paymentCategory: string | undefined = useWatch({ control, name: 'paymentCategory' })
  const isFixedPrice: boolean = startCase(paymentCategory) === EPaymentCategoryOfProject.FIXED_PRICE
  const currencyOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(currencyList)

  async function fetchData(): Promise<void> {
    try {
      await adminProjectStore.getCurrencyList()
    } catch (error) {
      handleError(
        error as Error,
        'src/containers/ProjectsNew/V2/ProjectDetailAdminMobile/components/GeneralInformationSection/index.tsx',
        'fetchData'
      )
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <SectionWrapper spacing={{ base: 4 }}>
      <SectionHeader>{ESectionHeaderValues.PAYMENT}</SectionHeader>
      <SectionBody spacing={{ base: isEditing ? 6 : 4 }}>
        <GridLayout columns={!isEditing ? 2 : 1} gap={{ base: !isEditing ? 4 : 6 }}>
          <FormFieldWithLabel {...getFormLabelProps('paymentCategory', 'Category of Payment', isEditing)}>
            {isEditing && (
              <GroupRadioButton
                {...getFormInputProps('paymentCategory', 'Category of Payment')}
                options={PaymentCategories}
                stackProps={{
                  direction: 'row',
                  spacing: 100,
                }}
              />
            )}
          </FormFieldWithLabel>
          <FormFieldWithLabel {...getFormLabelProps('currency', 'Currency', isEditing)}>
            {isEditing && (
              <ChakraInputDropdown
                {...getFormInputProps('currency', 'Currency', '- Select Currency -')}
                isRequire
                widthCustom="100%"
                optionsData={currencyOptions}
              />
            )}
          </FormFieldWithLabel>
        </GridLayout>
        <GridLayout columns={!isEditing ? 2 : 1} gap={{ base: !isEditing ? 4 : 6 }}>
          {isFixedPrice && (
            <FormFieldWithLabel {...getFormLabelProps('budgetProject', 'Budget', isEditing)}>
              {isEditing && <FormInput {...getFormInputProps('price', 'Price', 'Enter Price', true)} />}
            </FormFieldWithLabel>
          )}
          <FormFieldWithLabel {...getFormLabelProps('paymentPeriod', 'Payment Period (days)', isEditing)}>
            {isEditing && (
              <FormInput
                {...getFormInputProps('paymentPeriod', 'Payment Period (days)', 'Enter Payment Period', true)}
              />
            )}
          </FormFieldWithLabel>
        </GridLayout>
      </SectionBody>
    </SectionWrapper>
  )
}

export default PaymentSection
