import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { ERoleOfUser } from 'constants/enum'
import { ELocalStorageKeys } from 'constants/localStorageKeys'
import { useStores } from 'utils/hooks/useStores'
import { EventName } from '../../constants'
import ConfirmDialog from '../ConfirmDeleteDialog'
import ConfirmDialogYesNo from '../ConfirmDeleteDialogYesNo'
import Header from '../Header'
import Loading from '../Loading'
import Sidebar from '../Sidebar'
import Snackbar from '../Snackbar'
import { getPageTitle } from './utils'

import './index.scss'

const Layout = (props) => {
  const {
    component: Component,
    match,
    notifications,
    getNotificationsList,
    unReadFeedbackNumber,
    getUnreadFeedbackNumber,
  } = props
  const { layoutStore } = useStores()

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const [isOpenLoading, setIsOpenLoading] = useState<boolean>(false)
  const [isOpenUserPannel, setIsOpenUserPannel] = useState<boolean>(false)
  const [isActiveUserPannel, setIsActiveUserPannel] = useState<boolean>(false)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false)
  const [variant, setVariant] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [confirmDialogTitle, setConfirmDialogTitle] = useState<string>('')
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false)
  const [confirmDialogContent, setConfirmDialogContent] = useState<void>()
  const [OKClickConfirmDialogHandler, setOKClickConfirmDialogHandler] = useState<void>()
  const [isCorrectNameOfDeleteDialog, setIsCorrectNameOfDeleteDialog] = useState<boolean>(false)
  const [deletedItem, setDeletedItem] = useState<object>()
  const [deletedItemType, setDeletedItemType] = useState<string>()
  const [isOpenConfirmDialogYesNo, setIsOpenConfirmDialogYesNo] = useState<boolean>(false)
  const [OKClickConfirmDialogYesNoHandler, setOKClickConfirmDialogYesNoHandler] = useState<void>()
  const [deletedItemOfDialogYesNo, setDeletedItemOfDialogYesNo] = useState<void>()

  const { path } = match
  const pathElement: string[] = path.split('/')
  const action: string = pathElement[1]

  useEffect(() => {
    getNotificationsList()
    const role: string = window.localStorage.getItem(ELocalStorageKeys.USER_ROLE)
    if (role === ERoleOfUser.ADMIN) {
      getUnreadFeedbackNumber()
    }
    mobileScreenDetector()
    addEvent()

    return () => removeEvent()
  }, [])

  useEffect(() => {
    const currentPageTitle: string = getPageTitle(path)
    layoutStore.setPageTitle(currentPageTitle)
  }, [path])

  // *TODO: Move to store later
  function addEvent(): void {
    document.addEventListener(EventName.openErrorSnackbar, errorSnackBarHandler)
    document.addEventListener(EventName.openLoading, closeLoadingHandler)
    document.addEventListener(EventName.closeLoading, closeLoadingHandler)
    document.addEventListener(EventName.openDeleteProjectConfirmDialog, openConfirmDialogHandler)
    document.addEventListener(EventName.updateDeleteProjectConfirmDialog, updateConfirmDialogHandler)
    document.addEventListener(EventName.openDeleteProjectSettingsConfirmDialog, openConfirmDialogHandler)
    document.addEventListener(EventName.updateDeleteProjectSettingsConfirmDialog, updateConfirmDialogHandler)
    document.addEventListener(EventName.openDeleteUserConfirmDialog, openConfirmDialogHandler)
    document.addEventListener(EventName.updateDeleteUserConfirmDialog, updateConfirmDialogHandler)
    document.addEventListener(EventName.openDeletePartnerConfirmDialog, openConfirmDialogHandler)
    document.addEventListener(EventName.updateDeletePartnerConfirmDialog, updateConfirmDialogHandler)
    document.addEventListener(EventName.openDeleteInternalDocumentConfirmDialog, openConfirmDialogHandler)
    document.addEventListener(EventName.updateDeleteInternalDocumentConfirmDialog, updateConfirmDialogHandler)
    document.addEventListener(EventName.openDeleteTimesheetConfirmDialogYesNo, openConfirmDialogYesNoHandler)
    document.addEventListener(EventName.openDeleteInfoConfirmDialogYesNo, openConfirmDialogYesNoHandler)
    document.addEventListener(EventName.openDeleteAdvancePaymentConfirmDialogYesNo, openConfirmDialogYesNoHandler)
    document.addEventListener(EventName.openDeletePolicyConfirmDialogYesNo, openConfirmDialogYesNoHandler)
    document.addEventListener(EventName.openDeleteFeedbackConfirmDialogYesNo, openConfirmDialogYesNoHandler)
    document.addEventListener(EventName.openCloseCommitTimeRequestDialogYesNo, openConfirmDialogYesNoHandler)
  }

  // *TODO: Move to store later
  function removeEvent(): void {
    document.removeEventListener(EventName.openErrorSnackbar, errorSnackBarHandler)
    document.removeEventListener(EventName.openLoading, closeLoadingHandler)
    document.removeEventListener(EventName.closeLoading, closeLoadingHandler)
    document.removeEventListener(EventName.openDeleteProjectConfirmDialog, openConfirmDialogHandler)
    document.removeEventListener(EventName.updateDeleteProjectConfirmDialog, updateConfirmDialogHandler)
    document.removeEventListener(EventName.openDeleteProjectSettingsConfirmDialog, openConfirmDialogHandler)
    document.removeEventListener(EventName.updateDeleteProjectSettingsConfirmDialog, updateConfirmDialogHandler)
    document.removeEventListener(EventName.openDeleteUserConfirmDialog, openConfirmDialogHandler)
    document.removeEventListener(EventName.updateDeleteUserConfirmDialog, updateConfirmDialogHandler)
    document.removeEventListener(EventName.openDeletePartnerConfirmDialog, openConfirmDialogHandler)
    document.removeEventListener(EventName.updateDeletePartnerConfirmDialog, updateConfirmDialogHandler)
    document.removeEventListener(EventName.openDeleteInternalDocumentConfirmDialog, openConfirmDialogHandler)
    document.removeEventListener(EventName.updateDeleteInternalDocumentConfirmDialog, updateConfirmDialogHandler)
    document.removeEventListener(EventName.openDeleteTimesheetConfirmDialogYesNo, openConfirmDialogYesNoHandler)
    document.removeEventListener(EventName.openDeleteInfoConfirmDialogYesNo, openConfirmDialogYesNoHandler)
    document.removeEventListener(EventName.openDeleteAdvancePaymentConfirmDialogYesNo, openConfirmDialogYesNoHandler)
    document.removeEventListener(EventName.openDeletePolicyConfirmDialogYesNo, openConfirmDialogYesNoHandler)
    document.removeEventListener(EventName.openDeleteFeedbackConfirmDialogYesNo, openConfirmDialogYesNoHandler)
    document.removeEventListener(EventName.openCloseCommitTimeRequestDialogYesNo, openConfirmDialogYesNoHandler)
  }

  function mobileScreenDetector(): void {
    const isMobile: boolean = window.matchMedia('only screen and (max-width: 767px)').matches
    if (isMobile) {
      setIsCollapsed(true)
      window.localStorage.setItem(ELocalStorageKeys.IS_COLLAPSED, JSON.stringify(true))
    }
  }

  function closeLoadingHandler(objectEvent: CustomEvent): void {
    setIsOpenLoading(objectEvent?.detail?.isOpen)
  }

  function updateConfirmDialogHandler(objectEvent: CustomEvent): void {
    setIsCorrectNameOfDeleteDialog(objectEvent?.detail?.isCorrectName)
  }

  function openConfirmDialogHandler(objectEvent: CustomEvent): void {
    setIsOpenConfirmDialog(objectEvent?.detail?.isOpen)
    setConfirmDialogTitle(objectEvent?.detail?.title)
    setConfirmDialogContent(objectEvent?.detail?.content)
    setDeletedItem(objectEvent?.detail?.item)
    setDeletedItemType(objectEvent?.detail?.itemType)
    setOKClickConfirmDialogHandler(() => objectEvent?.detail?.OKClickHandler)
  }

  function openConfirmDialogYesNoHandler(objectEvent: CustomEvent): void {
    setIsOpenConfirmDialogYesNo(objectEvent?.detail?.isOpen)
    setDeletedItemOfDialogYesNo(objectEvent?.detail?.item)
    setOKClickConfirmDialogYesNoHandler(() => objectEvent?.detail?.OKClickHandler)
  }

  function errorSnackBarHandler(details): void {
    openSnackbar(details?.variant ?? '', details?.message ?? '')
    setTimeout(() => {
      handleCloseSnackbar()
    }, 3000)
  }

  function successSnackBarHandler(details): void {
    openSnackbar(details?.variant ?? '', details?.message ?? '')
    setTimeout(() => {
      handleCloseSnackbar()
    }, 3000)
  }

  function openSnackbar(variant: string, message: string): void {
    setVariant(variant)
    setMessage(message)
    setIsSnackbarOpen(true)
  }

  function handleCloseSnackbar(): void {
    setIsSnackbarOpen(false)
  }

  function handleCloseConfirmDialog(): void {
    setIsOpenConfirmDialog(false)
  }

  function handleCloseConfirmDialogYesNo(): void {
    setIsOpenConfirmDialogYesNo(false)
  }

  function triggerCollapsedSidebar(): void {
    setIsCollapsed(!isCollapsed)
    window.localStorage.setItem(ELocalStorageKeys.IS_COLLAPSED, JSON.stringify(!isCollapsed))
  }

  function triggerToggleUserPannel(): void {
    setIsOpenUserPannel(!isOpenUserPannel)
    setIsActiveUserPannel(!isActiveUserPannel)
  }

  function handleOpenLoading(): void {
    setIsOpenLoading(true)
  }

  function handleCloseLoading(): void {
    setIsOpenLoading(false)
  }

  return (
    <div className="app">
      <Header
        {...props}
        notifications={notifications}
        isCollapsed={isCollapsed}
        isOpenUserPannel={isOpenUserPannel}
        isActiveUserPannel={isActiveUserPannel}
        getNotificationsList={getNotificationsList}
        triggerCollapsedSidebarHandler={triggerCollapsedSidebar}
        triggerToggleUserPannelHandler={triggerToggleUserPannel}
      />
      <Sidebar
        {...props}
        isCollapsed={isCollapsed}
        setIsCollapsed={triggerCollapsedSidebar}
        actionTab={action}
        unReadFeedbackNumber={unReadFeedbackNumber}
      />
      <div className={isCollapsed ? 'collapsed app-wrapper' : 'app-wrapper'}>
        <div className="app-content-wrapper">
          <Component
            {...props}
            handleOpenSnackbar={successSnackBarHandler}
            handleCloseConfirmDialog={handleCloseConfirmDialog}
            handleCloseConfirmDialogYesNo={handleCloseConfirmDialogYesNo}
            handleOpenLoading={handleOpenLoading}
            handleCloseLoading={handleCloseLoading}
          />
        </div>
        {isOpenLoading && <Loading />}
      </div>
      <Snackbar open={isSnackbarOpen} variant={variant} message={message} handleClose={handleCloseSnackbar} />
      <ConfirmDialog
        isOpen={isOpenConfirmDialog}
        title={confirmDialogTitle}
        content={confirmDialogContent}
        OKClickHandler={OKClickConfirmDialogHandler}
        closeHandler={handleCloseConfirmDialog}
        isCorrectName={isCorrectNameOfDeleteDialog}
        item={deletedItem}
        itemType={deletedItemType}
      />
      <ConfirmDialogYesNo
        isOpen={isOpenConfirmDialogYesNo}
        OKClickHandler={OKClickConfirmDialogYesNoHandler}
        closeHandler={handleCloseConfirmDialogYesNo}
        item={deletedItemOfDialogYesNo}
      />
    </div>
  )
}

export default observer(Layout)
