import capitalize from 'lodash/capitalize'
const RoutesConstants = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  DASHBOARD: '/dashboard',
  USER_DASHBOARD: '/user-dashboard',
  USER_DASHBOARD_DETAIL: '/user-dashboard/:id',
  GUIDELINE: '/guideline',
  CONTRACT: '/contract',
  CONTRACT_DETAIL: '/contract/contract-detail/:id',
  PROJECTS_ADMIN_LIST: '/project/project-admin/list',
  PROJECT_ADMIN_CREATE: '/project/project-admin/create',
  PROJECT_ADMIN_DETAIL: '/project/project-admin/detail/:id',
  PROJECT_LIST: '/project/list',
  PROJECT_DETAIL: '/project/detail/:id',
  POLICIES_ADMIN: '/policies/policies-admin',
  POLICIES: '/policies',
  POLICY_NEW: '/policy/new',
  INFOS_ADMIN: '/infos/infos-admin',
  INFOS: '/infos',
  INFOS_NEW: '/infos/new',
  TIMESHEET: '/timesheet',
  V2_TIMESHEET: '/timesheet-v2',
  DIAGRAM: '/diagram',
  TIMESHEET_ADMIN: '/timesheet/timesheet-admin',
  TIMESHEET_ADMIN_OVERVIEW: '/timesheet/timesheet-admin/overview',
  PROFILE: '/profile',
  PROFILE_LABOR_CONTACT: '/profile/labor-contact',
  PROFILE_CHANGE_PASSWORD: '/profile/change-password',
  PROFILE_PROJECT_RATINGS: '/profile/project-ratings',
  PROFILE_CURRICULUM_VITAE: '/profile/curriculum-vitae',
  SETTINGS: '/settings',
  SETTING_GLOBAL_CONFIG: '/settings/global-config',
  SETTING_IAM: '/settings/iam',
  FEEDBACK: '/feedback',
  FEEDBACK_ADMIN: '/feedback/feedback-admin',
  FEEDBACK_NEW: '/feedback/new',
  ACTIVITY: '/activity',
  SHARE_CV_FULL: '/share-cv/:fileId/:userId/:codeShare',
  SHARE_CV: '/share-cv',
  PAYMENTS_ADMIN: '/payments/payments-admin',
  FREELANCER_FEE: '/payments/payments-admin/freelancer-fee',
  PAYROLL: '/payments/payments-admin/payroll',
  ADVANCED_PAYMENT: '/payments/payments-admin/advanced-payment',
  PAYMENTS: '/payments',
  COMMIT_TIME_ADMIN: '/commit-time/commit-time-admin',
  COMMIT_TIME: '/commit-time/',
  COMMIT_TIME_DASHBOARD: '/commit-time/dashboard',
  PARTNER: '/partners',
  PARTNER_NEW: '/partners/new',
  PARTNER_DETAIL: '/partners/detail/:id',
  INTERNAL_DOCUMENT: '/internal-documents',
  INTERNAL_DOCUMENT_NEW: '/internal-documents/new',
  INTERNAL_DOCUMENT_DETAIL: '/internal-documents/detail/:id',
  USER_OLD: '/users-old',
  USER_OLD_NEW: '/users-old/new',
  USER_OLD_DETAIL: '/users-old/detail/:id',
  USER_LIST: '/users',
  USER_CREATE: '/users/new',
  USER_DETAIL: '/users/detail/:id',
  USER_CV: '/users/detail/cv/:id',
  BIRTHDAY: '/birthday',
  ACCOUNTING: '/accounting',
  ACCOUNTING_REVENUE: '/accounting/revenue',
  ACCOUNTING_INCOME_LIST: '/accounting/income/list',
  ACCOUNTING_INCOME_CREATE: '/accounting/income/create',
  ACCOUNTING_INCOME_DETAIL: '/accounting/income/detail/:id',
  ACCOUNTING_EXPENSE_LIST: '/accounting/expense/list',
  QR_CODE: '/qr-code-generator',
  //*TODO: Turn on when integrate for notification page
  // NOTIFICATION: '/notification',
}

const defaultPassword = '9999@UDT'

const actionOnDataTable = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  ARCHIVE: 'ARCHIVE',
  ACTIVE: 'ACTIVE',
  Summary: 'Summary',
}

const Messages = {
  noData: 'There is no any data',
  fieldRequired: 'This field is required',
  fieldRequiredBy: (name) => `${capitalize(name)} is required`,
  projectFieldRequired: 'Please choose the project',
  isValidDateTimeOfTimesheet: 'Your Start time and End time are not valid',
  inValidStartTimeV2: "Start Time is not match format 'HH:mm'",
  invalidEmail: 'The email is invalid',
  serverError: 'There is something wrong',
  exceedCharacterOfString: 'You can not exceed 4000 characters',
  minOfCharacterTag: 'You can not add the tags lesser than 4 characters',
  requiredTagInThisProject: 'This project requires tags, please add tags',
  noticeEditTimesheet: '*If you want to edit or delete, please double click to the timesheet item',
  noticeEditTimesheetForSPScreen: '*If you want to edit or delete, please double click to the timesheet icon',
  noticeCreateTimesheet: '*Create new timesheet in here',
  validMaxMarginField: 'The margin can not be greater than 100%',
  validMinMarginField: 'The margin can not be lower than 1%',
  inValidRemuneration: 'The remuneration can not be greater than budget remain',
  noteSendRequestForAllProjects:
    'If you request commit by ALL PROJECT, all commits with specific project will be overwrited.',

  deleteDialogTitle: 'Are you absolutely sure?',
  deleteDialogWarning: 'Unexpected bad things will happen if you don’t read this!',
  deleteDialogContent: 'This action cannot be undone. This will permanently delete the ',
  deleteDialogGuide: 'Please type in the name to confirm',

  deleteDialogYesNoContent: 'Do you want to delete this. Please confirm!',

  archiveDialogContent: 'This action cannot be undone. This will permanently archive the ',
  unarchiveDialogContent: 'This action cannot be undone. This will permanently unarchive the ',

  sendRequestDialogTitle: 'Do you want to send request to change the commit time?',
  sendRequestDialogContentPart1: 'Your current commit time is ',
  sendRequestDialogContentPart2:
    'If you want to send request to change the commit time, please enter the new commit time',
  sendRequestDialogWarning: 'Your request will be considered by admin.',

  editCommitTimeDialogTitle: 'Do you want to edit commit time?',
  editCommitTimeDialogWarning: 'Unexpected bad things will happen if you don’t read this!',
  editCommitTimeDialogContentPart1: 'The current commit time is',
  editCommitTimeDialogContentPart2: 'If you want to edit the commit time, please enter new value',

  rejectRequestDialogTitle: 'Do you want to reject this request?',
  rejectRequestDialogWarning: 'Unexpected bad things will happen if you don’t read this!',
  rejectRequestDialogContentPart1: 'The current commit time is ',
  rejectRequestDialogContentPart2: 'If you want to reject the request, please enter your reason',

  createProjectSuccess: 'Create project successfully',
  updateProjectSuccess: 'Update project successfully',
  deleteProjectSuccess: 'Delete project successfully',
  unarchiveProjectSuccess: 'Unarchive project successfully',
  archiveProjectSuccess: 'Archive project successfully',

  createTechnologySuccess: 'Create Technology successfully',
  updateTechnologySuccess: 'Update Technology successfully',
  deleteTechnologySuccess: 'Delete Technology successfully',

  createProjectTypeSuccess: 'Create Project Type successfully',
  updateProjectTypeSuccess: 'Update Project Type successfully',
  deleteProjectTypeSuccess: 'Delete Project Type successfully',

  createCurrencySuccess: 'Create Currency successfully',
  updateCurrencySuccess: 'Update Currency successfully',
  deleteCurrencySuccess: 'Delete Currency successfully',

  createCountrySuccess: 'Create Country successfully',
  updateCountrySuccess: 'Update Country successfully',
  deleteCountrySuccess: 'Delete Country successfully',

  createDocTypeSuccess: 'Create Internal Document Type successfully',
  updateDocTypeSuccess: 'Update Internal Document Type successfully',
  deleteDocTypeSuccess: 'Delete Internal Document Type successfully',

  createTitleSuccess: 'Create Title successfully',
  updateTitleSuccess: 'Update Title successfully',
  deleteTitleSuccess: 'Delete Title successfully',

  createLevelSuccess: 'Create Level successfully',
  updateLevelSuccess: 'Update Level successfully',
  deleteLevelSuccess: 'Delete Level successfully',

  updateUserSuccess: 'Update user successfully',
  createUserSuccess: 'Create user successfully',
  deleteUserSuccess: 'Delete user successfully',
  unarchiveUserSuccess: 'Unarchive user successfully',
  archiveUserSuccess: 'Archive user successfully',

  updatePartnerSuccess: 'Update partner successfully',
  createPartnerSuccess: 'Create partner successfully',
  deletePartnerSuccess: 'Delete partner successfully',
  userCreatedWithDefaultPasswordAlert: 'New User has default password',
  unarchivePartnerSuccess: 'Unarchive partner successfully',
  archivePartnerSuccess: 'Archive partner successfully',

  updateInternalDocumentSuccess: 'Update internal document successfully',
  updateInternalDocumentFailed: 'Update internal document failed',
  createInternalDocumentSuccess: 'Create internal document successfully',
  createInternalDocumentFailed: 'Create internal document failed',
  deleteInternalDocumentSuccess: 'Delete internal document successfully',

  updateProfileSuccess: 'Update profile successfully',
  uploadCVProfileSuccess: 'Upload CV profile successfully',

  updateInfoSuccess: 'Update information successfully',
  deleteInfoSuccess: 'Delete information successfully',

  createPolicySuccess: 'Create policy successfully',
  updatePolicySuccess: 'Update policy successfully',
  deletePolicySuccess: 'Delete policy successfully',

  updateFeedbackSuccess: 'Update feedback successfully',
  deleteFeedbackSuccess: 'Delete feedback successfully',

  createTimesheetSuccess: 'Create timesheet successfully',
  copyTimesheetSuccess: 'Copy timesheet successfully',
  editTimesheetSuccess: 'Edit timesheet successfully',
  deleteTimesheetSuccess: 'Delete timesheet successfully',

  createTimesheetFailed: 'Create timesheet failed',
  copyTimesheetFailed: 'Copy timesheet failed',
  editTimesheetFailed: 'Edit timesheet failed',
  deleteTimesheetFailed: 'Delete timesheet failed',

  editHourlyRatePriceSuccess: 'Edit Hourly Rate successfully',
  editFixedRatePriceSuccess: 'Edit Fixed Rate successfully',
  editProjectsOfSupervisorSuccess: 'Edit Projects of Supervisor successfully',

  createRateExchangeSuccess: 'Create rate exchange successfully',
  editRateExchangeSuccess: 'Edit rate exchange successfully',
  deleteRateExchangeSuccess: 'Delete rate exchange successfully',

  sendRequestChangeCommitTimeSuccessfully: 'Send request change commit time successfully',
  rejectRequestChangeCommitTimeSuccessfully: 'Reject request change commit time successfully',
  approveRequestChangeCommitTimeSuccessfully: 'Approve request change commit time successfully',

  noNewNotification: 'There are not new notifications',

  uploadCVSuccess: 'The CV has uploaded successfully',

  workingScheduleRegisteSuccess: 'The working schedules has updated successfully',

  notifyBrowser: {
    title: 'Avengers Timesheet',
    updateTimesheetBody: 'Someone updates timesheet',
  },
  notAllowToDownloadCV: 'You are not allow to download this CV',
  longPollingSuccess: 'Success',

  noticeCopyLastWeekWorkingSchedules:
    '*If you want to copy the working schedules of last week, click to edit then click to COPY ICON BUTTON',
  warningCopyLastWeekWorkingSchedules:
    'NOTE: When you press the copy button, all current schedules will be overwritten. Please carefully!',

  noProfitChart: 'This project has not profit chart',
  noRevenuChart: 'This project has not revenue chart',

  updateAdvancePaymentSuccess: 'Update advance payment successfully',
  deleteAdvancePaymentSuccess: 'Delete advance payment successfully',

  invalidNewCommitTimeValue: 'Your new commit value is invalid and it must be lesser than 168 hours',
  invalidNewCommitTimeReason: 'Your reason must be greater than 20 characters and lesser than 200 characters',

  closeCommitTimeRequestSuccessfully: 'Close commit time request successfully',
  editCommitTimeSuccessfully: 'Edit commit time succesfully',

  sendRequestToEditPayment: 'Your request has sent succesfully',
  bankNameNote:
    '<Short name - City - Branch - Full name> | Ex: vietcombank - HCM - Ky Dong - Ngân hàng thương mại cổ phần Ngoại thương Việt Nam',
  fullNameNote: 'Please enter the name on bank account ',
  updateUserAlertTitle: 'Update important indicators of new user',
  updateUserAlertDescription: `Please set Level, Title, Payment category of new user. And new user has default password is ${defaultPassword}_<user's email>`,
  externalProjectNote: 'They are projects not under the management of UDT Company',

  unauthorized: "Unauthorized!"
}
const apiUrlV2 = process.env.REACT_APP_API_URL_V2
const apiURL = process.env.REACT_APP_API_URL
const imageStorageURL = process.env.REACT_APP_IMAGES_STORAGE_URL
const fileStorageURL = process.env.REACT_APP_FILES_STORAGE_URL
const shareCVURL = process.env.REACT_APP_SHARE_CV_FILE_URL
const taskManagementURL = process.env.REACT_APP_TASK_MANAGEMENT_URL
const featureFlagURL = process.env.REACT_APP_FEATURE_FLAG_API_URL
const featureFlagToken = process.env.REACT_APP_FEATURE_FLAG_API_ACCESS_TOKEN
const environment = process.env.REACT_APP_ENVIRONMENT
const smartLookKey = process.env.REACT_APP_SMART_LOOK_KEY;

const AppConfig = {
  ENVIROMENT: environment,
  FEATURE_FLAG_API_ACCESS_TOKEN: featureFlagToken,
  FEATURE_FLAG_API_URL: featureFlagURL,
  IMAGES_STORAGE_URL: imageStorageURL,
  FILES_STORAGE_URL: fileStorageURL,
  SHARE_CV_URL: shareCVURL,
  API_URL: apiURL,
  API_URL_V2: apiUrlV2,
  SMART_LOOK_KEY: smartLookKey,
  DEFAULT_TOKEN: process.env.REACT_APP_DEFAULT_TOKEN,
  LIMIT_CHARACTERS_OF_STRING: 4000,
  MIN_CHARACTERS_OF_REQUEST_COMMIT_TIME_REASON: 20,
  MAX_CHARACTERS_OF_REQUEST_COMMIT_TIME_REASON: 200,
  MIN_CHARACTERS_OF_TAG: 4,
  MAX_TIME_SEND_REQUEST_COMMIT_TIME: 168,
  TASK_MANAGEMENT_URL: taskManagementURL,
  chart: {
    normal: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
    },
    responsive: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
  },
  TIME_OUT_LONGPOLLING: 60000,
  ENABLED_DOWNLOAD_CV: 'abRDZ$3UKFHxa$V',
  DISALLOW_DOWNLOAD_CV: 'uRMR6M!JWUFxH4Z',
}

const EventName = {
  openErrorSnackbar: 'error-snack-bar',

  openLoading: 'open-loading',
  closeLoading: 'close-loading',

  openDeleteProjectConfirmDialog: 'open-project-delete-confirm-dialog',
  updateDeleteProjectConfirmDialog: 'update-project-delete-confirm-dialog',

  openDeleteProjectSettingsConfirmDialog: 'open-project-settings-delete-confirm-dialog',
  updateDeleteProjectSettingsConfirmDialog: 'update-project-settings-delete-confirm-dialog',

  openDeleteUserConfirmDialog: 'open-user-delete-confirm-dialog',
  updateDeleteUserConfirmDialog: 'update-user-delete-confirm-dialog',

  updateDeletePartnerConfirmDialog: 'update-partner-delete-confirm-dialog',
  openDeletePartnerConfirmDialog: 'open-partner-delete-confirm-dialog',

  updateDeleteInternalDocumentConfirmDialog: 'update-internal-document-delete-confirm-dialog',
  openDeleteInternalDocumentConfirmDialog: 'open-internal-document-delete-confirm-dialog',

  openDeleteTimesheetConfirmDialogYesNo: 'open-timesheet-delete-confirm-dialog-yes-no',

  openDeleteInfoConfirmDialogYesNo: 'open-info-delete-confirm-dialog-yes-no',

  openDeleteAdvancePaymentConfirmDialogYesNo: 'open-advance-payment-delete-confirm-dialog-yes-no',

  openDeletePolicyConfirmDialogYesNo: 'open-policy-delete-confirm-dialog-yes-no',

  openDeleteFeedbackConfirmDialogYesNo: 'open-feedback-delete-confirm-dialog-yes-no',

  openCloseCommitTimeRequestDialogYesNo: 'open-close-commit-time-request-dialog-yes-no',

  storage: 'storage',
  mousedown: 'mousedown',
}

const Months = [
  {
    key: 1,
    value: 'Jan',
  },
  {
    key: 2,
    value: 'Feb',
  },
  {
    key: 3,
    value: 'Mar',
  },
  {
    key: 4,
    value: 'Apr',
  },
  {
    key: 5,
    value: 'May',
  },
  {
    key: 6,
    value: 'Jun',
  },
  {
    key: 7,
    value: 'Jul',
  },
  {
    key: 8,
    value: 'Aug',
  },
  {
    key: 9,
    value: 'Sep',
  },
  {
    key: 10,
    value: 'Oct',
  },
  {
    key: 11,
    value: 'Nov',
  },
  {
    key: 12,
    value: 'Dec',
  },
]

const Title = {
  dashboard: {
    chartTitle: 'Solar Employment Growth by Sector',
    chartSubTitle: 'Source: thesolarfoundation.com',
    yAxis: 'Number of Employees',
  },
}

const noticeForChangeOptionOfPayment =
  'If you want to change this field, please send request to admin and give us the reason for this changing.'

export {
  RoutesConstants,
  Messages,
  AppConfig,
  EventName,
  Months,
  Title,
  noticeForChangeOptionOfPayment,
  actionOnDataTable,
}
