import { ISuccessResponse } from 'API/constants'
import { getListCreatableOptions } from 'API/creatableOption'
import {
  ICreatableOptionListWithFilterRequest,
  ICreatableOptionListWithFilterResponse,
} from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import { filterPaymentsAdmin, editPaymentAdmin, editRequestPayment } from 'API/payment'
import { IFilterPaymentsPayload } from 'API/payment/constants'
import { getProjectList } from 'API/project'
import { IProjectsOfUserResponse } from 'API/project/constants'
import { getUserListWithPagination } from 'API/user'
import { IUserListResponse } from 'API/user/constants'
import { makeAutoObservable } from 'mobx'
import { IPaymentList } from 'types/payment'
import { ECreatableOptionScope, ECreatableOptionTypes, EStatusOfPaymentRequest } from 'constants/enum'
import { ICreatableOption, IUser, IProject } from 'constants/schema'
import RootStore from '../rootStore'

class AdminPaymentStore {
  rootStore: RootStore
  users: IUser[]
  projects: IProject[]
  payments: IPaymentList[]
  paymentCategoryOptions: ICreatableOption[]

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getUserList(): Promise<void> {
    try {
      const response: ISuccessResponse<IUserListResponse> = await getUserListWithPagination()
      this.users = response?.data?.users
    } catch (error) {
      handleError(error, 'src/store/admin/paymentStore.ts', 'getUserList')
    }
  }

  public async getProjectList(): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectsOfUserResponse> = await getProjectList()
      this.projects = response?.data?.projects
    } catch (error) {
      handleError(error, 'src/store/admin/paymentStore.ts', 'getProjectList')
    }
  }

  public async getPaymentCategoryOptionsList(): Promise<void> {
    try {
      const payload: ICreatableOptionListWithFilterRequest = {
        filter: { scope: ECreatableOptionScope.USER, type: ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER },
      }
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptions(payload)
      this.paymentCategoryOptions = response?.data?.creatableOptions
    } catch (error) {
      handleError(error, 'src/store/admin/paymentStore.ts', 'getPaymentCategoryOptionsList')
    }
  }

  public async filterPayments(payload: IFilterPaymentsPayload): Promise<void> {
    try {
      const { paymentsList } = await filterPaymentsAdmin(payload)
      this.payments = paymentsList
    } catch (error) {
      handleError(error, 'src/store/admin/paymentStore.ts', 'filterPaymentsAdmin')
    }
  }

  public async editPayment(id: string, field: string, currentValue: boolean): Promise<void> {
    try {
      await editPaymentAdmin({
        id,
        field,
        value: !currentValue,
      })
    } catch (error) {
      handleError(error, 'src/store/admin/paymentStore.ts', 'editPayment')
    }
  }

  public async editRequestPayment(id: string, value: EStatusOfPaymentRequest): Promise<void> {
    try {
      await editRequestPayment({ id, value })
    } catch (error) {
      handleError(error, 'src/store/admin/paymentStore.ts', 'editRequestPayment')
    }
  }
}

export default AdminPaymentStore
