import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

import { Messages } from '../../constants'
import { StyledDialogTitle, DialogContent, DialogActions, StyledOkButton } from './confirmDeleteDialogYesNo.styles.ts'

class CustomizedDialogYesNo extends React.Component {
  render() {
    const { isOpen, OKClickHandler, closeHandler, item } = this.props
    return (
      <div>
        <Dialog onClose={closeHandler} aria-labelledby="customized-dialog-title" open={isOpen}>
          <StyledDialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {Messages.deleteDialogTitle}
          </StyledDialogTitle>
          <DialogContent>{Messages.deleteDialogYesNoContent}</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeHandler}>
              Cancel
            </Button>
            <StyledOkButton variant="contained" color="secondary" onClick={() => OKClickHandler(item._id)}>
              Delete
            </StyledOkButton>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default CustomizedDialogYesNo
