import React, { useEffect, useState } from 'react'
import { HStack, VStack, Container, Text, Button } from '@chakra-ui/react'
import MoreDropBox from 'components/Table/components/MoreDropBox'
import ConfirmArchiveModal from 'componentsCharka/ConfirmArchiveModal'
import ConfirmDeleteModal from 'componentsCharka/ConfirmDeleteModal'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { getParsedProjectValueBeforeRenderFE } from 'utils'
import { RoutesConstants, actionOnDataTable } from 'constants/index'
import { IProject } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import { ReactComponent as Edit } from '../../../../../assets/images/svg/edit-white.svg'
import LogoProject from '../../../components/LogoProject'
import ProjectDetailForm from '../../../components/ProjectDetailForm'
import ProjectEditForm from '../../../components/ProjectEditForm'
import SettingProject from '../../../components/SettingProject'
import { IRowActionItem } from '../../../constant'
import styles from './index.module.scss'

const ProjectDetailAdminDesktop = () => {
  const title: string = 'Project Detail'
  window.sessionStorage.setItem('subPageTitle', title)
  window.localStorage.removeItem('pageTitle')
  window.localStorage.setItem('pageTitle', 'Projects')
  window.dispatchEvent(new Event('storage'))
  const { adminProjectStore } = useStores()
  const { rawProjectDetailData, partnerList, usersList, technologiesList, countriesList, currencyList, projectTypes } =
    adminProjectStore
  const methods = useForm()
  const history = useHistory()
  const params = useParams()
  const projectId = get(params, 'id', '')
  const { handleSubmit, reset, setValue } = methods
  const partnerCategory: string[] = ['owner', 'client']
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false)
  const [projectDetail, setProjectDetail] = useState<IProject>()

  function getProjectDetail(): void {
    adminProjectStore.getProjectDetail(projectId)
  }

  useEffect(() => {
    adminProjectStore.getUserList()
    adminProjectStore.getPartnerList(partnerCategory)
    adminProjectStore.getTechnologyList()
    adminProjectStore.getProjectTypes()
    adminProjectStore.getCurrencyList()
    adminProjectStore.getCountryList()
  }, [])

  useEffect(() => {
    setProjectDetail(getParsedProjectValueBeforeRenderFE(rawProjectDetailData, isEdit))
  }, [isEdit, rawProjectDetailData])

  useEffect(getProjectDetail, [projectId])

  useEffect(() => {
    if (projectDetail) {
      reset(projectDetail)
    }
  }, [projectDetail])

  function toggleOpenDeleteModal() {
    setOpenDeleteModal(!openDeleteModal)
  }

  function toggleOpenArchiveModal() {
    setOpenArchiveModal(!openArchiveModal)
  }

  function callOpenDialogConfirm(project: IProject, nameOfEvent: string) {
    set(project, 'shortName', project?.name ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      toggleOpenDeleteModal()
    } else {
      toggleOpenArchiveModal()
    }
  }
  function handleClickOKConfirmedDelete(id: string) {
    adminProjectStore.deleteProject(id)
    history.push(RoutesConstants.PROJECTS_ADMIN_LIST)
  }
  function handleClickOKOfUnarchiveConfirm(id: string) {
    adminProjectStore.unArchiveProject(id)
    history.push(RoutesConstants.PROJECTS_ADMIN_LIST)
  }
  function handleClickOKOfArchiveConfirm(id: string) {
    adminProjectStore.archiveProject(id)
    history.push(RoutesConstants.PROJECTS_ADMIN_LIST)
  }
  function submitHandler(data: any) {
    adminProjectStore.updateProject(data)
    setIsEdit(false)
  }
  function editHandler() {
    setIsEdit(true)
  }
  function cancelHandler() {
    setIsEdit(false)
  }
  const isArchived: boolean = projectDetail?.isArchived ?? false
  const actions: IRowActionItem[] = [
    {
      name: !!isArchived ? 'Unarchive' : 'Archive',
      handler: () => callOpenDialogConfirm(projectDetail, actionOnDataTable.ARCHIVE),
    },
  ]
  actions.push({
    name: 'Delete',
    customClassNames: ['isDanger'],
    handler: () => callOpenDialogConfirm(projectDetail, actionOnDataTable.DELETE),
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data, event) => submitHandler(data))}>
        <VStack>
          <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
            <HStack margin={{ base: 6, lg: 6 }} height="full" background="white" justifyContent="space-between">
              <Text fontSize="lg" color="gray.700" margin={0}>
                {projectDetail ? projectDetail.name : ''}
              </Text>
              <HStack>
                <MoreDropBox isBackground actions={actions} />
                {!isEdit ? (
                  <Button
                    fontWeight={'500'}
                    bg={'#4D5DFB'}
                    className={styles.editButton}
                    variant="solid"
                    onClick={editHandler}
                    type="button"
                    leftIcon={<Edit />}>
                    Edit Project
                  </Button>
                ) : (
                  <>
                    <Button
                      fontWeight={'500'}
                      colorScheme="blue"
                      variant="outline"
                      isFullWidth
                      type="button"
                      onClick={cancelHandler}
                      _hover={{
                        cursor: 'pointer',
                      }}>
                      Cancel
                    </Button>
                    <Button
                      fontWeight={'500'}
                      bg={'#4D5DFB'}
                      className={styles.editButton}
                      minWidth={'none'}
                      isFullWidth
                      type="submit"
                      _hover={{
                        cursor: 'pointer',
                      }}>
                      Save
                    </Button>
                  </>
                )}
              </HStack>
            </HStack>
          </Container>
          <HStack width="full" justifyContent="space-between" alignItems="flex-start">
            <VStack width="100%">
              {isEdit ? (
                <ProjectEditForm
                  partnerList={partnerList}
                  userList={usersList}
                  projectTypes={projectTypes}
                  technologyList={technologiesList}
                  currencyList={currencyList}
                  countryList={countriesList}
                  submitHandler={submitHandler}
                  methods={methods}
                  isEdit={isEdit}
                />
              ) : (
                <ProjectDetailForm methods={methods} />
              )}
            </VStack>
            <VStack width="300px" height="100%">
              <LogoProject
                type="logo"
                name="logo"
                isDetail={!isEdit}
                afterUploadHandler={(value) => {
                  setValue('logo', value)
                }}
              />
              <SettingProject isDisabled={!isEdit} />
            </VStack>
          </HStack>
        </VStack>
      </form>
      <ConfirmDeleteModal
        data={projectDetail}
        isOpen={openDeleteModal}
        closeHandler={toggleOpenDeleteModal}
        OKClickHandler={handleClickOKConfirmedDelete}
        title={'Project'}
        content={'Project name'}
      />
      <ConfirmArchiveModal
        data={projectDetail}
        isOpen={openArchiveModal}
        closeHandler={toggleOpenArchiveModal}
        OKClickHandler={projectDetail?.isArchived ? handleClickOKOfUnarchiveConfirm : handleClickOKOfArchiveConfirm}
        title={'Project'}
        content={'Project name'}
      />
    </FormProvider>
  )
}

export default observer(ProjectDetailAdminDesktop)
