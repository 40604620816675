import { useState } from 'react'
import { FormControl, InputGroup, FormLabel, Box, Checkbox } from '@chakra-ui/react'
import { GroupBase, Select, components, ValueContainerProps, OptionProps } from 'chakra-react-select'
import { useFormContext, Controller, useWatch } from 'react-hook-form'
import { backgroundGrey600 } from 'theme/globalStyles'
import { getValidArray } from 'utils'
import { IOption } from 'constants/common'
import { OptionWrapper } from './acronymDropdown.styles'
import { IAcronymDropdownProps } from './constants'
import { findOptionByValue, getValueContainerLabel } from './utils'

const AcronymDropdown = (props: IAcronymDropdownProps) => {
  const {
    name,
    label,
    placeholder,
    optionsData,
    defaultValue,
    closeMenuOnSelect = false,
    isDisabled = false,
    emptySelectionMessage,
  } = props
  const { control, setValue } = useFormContext()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const fieldValue = useWatch({ control, name })

  const AcronymValueContainer = (props: ValueContainerProps) => {
    const { ValueContainer } = components
    const { hasValue } = props
    const valueContainerLabel: string = hasValue
      ? getValueContainerLabel(fieldValue)
      : emptySelectionMessage ?? placeholder
    return (
      <ValueContainer {...props}>
        {/* // *INFO: On mobile and tablet it does't work with onClick, so we need to use touch event */}
        <Box
          width="100%"
          paddingLeft={2}
          onClick={!isDisabled ? toggleMenu : undefined}
          onTouchEnd={!isDisabled ? toggleMenu : undefined}>
          {valueContainerLabel}
        </Box>
      </ValueContainer>
    )
  }

  const OptionWithCheckbox = (props: OptionProps<IOption>) => {
    const { data, isSelected } = props

    return (
      <Box as="span" {...props}>
        <OptionWrapper background="none" onClick={() => toggleOption(data, isSelected)}>
          <Checkbox colorScheme="blue" isChecked={isSelected} borderColor="gray.700">
            {data.label}
          </Checkbox>
        </OptionWrapper>
      </Box>
    )
  }

  function toggleOption(option: IOption, isSelected: boolean): void {
    if (isSelected) {
      removeOption(option)
    } else {
      selectOption(option)
    }
  }

  function selectOption(selectedOption: IOption): void {
    const isOptionAdded: IOption | undefined = findOptionByValue(fieldValue, selectedOption.value)
    if (!isOptionAdded) {
      setValue(name, [...fieldValue, selectedOption])
    }
  }

  function removeOption(removedOption: IOption): void {
    const newOptionList: IOption[] = getValidArray(fieldValue).filter(
      (option: IOption) => option?.value !== removedOption?.value
    ) as IOption[]
    setValue(name, newOptionList)
  }

  function toggleMenu(): void {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <FormControl>
      <FormLabel fontWeight="normal" color="gray.700" lineHeight={6}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: false }}
        render={({}) => (
          <InputGroup>
            <Select<IOption, true, GroupBase<IOption>>
              colorScheme="teal"
              hideSelectedOptions={false}
              options={optionsData}
              placeholder={placeholder}
              closeMenuOnSelect={closeMenuOnSelect}
              defaultValue={defaultValue}
              size="md"
              isClearable={false}
              value={fieldValue}
              isMulti
              isDisabled={isDisabled}
              menuIsOpen={isMenuOpen}
              components={{ ValueContainer: AcronymValueContainer, Option: OptionWithCheckbox }}
              chakraStyles={{
                container: (provided: Record<string, unknown>) => ({
                  ...provided,
                  width: 'full',
                  cursor: 'pointer',
                  background: isDisabled ? 'gray.100' : 'white',
                }),
                dropdownIndicator: (provided: Record<string, unknown>) => ({
                  ...provided,
                  background: 'transparent',
                  paddingX: 2,
                  cursor: 'pointer',
                }),
                indicatorSeparator: (provided: Record<string, unknown>) => ({
                  ...provided,
                  display: 'none',
                }),
                menu: (provided: Record<string, unknown>) => ({
                  ...provided,
                  boxShadow: `0 0 0 1px ${backgroundGrey600}`,
                }),
                menuList: (provided: Record<string, unknown>) => ({
                  ...provided,
                  '::-webkit-scrollbar': {
                    width: '4px',
                    height: '0px',
                  },
                  '::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                  },
                  '::-webkit-scrollbar-thumb': {
                    background: '#888',
                  },
                  '::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                  },
                }),
              }}
            />
          </InputGroup>
        )}
      />
    </FormControl>
  )
}

export default AcronymDropdown
