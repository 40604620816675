import { Alert, AlertIcon, AlertTitle, AlertDescription, AlertStatus } from '@chakra-ui/react'

interface ICustomeAlertProps {
  status: AlertStatus
  title: string
  description: string
}

const CustomeAlert = (props: ICustomeAlertProps) => {
  const { status, title, description } = props
  return (
    <Alert status={status}>
      <AlertIcon />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  )
}

export default CustomeAlert
