import React from 'react'
import FormModel from '../../../../components/FormModel'

import FormRateExchangeDetail from '../components/FormRateExchangeDetail'
import RateExchangeItem from '../components/Item/RateExchangeItem'

class RateExchange extends React.Component {
  render() {
    const {
      handleSubmit,
      getDetailRateExchange,
      rateExchanges,
      currencies,
      rateExchangeDetail,
      deleteRateExchange,
      viewMode,
      cancelEditHandler,
      validationSchema,
    } = this.props

    return (
      <div className="container-fluid setting-item">
        <div className="box box-default">
          <div className="box-header">Rate Exchange</div>
          <div className="box-divider" />
          <div className={`box-body`}>
            <div className="container-fluid">
              <div>
                <FormModel
                  initialValues={rateExchangeDetail}
                  schema={validationSchema}
                  handleSubmit={handleSubmit}
                  component={
                    <FormRateExchangeDetail
                      currencies={currencies}
                      cancelEditHandler={cancelEditHandler}
                      viewMode={viewMode}
                      {...this.props}
                    />
                  }
                />
              </div>
              <div className="setting-list-wrapper">
                {rateExchanges &&
                  rateExchanges.map((item, index) => (
                    <RateExchangeItem
                      key={item._id}
                      data={item}
                      index={index}
                      editClickHandler={() => getDetailRateExchange(item._id)}
                      deleteClickHandler={() => deleteRateExchange(item, 'rate-exchange')}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RateExchange
