import { useEffect } from 'react'
import { handleError } from 'API/error'
import AcronymDropdown from 'componentsCharka/AcronymDropdown'
import CheckboxFieldWithLabel from 'componentsCharka/CheckboxFieldWithLabel'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import FormFieldWithLabel from 'componentsCharka/FormFieldWithLabel'
import Helper from 'Helper'
import { useFormContext, useWatch } from 'react-hook-form'
import { IUser } from 'types/user'
import { IOption } from 'constants/common'
import { EPartnerCategory } from 'constants/enum'
import { useStores } from 'utils/hooks/useStores'
import { IFormSectionBaseProps, ESectionHeaderValues } from '../../constants'
import { SectionWrapper, SectionHeader, SectionBody, GridLayout } from '../../projectDetailAdmin.styles'
import { getFormInputProps, getFormLabelProps } from '../../utils'
import FormInput from '../FormInput'
import AvatarDevGroup from './components/AvatarDevGroup'

const WorkingInformationSection = (props: IFormSectionBaseProps) => {
  const { isEditing } = props
  const { control } = useFormContext()
  const { adminProjectStore } = useStores()
  const { partnerList, technologiesList, projectTypes } = adminProjectStore
  const untilNow: boolean | undefined = useWatch({ control, name: 'untilNow' })
  const developers: IUser[] | undefined = useWatch({ control, name: 'developerDetailOnProject' })
  const partnerOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(partnerList)
  const projectTypeOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(projectTypes)
  const technologyOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(technologiesList)

  async function fetchData(): Promise<void> {
    try {
      await Promise.all([
        adminProjectStore.getUserList(),
        adminProjectStore.getPartnerList([EPartnerCategory.OWNER, EPartnerCategory.CLIENT]),
        adminProjectStore.getTechnologyList(),
        adminProjectStore.getProjectTypes(),
      ])
    } catch (error) {
      handleError(
        error as Error,
        'src/containers/ProjectsNew/V2/ProjectDetailAdminMobile/components/WorkingInformationSection/index.tsx',
        'fetchData'
      )
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <SectionWrapper spacing={{ base: 4 }}>
      <SectionHeader>{ESectionHeaderValues.WORKING_INFORMATION}</SectionHeader>
      <SectionBody spacing={{ base: isEditing ? 6 : 4 }}>
        <GridLayout columns={{ base: isEditing ? 1 : 2 }} gap={{ base: !isEditing ? 4 : 6 }}>
          <FormFieldWithLabel {...getFormLabelProps('startedDate', 'Start Date', isEditing)}>
            {isEditing && (
              <FormInput
                {...getFormInputProps('startedDate', 'Start Date', '--/--/----  -   --/--/----', true)}
                type="date"
              />
            )}
          </FormFieldWithLabel>
          {!untilNow && (
            <FormFieldWithLabel {...getFormLabelProps('endedDate', 'End Date', isEditing)}>
              {isEditing && (
                <FormInput
                  {...getFormInputProps('endedDate', 'End Date (Optional)', '--/--/----  -   --/--/----')}
                  type="date"
                />
              )}
            </FormFieldWithLabel>
          )}
          <FormFieldWithLabel {...getFormLabelProps('untilNowProject', 'Until Now', isEditing)}>
            {isEditing && <CheckboxFieldWithLabel {...getFormInputProps('untilNow', 'Until Now')} />}
          </FormFieldWithLabel>
        </GridLayout>
        <GridLayout gap={{ base: !isEditing ? 4 : 6 }}>
          <FormFieldWithLabel {...getFormLabelProps('commitedHours', 'Commited Hours (hrs/week)', isEditing)}>
            {isEditing && (
              <FormInput
                {...getFormInputProps('commitedHours', 'Commited Hours (hrs/week) (Optional)', 'Enter Commited hours')}
              />
            )}
          </FormFieldWithLabel>
          <FormFieldWithLabel {...getFormLabelProps('technology', 'Technology', isEditing)}>
            {isEditing && (
              <AcronymDropdown
                {...getFormInputProps('technology', 'Technology (Optional)', '- Select Technology -')}
                optionsData={technologyOptions}
              />
            )}
          </FormFieldWithLabel>
        </GridLayout>
        <GridLayout gap={{ base: !isEditing ? 4 : 6 }}>
          <FormFieldWithLabel {...getFormLabelProps('neededDeveloperNumber', 'Needed Developer Number', isEditing)}>
            {isEditing && (
              <FormInput {...getFormInputProps('neededDeveloperNumber', 'Needed Developer Number (Optional)')} />
            )}
          </FormFieldWithLabel>
          {isEditing ? (
            <AcronymDropdown
              {...getFormInputProps('userId', 'Working Developers (Optional)')}
              isDisabled
              emptySelectionMessage="0 developers"
            />
          ) : (
            <AvatarDevGroup {...getFormInputProps('listDeveloper', 'Working Developers')} developers={developers} />
          )}
        </GridLayout>
        <GridLayout gap={{ base: !isEditing ? 4 : 6 }}>
          <FormFieldWithLabel {...getFormLabelProps('partnerId', 'Belongs To Partner', isEditing)}>
            {isEditing && (
              <ChakraInputDropdown
                {...getFormInputProps('partnerId', 'Belongs To Partner', '- Select Partner -')}
                isRequire
                widthCustom="100%"
                optionsData={partnerOptions}
              />
            )}
          </FormFieldWithLabel>
          <FormFieldWithLabel {...getFormLabelProps('type', 'Project Type (Optional)', isEditing)}>
            {isEditing && (
              <ChakraInputDropdown
                {...getFormInputProps('type', 'Project Type', '- Select Project Type -')}
                widthCustom="100%"
                optionsData={projectTypeOptions}
              />
            )}
          </FormFieldWithLabel>
        </GridLayout>
      </SectionBody>
    </SectionWrapper>
  )
}

export default WorkingInformationSection
