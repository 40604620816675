import api from 'API'
import { ISuccessResponse } from 'API/constants'
import { getErrorMessage, handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import { IPartner, IUser } from 'constants/schema'
import {
  IPartnerListPagination,
  IPartnerListPaginationRequest,
  IPartnerListRequest,
  IPartnerListResponse,
  PARTNER_BASE_URL,
  ICreatePartnerResponse,
  IPartnerDetailResponse,
  IUpdatePartnerResponse,
} from './constants'

export async function getPartners(payload: IPartnerListRequest): Promise<ISuccessResponse<IPartnerListResponse>> {
  try {
    const url = `${PARTNER_BASE_URL}/listWithFilter`
    return api.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getPartnerListWithPagination(
  payload: IPartnerListPaginationRequest
): Promise<IPartnerListPagination> {
  try {
    const url: string = `${PARTNER_BASE_URL}/list`
    const response: AxiosResponse = await api.post(url, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/partner', 'getPartnerListWithPagination')
    return {} as IPartnerListPagination
  }
}

export async function getPartnerDetail(partnerId: string): Promise<IPartnerDetailResponse> {
  try {
    const response: AxiosResponse = await api.get(`${PARTNER_BASE_URL}/detail/${partnerId}`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/partner', 'getPartnerDetail')
    return {} as IPartnerDetailResponse
  }
}

export async function updatePartner(payload: Partial<IPartner>, partnerId: string): Promise<IUpdatePartnerResponse> {
  try {
    const response: AxiosResponse = await api.post(`${PARTNER_BASE_URL}/update/${partnerId}`, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/partner', 'updatePartner')
    return {} as IUpdatePartnerResponse
  }
}

export async function createPartner(payload: Partial<IPartner>): Promise<ICreatePartnerResponse> {
  try {
    const response: AxiosResponse = await api.post(`${PARTNER_BASE_URL}/create`, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/partner', 'createPartner')
    return {} as ICreatePartnerResponse
  }
}

export async function getUserBelongToPartner(partnerId: string): Promise<IUser> {
  try {
    const response: AxiosResponse = await api.get(`${PARTNER_BASE_URL}/${partnerId}/user`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/partner', 'getUserByPartnerId')
    return {} as IUser
  }
}
