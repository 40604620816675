import { IOption } from 'constants/common'

export function generateValidValue(value: string | IOption | IOption[]): string {
  if (typeof value === 'string' || typeof value === 'number') {
    return value
  }
  if (Array.isArray(value)) {
    return value.map((item: IOption) => item.label).join(', ')
  }
  return value?.label
}
