import { chakra, Button, Tag as CkTag, Td, Th } from '@chakra-ui/react'
import { backgroundSecondary } from 'theme/globalStyles'

export const SaveButton = chakra(Button, {
  baseStyle: {
    width: '67px',
    fontSize: 'md',
    fontWeight: 500,
    lineHeight: 6,
    borderRadius: 6,
    color: 'white',
    background: backgroundSecondary,
    _hover: {
      cursor: 'pointer',
      background: 'blue',
    },
  },
})

export const CancelButton = chakra(Button, {
  baseStyle: {
    fontSize: 'md',
    fontWeight: 500,
    lineHeight: 6,
    borderRadius: 6,
    color: 'gray.700',
    _hover: {
      cursor: 'pointer',
      background: 'gray.300',
    },
  },
})

export const InformationButton = chakra(Button, {
  baseStyle: {
    width: 10,
    height: 10,
    padding: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    _focus: {
      boxShadow: 'none',
    },
  },
})

export const MobileTag = chakra(CkTag, {
  baseStyle: {
    height: 5,
    fontWeight: 500,
    lineHeight: 4,
    padding: '2px 8px',
    border: '1px solid',
    borderRadius: 6,
    boxSizing: 'border-box',
  },
})

export const MobileTableTh = chakra(Th, {
  baseStyle: {
    padding: 2,
    color: 'gray.600',
    fontSize: 'xs',
    fontWeight: 700,
    lineHeight: 4,
    boxSizing: 'border-box',
  },
})

export const MobileTableTd = chakra(Td, {
  baseStyle: () => ({
    minWidth: 36,
    height: 12,
    padding: 2,
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 400,
    lineHeight: 5,
    boxSizing: 'border-box',
  }),
})

export const CVButton = chakra(Button, {
  baseStyle: {
    width: '40px',
    height: '40px',
    background: 'blackAlpha.600',
    padding: 0,
    _hover: {
      cursor: 'pointer',
      background: 'blackAlpha.600',
    },
  },
})
