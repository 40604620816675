import { FormControl, FormLabel, SimpleGrid } from '@chakra-ui/react'
import CheckboxField from 'componentsCharka/CheckBoxField'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { IOption } from 'types/creatableOption'

export interface IGroupCheckBox {
  label?: string
  name?: string
  data?: IOption[]
}

export interface ISelect {
  label?: string
  value?: boolean
}

const GroupCheckBox = (props: IGroupCheckBox) => {
  const { name, label } = props
  const { control } = useFormContext()

  const { fields } = useFieldArray({
    control,
    name: name,
  })
  return (
    <FormControl marginTop={'10px'} marginBottom={'10px'}>
      <FormLabel color="gray.700" lineHeight={6} marginBottom={'10px'} marginInlineEnd={0}>
        {label}
      </FormLabel>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 2, lg: 2 }} width="full">
        {fields &&
          fields.length > 0 &&
          fields.map((field, index) => {
            return <CheckboxField name={`${name}.${index}.checked`} label={field} />
          })}
      </SimpleGrid>
    </FormControl>
  )
}
export default GroupCheckBox
