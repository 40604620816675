import { useState } from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'
import { HiEye, HiEyeOff } from 'react-icons/hi'

export interface IPasswordField {
  name?: string
  label?: string
  placeholder?: string
}
const PasswordField = (props: IPasswordField) => {
  const { name, label, placeholder } = props
  const [isShow, toggleShow] = useState(false)

  function onClickShowButton(): void {
    toggleShow(!isShow)
  }

  const {
    formState: { errors },
    control,
  } = useFormContext()

  return (
    <FormControl id={name} isInvalid={errors[name]}>
      <FormLabel fontWeight={'normal'} mb={2} color="gray.700">
        {label}
      </FormLabel>
      <InputGroup>
        <InputRightElement zIndex={0}>
          <IconButton
            bg="transparent !important"
            variant="ghost"
            aria-label={isShow ? 'Mask password' : 'Reveal password'}
            icon={isShow ? <HiEyeOff color="#A0AEC0" /> : <HiEye color="#A0AEC0" />}
            onClick={onClickShowButton}
          />
        </InputRightElement>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                type={isShow ? 'text' : 'password'}
                autoComplete="password"
                placeholder={placeholder}
                focusBorderColor={errors[name] ? 'red.500' : 'gray.200'}
              />
            )
          }}
        />
      </InputGroup>
      <FormErrorMessage>{errors[name] && errors[name]?.message}</FormErrorMessage>
    </FormControl>
  )
}

PasswordField.displayName = 'PasswordField'
export default PasswordField
