import React, { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import FormModel from 'components/FormModel'
import Table from 'components/Table'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import { observer } from 'mobx-react'
import moment from 'moment'
import { IBirthday } from 'types/user'
import { getValidArray } from 'utils'
import { Messages } from 'constants/index'
import { useStores } from 'utils/hooks/useStores'
import FilterForm from './components/FilterForm'
import { getHeaderList } from './constants'

const FreelancerContractList = () => {
  const { adminUserStore } = useStores()
  const { bithdayList } = adminUserStore

  useEffect(() => {
    adminUserStore.getBirthdayList()
  }, [])

  const dataInTable = getValidArray(bithdayList).map((item: IBirthday) => {
    return {
      ...item,
      birthday: moment(item?.dateOfBirth ?? '').format('DD/MM/YYYY'),
    }
  })

  function filterSubmitHandler(data) {
    adminUserStore.getBirthdayList(data)
  }

  return (
    <Box bg="white" p={6} borderRadius="6px" border="1px solid #E2E8F0">
      <FormModel initialValues={{}} handleSubmit={filterSubmitHandler} component={<FilterForm />} />
      {bithdayList && getValidArray(bithdayList).length > 0 ? (
        <Table headerList={getHeaderList()} tableData={dataInTable} isStriped />
      ) : (
        <EmptyContentBox text={Messages.noData} />
      )}
    </Box>
  )
}

export default observer(FreelancerContractList)
