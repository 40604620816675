/* eslint-disable max-lines */
import React from 'react'
import UnderConstruction from 'containers/UnderConstruction'
// Show Under Construction page
const DashboardUser = () => {

  return (
    <UnderConstruction />
  )
}

export default DashboardUser
