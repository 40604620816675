import { Button } from '@chakra-ui/react'
import { ReactComponent as EditIcon } from 'assets/images/svg/edit-fill.svg'

interface IEditButtonProps {
  onClick: () => void
}

const EditButton = (props: IEditButtonProps) => {
  const { onClick } = props

  return (
    <Button padding={0} background="transparent" cursor="pointer" onClick={onClick}>
      <EditIcon color="gray.700" width="16px" height="20px" />
    </Button>
  )
}

export default EditButton
