import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'

const styles = {
  colorSwitchBase: {
    '&$colorChecked': {
      color: green[400],
      '& + $colorBar': {
        backgroundColor: green[400],
      },
    },
    '&$colorDisabled': {
      cursor: 'not-allowed',
    },
  },
  colorBar: {},
  colorChecked: {},
  colorDisabled: {},
}

class SwitchesGroup extends React.Component {
  render() {
    const { value, clickHandler, label = '', disabled, classes } = this.props
    return (
      <FormControlLabel
        label={label}
        className="radio_label"
        control={
          <Switch
            checked={value}
            onChange={disabled ? null : clickHandler}
            value={value}
            classes={{
              switchBase: classes.colorSwitchBase,
              checked: classes.colorChecked,
              bar: classes.colorBar,
              disabled: classes.colorDisabled,
            }}
          />
        }
      />
    )
  }
}

export default withStyles(styles)(SwitchesGroup)
