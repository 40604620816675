import 'firebase/messaging'
import firebase from 'firebase/app'
import { NotificationId } from '../constants/defaultOptionList'

export const firebaseCloudMessaging = {
  //checking whether token is available in indexed DB
  tokenInLocalStorage: () => {
    return localStorage.getItem(NotificationId)
  },
  //initializing firebase app
  init: async function () {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: process.env.REACT_APP_FCM_API_KEY,
        projectId: process.env.REACT_APP_FCM_PROJECT_ID,
        messagingSenderId: process.env.REACT_APP_FCM_SENDER_ID,
        appId: process.env.REACT_APP_FCM_APP_ID,
      })
    } else {
      firebase.app()
    }

    // Run in client side
    if (process.browser) {
      try {
        if (!('PushManager' in window)) {
          console.log("Push messaging isn't supported.")
          return ''
        }
        if (firebase.messaging.isSupported()) {
          const messaging = firebase.messaging()
          const tokenInLocalForage = this.tokenInLocalStorage()
          //if FCM token is already there just return the token
          if (tokenInLocalForage !== null) {
            return tokenInLocalForage
          }
          //requesting notification permission from browser
          const status = await Notification.requestPermission()
          if (status && status === 'granted') {
            //getting token from FCM
            const fcmToken = await messaging.getToken()
            if (fcmToken) {
              //setting FCM token in indexed db using localforage
              localStorage.setItem(NotificationId, fcmToken)
              //return the FCM token after saving it
              return fcmToken
            }
          }
        }
      } catch (error) {
        console.log(error)
        return null
      }
    }
    return null
  },
  getFirebase: function () {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: process.env.REACT_APP_FCM_API_KEY,
        projectId: process.env.REACT_APP_FCM_PROJECT_ID,
        messagingSenderId: process.env.REACT_APP_FCM_SENDER_ID,
        appId: process.env.REACT_APP_FCM_APP_ID,
      })
    } else {
      firebase.app()
    }
    return firebase
  },
}
