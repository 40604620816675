import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

import CommitTimeOverview from './CommitTimeOverview'
import CommitTimeRequest from './CommitTimeRequest'
import CommitTimeReport from './CommitTimeReport'

import './index.scss'

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
}

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
})

class FullWidthTabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: undefined,
    }
  }

  componentDidMount() {
    const { history } = this.props
    const queryString = history.location.search
    if (queryString) {
      const indexQueryString = queryString.split('?')[1]
      const index = indexQueryString.split('=')[1]
      this.handleChangeIndex(parseInt(index))
    }
  }
  componentDidUpdate(prevProps,prevState) {
    const { history } = this.props
    const queryString = history.location.search
    if (queryString) {
      const indexQueryString = queryString.split('?')[1]
      const index = indexQueryString.split('=')[1]
      if(prevState.value !== Number(index) || queryString !== prevProps.history.location.search){
        this.handleChangeIndex(parseInt(index))
      }
    }
  }

  addQuery = (query) => {
    const { history } = this.props
    const currentLocation = history.location.pathname
    const newLocation = `${currentLocation}?index=${query}`
    history.replace(newLocation)
  }

  handleChange = (event, value) => {
    this.setState({ value }, () => {
      this.addQuery(value)
    })
  }

  handleChangeIndex = (index) => {
    this.setState({ value: index })
  }

  render() {
    const { classes, theme } = this.props
    let { value } = this.state
    if (!value) {
      value = 0
    }
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            variant="scrollable"
            scrollButtons="auto">
            <Tab label="Overview" />
            <Tab label="Request History" />
            <Tab label="Report" />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={this.handleChangeIndex}>
          <TabContainer dir={theme.direction}>{value === 0 && <CommitTimeOverview {...this.props} />}</TabContainer>
          <TabContainer dir={theme.direction}>{value === 1 && <CommitTimeRequest {...this.props} />}</TabContainer>
          <TabContainer dir={theme.direction}>{value === 2 && <CommitTimeReport {...this.props} />}</TabContainer>
        </SwipeableViews>
      </div>
    )
  }
}

FullWidthTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(FullWidthTabs)
