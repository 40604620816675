import { IHourlyProjectRating, IFixedProjectRating } from 'constants/schema'
export const HOURLY_RATING_BASE_URL = '/hourly-project-rate'

export const FIXED_RATING_BASE_URL = '/fixed-project-rate'

export interface IHourlyProjectRatingList {
  hourlyRatingPriceData: {
    hourlyRatingPrices: IHourlyProjectRating[]
    pastedData: IHourlyProjectRating[]
  }
}

export interface IFixedProjectRatingList {
  fixedRatingPriceData: {
    fixedRatingPrices: IFixedProjectRating[]
    pastedData: IFixedProjectRating[]
  }
}

export interface IProjectRatings {
  hourlyProjectRatings?: {
    hourlyRatingPrices: IHourlyProjectRating[]
  }

  fixedProjectRatings?: {
    fixedRatingPrices: IFixedProjectRating[]
  }
}

export interface IUpdateHourlyPayload {
  hourlyRatingPrices: IHourlyProjectRating[]
}

export interface IUpdateFixedPayload {
  fixedRatingPrices: IFixedProjectRating[]
}
