import React from 'react'
import { Text, VStack } from '@chakra-ui/react'
import { ReactComponent as Empty } from 'assets/images/svg/empty.svg'

interface IEmptyContentBoxProps {
  text: string
}
const EmptyContentBox = (props: IEmptyContentBoxProps) => {
  const { text } = props
  return (
    <VStack paddingBottom={{ sm: '10px' }} spacing="72px" align="center" width="full">
      <Text as={'i'} color={'gray.600'}>
        {text}
      </Text>
      <Empty />
    </VStack>
  )
}

export default EmptyContentBox
