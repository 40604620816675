import { VStack, HStack, Text } from '@chakra-ui/react'
import { ReactComponent as DownloadIcon } from 'assets/images/svg/download.svg'
import { ReactComponent as EyeIcon } from 'assets/images/svg/eye-white.svg'
import { ReactComponent as CvFile } from 'assets/images/svg/uploadCV.svg'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import { borderColor } from 'theme/globalStyles'
import { EUserDetailTitle } from 'containers/UserNew/UserDetail/constant'
import { CVButton } from 'containers/UserNew/UserDetail/userDetail.styles'
import Section from '../../../Section'

interface IMobileCVProps {
  userCVName?: string
  handleViewDetail: () => void
  handleDownload: () => void
}

const MobileCV = (props: IMobileCVProps) => {
  const { userCVName, handleViewDetail, handleDownload } = props

  return (
    <Section title={EUserDetailTitle.CURRICULUMN_VITAE}>
      <VStack>
        {userCVName ? (
          <VStack
            width="343px"
            height="343px"
            justifyContent="center"
            border={`1px solid ${borderColor}`}
            borderRadius={8}
            boxSizing="border-box">
            <VStack height="full" justifyContent="center" spacing={4}>
              <CvFile />
              <Text>{userCVName}</Text>
            </VStack>
            <HStack width="full" justifyContent="flex-end" padding={4} boxSizing="border-box">
              <CVButton onClick={handleViewDetail}>
                <EyeIcon />
              </CVButton>
              <CVButton onClick={handleDownload}>
                <DownloadIcon color="white" />
              </CVButton>
            </HStack>
          </VStack>
        ) : (
          <EmptyContentBox text="There is no CV here" />
        )}
      </VStack>
    </Section>
  )
}

export default MobileCV
