import api from 'API'
import { ISuccessResponse } from 'API/constants'
import { getErrorMessage } from 'API/error'
import { IProjectTypeListResponse, PROJECT_TYPE_BASE_URL } from './constants'

export async function getProjectTypes(): Promise<ISuccessResponse<IProjectTypeListResponse>> {
  try {
    return api.get(`${PROJECT_TYPE_BASE_URL}/list`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
