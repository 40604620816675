import React from 'react'
import { chakra } from '@chakra-ui/react'
import cx from 'classnames'
import { getValidArray } from 'utils'
import styles from '../StatusCell/statusCell.module.scss'

interface ITagListCellProps {
  values?: string[]
}

const TagListCell = (props: ITagListCellProps) => {
  const { values } = props
  return (
    <>
      {getValidArray(values).map((item, index) => (
        <chakra.div
          key={index}
          className={cx({
            [styles.statusCell]: true,
            [styles['Technology']]: 'Technology' ? true : false,
          })}>
          {item}
        </chakra.div>
      ))}
    </>
  )
}

export default TagListCell
