import Helper from './index'
import { Messages, EventName } from '../constants'

function errorHandler(error) {
  if (error && error.status) {
    switch (error.status) {
      case 500:
        callErrorSnackBar(Messages.serverError)
        break
      case 409:
        callErrorSnackBar(error?.data?.message)
        break
      case 403:
        callErrorSnackBar(error?.data?.message)
        break
      case 401: {
        callErrorSnackBar(error?.data?.message || Messages.unauthorized)
        Helper.beforeLogoutHandler()
        break
      }
      default:
    }
  }
}

function callErrorSnackBar(message) {
  document.dispatchEvent(new CustomEvent(EventName.openErrorSnackbar, { detail: { message, variant: 'error' } }))
}

export default errorHandler
