import { useHistory } from 'react-router-dom'
import { IAuthUser } from 'types/user'
import TimeSheetAdmin from 'containers/TimeSheet/TimeSheetAdmin'
import TimeSheetUser from 'containers/TimeSheet/TimeSheetUser'
import useFeatureFlag from 'utils/hooks/useFeatureFlag'
import { AppConfig, RoutesConstants } from '../../../../constants'
import { ERoleOfUser, EPlatformVersion, EFeatureFlags, EEnvironment } from '../../../../constants/enum'
import V2TimeSheetUser from '../TimeSheetUser'
import './index.scss'
import { refreshToken } from 'API/auth'
import { ISuccessResponse } from 'API/constants'
import { IRefreshTokenResponse } from 'API/auth/constants'
import { handleError } from 'API/error'
import { useEffect } from 'react'

const TimesheetLayout = (props) => {
  const history = useHistory<History>()
  const url: string = window.location.pathname
  const isV2TimeSheet: boolean = url.includes(EPlatformVersion.V2)
  const isAdmin: boolean = window.localStorage.getItem('userRole') === ERoleOfUser.ADMIN
  const v1TimesheetPath: string = isAdmin ? RoutesConstants.TIMESHEET_ADMIN : RoutesConstants.TIMESHEET
  const { isEnabled: isTimesheetV2Enabled } = useFeatureFlag(EFeatureFlags.TIMESHEET_V2)
  const environment: EEnvironment = AppConfig.ENVIROMENT as EEnvironment
  const isProduction: boolean = environment === EEnvironment.PRODUCTION

  function renderTimesheet(): JSX.Element {
    if (isV2TimeSheet) {
      return <V2TimeSheetUser handleCloseConfirmDialog={props.handleCloseConfirmDialogYesNo} />
    }
    return isAdmin ? <TimeSheetAdmin /> : <TimeSheetUser />
  }

  function handleRedirectPage(): void {
    const redirectUrl: string = !isV2TimeSheet ? RoutesConstants.V2_TIMESHEET : v1TimesheetPath

    history.push(redirectUrl)
  }

  async function handleRefreshToken(): Promise<void> {
    try {
      const email: string = window.localStorage.getItem('email')
      const response: ISuccessResponse<IRefreshTokenResponse> = await refreshToken(email)
      const user: IAuthUser = response?.data?.user

      if (user) {
        window.localStorage.setItem('userRole', user?.role)
        window.localStorage.setItem('email', user?.email)
        window.localStorage.setItem('fullName', user?.fullName)
        window.localStorage.setItem('aToken', user?.aToken)
        window.localStorage.setItem('rToken', user?.rToken)
        window.localStorage.setItem('title', user?.title)
        window.localStorage.setItem('avatar', user?.avatar)
      }
    } catch (error) {
      handleError(error, 'src/containers/v2/TimeSheet/Layout/index.tsx', 'handleRefreshToken')
    }
  }

  useEffect(() => {
    handleRefreshToken()
  }, [])

  return (
    <>
      {/* *INFO: hidden toggle timesheet v2 */}
      {/* {isTimesheetV2Enabled && !isProduction && (
        <HStack
          width="full"
          padding="12px 24px"
          background={backgroundGrey50}
          justifyContent="flex-end"
          position="relative"
          top="-24px"
          left="-24px">
          <Switch id="switch" onChange={handleRedirectPage} isChecked={isV2TimeSheet} />
          <Text>New timesheet UI version</Text>
        </HStack>
      )} */}
      {renderTimesheet()}
    </>
  )
}

export default TimesheetLayout
