import { ReactNode } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Select from 'components/Select'
import CreatableSingle from 'components/Select/AutoCompleteSelect'
import MultipleSelect from 'components/Select/MultipleSelect'
import MultipleWithSearchBoxSelect from 'components/Select/MultipleWithSearchBoxSelect'
import ReactSelect from 'components/Select/ReactSelect'
import { Field } from 'formik'
import capitalize from 'lodash/capitalize'
import PropTypes from 'prop-types'

const styles = () => ({
  textField: { marginLeft: 20, marginRight: 20, width: 200, fontSize: 16 },
})

const SelectField = (props) => {
  const {
    classes,
    name,
    label,
    disabled,
    required,
    extraWidth,
    isCreatable,
    isMulti,
    options,
    data,
    isReactSelect,
    isMultiAndSearch,
  } = props
  const inputLabel = label || `${capitalize(props.name)}${required ? '*' : ''}`
  const className: string = extraWidth ? classes.textField + '98width' : classes.textField

  let component: ReactNode
  if (isCreatable) {
    component = CreatableSingle
  } else if (isReactSelect) {
    component = ReactSelect
  } else if (isMulti) {
    component = MultipleSelect
  } else if (isMultiAndSearch) {
    component = MultipleWithSearchBoxSelect
  } else {
    component = Select
  }

  return (
    <div className="field">
      <Field
        name={name}
        label={inputLabel}
        className={className}
        options={options}
        data={data}
        placeholder={isCreatable && `Choose or Create at least one ${name}...`}
        component={component}
        margin="normal"
        disabled={disabled}
      />
    </div>
  )
}

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  extraWidth: PropTypes.bool,
  isOnlyDate: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.array.isRequired,
  data: PropTypes.array,
  isReactSelect: PropTypes.bool,
  isMultiAndSearch: PropTypes.bool,
}

export default withStyles(styles)(SelectField)
