// *TODO: Fix the eslint-disable max-lines later
/* eslint-disable max-lines */
import { useEffect, useRef, useState } from 'react'
import { Avatar, Flex, HStack, Icon, Image, Text, chakra } from '@chakra-ui/react'
import { logout } from 'API/auth'
import { EStatusCode, ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { readAllNotification, updateNotification } from 'API/notification'
import NotificationDropDown from 'components/Header/components/NotificationDropDown'
import Helper from 'Helper'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { backgroundWhite } from 'theme/globalStyles'
import { INotification } from 'types/notification'
import { getValidArray } from 'utils'
import { EPathName, ERoleOfUser } from 'constants/enum'
import { RoutesConstants } from 'constants/index'
import { ELocalStorageKeys } from 'constants/localStorageKeys'
import { ESessionsStorageKeys } from 'constants/sessionStorageKeys'
import { useStores } from 'utils/hooks/useStores'
import { ReactComponent as ArrowRight } from '../../assets/images/svg/arrow-right-s-line.svg'
import { ReactComponent as BlackNotificationIcon } from '../../assets/images/svg/black-notification-fill.svg'
import { ReactComponent as CollapseICon } from '../../assets/images/svg/collapse.svg'
import { ReactComponent as GrayNotificationIcon } from '../../assets/images/svg/gray-notification-fill.svg'
import { ReactComponent as ExpandIcon } from '../../assets/images/svg/hamburger.svg'
import { EventName } from '../../constants'
import { IHeaderProps } from './constants'

import './index.scss'

const Header = (props: IHeaderProps) => {
  const {
    notifications,
    history,
    triggerCollapsedSidebarHandler,
    isCollapsed,
    triggerToggleUserPannelHandler,
    isOpenUserPannel,
    isActiveUserPannel,
    getNotificationsList,
  } = props
  const headerRef = useRef(null)
  const { layoutStore } = useStores()
  const { pageTitle } = layoutStore

  const [isOpenNotificationDropDown, setIsOpenNotificationDropDown] = useState<boolean>(false)
  const [subPageTitle, setSubPageTitle] = useState<string>(
    window.sessionStorage.getItem(ESessionsStorageKeys.SUB_PAGE_TITLE)
  )

  const { location } = history
  const { search, pathname } = location

  const unReadNotifications: INotification[] = getValidArray(notifications).filter(
    (item: INotification) => !item.isRead
  )

  const fullName: string = window.localStorage.getItem(ELocalStorageKeys.FULL_NAME)
  const email: string = window.localStorage.getItem(ELocalStorageKeys.EMAIL)
  const avatar: string = window.localStorage.getItem(ELocalStorageKeys.AVATAR)
  const role: string = window.localStorage.getItem(ELocalStorageKeys.USER_ROLE)

  window.addEventListener(EventName.storage, () => {
    setSubPageTitle(window.sessionStorage.getItem(ESessionsStorageKeys.SUB_PAGE_TITLE))
  })

  async function editNotification(id: string, itemId: string): Promise<void> {
    try {
      const response = await updateNotification(id)
      const role = window.localStorage.getItem(ELocalStorageKeys.USER_ROLE)
      const key = response.key
      const urlRedirect = Helper.getRoutesOfActivity(key, role, itemId)
      if (urlRedirect) {
        history.push(urlRedirect)
      }
      getNotificationsList()
    } catch (error) {
      handleError(error, 'src/components/Header/index.tsx', 'editNotification')
    }
  }

  async function logoutHandler(): Promise<void> {
    try {
      const response: ISuccessResponse<object> = await logout()

      if (response?.status === EStatusCode.SUCCESS) {
        Helper.beforeLogoutHandler()
      }
    } catch (error) {
      handleError(error, 'src/components/Header/index.tsx', 'logoutHandler')
    }
  }

  function linkToProfile(): void {
    history.push(RoutesConstants.PROFILE)
  }

  function triggerToggleNotificationDropDown(): void {
    setIsOpenNotificationDropDown(!isOpenNotificationDropDown)
  }

  function handleClickOutside(event: Event): void {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      if (isOpenUserPannel) {
        triggerToggleUserPannelHandler()
      }
      if (isOpenNotificationDropDown) {
        triggerToggleNotificationDropDown()
      }
    }
  }

  async function clickReadAllHandler(): Promise<void> {
    try {
      await readAllNotification()
      getNotificationsList()
    } catch (error) {
      handleError(error, 'src/components/Header/index.tsx', 'clickReadAllHandler')
    }
  }

  function goBack(): void {
    if (subPageTitle) {
      setSubPageTitle(null)
      if (
        !pathname.includes(EPathName.DETAIL) &&
        !pathname.includes(EPathName.NEW) &&
        !pathname.includes(EPathName.PROJECT) &&
        (search.includes(EPathName.INDEX) || pathname.split('/').length >= 3)
      ) {
        history.replace(`${pathname}?${EPathName.INDEX}=0`)
        window.location.reload()
      } else {
        if (pathname.includes(EPathName.PROJECT)) {
          const previousPath =
            role === ERoleOfUser.ADMIN ? RoutesConstants.PROJECTS_ADMIN_LIST : RoutesConstants.PROJECT_LIST
          history.replace(`${previousPath}?${EPathName.INDEX}=0`)
        } else {
          const pageTitlePathname = pageTitle.replaceAll(' ', '-').toLowerCase()
          history.replace(`/${pageTitlePathname}`)
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener(EventName.mousedown, handleClickOutside)

    return () => {
      document.removeEventListener(EventName.mousedown, handleClickOutside)
    }
  }, [isOpenNotificationDropDown, isOpenUserPannel])

  return (
    <div className="app-header" ref={headerRef}>
      <nav className={isCollapsed ? 'collapsed myNavbar' : 'myNavbar'}>
        <Flex marginLeft={{ base: 4, md: 6 }}>
          <chakra.span
            className="sidebar-toogle"
            onClick={triggerCollapsedSidebarHandler}
            marginRight={{ base: 4, md: 6 }}
            color="gray.700">
            {isCollapsed ? (
              <ExpandIcon onClick={triggerCollapsedSidebarHandler} />
            ) : (
              <CollapseICon onClick={triggerCollapsedSidebarHandler} />
            )}
          </chakra.span>
          <HStack spacing={4}>
            <Text
              fontSize="lg"
              fontWeight="bold"
              marginTop={5}
              display="inline-block"
              placeSelf="center"
              onClick={goBack}
              textDecoration="unset"
              cursor={subPageTitle ? 'pointer' : 'unset'}>
              {pageTitle}
            </Text>
            {subPageTitle && (
              <>
                <ArrowRight />
                <Text
                  fontSize="lg"
                  fontWeight="500"
                  marginTop={5}
                  display="inline-block"
                  placeSelf="center"
                  color="gray.500">
                  {subPageTitle}
                </Text>
              </>
            )}
          </HStack>
        </Flex>
        <div className="myNavbar-custom-menu">
          <ul className="myNavbar-nav">
            <li className="user-menu">
              <div
                title="Your information"
                className={`user-menu-wrapper ${isActiveUserPannel ? 'active' : ''}`}
                onClick={triggerToggleUserPannelHandler}>
                <HStack
                  spacing={3}
                  background={backgroundWhite}
                  _hover={{ background: 'gray.50' }}
                  borderRadius="6px"
                  padding="4px 12px"
                  marginRight={3}>
                  <Avatar name={fullName} src={Helper.generateImageURL(avatar, ELocalStorageKeys.AVATAR)} size="sm" />
                  <Flex direction="column" display={{ base: 'none', md: 'flex' }} gap={1}>
                    <Text
                      width={{ md: '100px', lg: 'fit-content' }}
                      fontSize="sm"
                      fontWeight="400"
                      lineHeight="5"
                      margin={0}
                      color="gray.800">
                      {fullName}
                    </Text>
                    <Text fontSize="xs" color="gray.500" margin={0} lineHeight={4}>
                      {email}
                    </Text>
                  </Flex>
                </HStack>
                {isOpenUserPannel && (
                  <ul className="dropdown">
                    <li className="profile" onClick={linkToProfile}>
                      <div className="profile-content">
                        <Link to={RoutesConstants.PROFILE}>
                          <p className="profile-short">
                            <Image src="/assets/icons/user-profile.svg" alt="user-profile" className="icon" />
                            <span className="title-profile">My Profile</span>
                          </p>
                        </Link>
                      </div>
                    </li>
                    <li className="logout">
                      <button className="logout-button" onClick={logoutHandler}>
                        <Image src="/assets/icons/logout-red.svg" alt="logout" className="icon" />
                        <span className="title-logout">Logout</span>
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default observer(Header)
