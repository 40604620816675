import GuideData from './guideData'

import './index.scss'

const GuidelineContainer = () => (
  <div className="container guideline-page">
    <div className="container-content">
      <div className="guide-list">
        {GuideData.map((item) => {
          return (
            <div className="guide-item profile">
              <div
                className={`guide-item-head ${item.name}`}
                style={{
                  backgroundImage: `url(${item.image})`,
                }}></div>
              <div className="guide-item-body">
                <p className="guide-title">{item.title}</p>
                <p className="guide-purpose">
                  <span className="highlight-text">Purpose:</span> {item.purpose}
                </p>
                <p className="guide-content">
                  <span className="highlight-text">Guide: </span>
                  {item.content}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  </div>
)

export default GuidelineContainer
