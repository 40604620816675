import React from 'react'
import { Formik } from 'formik'

class FormModel extends React.Component {
  render() {
    const { schema, initialValues, handleSubmit, component, refComponent } = this.props
    return (
      <Formik
        ref={refComponent}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        render={(props) => React.cloneElement(component, props)}
      />
    )
  }
}

export default FormModel
