import { VStack, Text, Container } from '@chakra-ui/react'
import FormColorPicker from 'components/FormColorPicker'
import CheckboxFieldWithLabel from 'componentsCharka/CheckboxFieldWithLabel'
import { observer } from 'mobx-react'
import { useWatch } from 'react-hook-form'
import styles from '../LogoProject/index.module.scss'
interface ISettingProjectProps {
  name?: string
  isDisabled?: boolean
}

const SettingProject = (props: ISettingProjectProps) => {
  const { isDisabled } = props
  const projectColorWatcher: string = useWatch({ name: 'color' })

  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack alignItems={'flex-start'} className={styles.logoSection}>
        <Text alignSelf={'flex-start'} className={styles.headerBox} paddingBottom={3}>
          Settings
        </Text>
        <VStack alignItems={'flex-start'}>
          <CheckboxFieldWithLabel name="isPastLock" label="Past Lock" isDisabled={isDisabled} />
          <CheckboxFieldWithLabel name="requiredTag" label={'Require Tags'} isDisabled={isDisabled} />
          <CheckboxFieldWithLabel name="canOverlap" label={'Can Overlap'} isDisabled={isDisabled} />
          <CheckboxFieldWithLabel
            name="isTimesheetSupervisorDailyReminderEnabled"
            label={'Timesheet Supervisor Daily Reminder'}
            isDisabled={isDisabled}
          />
          <FormColorPicker name="color" isEdit={!isDisabled} value={projectColorWatcher} />
        </VStack>
      </VStack>
    </Container>
  )
}

export default observer(SettingProject)
