import React from 'react'
import FormModel from '../../../../components/FormModel'

import FormDetail from '../components/FormDetail'
import Item from '../components/Item'

class Country extends React.Component {
  render() {
    const {
      handleSubmit,
      getDetailCountry,
      countries,
      countryDetail,
      deleteCountry,
      viewMode,
      cancelEditHandler,
      validationSchema,
    } = this.props

    return (
      <div className="container-fluid setting-item">
        <div className="box box-default">
          <div className="box-header">Country</div>
          <div className="box-divider" />
          <div className={`box-body`}>
            <div className="container-fluid">
              <div>
                <FormModel
                  initialValues={countryDetail}
                  schema={validationSchema}
                  handleSubmit={handleSubmit}
                  component={<FormDetail cancelEditHandler={cancelEditHandler} viewMode={viewMode} {...this.props} />}
                />
              </div>
              <div className="setting-list-wrapper">
                {countries &&
                  countries.map((item, index) => (
                    <Item
                      key={item._id}
                      data={item}
                      index={index}
                      editClickHandler={() => getDetailCountry(item._id)}
                      deleteClickHandler={() => deleteCountry(item, 'country')}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Country
