import { InputGroup, InputLeftAddon, Input, FormLabel, FormControl } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

export interface IWebLinkInputProps {
  name: string
  label: string
  placeholder?: string
}

const WebLinkInput = (props: IWebLinkInputProps) => {
  const { name, label, placeholder } = props
  const { register } = useFormContext()

  return (
    <FormControl>
      {label && (
        <FormLabel color="gray.700" fontWeight={400}>
          {label}
        </FormLabel>
      )}
      <InputGroup height={10}>
        <InputLeftAddon children="https://" boxSizing="border-box" />
        <Input {...register(name)} placeholder={placeholder} boxSizing="border-box" />
      </InputGroup>
    </FormControl>
  )
}

export default WebLinkInput
