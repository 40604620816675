import { ViewIcon, DownloadIcon } from '@chakra-ui/icons'
import { VStack, HStack, Text, Box, Container, useMediaQuery } from '@chakra-ui/react'
import { ReactComponent as CvFile } from 'assets/images/svg/uploadCV.svg'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import { saveAs } from 'file-saver'
import { useFormContext } from 'react-hook-form'
import { pdfjs } from 'react-pdf'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toBase64 } from 'utils'
import { maxMobileSize } from 'constants/common'
import { RoutesConstants } from 'constants/index'
import ApiHelper from '../../../../../Helper/api'
import styles from '../../index.module.scss'
import MobileCV from './components/MobileCV'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface ICurriculumViate {
  userId: string
  name: string
}

const CurriculumViate = (props: ICurriculumViate) => {
  const { getValues } = useFormContext()
  const { name, userId } = props
  const [isMobile] = useMediaQuery(maxMobileSize)
  const history = useHistory()

  const userCvName = getValues(name)

  const handleViewDetailCV = () => {
    const pathname = generatePath(RoutesConstants.USER_CV, { id: userId })

    history.push(pathname)
  }

  const downloadCVHandler = () => {
    ApiHelper.downloadFile(
      {
        dataType: 'pdf',
        url: `/users/download-cv/${userId}/admin-download/${userCvName}`,
      },
      async (response) => {
        const cv = response.data
        const result = await toBase64(new Blob([cv], { type: 'application/pdf' }))
        saveAs(result, userCvName)
      }
    )
  }

  return (
    <>
      {isMobile ? (
        <MobileCV userCVName={userCvName} handleViewDetail={handleViewDetailCV} handleDownload={downloadCVHandler} />
      ) : (
        <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
          <VStack padding="15px 12px" className={styles.cvSection}>
            <Text fontSize={{ xl: '16px', lg: '14px', md: '14px', sm: '14px' }}>Curriculum Vitae</Text>
            {userCvName ? (
              <Box width={'100%'}>
                <CvFile />
                <Text>{userCvName}</Text>
                <HStack justifyContent={'end'}>
                  <Box onClick={handleViewDetailCV} className={styles.cvButton}>
                    <ViewIcon className={styles.svgIcon} />
                  </Box>
                  <Box onClick={downloadCVHandler} className={styles.cvButton}>
                    <DownloadIcon className={styles.svgIcon} />
                  </Box>
                </HStack>
              </Box>
            ) : (
              <EmptyContentBox text="There is no CV here" />
            )}
          </VStack>
        </Container>
      )}
    </>
  )
}

export default CurriculumViate
