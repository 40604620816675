import api from 'API'
import { ISuccessResponse } from 'API/constants'
import { getErrorMessage } from 'API/error'
import { COUNTRY_BASE_URL, ICountryListResponse } from './constants'

export async function getCountries(): Promise<ISuccessResponse<ICountryListResponse>> {
  try {
    return api.get(`${COUNTRY_BASE_URL}/list`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
