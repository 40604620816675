import { Container, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import LabelForm from 'componentsCharka/LabelForm'
import { IUser } from 'types/user'
import { getValidDevelopers } from '../ProjectDetailForm/utils'
import AvatarDevGroup from './components/avatarDevGroup'
import styles from './index.module.scss'

const ProjectDetailForm = (props) => {
  const { methods } = props
  const title: string = 'Project Detail'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))
  const { getValues } = methods
  const listDeveloper = getValues('developerDetailOnProject')
  const validDevelopers: IUser[] = getValidDevelopers(listDeveloper)

  const untilNow = getValues('untilNow')
  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack background="white" padding={{ base: 6, lg: 6 }} borderRadius="8px">
        <Text alignSelf={'flex-start'} className={styles.headerBox}>
          General Information
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <LabelForm name="name" label="Project Name" readonly={true} />
          <LabelForm name="companyName" label="Company Name" readonly={true} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <LabelForm name="companyWebsite" label="Company Website" isHighligh={true} readonly={true} />
          <LabelForm name="companyPhone" label="Company Phone Number" isHighligh readonly={true} />
          <LabelForm name="country" label="Country" readonly={true} />
          {/* <LabelForm name="city" label="City" readonly={true} /> */}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
          <LabelForm name="companyPhone" label="Address Detail" readonly={true} />
        </SimpleGrid>
        <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
          Working Information
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
          <LabelForm name="startedDate" label="Start Date" readonly={true} />
          {!untilNow && <LabelForm name="endedDate" label="End Date" readonly={true} />}
          {untilNow && <LabelForm name="untilNowProject" label="Until Now" readonly={true} />}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <LabelForm name="commitedHours" label="Commited Hours (hrs/week)" readonly={true} />
          <LabelForm name="technology" label="Technology" readonly={true} type="textarea" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <LabelForm name="neededDeveloperNumber" label="Needed Developer Number" readonly={true} />
          <AvatarDevGroup name="listDeveloper" data={validDevelopers} label="Working Developers" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <LabelForm name="partnerId" label="Belongs To Partner" readonly={true} />
          <LabelForm name="type" label="Project Type" isTagFormat readonly={true} />
        </SimpleGrid>
      </VStack>
    </Container>
  )
}
export default ProjectDetailForm
