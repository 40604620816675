import api from 'API'
import apiV2 from 'API/apiV2'
import { ISuccessResponse } from 'API/constants'
import { getErrorMessage, handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import { IProject } from 'constants/schema'
import {
  IProjectDetailOfUserResponse,
  IProjectListWithPaginationRequest,
  IProjectDetailResponse,
  IProjectMessageResponse,
  IProjectsOfUserResponse,
  PROJECT_BASE_URL,
  IProjectListWithPaginationResponse,
} from './constants'

export async function getProjectsOfUser(): Promise<ISuccessResponse<IProjectsOfUserResponse>> {
  try {
    const url = `${PROJECT_BASE_URL}/list-of-user`
    return apiV2.get(url)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getProjectList(): Promise<ISuccessResponse<IProjectsOfUserResponse>> {
  try {
    return api.get(`${PROJECT_BASE_URL}/list`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getProjectDetailOfUser(
  projectId: string
): Promise<ISuccessResponse<IProjectDetailOfUserResponse>> {
  try {
    return api.get(`${PROJECT_BASE_URL}/detail-of-user/${projectId}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getProjectListWithPagination(
  payload: IProjectListWithPaginationRequest
): Promise<ISuccessResponse<IProjectListWithPaginationResponse>> {
  try {
    const url = `${PROJECT_BASE_URL}/list`
    return api.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function createProject(payload: IProject): Promise<IProjectMessageResponse> {
  try {
    const url = `${PROJECT_BASE_URL}/create-project`
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function archive(id: string): Promise<ISuccessResponse<IProjectMessageResponse>> {
  try {
    return api.post(`${PROJECT_BASE_URL}/archive/${id}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function unarchive(id: string): Promise<ISuccessResponse<IProjectMessageResponse>> {
  try {
    return api.post(`${PROJECT_BASE_URL}/unarchive/${id}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function deleteProject(id: string): Promise<ISuccessResponse<IProjectMessageResponse>> {
  try {
    return api.post(`${PROJECT_BASE_URL}/delete/${id}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getProjectDetail(id: string): Promise<ISuccessResponse<IProjectDetailResponse>> {
  try {
    return api.get(`${PROJECT_BASE_URL}/detail/${id}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function updateProject(id: string, payload: IProject): Promise<ISuccessResponse<IProjectDetailResponse>> {
  try {
    const url = `${PROJECT_BASE_URL}/update/${id}`
    return api.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getListOfSupervisor(): Promise<IProjectsOfUserResponse> {
  try {
    const url = `${PROJECT_BASE_URL}/list-of-supervisor`
    const response: ISuccessResponse<IProjectsOfUserResponse> = await apiV2.get(url)
    return response.data
  } catch (error) {
    handleError(error as Error, 'src/API/project/index.ts', 'getListOfSupervisor')
    return {} as IProjectsOfUserResponse
  }
}

export async function getProjectDetailOfUserV2(
  projectId: string
): Promise<ISuccessResponse<IProjectDetailOfUserResponse>> {
  try {
    return apiV2.get(`${PROJECT_BASE_URL}/detail-of-user/${projectId}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getProjectsOfUserV2(): Promise<ISuccessResponse<IProjectsOfUserResponse>> {
  try {
    const url = `${PROJECT_BASE_URL}/list-of-user`
    return apiV2.get(url)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
