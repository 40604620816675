import api, { getUploadHeader } from 'API'
import { IHeader, ISuccessResponse } from 'API/constants'
import { getErrorMessage } from 'API/error'
import { EUploadFileType } from 'types/upload'
import { IUploadImageResponse, UPLOAD_BASE_URL, IUploadFileResponse } from './constants'

export async function uploadImage(data: FormData, type: string): Promise<ISuccessResponse<IUploadImageResponse>> {
  try {
    const url = `${UPLOAD_BASE_URL}/images/${type}`
    const uploadHeader: IHeader = getUploadHeader()
    return api.post(url, data, {
      headers: {
        ...uploadHeader,
      },
    })
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function uploadFileCv(
  payload: FormData,
  type: EUploadFileType
): Promise<ISuccessResponse<IUploadFileResponse>> {
  try {
    const uploadHeader: IHeader = getUploadHeader()
    return api.post(`${UPLOAD_BASE_URL}/file/${type}`, payload, {
      headers: {
        ...uploadHeader,
      },
    })
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function deleteFileCv(type: EUploadFileType): Promise<void> {
  try {
    const uploadHeader: IHeader = getUploadHeader()
    return api.post(`${UPLOAD_BASE_URL}/file/delete/${type}`, {
      headers: {
        ...uploadHeader,
      },
    })
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
