import { Text } from '@chakra-ui/react'

export interface INoDataTextProps {
  isHighligh?: boolean
  customStyle?: Record<string, unknown>
}

const NoDataText = (props: INoDataTextProps) => {
  const { isHighligh, customStyle } = props
  return (
    <Text
      paddingLeft={0}
      fontSize="md"
      color={isHighligh ? 'blue.500' : 'gray.700'}
      fontStyle={'italic'}
      fontWeight={'500'}
      border={0}
      {...customStyle}>
      N/A
    </Text>
  )
}

export default NoDataText
