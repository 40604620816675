import React from 'react'
import MultiRef from 'react-multi-ref'
import * as Yup from 'yup'

import FormModel from '../../../components/FormModel'
import FeedbackDetail from './components/FeedbackDetail'
import { AppConfig, Messages, RoutesConstants } from '../../../constants'
import { ERoleOfUser } from '../../../constants/enum'
import { getValidArray } from 'utils'
import { getFeedbackList, updateFeedback } from 'API/feedback'
import { getUserListAdmin } from 'API/user'

import './index.scss'
import { handleError } from 'API/error'
const validationSchema = Yup.object().shape({
  receiver: Yup.array().required(Messages.fieldRequired),
  question: Yup.string()
    .required(Messages.fieldRequired)
    .max(AppConfig.LIMIT_CHARACTERS_OF_STRING, Messages.exceedCharacterOfString),
  name: Yup.string().required(Messages.fieldRequired),
})
class FeedbackList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      feedbacks: [],
      selectedFeedback: null,
      admins: [],
    }
  }
  componentDidMount() {
    this.getListAdminsHandler()
    this.getListFeedbackHandler()
  }
  getListAdminsHandler = async () => {
    try {
      const response = await getUserListAdmin()
      this.setState({
        admins: response?.admins,
      })
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackList/index.js', 'getListAdminsHandler')
    }
  }
  _actRefs = new MultiRef()
  getListFeedbackHandler = async () => {
    try {
      const response = await getFeedbackList()
      this.setState({
        feedbacks: response?.feedbacks,
      })
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackList/index.js', 'getListFeedbackHandler')
    }
  }
  handleToggleActiveEdit = (values) => {
    const isAdmin = localStorage.getItem('userRole') === ERoleOfUser.ADMIN
    const userId = localStorage.getItem('userId')
    const canEdit = isAdmin || values?.sender === userId
    if (canEdit) {
      this.setState({
        selectedFeedback: values._id,
      })
    }
  }

  linkToCreateFeedback = () => {
    const { history } = this.props
    history.push(RoutesConstants.FEEDBACK_NEW)
  }
  editFeedbackHandler = async (values) => {
    try {
      const feedbackID = values._id
      await updateFeedback(feedbackID, values)
      this.props.handleOpenSnackbar({ variant: 'success', message: Messages.updateFeedbackSuccess })
      this.handleToggleActiveEdit(null)
      this.getListFeedbackHandler()
    } catch (error) {
      handleError(error, 'src/containers/Feedback/FeedbackList/index.js', 'editFeedbackHandler')
    }
  }
  resetFormWhenPressCancel = (id) => {
    this._actRefs.map.get(id).resetForm()
    this.setState({
      selectedFeedback: null,
    })
  }
  render() {
    const { feedbacks, selectedFeedback, admins } = this.state
    return (
      <div className="container feedback-list-page">
        <div>
          <div className="options-sub">
            <div className="create-new-project">
              <button className="primary-button" onClick={this.linkToCreateFeedback}>
                Create New Feedback
              </button>
            </div>
          </div>
          <div className="feedback-list">
            {getValidArray(feedbacks).map((item) => {
              const viewModeFeedbackDetail = selectedFeedback === item._id ? 'edit' : 'show'
              return (
                <div className="project-detail-sub" key={item._id}>
                  <FormModel
                    refComponent={this._actRefs.ref(item._id)}
                    initialValues={item}
                    handleSubmit={this.editFeedbackHandler}
                    schema={validationSchema}
                    component={
                      <FeedbackDetail
                        admins={admins}
                        viewMode={viewModeFeedbackDetail}
                        cancelForm={this.resetFormWhenPressCancel}
                        handleToggleActiveEdit={this.handleToggleActiveEdit}
                      />
                    }
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default FeedbackList
