import React, { Dispatch, SetStateAction } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  DrawerBody,
  DrawerCloseButton,
  Button,
  Box,
  HStack,
} from '@chakra-ui/react'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { backgroundGrey200, backgroundSecondary } from 'theme/globalStyles'
import { IOption } from 'constants/common'
import { PartnerCategory } from 'constants/defaultOptionList'
import Helper from '../../../Helper'

interface ICategoryFilter {
  category?: IOption[]
}

export interface IFilterProps {
  dirty?: boolean
  isSubmitting?: boolean
  openModalFilterForm?: boolean
  setOpenFilterForm: Dispatch<SetStateAction<boolean>>
  filterSubmit: (data: ICategoryFilter) => void
  clearForm?: () => void
}

const FilterForm = (props: IFilterProps) => {
  const { dirty, isSubmitting, openModalFilterForm, filterSubmit, setOpenFilterForm } = props
  const partnerCategoryOptions: IOption[] = Helper.createOptionsOfSelect(PartnerCategory)
  const methods: UseFormReturn = useForm()
  const { reset, handleSubmit } = methods

  function closeModal(): void {
    setOpenFilterForm(false)
  }

  function resetForm(): void {
    reset({
      category: [],
    })
  }

  return (
    <HStack spacing={4} alignSelf="flex-start" marginBottom={6} display={{ base: 'none', md: 'flex' }}>
      <Drawer size="md" isOpen={openModalFilterForm} onClose={closeModal}>
        <DrawerOverlay />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(filterSubmit)}>
            <DrawerContent>
              <DrawerCloseButton cursor="pointer" color="gray" onClick={closeModal} />
              <DrawerHeader borderBottom={`1px solid ${backgroundGrey200}`}>Filter</DrawerHeader>
              <DrawerBody paddingY={6}>
                <ChakraInputDropdown
                  isMulti
                  name="category"
                  label="Partner Category"
                  optionsData={partnerCategoryOptions}
                />
              </DrawerBody>
              <DrawerFooter justifyContent="space-between" borderTop={`1px solid ${backgroundGrey200}`}>
                <Button
                  colorScheme="blue"
                  color="blue.500"
                  variant="ghost"
                  margin={3}
                  cursor="pointer"
                  onClick={resetForm}>
                  Reset
                </Button>
                <Box>
                  <Button variant="outline" margin={3} cursor="pointer" background="none" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    background={backgroundSecondary}
                    fontWeight={500}
                    type="submit"
                    cursor="pointer"
                    disabled={!dirty && isSubmitting}>
                    Apply Filter
                  </Button>
                </Box>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </FormProvider>
      </Drawer>
    </HStack>
  )
}

export default FilterForm
