import { ITableHeader } from 'components/Table'
import { IPartner } from 'constants/schema'

export interface Props {
  history: any
  match: any
  handleCloseConfirmDialog?: () => void
  handleOpenSnackbar?: ({ variant, message }) => void
}
export interface State {
  partners: unknown[]
  count: number
  filter?: {
    category: { label: string; value: string }[]
    fullName: any
  }
  open?: boolean
  exportNow?: boolean
  openDeleteModal?: boolean
  openArchiveModal?: boolean
  selectedPartner?: IPartner
  isCorrectName?: boolean
}
export interface IRowActionItem {
  name: string
  customClassNames?: string[]
  handler: () => void
}
export const headerListTabletTable = {
  type: 'Type',
  category: 'Category',
  number: 'Number',
  fullName: 'Full Name',
  titleId: 'Title',
  status: 'Status',
  actionsTablet: '',
}
export function getHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Number',
      accessor: 'number',
    },
    {
      Header: 'Full Name',
      accessor: 'fullName',
    },
    {
      Header: 'Partner Type',
      accessor: 'type',
    },
    {
      Header: 'Category',
      accessor: 'category',
    },
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}
