import { Button as CkButton, chakra } from '@chakra-ui/react'

export const Button = chakra(CkButton, {
  baseStyle: () => ({
    paddingRight: '15px',
    background: 'transparent',
    height: '1em',
    width: '1em',
    _focus: {
      boxShadow: 'none',
    },
    _hover: {
      cursor: 'pointer',
      background: 'transparent',
    },
  }),
})
