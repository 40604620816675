import api from 'API'
import apiV2 from 'API/apiV2'
import { ISuccessResponse } from 'API/constants'
import { getErrorMessage } from 'API/error'
import { AxiosResponse } from 'axios'
import { ILoginForm } from 'types/user'
import { AUTH_BASE_URL, ILoginResponse, IRefreshTokenResponse } from './constants'

export async function login(payload: Omit<ILoginForm, 'isRememberMe'>): Promise<ISuccessResponse<ILoginResponse>> {
  try {
    return api.post(`${AUTH_BASE_URL}/login`, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function loginV2(payload: Omit<ILoginForm, 'isRememberMe'>): Promise<ILoginResponse> {
  try {
    const url: string = `${AUTH_BASE_URL}/login`
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

// *INFO: using "object" type because the API response empty object
export async function logout(): Promise<ISuccessResponse<object>> {
  try {
    return api.get(`${AUTH_BASE_URL}/logout`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function refreshToken(email: string): Promise<ISuccessResponse<IRefreshTokenResponse>> {
  try {
    return api.get(`${AUTH_BASE_URL}/refresh-token/${email}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
