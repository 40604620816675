import React from 'react'
import { Field } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import SelectField from 'components/SelectField'

import Helper from '../../../../Helper'
import Select from '../../../../components/Select'
import { TypeOfFilterOfCommitTime, GroupByOfCommitTime } from '../../../../constants/defaultOptionList'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
})

class FilterFormOfCommitTime extends React.Component {
  render() {
    const {
      classes,
      users,
      projects,
      dirty,
      isSubmitting,
      handleSubmit,
      isShowProjectList,
      isShowUserList,
      isShowGroupSwitch,
      selectedTypeOfFilerHandler,
    } = this.props
    const usersOptions = Helper.createOptionsOfReactSelectFromDB(users)
    const projectsOptions = Helper.createOptionsOfReactSelectFromDB(projects)
    const typeOfFilerOptions = Helper.createOptionsOfSelect(TypeOfFilterOfCommitTime)
    const groupByOptions = Helper.createOptionsOfSelect(GroupByOfCommitTime)
    return (
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="box box-default">
            <div className="box-header">Filter</div>
            <div className="box-divider" />
            <div className={`box-body`}>
              <div className="container-fluid">
                <div className="fields">
                  <div className="field">
                    <Field
                      name="typeOfFilter"
                      label="Type of filter"
                      className={classes.textField}
                      margin="normal"
                      component={Select}
                      options={typeOfFilerOptions}
                      onChangeHandler={selectedTypeOfFilerHandler}
                    />
                  </div>
                </div>
                {isShowUserList && (
                  <div className="fields">
                    <div className="field">
                      <SelectField name="user" label="User*" isReactSelect options={usersOptions} data={users} />
                    </div>
                  </div>
                )}
                {isShowProjectList && (
                  <div className="fields">
                    <div className="field">
                      <SelectField
                        name="project"
                        label="Project*"
                        isReactSelect
                        options={projectsOptions}
                        data={projects}
                      />
                    </div>
                  </div>
                )}
                {isShowGroupSwitch && (
                  <div className="fields">
                    <div className="field">
                      <Field
                        name="groupBy"
                        label="Group by*"
                        className={classes.textField}
                        margin="normal"
                        component={Select}
                        options={groupByOptions}
                      />
                    </div>
                  </div>
                )}
                <div className="submit-option">
                  <button className="primary-button" type="submit" disabled={!dirty && isSubmitting}>
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default withStyles(styles)(FilterFormOfCommitTime)
