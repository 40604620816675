import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Download from '@material-ui/icons/CloudDownload'

import { AppConfig } from '../../constants'

const styles = {
  inlineli: {
    marginRight: '2.5rem',
    height: '0.7rem',
    width: '0.7rem',
  },
  inlineButtonLi: {
    marginRight: '2.5rem',
    height: '0.7rem',
    width: '4rem',
  },
  inlinetoolbutton: {
    height: '35px',
    width: '35px',
    marginTop: '5px',
  },
  inlineIcon: {
    marginTop: '-7px',
  },
  inlineActiveIcon: {
    marginTop: '-7px',
    color: '#2D3748',
  },
}

class ShareCVInline extends React.Component {
  copyToClipboardHandler = () => {
    const { cvFileName } = this.props
    let shareCVURL = undefined
    if (cvFileName) {
      shareCVURL = `${AppConfig.SHARE_CV_URL}/${cvFileName}`
    }
    // Create new element
    var el = document.createElement('textarea')
    // Set value (string to be copied)
    el.value = shareCVURL
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '')
    el.style = { position: 'absolute', left: '-9999px' }
    document.body.appendChild(el)
    // Select text inside element
    el.select()
    // Copy text to clipboard
    document.execCommand('copy')
    // Remove temporary element
    document.body.removeChild(el)
  }
  render() {
    const { cvFileName, downloadCVHandler, enabledDownload } = this.props
    if (cvFileName) {
      return (
        <ul id="toggle-edit-save-button" className="list-unstyled list-inline headericons float-right">
          {enabledDownload && (
            <li style={styles.inlineli} className="list-inline-item">
              <Tooltip title="Download CV">
                <IconButton
                  data-cy="download"
                  aria-label="Download CV"
                  style={styles.inlinetoolbutton}
                  onClick={downloadCVHandler}>
                  <Download style={styles.inlineIcon} />
                </IconButton>
              </Tooltip>
            </li>
          )}
        </ul>
      )
    } else {
      return <ul id="toggle-edit-save-button" className="list-unstyled list-inline headericons float-right"></ul>
    }
  }
}

export default ShareCVInline
