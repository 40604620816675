import React from 'react'
import { ButtonWithIconWrapper } from './buttonWithIcon.styles'
import IconWithText from './components/IconWithText'

export interface IButtonWithIconProps {
  iconName: string
  label?: string
  onClick?: () => void
  innerRef?: React.LegacyRef<HTMLDivElement>
  size?: number
  width?: number
  height?: number
  zIndex?: number
  color?: string
  border?: string
  fontSize?: number | string
  fontWeight?: number | string
  lineHeight?: number | string
  background?: string
  padding?: number | string
  isDisabled?: boolean
}
const ButtonWithIcon = (props: IButtonWithIconProps) => {
  const {
    iconName,
    label,
    onClick,
    innerRef,
    width,
    height,
    size,
    zIndex,
    color,
    border,
    fontSize,
    fontWeight,
    lineHeight,
    background,
    padding,
    isDisabled,
  } = props
  return (
    <ButtonWithIconWrapper
      border={border}
      background={background}
      opacity={isDisabled ? 0.5 : 1}
      ref={innerRef}
      onClick={onClick}
      zIndex={zIndex}
      padding={padding}
      width={width}
      height={height}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}>
      <IconWithText
        color={color}
        iconName={iconName}
        label={label}
        size={size}
        className={`button-with-icon__icon--${width ?? size}w`}
        spacing={2}
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
      />
    </ButtonWithIconWrapper>
  )
}
export default ButtonWithIcon
