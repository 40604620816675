import { chakra, FormLabel as CkFormLabel, Input as CkInput } from '@chakra-ui/react'
import { textGrey700 } from 'theme/globalStyles'

export const FormLabel = chakra(CkFormLabel, {
  baseStyle: () => ({
    fontSize: 'md',
    fontWeight: 400,
    color: textGrey700,
  }),
})

export const Input = chakra(CkInput, {
  baseStyle: () => ({
    boxSizing: 'border-box',
  }),
})
