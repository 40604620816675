import React, { useState } from 'react'
import { HStack, useMediaQuery } from '@chakra-ui/react'
import { IProject } from 'types/project'
import { maxMobileSize } from 'constants/common'
import SubTabItem from 'containers/ProfileNew/components/SubTabItem'
import { EContractProjectRatingSubTab } from '../../../constants'
import { EUserDetailTitle } from '../../constant'
import Section from '../Section'
import FixedProjectRating from './FixedProjectRating'
import HourlyProjectRating from './HourlyProjectRating'
import MobileProjectRatingTable from './MobileProjectRatingTable'

interface IProjectRating {
  name: string
  projectList: IProject[]
  isEdit?: boolean
}
const ProjectRating = (props: IProjectRating) => {
  const { name, isEdit } = props
  const [isMobile] = useMediaQuery(maxMobileSize)
  const [currentTab, setCurrentTab] = useState<EContractProjectRatingSubTab>(
    EContractProjectRatingSubTab.HOURLY_PROJECT_RATING
  )

  return (
    <Section title={EUserDetailTitle.PROJECT_RATINGS}>
      {isMobile ? (
        <MobileProjectRatingTable name={name} isEdit={isEdit} />
      ) : (
        <>
          <HStack alignSelf="flex-start">
            <SubTabItem
              tab={EContractProjectRatingSubTab.HOURLY_PROJECT_RATING}
              isActive={currentTab === EContractProjectRatingSubTab.HOURLY_PROJECT_RATING}
              setCurrentTab={setCurrentTab}
            />
            <SubTabItem
              tab={EContractProjectRatingSubTab.FIXED_PROJECT_RATING}
              isActive={currentTab === EContractProjectRatingSubTab.FIXED_PROJECT_RATING}
              setCurrentTab={setCurrentTab}
            />
          </HStack>
          <HStack flex={5} alignItems="flex-start">
            {currentTab === EContractProjectRatingSubTab.HOURLY_PROJECT_RATING && (
              <HourlyProjectRating isEdit={isEdit} name={`${name}.hourlyProjectRatings`} />
            )}
            {currentTab === EContractProjectRatingSubTab.FIXED_PROJECT_RATING && (
              <FixedProjectRating isEdit={isEdit} name={`${name}.fixedProjectRatings`} />
            )}
          </HStack>
        </>
      )}
    </Section>
  )
}

export default ProjectRating
