import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import './index.scss'

const styles = (theme) => ({
  progress: {
    margin: theme.spacing.unit * 2,
    color: '#2D3748',
    fontWeight: '20px',
  },
})

class CircularDeterminate extends React.Component {
  state = {
    completed: 0,
  }

  componentDidMount() {
    this.timer = setInterval(this.progress, 20)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  progress = () => {
    const { completed } = this.state
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 })
  }

  render() {
    const { classes } = this.props
    return (
      <div className="loading">
        <div className="loading-wrapper">
          <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} size={54} />
          <p className="text">Loading</p>
        </div>
      </div>
    )
  }
}

CircularDeterminate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CircularDeterminate)
