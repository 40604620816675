import api from 'API'
import apiV2 from 'API/apiV2'
import { ISuccessResponse } from 'API/constants'
import { getErrorMessage, handleError } from 'API/error'
import { ITimesheet } from 'types/timesheet'
import {
  ICreateEditTimesheetResponse,
  IDetailTimesheetResponseData,
  IListForSPScreenResponseData,
  ITimesheetHistoryResponse,
  ITimeSheetList,
  ITimesheetRequest,
  TIMESHEET_BASE_URL,
  IProjectHistoryRequest,
  IProjectHistoryResponse,
  IUserHistoryRequest,
  IUserHistoryResponse,
} from './constants'

export async function getTimesheetHistory(): Promise<ISuccessResponse<ITimesheetHistoryResponse>> {
  try {
    return apiV2.post(`${TIMESHEET_BASE_URL}/history/project`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getTimeSheetListForSPScreen(
  projectID: string
): Promise<ISuccessResponse<IListForSPScreenResponseData>> {
  try {
    return api.get(`${TIMESHEET_BASE_URL}/list-sp-screen/${projectID}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getDetailTimeSheetForAdmin(
  timesheetID: string
): Promise<ISuccessResponse<IDetailTimesheetResponseData>> {
  try {
    return api.get(`${TIMESHEET_BASE_URL}/detail-admin/${timesheetID}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getListAdmin(projectID: string): Promise<ISuccessResponse<ITimeSheetList>> {
  try {
    return api.get(`${TIMESHEET_BASE_URL}/list-admin/${projectID}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function deleteByAdmin(timesheetID: string): Promise<void> {
  try {
    return api.post(`${TIMESHEET_BASE_URL}/delete-of-admin/${timesheetID}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function createTimeSheet(
  payload: Partial<ITimesheet>
): Promise<ISuccessResponse<ICreateEditTimesheetResponse> | unknown> {
  try {
    const url = `${TIMESHEET_BASE_URL}/create-timesheet`
    return api.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function editTimeSheetByAdmin(
  timesheetID: string,
  payload: ITimesheetRequest
): Promise<ISuccessResponse<ICreateEditTimesheetResponse> | unknown> {
  try {
    const url = `${TIMESHEET_BASE_URL}/edit-timesheet-of-admin/${timesheetID}`
    return api.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

// *INFO: APIs timesheet of user
export async function getTimeSheetListOfProject(projectID: string): Promise<ISuccessResponse<ITimeSheetList>> {
  try {
    return api.get(`${TIMESHEET_BASE_URL}/list/${projectID}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getTimeSheetList(): Promise<ISuccessResponse<ITimeSheetList>> {
  try {
    return api.get(`${TIMESHEET_BASE_URL}/list`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getDetailTimeSheetForUser(
  timesheetId: string
): Promise<ISuccessResponse<IDetailTimesheetResponseData>> {
  try {
    return api.get(`${TIMESHEET_BASE_URL}/detail/${timesheetId}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function editTimesheet(
  timesheetId: string,
  payload: ITimesheetRequest
): Promise<ISuccessResponse<ICreateEditTimesheetResponse> | unknown> {
  try {
    const url = `${TIMESHEET_BASE_URL}/edit-timesheet/${timesheetId}`
    return api.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function deleteTimesheet(timesheetId: string): Promise<void> {
  try {
    return api.post(`${TIMESHEET_BASE_URL}/delete/${timesheetId}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getUserHistory(payload: IUserHistoryRequest): Promise<ISuccessResponse<IUserHistoryResponse>> {
  try {
    const url = `${TIMESHEET_BASE_URL}/history-admin/user`
    return apiV2.post(url, payload)
  } catch (error) {
    handleError(error as Error, 'src/API/timesheet/index.ts', 'getUserHistory')
    return {} as ISuccessResponse<IUserHistoryResponse>
  }
}

export async function getProjectHistory(
  payload: IProjectHistoryRequest
): Promise<ISuccessResponse<IProjectHistoryResponse>> {
  try {
    const url = `${TIMESHEET_BASE_URL}/history-admin/project`
    return apiV2.post(url, payload)
  } catch (error) {
    handleError(error as Error, 'src/API/timesheet/index.ts', 'getProjectHistory')
    return {} as ISuccessResponse<IProjectHistoryResponse>
  }
}

export async function getTimeSheetListV2(): Promise<ISuccessResponse<ITimeSheetList>> {
  try {
    return apiV2.get(`${TIMESHEET_BASE_URL}/list`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getTimeSheetListOfProjectV2(projectID: string): Promise<ISuccessResponse<ITimeSheetList>> {
  try {
    return apiV2.get(`${TIMESHEET_BASE_URL}/list/${projectID}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getDetailTimeSheetForUserV2(
  timesheetId: string
): Promise<ISuccessResponse<IDetailTimesheetResponseData>> {
  try {
    return apiV2.get(`${TIMESHEET_BASE_URL}/detail/${timesheetId}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function deleteTimesheetV2(timesheetId: string): Promise<void> {
  try {
    return apiV2.post(`${TIMESHEET_BASE_URL}/delete/${timesheetId}`)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function editTimesheetV2(
  timesheetId: string,
  payload: ITimesheetRequest
): Promise<ISuccessResponse<ICreateEditTimesheetResponse> | unknown> {
  try {
    const url = `${TIMESHEET_BASE_URL}/edit-timesheet/${timesheetId}`
    return apiV2.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function createTimeSheetV2(
  payload: Partial<ITimesheet>
): Promise<ISuccessResponse<ICreateEditTimesheetResponse> | unknown> {
  try {
    const url = `${TIMESHEET_BASE_URL}/create-timesheet`
    return apiV2.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
