import { chakra, Flex } from '@chakra-ui/react'

export const ModalFlexForm = chakra(Flex, {
  baseStyle: () => ({
    direction: { base: 'column-reverse', md: 'row' },
    mt: '6',
    align: 'center',
    justify: 'space-between',
  }),
})
