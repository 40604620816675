import { SimpleGrid, Wrap } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import { useFormContext, useWatch } from 'react-hook-form'
import { generatePath, useHistory } from 'react-router-dom'
import { THistory } from 'types/common'
import { getValidArray } from 'utils'
import { IOption } from 'constants/common'
import { EYesNoOption } from 'constants/enum'
import { RoutesConstants } from 'constants/index'
import { MobileTag } from 'containers/UserNew/UserDetail/userDetail.styles'
import { EUserDetailTitle, IUserProfile } from '../../../../constant'
import FormView from '../../../FormView'
import Section from '../../../Section'

interface IDetailFormMobileProps {
  name: string
}

const DetailFormMobile = (props: IDetailFormMobileProps) => {
  const { name } = props
  const { control } = useFormContext()
  const profileDetail: IUserProfile = useWatch({ control, name })
  const history: THistory = useHistory()

  function gotoProjectDetail(id: string): void {
    history.push(generatePath(RoutesConstants.PROJECT_ADMIN_DETAIL, { id }))
  }

  return (
    <Section title={EUserDetailTitle.USER_PROFILE_DETAIL}>
      <FormView label="Partner" value={profileDetail?.partnerId} textColor="blue.500" />
      <FormView label="Full Name" value={profileDetail?.fullName} />
      <FormView label="Email" value={profileDetail?.email} textColor="blue.500" />
      <FormView label="Phone Number" value={profileDetail?.phone} />
      <FormView label="Bank Name" value={profileDetail?.bankName} />
      <FormView label="Bank Account" value={profileDetail?.bankAccount} />
      <FormView label="Gender" value={profileDetail?.gender} />
      <FormView label="Place of Birth" value={profileDetail?.placeOfBirth} />
      <FormView label="Date of Birth" value={profileDetail?.dateOfBirth} />
      <FormView label="Permanent Address" value={profileDetail?.address} />
      <FormView label="Nationality" value={profileDetail?.nationality} />
      <FormView label="ID Information" value={profileDetail?.IDInformation} />
      <FormView label="Education" value={profileDetail?.education} />
      <FormView label="Major" value={profileDetail?.major} />
      <SimpleGrid columns={2} gap={4} width="full">
        <FormView label="Join Date" value={profileDetail?.joinDate} />
        <FormView label="Role" value={capitalize(profileDetail?.role)} />
        <FormView label="Title" value={profileDetail?.titleId} />
        <FormView label="Level" value={profileDetail?.levelId} />
        <FormView label="Default Rate" value={profileDetail?.defaultRating} />
        <FormView label="Payment Category" value={profileDetail?.paymentCategoryId} />
        <FormView label="Is Supervisor" value={profileDetail?.isSupervisor} />
        <FormView label="Net Salary (VND)" value={profileDetail?.fixedSalary} />
      </SimpleGrid>
      {/* TODO: integrate for technology later
        <FormView label="Technology">
          <Wrap>
            {getValidArray(fakeTechnologyData).map((technology: IOption) => {
              return (
                <Tag color={handleTextColor(technology?.label)} background={handleBackgroundColor(technology?.label)}>
                  {technology?.label}
                </Tag>
              )
            })}
          </Wrap>
        </FormView> */}
      {profileDetail?.isSupervisor?.toLowerCase() === EYesNoOption.YES && (
        <FormView label="Projects Of Supervisor">
          <Wrap>
            {getValidArray(profileDetail?.projectsOfSupervisor).map((project: IOption) => {
              return (
                <MobileTag
                  key={project?.value}
                  color="gray.500"
                  background="gray.50"
                  onClick={() => gotoProjectDetail(project?.value)}>
                  {project?.label}
                </MobileTag>
              )
            })}
          </Wrap>
        </FormView>
      )}
      <FormView label="Projects">
        <Wrap>
          {getValidArray(profileDetail?.projects).map((project: IOption) => {
            return (
              <MobileTag
                key={project?.value}
                color="gray.500"
                background="gray.50"
                onClick={() => gotoProjectDetail(project?.value)}>
                {project?.label}
              </MobileTag>
            )
          })}
        </Wrap>
      </FormView>
      <FormView label="Extra Projects">
        <Wrap>
          {getValidArray(profileDetail?.extraProjects).map((project: IOption) => {
            return (
              <MobileTag
                key={project?.value}
                color="gray.500"
                background="gray.50"
                onClick={() => gotoProjectDetail(project?.value)}>
                {project?.label}
              </MobileTag>
            )
          })}
        </Wrap>
      </FormView>
      <FormView label="External Projects">
        <Wrap>
          {getValidArray(profileDetail?.externalProjects).map((project: IOption) => {
            return (
              <MobileTag
                key={project?.value}
                color="gray.500"
                background="gray.50"
                onClick={() => gotoProjectDetail(project?.value)}>
                {project?.label}
              </MobileTag>
            )
          })}
        </Wrap>
      </FormView>
    </Section>
  )
}

export default DetailFormMobile
