import { ChangeEvent, useRef, useState, useEffect } from 'react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { VStack, Image, Input, HStack } from '@chakra-ui/react'
import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { uploadImage } from 'API/upload'
import { IUploadImageResponse } from 'API/upload/constants'
import CheckboxFieldWithLabel from 'componentsCharka/CheckboxFieldWithLabel'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import Helper from 'Helper'
import { useFormContext, useWatch } from 'react-hook-form'
import { FaFileUpload } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { ILogoAndSettingSectionProps, ESectionHeaderValues } from '../../constants'
import { SectionHeader, SectionWrapper } from '../../projectDetailAdmin.styles'
import { LogoActionButton } from './logoAndSetting.styles'

const LogoAndSettingSection = (props: ILogoAndSettingSectionProps) => {
  const { type, name, isEditing } = props
  const { control, register, setValue } = useFormContext()
  const logoRef = useRef<HTMLInputElement | null>(null)
  const [selectedImage, selectImage] = useState<File | null>(null)
  const [preview, setPreview] = useState<string>('')
  const [logoURL, setLogoURL] = useState<string | null>(null)
  const logoURLForm: string | undefined = useWatch({ name, control })
  const fullLogoURL: string = Helper.generateImageURL(logoURLForm, 'logo')

  function handleChooseLogo(): void {
    logoRef?.current?.click()
  }

  function handleClearLogo(): void {
    selectImage(null)
    setLogoURL(null)
  }

  async function handleChangeLogo(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    if (!!event?.target?.files?.[0]) {
      !!event?.target?.files?.[0] && selectImage(event?.target?.files?.[0])
    }
    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('myImage', file)
    try {
      const response: ISuccessResponse<IUploadImageResponse> = await uploadImage(formData, type)
      const filename: string = response?.data?.fileName ?? ''
      setValue(name, filename)
      toast.success('Upload image successfully')
    } catch (error) {
      toast.error('Upload image failed')
      handleError(
        error as Error,
        'src/containers/ProjectsNew/V2/ProjectDetailAdminMobile/components/LogoAndSettingSection/index.tsx',
        'handleChangeLogo'
      )
    }
  }

  useEffect(() => {
    setLogoURL(fullLogoURL)
  }, [fullLogoURL])

  useEffect(() => {
    if (!selectedImage) {
      setPreview('')
      return
    }

    const objectUrl = URL.createObjectURL(selectedImage)
    setPreview(objectUrl)

    //* INFO: disable unnecessary eslint rule eslint-disable-next-line consistent-return
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedImage])

  return (
    <>
      <SectionWrapper>
        <SectionHeader>{ESectionHeaderValues.LOGO}</SectionHeader>
        {logoURL ? (
          <Image src={preview.length === 0 ? logoURL : preview} maxWidth="100%" maxHeight="100%" borderRadius={2} />
        ) : (
          <EmptyContentBox text="No Photo" />
        )}
        {isEditing && (
          <HStack justifyContent="flex-end" width="100%">
            <LogoActionButton onClick={handleChooseLogo}>
              <FaFileUpload fontSize="16px" color="gray.700" />
            </LogoActionButton>
            <Input
              {...register('file')}
              type="file"
              ref={logoRef}
              accept="image/*"
              display="none"
              onChange={handleChangeLogo}
            />
            {(logoURL || preview) && (
              <LogoActionButton onClick={handleClearLogo}>
                <SmallCloseIcon fontSize="16px" />
              </LogoActionButton>
            )}
          </HStack>
        )}
      </SectionWrapper>
      <SectionWrapper alignItems="flex-start" spacing={4}>
        <SectionHeader>{ESectionHeaderValues.SETTINGS}</SectionHeader>
        <VStack alignItems="flex-start" spacing={4}>
          <CheckboxFieldWithLabel name="isPastLock" label="Past Lock" isDisabled={!isEditing} />
          <CheckboxFieldWithLabel name="requiredTag" label="Require Tags" isDisabled={!isEditing} />
          <CheckboxFieldWithLabel name="canOverlap" label="Can Overlap" isDisabled={!isEditing} />
          <CheckboxFieldWithLabel
            name="isTimesheetSupervisorDailyReminderEnabled"
            label="Timesheet Supervisor Daily Reminder"
            isDisabled={!isEditing}
          />
        </VStack>
      </SectionWrapper>
    </>
  )
}

export default LogoAndSettingSection
