import React from 'react'
import Email from '@material-ui/icons/Email'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import Helper from '../../../../Helper'

function formatRow(index) {
  let result = 'even'
  if (index % 2 === 0) {
    result = 'odd'
  }
  return result
}
class CommitTimeTable extends React.Component {
  renderProjectName = (item) => {
    let className = 'user'
    let taskName = <p>{item.projectName}</p>
    let updatedInfo
    if (item.isAdminUpdated) {
      className = 'admin'
    }
    if (item.isActive && item.isAdminUpdated && item.updatedBy) {
      updatedInfo = <p className={`editted-info ${className}`}>{`Edited by ${item.updatedBy}`}</p>
    }
    return (
      <td className="project-name">
        {taskName}
        {updatedInfo}
      </td>
    )
  }

  renderStatus = (item) => {
    let className = 'status'
    let text = ''
    if (item.isActive) {
      className += ' active'
      text = 'Actived'
    } else {
      className += ' disable'
      text = 'Disabled'
    }
    return <td className={className}>{text}</td>
  }

  render() {
    const { data, sendRequestHandler } = this.props
    const sortedData = Helper.sortCommitTimeList(data)
    return (
      <div className="table-content">
        <table className="report-table">
          <thead>
            <tr>
              <th>Project</th>
              <th>Commit Time Per Week (Hours)</th>
              <th>Status</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {sortedData &&
              sortedData.map((item, index) => (
                <tr key={item._id} className={formatRow(index)}>
                  {this.renderProjectName(item)}
                  <td>{Helper.getFormattedNumber(item.commitTime, 1)}</td>
                  {this.renderStatus(item)}
                  <td className="options">
                    <Tooltip title={'Request Change'}>
                      <IconButton
                        data-cy={`cancel`}
                        aria-label={'Request Change'}
                        onClick={() => sendRequestHandler(item._id)}>
                        <Email />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default CommitTimeTable
