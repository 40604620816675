import { ITableHeader } from 'components/Table'

export function getHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Full name',
      accessor: 'fullName',
    },
    {
      Header: 'Birthday (DD/MM/YYYY)',
      accessor: 'birthday',
    },
  ]

  return headers
}
