import React from 'react'
import MultiRef from 'react-multi-ref'
import omit from 'lodash/omit'
import * as Yup from 'yup'
import FormModel from '../../../components/FormModel'
import PolicyDetail from './components/PolicyDetail'
import { AppConfig, Messages, EventName, RoutesConstants } from '../../../constants'
import { getPolicyListAdmin, updatePolicy, deletePolicy } from 'API/policy'
import './index.scss'
import { handleError } from 'API/error'
import { toast } from 'react-toastify'

const validationSchema = Yup.object().shape({
  title: Yup.string().required(Messages.fieldRequired),
  content: Yup.string()
    .required(Messages.fieldRequired)
    .max(AppConfig.LIMIT_CHARACTERS_OF_STRING, Messages.exceedCharacterOfString),
  publishedDate: Yup.date().required(Messages.fieldRequired),
})
class PolicyList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      policies: [],
      selectedPolicy: null,
    }
    this.formik = React.createRef()
  }
  _actRefs = new MultiRef()
  componentDidMount() {
    this.getListPolicyHandler()
  }
  getListPolicyHandler = async () => {
    try {
      const { policies } = await getPolicyListAdmin()
      this.setState({ policies })
    } catch (error) {
      handleError(error, 'src/containers/Policy/PolicyListAdmin/index.js', 'getListPolicyHandler')
    }
  }
  handleToggleActiveEdit = (id) => {
    this.setState({
      selectedPolicy: id,
    })
  }

  linkToCreatePolicy = () => {
    const { history } = this.props
    history.push(RoutesConstants.POLICY_NEW)
  }
  editPolicyHandler = async (values) => {
    try {
      const policyId = values?._id
      const publishedDate = new Date(values?.publishedDate)
      const payload = {
        ...omit(values, ['_id', 'createdAt', 'updatedAt']),
        publishedDate,
      }
      await updatePolicy(payload, policyId)
      toast.success(Messages.updatePolicySuccess)
      this.handleToggleActiveEdit(null)
      await this.getListPolicyHandler()
    } catch (error) {
      toast.error('Update policy failed')
      handleError(error, 'src/containers/Policy/PolicyListAdmin/index.js', 'editPolicyHandler')
    }
  }
  handleClickOKOfDialogConfirm = async (id) => {
    try {
      await deletePolicy(id)
      this.props.handleCloseConfirmDialogYesNo()
      toast.success(Messages.deletePolicySuccess)
      await this.getListPolicyHandler()
    } catch (error) {
      toast.error('Delete policy failed')
      handleError(error, 'src/containers/Policy/PolicyListAdmin/index.js', 'handleClickOKOfDialogConfirm')
    }
  }
  callOpenDialogConfirm = (data) => {
    document.dispatchEvent(
      new CustomEvent(EventName.openDeletePolicyConfirmDialogYesNo, {
        detail: {
          isOpen: true,
          OKClickHandler: this.handleClickOKOfDialogConfirm,
          item: data,
        },
      })
    )
  }
  deletePolicyHandler = (policy) => {
    this.callOpenDialogConfirm(policy)
  }
  resetFormWhenPressCancel = (id) => {
    this._actRefs.map.get(id).resetForm()
    this.setState({
      selectedPolicy: null,
    })
  }
  render() {
    const { policies, selectedPolicy } = this.state
    return (
      <div className="container policy-list-page">
        <div>
          <div className="options-sub">
            <div className="create-new-project">
              <button className="primary-button" onClick={this.linkToCreatePolicy}>
                Create New Policy
              </button>
            </div>
          </div>
          <div className="policy-list">
            {policies &&
              policies.map((item) => {
                const viewModePolicyDetail = selectedPolicy === item._id ? 'edit' : 'show'
                return (
                  <div className="project-detail-sub" key={item._id}>
                    <FormModel
                      refComponent={this._actRefs.ref(item._id)}
                      initialValues={item}
                      handleSubmit={this.editPolicyHandler}
                      schema={validationSchema}
                      component={
                        <PolicyDetail
                          viewMode={viewModePolicyDetail}
                          cancelForm={this.resetFormWhenPressCancel}
                          handleToggleActiveEdit={this.handleToggleActiveEdit}
                          handleDelete={this.deletePolicyHandler}
                        />
                      }
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}

export default PolicyList
