import { RoutesConstants } from '../../constants'
// Dashboard
import Dashboard from '../Dashboard'
import DashboardUser from '../DashboardUser'
// Partner
import PartnerList from '../Partner/PartnerList'
// import PartnerCreate from '../Partner/PartnerForm'
import PartnerCreate from '../Partner/PartnerCreate'
import PartnerDetail from '../Partner/PartnerDetail'
// Internal Document
import InternalDocumentCreate from '../InternalDocument/InternalDocumentForm'
import InternalDocumentList from '../InternalDocument/InternalDocumentList'
// Guideline
import Guideline from '../Guideline'
// Contract
import Contract from '../Contract'
import ContractDetail from '../Contract/Detail'
// User
import UserNewCreate from '../UserNew/UserCreate'
import CurriculumViateDetail from '../UserNew/UserCV'
import UserNewDetail from '../UserNew/UserDetail'
import UserNewList from '../UserNew/UserList'
// Profile
import Profile from '../ProfileNew/ProfileDetail'
//ProjectNew
import ProjectNewCreate from '../ProjectsNew/ProjectCreate'
import ProjectDetail from '../ProjectsNew/ProjectDetail'
import ProjectDetailAdmin from '../ProjectsNew/ProjectDetailAdmin'
import ProjectsList from '../ProjectsNew/ProjectList'
import ProjectsNewListAdmin from '../ProjectsNew/ProjectsListAdmin'
// Policy
import PolicyCreate from '../Policy/PolicyCreate'
import PolicyList from '../Policy/PolicyList'
import PolicyListAdmin from '../Policy/PolicyListAdmin'
// Announcement
import InfosCreate from '../Information/InformationCreate'
import Infors from '../Information/InformationList'
import InfosAdmin from '../Information/InformationListAdmin'
// Timesheet
import TimeSheetAdmin from '../TimeSheet/TimeSheetAdmin'
import TimeSheetUser from '../TimeSheet/TimeSheetUser'
// V2 Timesheet
import V2TimeSheetUser from '../v2/TimeSheet/TimeSheetUser'
// Layout Timesheet
import TimesheetLayout from 'containers/v2/TimeSheet/Layout'

// Feedback
import FeedbackCreate from '../Feedback/FeedbackCreate'
import FeedbackList from '../Feedback/FeedbackList'
import FeedbackListAdmin from '../Feedback/FeedbackListAdmin'
// Setting
import Settings from '../Settings'
// Commit Time
import CommitTime from '../CommitTime/CommitTime'
import CommitTimeAdmin from '../CommitTime/CommitTimeAdmin'
// Payment
import Payment from '../Payment/Payment'
import PaymentAdmin from '../Payment/PaymentAdmin'
// UnderConstruction
import UnderConstruction from '../UnderConstruction'
// Birthday
import Birthday from '../BirthdayOfStaff'
// Accounting
import Accounting from '../Accounting'
import IncomeCreate from '../Accounting/Income/IncomeCreate'
import IncomeDetail from '../Accounting/Income/IncomeDetail'
import IncomeList from '../Accounting/Income/IncomeList'
import Diagram from '../Diagram'
// Notification
//*TODO: Turn on when integrate for notification page
// import NotificationPage from '../Notification'
import QRCodePage from '../QRCode'

const Routes = {
  admin: [
    {
      key: 'dashboard',
      path: RoutesConstants.DASHBOARD,
      exact: true,
      component: Dashboard,
    },
    {
      key: 'dashboardUserDetail',
      path: RoutesConstants.USER_DASHBOARD_DETAIL,
      exact: true,
      component: DashboardUser,
    },
    {
      key: 'diagram',
      path: RoutesConstants.DIAGRAM,
      exact: true,
      component: Diagram,
    },
    //*TODO: Turn on when integrate for notification page
    // {
    //   key: 'notification',
    //   path: RoutesConstants.NOTIFICATION,
    //   exact: true,
    //   component: NotificationPage
    // },
    {
      key: 'payments',
      path: RoutesConstants.PAYMENTS_ADMIN,
      exact: true,
      component: PaymentAdmin,
    },
    {
      key: 'guideline',
      path: RoutesConstants.GUIDELINE,
      exact: true,
      component: Guideline,
    },
    // Birthday
    {
      key: 'birthday',
      path: RoutesConstants.BIRTHDAY,
      exact: true,
      component: Birthday,
    },
    {
      key: 'contract',
      path: RoutesConstants.CONTRACT,
      exact: true,
      component: Contract,
    },
    {
      key: 'contractDetail',
      path: RoutesConstants.CONTRACT_DETAIL,
      exact: true,
      component: ContractDetail,
    },
    {
      key: 'usersNewList',
      path: RoutesConstants.USER_LIST,
      exact: true,
      component: UserNewList,
    },
    {
      key: 'usersNewCreate',
      path: RoutesConstants.USER_CREATE,
      exact: true,
      component: UserNewCreate,
    },
    {
      key: 'usersNewEdit',
      path: RoutesConstants.USER_DETAIL,
      exact: true,
      component: UserNewDetail,
    },
    {
      key: 'usersCVDetail',
      path: RoutesConstants.USER_CV,
      exact: true,
      component: CurriculumViateDetail,
    },
    {
      key: 'profileNew',
      path: RoutesConstants.PROFILE,
      exact: true,
      component: Profile,
    },
    //ProjectNew
    {
      key: 'projectsNewCreate',
      path: RoutesConstants.PROJECT_ADMIN_CREATE,
      exact: true,
      component: ProjectNewCreate,
    },
    {
      key: 'projectsNewAdmin',
      path: RoutesConstants.PROJECTS_ADMIN_LIST,
      exact: true,
      component: ProjectsNewListAdmin,
    },
    {
      key: 'projectsNewDetail',
      path: RoutesConstants.PROJECT_ADMIN_DETAIL,
      exact: true,
      component: ProjectDetailAdmin,
    },
    {
      key: 'timesheet',
      path: RoutesConstants.TIMESHEET_ADMIN,
      exact: true,
      component: TimesheetLayout,
    },
    // *TODO: used to temporarily test with admin account. need to confirm about YourTimesheet of Admin
    {
      key: 'v2YourTimesheet',
      path: RoutesConstants.V2_TIMESHEET,
      exact: true,
      component: TimesheetLayout,
    },
    {
      key: 'timesheetAdminOverview',
      path: RoutesConstants.TIMESHEET_ADMIN_OVERVIEW,
      exact: true,
      component: TimesheetLayout,
    },
    {
      key: 'policyCreate',
      path: RoutesConstants.POLICY_NEW,
      exact: true,
      component: PolicyCreate,
    },
    {
      key: 'policyAdmin',
      path: RoutesConstants.POLICIES_ADMIN,
      exact: true,
      component: PolicyListAdmin,
    },
    {
      key: 'infoAdmin',
      path: RoutesConstants.INFOS_ADMIN,
      exact: true,
      component: InfosAdmin,
    },
    {
      key: 'infoCreate',
      path: RoutesConstants.INFOS_NEW,
      exact: true,
      component: InfosCreate,
    },
    {
      key: 'settings',
      path: RoutesConstants.SETTINGS,
      exact: true,
      component: Settings,
    },
    {
      key: 'feedback',
      path: RoutesConstants.FEEDBACK_ADMIN,
      exact: true,
      component: FeedbackListAdmin,
    },
    {
      key: 'commit-time-admin',
      path: RoutesConstants.COMMIT_TIME_ADMIN,
      exact: true,
      component: CommitTimeAdmin,
    },
    {
      key: 'activity',
      path: RoutesConstants.ACTIVITY,
      exact: true,
      component: UnderConstruction,
    },
    {
      key: 'partner',
      path: RoutesConstants.PARTNER,
      exact: true,
      component: PartnerList,
    },
    {
      key: 'partner',
      path: RoutesConstants.PARTNER_NEW,
      exact: true,
      component: PartnerCreate,
    },
    {
      key: 'partner',
      path: RoutesConstants.PARTNER_DETAIL,
      exact: true,
      component: PartnerDetail,
    },
    {
      key: 'internalDocument',
      path: RoutesConstants.INTERNAL_DOCUMENT,
      exact: true,
      component: InternalDocumentList,
    },
    {
      key: 'internalDocument',
      path: RoutesConstants.INTERNAL_DOCUMENT_NEW,
      exact: true,
      component: InternalDocumentCreate,
    },
    {
      key: 'internalDocument',
      path: RoutesConstants.INTERNAL_DOCUMENT_DETAIL,
      exact: true,
      component: InternalDocumentCreate,
    },
    {
      key: 'payment',
      path: RoutesConstants.PAYMENTS_ADMIN,
      exact: true,
      component: PaymentAdmin,
    },
    {
      key: 'accounting',
      path: RoutesConstants.ACCOUNTING,
      exact: true,
      component: Accounting,
    },
    {
      key: 'accounting_income_list',
      path: RoutesConstants.ACCOUNTING_INCOME_LIST,
      exact: true,
      component: IncomeList,
    },
    {
      key: 'accounting_income_create',
      path: RoutesConstants.ACCOUNTING_INCOME_CREATE,
      exact: true,
      component: IncomeCreate,
    },
    {
      key: 'accounting_income_detail',
      path: RoutesConstants.ACCOUNTING_INCOME_DETAIL,
      exact: true,
      component: IncomeDetail,
    },
    {
      key: 'qrcode',
      path: RoutesConstants.QR_CODE,
      exact: true,
      component: QRCodePage,
    },
  ],
  user: [
    {
      key: 'guideline',
      path: RoutesConstants.GUIDELINE,
      exact: true,
      component: Guideline,
    },
    //*TODO: Turn on when integrate for notification page
    // {
    //   key: 'notification',
    //   path: RoutesConstants.NOTIFICATION,
    //   exact: true,
    //   component: NotificationPage,
    // },
    {
      key: 'payments',
      path: RoutesConstants.PAYMENTS,
      exact: true,
      component: Payment,
    },
    {
      key: 'projectsNewDetail',
      path: RoutesConstants.PROJECT_DETAIL,
      exact: true,
      component: ProjectDetail,
    },
    {
      key: 'projectsNewList',
      path: RoutesConstants.PROJECT_LIST,
      exact: true,
      component: ProjectsList,
    },
    {
      key: 'timesheet',
      path: RoutesConstants.TIMESHEET,
      exact: true,
      component: TimesheetLayout,
    },
    {
      key: 'v2Timesheet',
      path: RoutesConstants.V2_TIMESHEET,
      exact: true,
      component: TimesheetLayout,
    },
    {
      key: 'dashboardUser',
      path: RoutesConstants.USER_DASHBOARD,
      exact: true,
      component: DashboardUser,
    },
    {
      key: 'dashboardUserDetail',
      path: RoutesConstants.USER_DASHBOARD_DETAIL,
      exact: true,
      component: DashboardUser,
    },
    {
      key: 'policy',
      path: RoutesConstants.POLICIES,
      exact: true,
      component: PolicyList,
    },
    {
      key: 'info',
      path: RoutesConstants.INFOS,
      exact: true,
      component: Infors,
    },
    {
      key: 'profileNew',
      path: RoutesConstants.PROFILE,
      exact: true,
      component: Profile,
    },
    {
      key: 'feedbackCreate',
      path: RoutesConstants.FEEDBACK_NEW,
      exact: true,
      component: FeedbackCreate,
    },
    {
      key: 'feedback',
      path: RoutesConstants.FEEDBACK,
      exact: true,
      component: FeedbackList,
    },
    {
      key: 'commit-time',
      path: RoutesConstants.COMMIT_TIME,
      exact: true,
      component: CommitTime,
    },
  ],
}

export default Routes
