import styled from '@emotion/styled'
import IconWithText from 'componentsCharka/IconWithText'

export const IconWithTextWrapper = styled(IconWithText)`
  width: 100%;

  .icon {
    opacity: 0.6;
  }

  .active-icon {
    opacity: 1;
  }

  div > span {
    width: 16px !important;
    height: 16px !important;
  }
`
