/* eslint-disable max-lines */
import { ISuccessResponse } from 'API/constants'
import { getListCreatableOptions } from 'API/creatableOption'
import { ICreatableOptionListWithFilterResponse } from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import { getPartners } from 'API/partner'
import { IPartnerListResponse } from 'API/partner/constants'
import { getProjectList } from 'API/project'
import { IProjectListResponse } from 'API/project/constants'
import {
  getHourlyProjectRatingAdmin,
  getFixedProjectRatingAdmin,
  updateHourlyProjectRating,
  updateFixedProjectRating,
} from 'API/projectRating'
import {
  IHourlyProjectRatingList,
  IFixedProjectRatingList,
  IUpdateHourlyPayload,
  IUpdateFixedPayload,
} from 'API/projectRating/constants'
import {
  createUser,
  deleteUser,
  archivedUser,
  unArchivedUser,
  getUserDetail,
  updateUserDetail,
  updateUserProjectRating,
  getBirthdayList,
  getUserList,
} from 'API/user'
import {
  IBirthdayFilter,
  IBirthdayList,
  ICreateUserRequest,
  IUserDetail,
  IUserListResponse,
  IUserProfileResponse,
} from 'API/user/constants'
import Helper from 'Helper'
import ApiHelper from 'Helper/api'
import delay from 'lodash/delay'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { IProject } from 'types/project'
import { IUser, IBirthday } from 'types/user'
import { getParsedUserValueBeforeSendBE, getParsedUserValueBeforeRenderFE, getValidArray } from 'utils'
import { IOption } from 'constants/common'
import { ECreatableOptionScope } from 'constants/enum'
import { Messages } from 'constants/index'
import { IHourlyProjectRating, IFixedProjectRating } from 'constants/schema'
import { IPartner, ICreatableOption } from 'constants/schema'
import { trackIdentify } from 'utils/tracking'
import RootStore from '../rootStore'

class AdminUserFormStore {
  rootStore: RootStore
  userList: IUser[]
  projectList: IProject[] = []
  partnerList: IPartner[] = []
  creatableOptionList: ICreatableOption[] = []
  userDetail: IUserDetail
  cvFileName: string
  bithdayList: IBirthday[]

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async createUser(data: IUser): Promise<void> {
    try {
      const parseValues: ICreateUserRequest = getParsedUserValueBeforeSendBE(data)
      if (!parseValues) {
        return
      }
      await createUser(parseValues)
      toast.success(Messages.createUserSuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'createUser')
    }
  }

  public async deleteUser(userId: string) {
    try {
      await deleteUser(userId)
      toast.success(Messages.deleteUserSuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'deleteUser')
    }
  }

  public async archivedUser(userId: string) {
    try {
      await archivedUser(userId)
      toast.success(Messages.archivedUserSuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'archivedUser')
    }
  }

  public async unArchivedUser(userId: string) {
    try {
      await unArchivedUser(userId)
      toast.success(Messages.unArchivedUserSuccess)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'unArchivedUser')
    }
  }

  public async getBirthdayList(filter?: { category: IOption; month: IOption }): Promise<void> {
    try {
      let category = 'all'
      let month
      if (filter) {
        category = filter?.category?.value || 'all'
        month = Number(filter?.month?.value)
      }
      const data: IBirthdayFilter = {
        category,
        month,
      }
      const response: IBirthdayList = await getBirthdayList(data)
      this.bithdayList = response?.birthdayList
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getBirthdayList')
    }
  }

  public async updateUser(data: IUserDetail, userId: string) {
    const profileDetailData = data.profileDetailData
    const projectRatings = data.projectRatings
    try {
      if (projectRatings) {
        const { hourlyProjectRatings, fixedProjectRatings } = projectRatings
        if (hourlyProjectRatings && hourlyProjectRatings.hourlyRatingPrices) {
          const hourlyRatingPrices = hourlyProjectRatings.hourlyRatingPrices
          const formattedHourlyRatingPrices = getValidArray(hourlyRatingPrices).map((hourlyRate) => {
            return {
              ...hourlyRate,
              project: get(hourlyRate, 'project.value', ''),
            }
          })
          const formattedData = {
            hourlyRatingPrices: formattedHourlyRatingPrices,
          }
          this.editHourlyRatePrice(userId, formattedData)
        }
        if (fixedProjectRatings && fixedProjectRatings.fixedRatingPrices) {
          const fixedRatingPrices = fixedProjectRatings.fixedRatingPrices
          const formattedFixedRatingPrices = getValidArray(fixedRatingPrices).map((fixedRate) => {
            return {
              ...fixedRate,
              project: get(fixedRate, 'project.value', ''),
            }
          })
          const formattedFixedData = {
            fixedRatingPrices: formattedFixedRatingPrices,
          }
          this.editFixedRatePrice(userId, formattedFixedData)
        }
      }
      if (profileDetailData) {
        const parsedProfileDetailData = getParsedUserValueBeforeSendBE(profileDetailData)
        const data = {
          updatedUserData: parsedProfileDetailData,
          isUpdateProjectRatings: false,
        }
        await updateUserDetail(userId, data)
        toast.success(Messages.updateUserSuccess)
      }
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'updateUser')
    }
  }

  public async getUsersList(): Promise<void> {
    try {
      const response: ISuccessResponse<IUserListResponse> = await getUserList()
      this.userList = response?.data?.users as IUser[]
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getUsersList')
    }
  }

  public async getProjectList(callback?: Function): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectListResponse> = await getProjectList()
      this.projectList = response?.data?.projects
      if (callback && typeof callback === 'function') {
        callback()
      }
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getProjectList')
    }
  }

  public async getPartnerList(): Promise<void> {
    try {
      const response: ISuccessResponse<IPartnerListResponse> = await getPartners({
        filter: { isDeleted: false, isArchived: false },
      })
      this.partnerList = response?.data?.partners
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getPartnerList')
    }
  }

  public async getOptionList(): Promise<void> {
    try {
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptions({
        filter: { scope: ECreatableOptionScope.USER },
      })
      this.creatableOptionList = response?.data?.creatableOptions
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getOptionList')
    }
  }

  public getUserDetail = async (userId: string, isEdit: boolean) => {
    await this.getUserProfileDetail(isEdit, userId)
  }

  public async updateProjectRatings(userId: string, newProjects: string[], projectsNotAdded: string[]) {
    try {
      const data = {
        newProjects,
        projectsNotAdded,
      }
      // *TODO: fix asynchronous for updateUserProjectRating API and then remove delay time
      await updateUserProjectRating(userId, data)
      delay(() => {
        this.getUserProfileDetail(true, userId)
      }, 500)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'updateProjectRatings')
    }
  }

  private async getHourlyRatePriceData(userId: string) {
    const hourlyRatingData: IHourlyProjectRatingList = await getHourlyProjectRatingAdmin(userId)
    const hourlyProjectRatingsData: IHourlyProjectRating[] = hourlyRatingData?.hourlyRatingPriceData?.hourlyRatingPrices
    const formattedHourlyProjectRatingsData: IHourlyProjectRating[] = getValidArray(hourlyProjectRatingsData).map(
      (hourlyProjectRatingData: IHourlyProjectRating) => {
        const projectData = this.projectList.find((item) => item._id === hourlyProjectRatingData.project)
        return {
          ...hourlyProjectRatingData,
          applyDate: Helper.getFormattedDateWith_YYYYMMDD(hourlyProjectRatingData?.applyDate),
          project: projectData ? { value: projectData._id, label: projectData.name } : {},
        }
      }
    )
    return {
      hourlyRatingPrices: formattedHourlyProjectRatingsData,
    }
  }

  private async getFixedRatePriceData(userId: string) {
    const fixedRatingData: IFixedProjectRatingList = await getFixedProjectRatingAdmin(userId)
    const fixedProjectRatingsData: IFixedProjectRating[] = fixedRatingData?.fixedRatingPriceData?.fixedRatingPrices
    const formattedFixedProjectRatingsData: IFixedProjectRating[] = getValidArray(fixedProjectRatingsData).map(
      (fixedProjectRatingData: IFixedProjectRating) => {
        const projectData = this.projectList.find((item) => item._id === fixedProjectRatingData.project)
        return {
          ...fixedProjectRatingData,
          applyDate: Helper.getFormattedDateWith_YYYYMMDD(fixedProjectRatingData?.applyDate),
          project: projectData ? { value: projectData._id, label: projectData.name } : {},
        }
      }
    )
    return {
      fixedRatingPrices: formattedFixedProjectRatingsData,
    }
  }

  private getUserProfileDetail = async (isEdit: boolean, userId: string) => {
    try {
      const [hourlyProjectRatings, fixedProjectRatings] = await Promise.all([
        this.getHourlyRatePriceData(userId),
        this.getFixedRatePriceData(userId),
      ])

      const response: IUserProfileResponse = await getUserDetail(userId)
      const currentUserData: IUser = response?.user
      const currentUser: IUserDetail = getParsedUserValueBeforeRenderFE(currentUserData, this.projectList, isEdit)

      this.userDetail = {
        ...currentUser,
        projectRatings: {
          hourlyProjectRatings,
          fixedProjectRatings,
        },
      }
      trackIdentify(userId, this.userDetail?.profileDetailData?.email, this.userDetail?.profileDetailData?.fullName)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'getUserProfileDetail')
    }
  }

  private async editHourlyRatePrice(userId: string, payload: IUpdateHourlyPayload) {
    try {
      await updateHourlyProjectRating(userId, payload)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'editHourlyRatePrice')
    }
  }

  private async editFixedRatePrice(userId: string, payload: IUpdateFixedPayload) {
    try {
      await updateFixedProjectRating(userId, payload)
    } catch (error) {
      handleError(error, 'src/store/admin/userStore.ts', 'editFixedRatePrice')
    }
  }

  public getCVNameByUserId = (userId: string) => {
    ApiHelper.post({ url: `/users/detail/cv/${userId}` }, (response) => {
      const data = response.data
      this.cvFileName = data.data?.cv
    })
  }
}

export default AdminUserFormStore
