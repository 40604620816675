import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'

const styles = {
  inlineli: {
    marginRight: '1.5rem',
    height: '0.7rem',
    width: '0.7rem',
  },
  inlinetoolbutton: {
    height: '35px',
    width: '35px',
    marginTop: '5px',
  },
  inlineIcon: {
    marginTop: '-7px',
  },
  inlineActiveIcon: {
    marginTop: '-7px',
    color: '#2D3748',
  },
}

class Item extends React.Component {
  render() {
    const { index, data, editClickHandler, deleteClickHandler } = this.props
    return (
      <div className="item rate-exchange">
        <div className="item-content">
          <span>{index + 1}.</span>
          <p className="highline">{data.label}</p>
          <p className="value">{data.value}</p>
        </div>
        <div className="item-options">
          <Tooltip title="Edit">
            <IconButton data-cy={`edit`} aria-label="Edit" style={styles.inlinetoolbutton} onClick={editClickHandler}>
              <Edit style={styles.inlineIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              data-cy={`edit`}
              aria-label="Delete"
              style={styles.inlinetoolbutton}
              onClick={deleteClickHandler}>
              <Delete style={styles.inlineIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }
}

export default Item
