import { Text, VStack } from '@chakra-ui/react'
import styles from './titleLogin.module.scss'

interface ITitleLoginProps {
  title: string
  subtitle: string
}

export const TitleLogin = (props: ITitleLoginProps) => {
  const { title, subtitle } = props
  return (
    <VStack spacing={{ base: '4', md: '16' }}>
      <Text fontSize={{ base: 'xl', md: '4xl' }} className={styles.title}>
        {title}
      </Text>
      <Text fontSize={{ base: 'md', md: 'md' }} className={styles.subtitle}>
        {subtitle}
      </Text>
    </VStack>
  )
}
