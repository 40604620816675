import { IOption } from 'types/creatableOption'

export interface IFormLabel {
  value: string
  label: string
  checked?: boolean
}

export interface IFilterValuesOfProjects {
  selectedTechnologies: IFormLabel[]
  selectedCountries: IFormLabel[]
  selectedPartnerId: IFormLabel[]
  selectedUserId: IFormLabel[]
  isArchived: boolean
  isActive: boolean
}

export interface IProjectFilter {
  isActive?: boolean
  isArchived?: boolean
  userId?: string[]
  country?: string[]
  partnerId?: string[]
  technology?: string[]
}

export interface IProjectFilterAdmin {
  isActive?: boolean
  isArchived?: boolean
  userId?: IFormLabel[]
  countries?: IFormLabel[]
  partnerId?: IFormLabel[]
  technologies?: IFormLabel[]
}

export interface IStoredProjectFilterAdmin {
  selectedTechnologies: IOption[]
  selectedCountries: IOption[]
  selectedPartnerId: IOption[]
  selectedUserId: IOption[]
  isArchived: boolean
  isActive: boolean
}

export enum EStoredFilterValue {
  SELECTED_TECHNOLOGIES = 'selectedTechnologies',
  SELECTED_COUNTRIES = 'selectedCountries',
  SELECTED_PARTNER_ID = 'selectedPartnerId',
  SELECTED_USER_ID = 'selectedUserId',
  IS_ACTIVE = 'isActive',
  IS_ARCHIVED = 'isArchived',
}
