import React from 'react'

import CommitTimeReportTable from '../../components/CommitTimeReportTableOfUser'
import { getCommitTimeReports } from 'API/commit-time'
import { handleError } from 'API/error'

class CommitTimeReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commitTimeReportList: [],
    }
  }
  componentDidMount() {
    this.getListReportHandler()
    window.sessionStorage.setItem('subPageTitle', 'Report')
    window.dispatchEvent(new Event('storage'))
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem('subPageTitle')
    this.setState = () => {
      return
    }
  }

  async getListReportHandler() {
    try {
      const { commitTimeReportList } = await getCommitTimeReports()
      this.setState({
        commitTimeReportList,
      })
    } catch (error) {
      handleError(error, 'src/containers/CommitTime/CommitTime/CommitTimeReport/index.js', 'getListReportHandler')
    }
  }

  render() {
    const { commitTimeReportList } = this.state
    return (
      <div className="timesheet-page container">
        <div className="timesheet-content">
          {commitTimeReportList && commitTimeReportList.length > 0 && (
            <CommitTimeReportTable data={commitTimeReportList} />
          )}
        </div>
      </div>
    )
  }
}

export default CommitTimeReport
