export interface ITitle {
  handleSubmit?: any
  getDetailTitle?: any
  titles?: any
  titleDetail?: string[]
  deleteTitle?: any
  viewMode?: any
  cancelEditHandler?: any
  validationSchema?: any
}

export interface ILevel {
  handleSubmit?: any
  getDetailLevel?: any
  levels?: any
  levelDetail?: string[]
  deleteLevel?: any
  viewMode?: any
  cancelEditHandler?: any
  validationSchema?: any
}

export interface IInternalDocumentType {
  handleSubmit?: any
  getDetailInternalDocumentType?: any
  internalDocumentTypes?: any
  internalDocumentTypeDetail?: string[]
  deleteInternalDocumentType?: any
  viewMode?: any
  cancelEditHandler?: any
  validationSchema?: any
  name: string
}

export enum ESettingActionType {
  LIST = 'list',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum EViewModeForm {
  CREATE = 'create',
  EDIT = 'edit',
}
