import React, { useEffect } from 'react'
import { HStack, VStack, Container, Text } from '@chakra-ui/react'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useStores } from 'utils/hooks/useStores'
import LogoProject from '../components/LogoProject'
import ProjectDetailForm from '../components/ProjectDetailFormOfUser'
import SettingProject from '../components/SettingProject'

const ProjectDetail = () => {
  const { userProjectStore } = useStores()
  const { projectDetail } = userProjectStore
  const methods = useForm()
  const params = useParams()
  const projectId = get(params, 'id', '')
  const { reset, setValue } = methods

  function getProjectDetailOfUser(): void {
    userProjectStore.getProjectDetailOfUser(projectId)
  }

  useEffect(getProjectDetailOfUser, [projectId])

  useEffect(() => {
    if (projectDetail) {
      reset(projectDetail)
    }
  }, [projectDetail])

  return (
    <FormProvider {...methods}>
      <form>
        <VStack>
          <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
            <HStack margin={{ base: 6, lg: 6 }} height="full" background="white" justifyContent="space-between">
              <Text fontSize="lg" color="gray.700" margin={0}>
                {projectDetail ? projectDetail.name : ''}
              </Text>
            </HStack>
          </Container>
          <HStack width="full" justifyContent="space-between" alignItems="flex-start">
            <VStack width="100%">
              <ProjectDetailForm methods={methods} isEdit={false} />
            </VStack>
            <VStack width="300px" height="100%">
              <LogoProject
                type="logo"
                name="logo"
                isDetail={true}
                afterUploadHandler={(value) => {
                  setValue('logo', value)
                }}
              />
              <SettingProject isDisabled={true} />
            </VStack>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  )
}

export default observer(ProjectDetail)
