import { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'

const styles = (theme) => ({
  input: {
    color: 'rgba(0,0,0, 0.87)',
    '&$disabled': {
      cursor: 'not-allowed',
    },
  },
  disabled: {},
})
class CustomizedInput extends Component {
  renderValue = (value) => {
    const { type } = this.props
    switch (type) {
      case 'date':
        return moment(value).format('YYYY-MM-DD')
      default:
        return value
    }
  }

  render() {
    const {
      onChange,
      classes,
      placeholder = '',
      type = 'text',
      label,
      multiline,
      row,
      name,
      field,
      disabled,
      required,
      form: { touched, errors },
      rows,
      autoFocus,
      ...rest
    } = this.props
    return (
      <div className="input-field">
        <TextField
          error={Boolean(touched[field.name] && errors[field.name])}
          label={label}
          className="input"
          InputProps={{
            classes: {
              input: classes.input,
              disabled: classes.disabled,
            },
            inputProps: {
              step: rest?.step,
              min: rest?.min,
            },
          }}
          id={field.name}
          value={this.renderValue(field.value) || ''}
          onChange={onChange || field.onChange}
          onKeyPress={rest.onKeyPress}
          onBlur={rest.onBlur || field.onBlur}
          maxLength={100}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          fullWidth
          multiline={multiline}
          row={row}
          autoFocus={autoFocus ?? false}
        />
        <span className="error-message">{touched[field.name] && errors[field.name]}</span>
      </div>
    )
  }
}

export default withStyles(styles)(CustomizedInput)
