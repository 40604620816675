import { Dispatch, SetStateAction } from 'react'
import { Stack } from '@chakra-ui/react'
import ButtonForm from 'components/ButtonForm'
import InputField from 'components/InputField'

interface IForgotPasswordFormProps {
  setIsSubmitted: Dispatch<SetStateAction<boolean>>
}

const ForgotPasswordForm = (props: IForgotPasswordFormProps) => {
  const { setIsSubmitted } = props

  function handleOnSubmit(): void {
    setIsSubmitted(true)
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack spacing="6">
        <InputField label="Your Email" type="email" name="email" placeholder="Enter your email" />
      </Stack>
      <ButtonForm label="Send" />
    </form>
  )
}

export default ForgotPasswordForm
