import AppVersionPane from './components/AppVersionPane'
import NormalSidebarV2 from './components/NormalSidebarV2'
import './index.scss'
// *INFO: Sidebar version 1 is retained for reference
// import NormalSidebar from './components/NormalSidebar'

const Sidebar = (props) => {
  const { isCollapsed, actionTab, unReadFeedbackNumber } = props

  return (
    <div className={isCollapsed ? 'collapsed main-sidebar' : 'main-sidebar'}>
      {/* INFO: Sidebar version 1 is retained for reference  */}
      {/* <NormalSidebar actionTab={actionTab} unReadFeedbackNumber={unReadFeedbackNumber} {...props} /> */}
      <NormalSidebarV2 actionTab={actionTab} unReadFeedbackNumber={unReadFeedbackNumber} {...props} />
      <AppVersionPane isCollapsed={isCollapsed} />
    </div>
  )
}

export default Sidebar
