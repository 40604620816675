import { IProject } from 'types/project'
import { ITimesheet } from 'types/timesheet'
import { IOption } from 'types/user'

export const TIMESHEET_BASE_URL: string = '/timesheet'

export interface IListForSPScreenResponseData {
  timesheetList: IResponseTimeSheetWithDeveloper[]
}

export interface IGroupTimeSheetToFE {
  id: string
  title: string
}

export interface ITimeSheetToFE {
  id: string
  group: string
  title: string
  start_time: Date
  end_time: Date
  description: string
  tags: string[]
  timesheetProject?: IProject
}

export interface IResponseTimeSheetWithDeveloper extends Partial<ITimeSheetToFE> {
  timeTotal?: number
  developerName?: string
  developerAvatar?: string
}

export interface ITimeSheetList {
  groups: IGroupTimeSheetToFE[]
  timesheets: ITimeSheetToFE[]
}

export interface IResponseTimeSheetWithTags extends Omit<Partial<ITimesheet>, 'tags'> {
  _id?: string
  tags?: IOption[]
}

export interface IDetailTimesheetResponseData {
  timesheet: IResponseTimeSheetWithTags
}

export interface ICreateEditTimesheetResponse {
  projectBudgetRemain: number
}

export interface ITimesheetRequest extends Omit<ITimesheet, 'id'> {
  _id?: string
}

export interface IProjectHistoryData {
  projectName: string
  timeTotal: number
  startedDate?: Date
  workingTimeOnProject: number
  salaryTotal: number
  developerTotalNumber?: number
}

export interface ITimesheetHistoryResponse {
  projectHistoryData: IProjectHistoryData[]
}

export interface IUserHistoryRequest {
  user: string
}

export interface IUserHistoryResponse {
  userHistoryData: IProjectHistoryData[]
}

export interface IProjectHistoryRequest {
  project: string
}

export interface IProjectHistoryResponse {
  projectHistoryData: IProjectHistoryData
}
