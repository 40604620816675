import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export function formatDateString(dateString: string): string {
  if (isValidDate(dateString)) {
    return dateString
  }

  const [startDateStr, endDateStr] = dateString.split(' - ')
  const formattedStartDate: string = dayjs(startDateStr).format('MMM D YYYY')
  const formattedEndDate: string = dayjs(endDateStr).format('MMM D YYYY')

  return `${formattedStartDate} - ${formattedEndDate}`
}

function isValidDate(dateString: string): boolean {
  const date = dayjs(dateString, 'MMM Do YYYY')
  return date.isValid()
}
