/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { HStack, VStack, useMediaQuery } from '@chakra-ui/react'
import Alert from 'componentsCharka/Alert'
import ConfirmArchiveModal from 'componentsCharka/ConfirmArchiveModal'
import ConfirmDeleteModal from 'componentsCharka/ConfirmDeleteModal'
import difference from 'lodash/difference'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IOption } from 'types/creatableOption'
import { IProfileDetailOfDetailForm } from 'types/user'
import { getValidArray, getBooleanValueFromText } from 'utils'
import { maxMobileSize, laptopSize } from 'constants/common'
import { Messages, actionOnDataTable, RoutesConstants } from 'constants/index'
import { useStores } from 'utils/hooks/useStores'
import CurriculumViate from './components/CurriculumVitae'
import DetailUserForm from './components/DetailUserForm'
import DetailFormMobile from './components/DetailUserForm/components/DetailFormMobile'
import EditUserForm from './components/EditUserForm'
import EditFormMobile from './components/EditUserForm/components/EditFormMobile'
import HeaderSection from './components/HeaderSection'
import LaborContract from './components/LaborContract'
import ProjectRating from './components/ProjectRating'
import UserAvatar from './components/UserAvatar'
import MobileAvatar from './components/UserAvatar/components/MobileAvatar'
import UserData from './components/UserData'
import { IRowActionItem } from './constant'
import styles from './index.module.scss'

const UserDetail = () => {
  const title: string = 'User Detail'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))
  const needToUpdateUser = window.sessionStorage.getItem('needToUpdateUser')
  const methods = useForm()
  const { handleSubmit, reset } = methods
  const params = useParams()
  const history = useHistory()
  const userId = get(params, 'id', '')
  const { adminUserStore } = useStores()
  const { partnerList, projectList, creatableOptionList, userDetail } = adminUserStore
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false)
  const [isMobile] = useMediaQuery(maxMobileSize)
  const [isLaptop] = useMediaQuery(laptopSize)
  const [isMiniDesktop] = useMediaQuery('(max-width: 1510px)')

  function getInformationOfUserDetail(): void {
    adminUserStore.getOptionList()
    adminUserStore.getPartnerList()
    adminUserStore.getProjectList(() => {
      adminUserStore.getUserDetail(userId, isEdit)
    })
  }

  useEffect(getInformationOfUserDetail, [isEdit, userId])
  useEffect(() => {
    if (userDetail) {
      reset({
        ...userDetail,
        profileDetailData: {
          ...userDetail?.profileDetailData,
          IDIssuePlace: {
            label: userDetail?.profileDetailData?.IDIssuePlace ?? '',
            value: userDetail?.profileDetailData?.IDIssuePlace ?? '',
          },
        },
      })
    }
  }, [userDetail])
  async function submitHandler(data) {
    window.sessionStorage.setItem('needToUpdateUser', 'false')
    await adminUserStore.updateUser(data, userId)
    await adminUserStore.getUserDetail(userId, false)
    setIsEdit(false)
  }
  async function updateProjectRating(newProjects) {
    try {
      const projectIdArray = getValidArray(newProjects).map((project: IOption) => project.value)
      const beforeProjects = userDetail.profileDetailData.projects
      const beforeProjectsIdArray = getValidArray(beforeProjects).map((project: IOption) => project.value)
      let projectsNotAdded = []
      if (beforeProjectsIdArray.length > projectIdArray.length) {
        projectsNotAdded = difference(beforeProjectsIdArray, projectIdArray)
      }
      await adminUserStore.updateProjectRatings(userId, projectIdArray, projectsNotAdded)
    } catch (error) {
      toast.error('Update project rating failed')
    }
  }
  function toggleOpenDeleteModal() {
    setOpenDeleteModal(!openDeleteModal)
  }
  function toggleOpenArchiveModal() {
    setOpenArchiveModal(!openArchiveModal)
  }
  function callOpenDialogConfirm(user: IProfileDetailOfDetailForm, nameOfEvent: string) {
    set(user, 'shortName', user?.fullName ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      toggleOpenDeleteModal()
    } else {
      toggleOpenArchiveModal()
    }
  }
  async function handleClickOKConfirmedDelete() {
    await adminUserStore.deleteUser(userId)
    history.push(RoutesConstants.USER_LIST)
  }
  async function handleClickOKOfUnarchiveConfirm() {
    await adminUserStore.unArchivedUser(userId)
    history.push(RoutesConstants.USER_LIST)
  }
  async function handleClickOKOfArchiveConfirm() {
    await adminUserStore.archivedUser(userId)
    history.push(RoutesConstants.USER_LIST)
  }
  function editHandler() {
    setIsEdit(true)
  }
  function cancelHandler() {
    setIsEdit(false)
  }
  const actions: IRowActionItem[] = [
    {
      name: !!userDetail?.profileDetailData?.isArchived ? 'Unarchive' : 'Archive',
      handler: () => callOpenDialogConfirm(userDetail?.profileDetailData, actionOnDataTable.ARCHIVE),
    },
  ]
  actions.push({
    name: 'Delete',
    customClassNames: ['isDanger'],
    handler: () => callOpenDialogConfirm(userDetail?.profileDetailData, actionOnDataTable.DELETE),
  })

  return (
    <VStack width="full">
      {getBooleanValueFromText(needToUpdateUser) && (
        <Alert
          status={'error'}
          title={Messages.updateUserAlertTitle}
          description={Messages.updateUserAlertDescription}
        />
      )}
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit((data) => submitHandler(data))}>
          <VStack width="full" spacing={6}>
            <HeaderSection
              fullName={userDetail?.profileDetailData?.fullName}
              isEdit={isEdit}
              handleEdit={editHandler}
              handleCancel={cancelHandler}
              actions={actions}
            />
            {!isMobile ? (
              <HStack
                spacing={6}
                width="full"
                marginTop="24px !important"
                justifyContent="space-between"
                alignItems="flex-start">
                <VStack width={isMiniDesktop && !isLaptop ? '72%' : '100%'} spacing={6}>
                  {isEdit ? (
                    <EditUserForm
                      userId={userId}
                      partnerList={partnerList}
                      projectList={projectList}
                      creatableOptionList={creatableOptionList}
                      methods={methods}
                      updateProjectRatingHandler={updateProjectRating}
                      name="profileDetailData"
                    />
                  ) : (
                    <DetailUserForm methods={methods} name="profileDetailData" />
                  )}
                  <ProjectRating isEdit={isEdit} name="projectRatings" projectList={projectList} />
                  <LaborContract />
                  <UserData name="profileDetailData.userData" />
                </VStack>
                {!isLaptop && (
                  <VStack width="300px" height="100%">
                    <UserAvatar name="profileDetailData.avatar" />
                    <CurriculumViate userId={userId} name="profileDetailData.cv" />
                  </VStack>
                )}
              </HStack>
            ) : (
              <VStack width="full" spacing={3} marginTop="0 !important">
                {isEdit ? (
                  <EditFormMobile
                    userId={userId}
                    partnerList={partnerList}
                    projectList={projectList}
                    creatableOptionList={creatableOptionList}
                    handleUpdateProjectRating={updateProjectRating}
                    name="profileDetailData"
                  />
                ) : (
                  <DetailFormMobile name="profileDetailData" />
                )}
                <ProjectRating isEdit={isEdit} name="projectRatings" projectList={projectList} />
                <LaborContract />
                <UserData name="profileDetailData.userData" />
                <MobileAvatar name="profileDetailData.avatar" />
                <CurriculumViate userId={userId} name="profileDetailData.cv" />
              </VStack>
            )}
          </VStack>
        </form>
        <ConfirmDeleteModal
          data={userDetail?.profileDetailData}
          isOpen={openDeleteModal}
          closeHandler={toggleOpenDeleteModal}
          OKClickHandler={handleClickOKConfirmedDelete}
          title={'User'}
          content={'Full Name'}
        />
        <ConfirmArchiveModal
          data={userDetail?.profileDetailData}
          isOpen={openArchiveModal}
          closeHandler={toggleOpenArchiveModal}
          OKClickHandler={
            userDetail?.profileDetailData?.isArchived ? handleClickOKOfUnarchiveConfirm : handleClickOKOfArchiveConfirm
          }
          title={'user'}
          content={'Full name'}
        />
      </FormProvider>
    </VStack>
  )
}
export default observer(UserDetail)
