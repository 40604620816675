import React from 'react'
import { chakra } from '@chakra-ui/react'
import cx from 'classnames'
import styles from './statusCell.module.scss'

interface IStatusCellProps {
  children: React.ReactNode
  status: string
}

const StatusCell = (props: IStatusCellProps) => {
  const { children, status = '' } = props
  const formattedStatus = status.replace(/\s/g, '-')

  return status ? (
    <chakra.div
      className={cx({
        [styles.statusCell]: true,
        [styles[formattedStatus]]: status ? true : false,
      })}>
      {formattedStatus !== 'Sent-to-the-partner' ? children : 'Sented'}
    </chakra.div>
  ) : (
    <></>
  )
}

export default StatusCell
