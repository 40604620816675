// vendors
import { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'

const style = {
  select: {
    color: 'rgba(0,0,0, 0.87)',
  },
}

class CustomizedSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentValue: [],
    }
  }
  renderValueInInputField = (selected) => {
    const { data } = this.props
    const renderValue = []
    if (data && data.length) {
      selected.forEach((element) => {
        const neededItem = data.find((item) => {
          return item._id === element
        })
        renderValue.push(neededItem?.value || neededItem?.name)
      })

      return renderValue.join(', ')
    }
  }
  render() {
    const {
      classes,
      className,
      label,
      onBlur,
      callback,
      relatedFieldName,
      field,
      required,
      options = [],
      disabled,
      form: { errors, touched, setFieldValue },
      ...rest
    } = this.props
    let { currentValue } = this.state
    if (field.value) currentValue = field.value
    return (
      <FormControl className="select-field">
        <InputLabel htmlFor={field.name} error={Boolean(touched[field.name] && errors[field.name])}>
          {label}
        </InputLabel>
        <Select
          error={Boolean(touched[field.name] && errors[field.name])}
          multiple
          data-cy={`${field.name}`}
          id={field.name}
          style={style.select}
          value={field.value || []}
          onChange={(option) => {
            // if (option) {
            setFieldValue(field.name, option.target.value)
            this.setState({
              currentValue: option.target.value,
            })
            if (onBlur) {
              setTimeout(() => onBlur(), 100) // setFieldValue is async func because of setState(values)
            }

            if (callback && relatedFieldName) {
              callback(option)
              setTimeout(() => onBlur(relatedFieldName), 100)
            }
            // }
          }}
          disabled={disabled}
          onKeyPress={rest.onKeyPress}
          input={<Input name={label} id={field.name} />}
          autoWidth
          renderValue={this.renderValueInInputField}>
          <MenuItem value="" disabled key="choose">
            Choose here...
          </MenuItem>
          {Array.isArray(options) &&
            options.map((option, i) => (
              <MenuItem key={i} value={option.value}>
                <Checkbox checked={currentValue.indexOf(option.value) > -1} />
                <ListItemText primary={option.text} />
              </MenuItem>
            ))}
        </Select>
        <span className="error-message">{touched[field.name] && errors[field.name]}</span>
      </FormControl>
    )
  }
}

export default CustomizedSelect
