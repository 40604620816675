import React from 'react'
import MultiRef from 'react-multi-ref'
import * as Yup from 'yup'

import FormModel from '../../../components/FormModel'
import InfoDetail from './components/InformationDetail'
import ApiHelper from '../../../Helper/api'
import { AppConfig, Messages, EventName, RoutesConstants } from '../../../constants'

import './index.scss'

const validationSchema = Yup.object().shape({
  title: Yup.string().required(Messages.fieldRequired),
  content: Yup.string()
    .required(Messages.fieldRequired)
    .max(AppConfig.LIMIT_CHARACTERS_OF_STRING, Messages.exceedCharacterOfString),
  publishedDate: Yup.date().required(Messages.fieldRequired),
})
class InfosList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      infos: [],
      selectedInfo: null,
    }
  }
  _actRefs = new MultiRef()
  componentDidMount() {
    this.getListInfosHandler()
  }
  getListInfosHandler = () => {
    ApiHelper.get(
      {
        url: `/informations/list-admin`,
      },
      (response) => {
        this.setState({
          infos: response.data.informations,
        })
      }
    )
  }
  handleToggleActiveEdit = (id) => {
    this.setState({
      selectedInfo: id,
    })
  }

  linkToCreateInfo = () => {
    const { history } = this.props
    history.push(RoutesConstants.INFOS_NEW)
  }
  editInformationHandler = (values) => {
    const infoID = values._id
    ApiHelper.post(
      {
        url: `/informations/update/${infoID}`,
        data: values,
      },
      (response) => {
        this.props.handleOpenSnackbar({ variant: 'success', message: Messages.updateInfoSuccess })
        this.handleToggleActiveEdit(null)
        this.getListInfosHandler()
      }
    )
  }
  handleClickOKOfDialogConfirm = (id, type = null) => {
    ApiHelper.post(
      {
        url: `/informations/delete/${id}`,
      },
      (response) => {
        this.props.handleCloseConfirmDialogYesNo()
        this.props.handleOpenSnackbar({ variant: 'success', message: Messages.deleteInfoSuccess })
        this.getListInfosHandler()
      }
    )
  }
  callOpenDialogConfirm = (data) => {
    document.dispatchEvent(
      new CustomEvent(EventName.openDeleteInfoConfirmDialogYesNo, {
        detail: {
          isOpen: true,
          OKClickHandler: this.handleClickOKOfDialogConfirm,
          item: data,
        },
      })
    )
  }
  deleteInfoHandler = (info) => {
    this.callOpenDialogConfirm(info)
  }
  resetFormWhenPressCancel = (id) => {
    this._actRefs.map.get(id).resetForm()
    this.setState({
      selectedInfo: null,
    })
  }
  render() {
    const { infos, selectedInfo } = this.state
    return (
      <div className="container infos-list-page">
        <div>
          <div className="options-sub">
            <div className="create-new-project">
              <button className="primary-button" onClick={this.linkToCreateInfo}>
                Create New Information
              </button>
            </div>
          </div>
          <div className="infos-list">
            {infos &&
              infos.map((item) => {
                const viewModeInfoDetail = selectedInfo === item._id ? 'edit' : 'show'
                return (
                  <div className="project-detail-sub" key={item._id}>
                    <FormModel
                      refComponent={this._actRefs.ref(item._id)}
                      initialValues={item}
                      handleSubmit={this.editInformationHandler}
                      schema={validationSchema}
                      component={
                        <InfoDetail
                          viewMode={viewModeInfoDetail}
                          cancelForm={this.resetFormWhenPressCancel}
                          handleToggleActiveEdit={this.handleToggleActiveEdit}
                          handleDelete={this.deleteInfoHandler}
                        />
                      }
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}

export default InfosList
