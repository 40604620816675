import { ChangeEvent, useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { deleteFileCv, uploadFileCv } from 'API/upload'
import { IUploadFileResponse } from 'API/upload/constants'
import { getCvFile } from 'API/user'
import { Document, Page, pdfjs } from 'react-pdf'
import { toast } from 'react-toastify'
import { EUploadFileType } from 'types/upload'
import { EProfileTab } from 'containers/ProfileNew/constants'
import EmptyCV from 'containers/ProfileNew/ProfileDetail/components/CurriculumVitae/components/EmptyCV'
import ButtonGroupUploadCV from './components/ButtonGroupUploadCV/Index'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface ICurriculumViate {
  isShowButton?: boolean
  cvFilePreview?: any
  cvFilePreviewName?: string
  cvFileName?: string
  getProfileDataHandler?: any
}

const CurriculumVitaeDetail = (props: ICurriculumViate) => {
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [disabledPre, setDisabledPre] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)
  const [cvFile, setCVFile] = useState<File | null>(null)
  const { isShowButton, cvFileName, cvFilePreview } = props

  const title: string = `${EProfileTab.CURRICULUM_VITAE} User Detail`
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))

  useEffect(() => {
    checkDisabledNavBtn()
  }, [pageNumber])

  function checkDisabledNavBtn() {
    let isDisabledPre
    let isDisabledNext
    if (numPages === 1) {
      isDisabledPre = true
      isDisabledNext = true
    }
    if (pageNumber === numPages) {
      isDisabledNext = true
    }
    if (pageNumber === 1) {
      isDisabledPre = true
    }
    if (pageNumber < numPages && pageNumber > 1) {
      isDisabledPre = false
      isDisabledNext = false
    }
    setDisabledPre(isDisabledPre)
    setDisabledNext(isDisabledNext)
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  function clickNextPageHandler() {
    setPageNumber(pageNumber + 1)
  }

  function clickPrePageHandler() {
    setPageNumber(pageNumber - 1)
  }

  function toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  async function getCVFileHandler() {
    try {
      if (cvFileName) {
        const response: ISuccessResponse<BlobPart> = await getCvFile(cvFileName)
        const cv: BlobPart = response.data
        const result: any = await toBase64(new Blob([cv], { type: 'application/pdf' }))
        setCVFile(result)
      }
      if (cvFilePreview) {
        setCVFile(cvFilePreview)
      }
    } catch (error) {
      handleError(error, 'src/componentsCharka/CurriculumVitaeDetail/index.tsx', 'getCVFileHandler')
    }
  }
  async function handleChangeFile(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    try {
      const file: File = event?.target?.files[0]
      const formData = new FormData()
      formData.append('myFile', file)
      const response: ISuccessResponse<IUploadFileResponse> = await uploadFileCv(formData, EUploadFileType.CV)
      if (!!event?.target?.files?.[0]) {
        !!event?.target?.files?.[0] && setCVFile(event?.target?.files?.[0])
      }
      const messages: string = response.data.messages
      toast.success(messages)
    } catch (error) {
      toast.error('Upload cv failed')
      handleError(error, 'src/componentsCharka/CurriculumVitaeDetail/index.tsx', 'handleChangeFile')
    }
  }

  async function handleDeleteCV(): Promise<void> {
    try {
      await deleteFileCv(EUploadFileType.CV)
      setCVFile(null)
      toast.success('Delete cv successfully')
    } catch (error) {
      toast.error('Delete cv failed')
      handleError(error, 'src/componentsCharka/CurriculumVitaeDetail/index.tsx', 'handleDeleteCV')
    }
  }

  useEffect(() => {
    getCVFileHandler()
  }, [cvFileName])

  return (
    <Box alignContent={'center'}>
      {!isShowButton ? (
        ''
      ) : (
        <ButtonGroupUploadCV
          handleChangeFile={handleChangeFile}
          handleDeleteCV={handleDeleteCV}
          isDisable={cvFilePreview ? false : true}
          file={cvFilePreview}
        />
      )}
      {cvFile ? (
        <div className={`box-body`}>
          <Document
            file={cvFile}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{
              cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}>
            <Page size="A4" orientation={'portrait'} pageNumber={pageNumber} />
          </Document>
          {numPages && (
            <div className="pagination-cv-page">
              <div className="pagination-sub">
                <span className={`nav-button ${disabledPre ? 'disabled' : ''}`} onClick={clickPrePageHandler}>
                  <span className="double-arrow">«</span>
                </span>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <span className={`nav-button ${disabledNext ? 'disabled' : ''}`} onClick={clickNextPageHandler}>
                  <span className="double-arrow">»</span>
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <EmptyCV />
      )}
    </Box>
  )
}

export default CurriculumVitaeDetail
