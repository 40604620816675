import { HTMLInputTypeAttribute } from 'react'
import { FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import { useFormContext } from 'react-hook-form'

export interface IFormInputProps {
  name: string
  label?: string
  type?: HTMLInputTypeAttribute
  placeholder?: string
  isRequired?: boolean
}

const FormInput = (props: IFormInputProps) => {
  const { name, label, type, placeholder, isRequired } = props
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const errorMessage: string = get(errors, `${name}.message`, '')

  return (
    <FormControl padding={0} isInvalid={!!errorMessage}>
      {label && (
        <FormLabel fontWeight="normal" color="gray.700" lineHeight={6}>
          {label}
        </FormLabel>
      )}
      <Input
        boxSizing="border-box"
        type={type}
        autoComplete={name}
        placeholder={placeholder}
        color="gray.700"
        paddingY={2}
        borderRadius={6}
        {...register(name, {
          required: isRequired ? `${startCase(label)} is required` : false,
        })}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

export default FormInput
