import React from 'react'
import { Table as CkTable, Tbody, Tr, Td, Container } from '@chakra-ui/react'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import { Messages } from 'constants/index'
import Pagination from '../DesktopTable/components/Pagination'
import { IPagination } from '../DesktopTable/index'
import MoreAction from './components/MoreAction'
import styles from './index.module.scss'

interface ITabletTable {
  headerList?
  tableData?
  isShowPagination?: boolean
  pagination?: IPagination
  isDetail?: boolean
  detailActionHandler?: (e?) => void
}
const TabletTable = (props: ITabletTable) => {
  const { tableData, headerList, isDetail, pagination, isShowPagination } = props
  function renderRow(item) {
    return (
      <Tr width={{ sm: 'auto' }} key={item._id}>
        {Object.entries(headerList).map(([key, value]) => (
          <Td
            data-label={value}
            key={key}
            onClick={key !== 'actionsTablet' ? item.actionsTablet[0].handler : () => console.log('')}>
            {key !== 'actionsTablet' ? item[key] : <MoreAction isDetail={isDetail} actions={item.actionsTablet} />}
          </Td>
        ))}
      </Tr>
    )
  }

  function renderBody() {
    return <Tbody width={{ sm: 'auto' }}>{tableData.map(renderRow)}</Tbody>
  }

  return (
    <Container padding="0px" background="white" width="auto">
      {tableData.length === 0 && <EmptyContentBox text={Messages.noData} />}
      {tableData && tableData.length > 0 && <CkTable className={styles.responsiveTable}>{renderBody()}</CkTable>}
      {isShowPagination && <Pagination pagination={pagination} />}
    </Container>
  )
}

export default TabletTable
