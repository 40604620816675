import { IFormLabelProps, IFormInputProps } from './constants'

// *INFO: Use isEdit instead of isEditing because a lot of old components use isEdit, it's will be change to isEditing in the future
export function getFormLabelProps(
  name: string,
  label: string,
  isEdit: boolean,
  isHighlighted?: boolean
): IFormLabelProps {
  return {
    name,
    label,
    isEdit,
    isHighlighted,
  }
}

export function getFormInputProps(
  name: string,
  label: string,
  placeholder?: string,
  isRequired: boolean = false
): IFormInputProps {
  return {
    name,
    label,
    placeholder,
    isRequired,
  }
}
