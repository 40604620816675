import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers'
import moment from 'moment'

const styles = {
  grid: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  sub: {
    width: '100%',
    marginTop: '10px',
  },
}

class MaterialUIPickers extends React.Component {
  renderValue = (value) => {
    if (value) {
      return moment(value).format('YYYY-MM-DD')
    } else {
      moment().format('YYYY-MM-DD')
    }
  }
  render() {
    const {
      classes,
      label,
      minDate,
      maxDate,
      disabled,
      onChangeHandler,
      field,
      onlyCalendar,
      form: { setFieldValue, touched, errors },
    } = this.props

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container className={classes.grid}>
          <InlineDatePicker
            id={field.name}
            className={classes.sub}
            margin="normal"
            onlyCalendar={onlyCalendar}
            label={label}
            minDate={minDate}
            maxDate={maxDate}
            onChange={
              onChangeHandler
                ? onChangeHandler
                : (value) => {
                    setFieldValue(field.name, moment(value).format('YYYY-MM-DDTHH:mm'))
                  }
            }
            labelFunc={(date) => (date === null ? '' : moment(date).format('MMM Do YYYY'))}
            value={this.renderValue(field.value)}
            disabled={disabled}
          />
        </Grid>
        <span className="error-message">{touched[field.name] && errors[field.name]}</span>
      </MuiPickersUtilsProvider>
    )
  }
}

MaterialUIPickers.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MaterialUIPickers)
