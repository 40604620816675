import { FormControl, FormLabel, Stack, Radio, RadioGroup } from '@chakra-ui/react'
import startCase from 'lodash/startCase'
import { observer } from 'mobx-react'
import { useFormContext, useWatch } from 'react-hook-form'
import { getValidArray } from 'utils'

export interface IGroupRadioButtonProps {
  name: string
  label?: string
  options?: string[]
  defaultValue?: string
  stackProps?: Record<string, unknown>
}

const GroupRadioButton = (props: IGroupRadioButtonProps) => {
  const { name, label, options, defaultValue, stackProps } = props
  const { register, control } = useFormContext()
  const currentValue: string = useWatch({ name, control })

  return (
    <FormControl>
      {label && (
        <FormLabel color="gray.700" fontWeight={400} marginBottom={4}>
          {label}
        </FormLabel>
      )}
      <RadioGroup defaultValue={defaultValue ?? options[0]} value={currentValue}>
        <Stack {...stackProps}>
          {getValidArray(options).map((option: string, index: number) => (
            <Radio {...register(name)} key={index} value={option} colorScheme="blue">
              {startCase(option)}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  )
}

export default observer(GroupRadioButton)
