// vendors
import React, { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import ListSubheader from '@material-ui/core/ListSubheader'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import SearchIcon from '@material-ui/icons/Search'

const style = {
  select: {
    color: 'rgba(0,0,0, 0.87)',
  },
}
class MultipleWithSearchBoxSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentValue: [],
      searchText: '',
      displayedListAfterSearch: [],
    }
  }
  containsText = (option, searchText) => {
    return option.text?.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1
  }

  onChangeHandler = (choosedOptions) => {
    const newOptions = []
    const { form, field } = this.props
    const { setFieldValue } = form
    choosedOptions.forEach((element) => {
      if (element) {
        newOptions.push(element)
      }
    })
    setFieldValue(field.name, newOptions)
  }

  renderValueInInputField = (selected) => {
    const { data } = this.props
    const renderValue = []
    if (data && data.length) {
      selected.forEach((element) => {
        const neededItem = data.find((item) => {
          return item._id === element
        })
        if (neededItem) {
          renderValue.push(neededItem?.value || neededItem?.name)
        }
      })
      return renderValue.join(', ')
    }
  }
  setSearchText = (newSearchText) => {
    const optionArray = this.props.options
    let showSearchOptions = optionArray
    const choosedOptionArray = optionArray.filter((option) => {
      const isIncluded = this.containsText(option, newSearchText)
      if (isIncluded) {
        return option
      }
    })
    if (choosedOptionArray && choosedOptionArray.length) {
      showSearchOptions = choosedOptionArray
    }
    this.setState({
      searchText: newSearchText,
      displayedListAfterSearch: showSearchOptions,
    })
  }
  render() {
    const {
      classes,
      className,
      label,
      onBlur,
      callback,
      relatedFieldName,
      field,
      required,
      options = [],
      disabled,
      form: { errors, touched },
      ...rest
    } = this.props
    let { currentValue, displayedListAfterSearch } = this.state
    if (displayedListAfterSearch && displayedListAfterSearch.length < 1) {
      displayedListAfterSearch = options
    }
    if (field.value) currentValue = field.value
    return (
      <FormControl className="select-field">
        <InputLabel htmlFor={field.name} error={Boolean(touched[field.name] && errors[field.name])}>
          {label}
        </InputLabel>
        <Select
          error={Boolean(touched[field.name] && errors[field.name])}
          MenuProps={{ autoFocus: false }}
          multiple
          data-cy={`${field.name}`}
          id={field.name}
          style={style.select}
          value={field.value || []}
          onChange={(option) => {
            this.onChangeHandler(option.target.value)
            this.setState({
              currentValue: option.target.value,
            })
            if (onBlur) {
              setTimeout(() => onBlur(), 100) // setFieldValue is async func because of setState(values)
            }
            if (callback && relatedFieldName) {
              callback(option)
              setTimeout(() => onBlur(relatedFieldName), 100)
            }
          }}
          disabled={disabled}
          onKeyPress={rest.onKeyPress}
          input={<Input name={label} id={field.name} />}
          autoWidth
          renderValue={this.renderValueInInputField}>
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => this.setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation()
                }
              }}
            />
          </ListSubheader>
          {Array.isArray(displayedListAfterSearch) &&
            displayedListAfterSearch.map((option, i) => (
              <MenuItem key={i} value={option.value}>
                <Checkbox checked={currentValue.indexOf(option.value) > -1} />
                <ListItemText primary={option.text} />
              </MenuItem>
            ))}
        </Select>
        <span className="error-message">{touched[field.name] && errors[field.name]}</span>
      </FormControl>
    )
  }
}

export default MultipleWithSearchBoxSelect
