import { Drawer, DrawerBody, DrawerContent, DrawerOverlay, HStack, Text, Flex } from '@chakra-ui/react'
import { backgroundGrey200 } from 'theme/globalStyles'
import { getValidArray } from 'utils'
import IconWithName from '../IconWithName'
import { IAction } from '../MoreDropBox'

export interface IActionDrawer {
  isOpen: boolean
  closeHandler: () => void
  actions: IAction[]
}

const ActionDrawer = (props: IActionDrawer) => {
  const { isOpen, closeHandler, actions } = props

  function onClickActionHandler(action: IAction): void {
    closeHandler()
    action.handler(action)
  }

  return (
    <Drawer isOpen={isOpen} onClose={closeHandler} placement="bottom">
      <DrawerOverlay />
      <DrawerContent maxHeight="350px" borderRadius="6px 6px 0 0" border={`1px solid ${backgroundGrey200}`}>
        <DrawerBody paddingY={2} paddingX={0}>
          {getValidArray(actions).map((action: IAction) => {
            const name: string = String(action.name)
            return (
              <HStack key={action.name}>
                <Flex
                  margin={0}
                  alignItems="center"
                  onClick={() => onClickActionHandler(action)}
                  height={12}
                  width="100%"
                  paddingLeft={4}
                  _hover={{ background: 'lightgrey', cursor: 'pointer' }}>
                  <IconWithName iconName={name} />
                  <Text size="md" color="gray.700">
                    {action.name}
                  </Text>
                </Flex>
              </HStack>
            )
          })}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default ActionDrawer
