import React, { useState } from 'react'
import { HStack, Text, useMediaQuery } from '@chakra-ui/react'
import cx from 'classnames'
import { FaEllipsisV } from 'react-icons/fa'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { getValidArray } from 'utils'
import { maxMobileSize } from 'constants/common'
import { ReactComponent as DownloadIcon } from '../../../../assets/images/svg/export.svg'
import { ReactComponent as EyeIcon } from '../../../../assets/images/svg/eye-fill-gray.svg'
import { ReactComponent as InformationIcon } from '../../../../assets/images/svg/information-white.svg'
import ActionDrawer from '../ActionDrawer'
import IconWithName from '../IconWithName'
import { Button } from './moreDropBox.styles'
import styles from './moreDropBox.module.scss'

export interface IAction {
  name: string
  customClassNames?: string[]
  // *INFO: Cannot define type of handler => use any
  handler: (information: any) => void
}

interface IMoreDropBoxProps {
  isDetail?: boolean
  // *INFO: Cannot define type => use any
  detailActionHandler?: (information: any) => void
  downloadActionHandler?: () => void
  summaryActionHandler?: (information: any) => void
  noPadding?: boolean
  actions?: IAction[]
  isBackground?: boolean
  isDownload?: boolean
  isTablet?: boolean
  isSummary?: boolean
}

const MoreDropBox = (props: IMoreDropBoxProps) => {
  const {
    actions = [],
    noPadding = false,
    isDetail,
    detailActionHandler,
    isBackground,
    isDownload,
    downloadActionHandler,
    isTablet,
    isSummary,
    summaryActionHandler,
  } = props

  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const [openActionDrawer, setOpenActionDrawer] = useState<boolean>(false)

  function toggleOpenActionDrawer(): void {
    setOpenActionDrawer(!openActionDrawer)
  }

  return (
    <div className={styles.container}>
      <UncontrolledDropdown className={isBackground ? styles.groupButtonBackground : styles.groupButton}>
        {isSummary && isMobile && (
          <Button
            leftIcon={<InformationIcon color="gray" />}
            className={cx({ [styles.noPadding]: noPadding })}
            onClick={summaryActionHandler}></Button>
        )}
        {isDetail && (
          <Button
            padding={4}
            marginRight={2}
            leftIcon={<EyeIcon width="16px" color="gray" />}
            className={cx({ [styles.noPadding]: noPadding })}
            onClick={detailActionHandler}></Button>
        )}
        {isDownload && (
          <Button
            leftIcon={<DownloadIcon color="gray" />}
            className={cx({ [styles.noPadding]: noPadding })}
            onClick={downloadActionHandler}></Button>
        )}
        {getValidArray(actions).length > 0 && (
          <>
            {isMobile ? (
              <Button
                leftIcon={<FaEllipsisV color="gray" />}
                className={cx({ [styles.noPadding]: noPadding })}
                onClick={toggleOpenActionDrawer}
                _hover={{
                  cursor: 'pointer',
                  background: 'none !important',
                }}>
                <ActionDrawer isOpen={openActionDrawer} closeHandler={toggleOpenActionDrawer} actions={actions} />
              </Button>
            ) : (
              <React.Fragment>
                <DropdownToggle className={cx({ [styles.noPadding]: noPadding })} nav>
                  <FaEllipsisV color="gray" />
                </DropdownToggle>
                <DropdownMenu right className={styles.menu}>
                  {getValidArray(actions).map((action: IAction, index: number) => {
                    const customClassNames: string[] = action.customClassNames || []
                    const customStyles: string[] = getValidArray(customClassNames).map((className) => styles[className])
                    const name: string = String(action.name)
                    return (
                      <DropdownItem key={index} onClick={action.handler} className={styles.item}>
                        <HStack>
                          <IconWithName iconName={name} />
                          <Text className={cx(styles.selection, [...customStyles])} size="md" color="gray.700">
                            {action.name}
                          </Text>
                        </HStack>
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </React.Fragment>
            )}
          </>
        )}
      </UncontrolledDropdown>
    </div>
  )
}

export default MoreDropBox
