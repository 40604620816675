import { Button, chakra } from '@chakra-ui/react'

export const StyledButton = chakra(Button, {
  baseStyle: () => ({
    paddingX: 4,
    paddingY: 2,
    lineHeight: 6,
    fontWeight: 500,
    cursor: 'pointer',
  }),
})
