import React from 'react'
import { Messages } from '../../../../../constants'

class ConfirmDialogContent extends React.Component {
  render() {
    const { data, inputDeleteDialogChangeHandler, type } = this.props
    let showName = data.value
    if (type === 'rate-exchange') {
      showName = data.label
    }
    return (
      <div className="confirm-delete-project-content confirm-dialog">
        <p className="warning-message">{Messages.deleteDialogWarning}</p>
        <p className="confirm-message">
          {Messages.deleteDialogContent} <span className="highlight">{showName}</span>
        </p>
        <div className="confirm-delete-again">
          <p className="guide-message">{Messages.deleteDialogGuide}</p>
          <input className="delete-input-field" onChange={(event) => inputDeleteDialogChangeHandler(event, showName)} />
        </div>
      </div>
    )
  }
}

export default ConfirmDialogContent
