import { chakra, Text } from '@chakra-ui/react'

export const FieldValue = chakra(Text, {
  baseStyle: {
    margin: 0,
    fontWeight: 500,
  },
})

export const FieldTitle = chakra(Text, {
  baseStyle: {
    color: { base: 'gray.500', md: 'gray.700' },
    margin: 0,
  },
})
