import React from 'react'

import ApiHelper from '../../../../Helper/api'
import { AppConfig, Messages } from '../../../../constants'
import CommitTimeTable from '../../components/CommitTimeTableOfUser'
import SendRequestDialog from '../../components/SendRequestDialog'
import { getListCommitTime, sendCommitTimeRequestChange } from 'API/commit-time'
import { handleError } from 'API/error'

class CommitTimeOverview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commitTimeList: [],
      isOpenSendRequestDialog: false,
      selectedCommitTime: undefined,
      isValidCommitTimeNumber: false,
    }
  }
  componentDidMount() {
    this.getCommitTimeList()
    window.sessionStorage.setItem('subPageTitle', 'Overview')
    window.dispatchEvent(new Event('storage'))
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem('subPageTitle')
    this.setState = () => {
      return
    }
  }
  getCommitTimeList = async () => {
    try {
      const response = await getListCommitTime()
      this.setState({
        commitTimeList: response?.commitTimeList,
      })
    } catch (error) {
      handleError(error, 'src/containers/CommitTime/CommitTime/CommitTimeOverview/index.js', 'getCommitTimeList')
    }
  }

  sendRequestHandler = (commitTimeId) => {
    const { commitTimeList } = this.state
    const selectedCommitTime = commitTimeList.find((item) => {
      return item._id === commitTimeId
    })
    this.setState({
      isOpenSendRequestDialog: true,
      selectedCommitTime,
    })
  }

  closeSendRequestDialog = () => {
    this.setState({
      isOpenSendRequestDialog: false,
    })
  }

  OKClickHandlerOfSendRequestDialog = async (data) => {
    try {
      await sendCommitTimeRequestChange(data)
      this.closeSendRequestDialog()
      this.props.handleOpenSnackbar({ variant: 'success', message: Messages.sendRequestChangeCommitTimeSuccessfully })
    } catch (error) {
      handleError(
        error,
        'src/containers/CommitTime/CommitTime/CommitTimeOverview/index.js',
        'OKClickHandlerOfSendRequestDialog'
      )
    }
  }

  render() {
    const { commitTimeList, isOpenSendRequestDialog, selectedCommitTime, isValidCommitTimeNumber } = this.state
    return (
      <div className="timesheet-page container">
        <div className="timesheet-content">
          <div className="container-fluid">
            <div className="box box-default">
              <div className="box-header">Commit Time List</div>
              <div className="box-divider" />
              <div className={`box-body`}>
                {commitTimeList && commitTimeList.length > 0 && (
                  <CommitTimeTable data={commitTimeList} sendRequestHandler={this.sendRequestHandler} />
                )}
              </div>
            </div>
          </div>
        </div>
        <SendRequestDialog
          isOpen={isOpenSendRequestDialog}
          closeHandler={this.closeSendRequestDialog}
          OKClickHandler={this.OKClickHandlerOfSendRequestDialog}
          isValidCommitTimeNumber={isValidCommitTimeNumber}
          item={selectedCommitTime}
        />
      </div>
    )
  }
}

export default CommitTimeOverview
