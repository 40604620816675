import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { THistory } from 'types/common'
import { IUser } from 'types/user'
import { RoutesConstants } from 'constants/index'
import { useStores } from 'utils/hooks/useStores'
import CreateUserForm from '../components/CreateUserForm'

const UserCreate = () => {
  const history: THistory = useHistory()
  const { adminUserStore } = useStores()
  const { partnerList, projectList, creatableOptionList } = adminUserStore

  useEffect(() => {
    adminUserStore.getOptionList()
    adminUserStore.getPartnerList()
    adminUserStore.getProjectList()
  }, [])

  const submitHandler = async (data: IUser) => {
    await adminUserStore.createUser(data)
    history.push(RoutesConstants.USER_LIST)
  }

  return (
    <CreateUserForm
      partnerList={partnerList}
      projectList={projectList}
      creatableOptionList={creatableOptionList}
      submitHandler={submitHandler}
    />
  )
}

export default observer(UserCreate)
