// GLOBAE2E8F0L
export const globalBackground = '#E5E5E5'

// BACKGROUND
export const backgroundPrimary = '#08C8F6'
export const backgroundSecondary = '#4D5DFB'
export const backgroundButton = '#319795'
export const backgroundButtonHover = '#2C7A7B'
export const backgroundWhite = '#fff'
export const backgroundGrey100 = '#EDF2F7'
export const backgroundGrey50 = '#F7FAFC'
export const backgroundGrey200 = '#E2E8F0'
export const backgroundGrey500 = 'rgba(255, 255, 255, 0.49)'
export const backgroundGrey600 = 'rgba(0, 0, 0, 0.06)'
export const backgroundGrey700 = '#2D3748'
export const backgroundWhite500 = 'rgba(255, 255, 255, 0.36)'
export const backgroundBlack100 = 'rgba(0, 0, 0, 0.1)'
export const backgroundRed500 = '#E53E3E'
export const backgroundBlueSecondary = '#4D5DFB'
export const backgroundBlue900 = '#1A365D'

// TEXT
export const textPrimary = '#08C8F6'
export const textSecondary = '#4D5DFB'
export const textWhite = 'white'
export const textGrey100 = '#EDF2F7'
export const textGrey200 = '#F7FAFC'
export const textGrey500 = '#718096'
export const textGrey600 = '#4A5568'
export const textGrey700 = '#2D3748'
export const textGrey800 = '#1A202C'

// OTHER
export const borderColor = '#E2E8F0'
export const submitButtonBackground = '#4D5DFB'
export const boxShadow = '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
