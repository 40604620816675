import React from 'react'
import { Messages } from '../../../../../constants'
import Helper from '../../../../../Helper'
import styles from './index.module.scss'

class SendRequestDialogContent extends React.Component {
  render() {
    let isShowNoteOfCommitTimeAllProject = false
    const { data, inputValueChangeHandler, inputReasonChangeHandler, isShowErrorMessage, errorMessage } = this.props
    let currentCommitTime = Helper.getFormattedNumber(data.commitTime, 1)
    if (currentCommitTime === '-') {
      currentCommitTime = 0
    }
    if (data.isAll) {
      isShowNoteOfCommitTimeAllProject = true
    }
    return (
      <div className="confirm-dialog">
        <p className="warning-message">{Messages.sendRequestDialogWarning}</p>
        <p className="confirm-message">
          {Messages.sendRequestDialogContentPart1}{' '}
          <span className="highlight">{currentCommitTime} (hours per week)</span>
        </p>
        <div className="confirm-delete-again">
          <p className="guide-message">{Messages.sendRequestDialogContentPart2}</p>
          {isShowErrorMessage && <span className="confirm-error-message">{errorMessage}</span>}
          <div className="field">
            <label>Value</label>
            <input
              className={styles.deleteInputField}
              type="number"
              min={0}
              onChange={(event) => inputValueChangeHandler(event)}
            />
          </div>
          <div className="field">
            <label>Reason</label>
            <textarea className={styles.deleteInputField} onChange={(event) => inputReasonChangeHandler(event)} />
          </div>
          {isShowNoteOfCommitTimeAllProject && (
            <span className="confirm-warning-message">*NOTE: {Messages.noteSendRequestForAllProjects}</span>
          )}
        </div>
      </div>
    )
  }
}

export default SendRequestDialogContent
