import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import get from 'lodash/get'
import { handleLogout } from 'utils'
import { AppConfig } from 'constants/index'
import { EAuthStorageKey, IHeader } from './constants'

const apiV2: AxiosInstance = axios.create({
  baseURL: AppConfig.API_URL_V2,
})

apiV2.interceptors.request.use(function (config: AxiosRequestConfig) {
  const authHeaderV2: IHeader = getAuthHeaderV2()
  config.headers = {
    ...authHeaderV2,
  }
  return config
})

apiV2.interceptors.response.use(
  function (response: AxiosResponse) {
    return {
      status: response?.status,
      data: get(response?.data, 'data', response?.data),
    }
  },
  function (error: AxiosError) {
    const errorResponse: AxiosResponse = error?.response

    handleApiV2Error(errorResponse)
    throw error
  }
)

function getAuthHeaderV2(): IHeader {
  const isRememberMe: boolean = JSON.parse(window.localStorage.getItem(EAuthStorageKey.IS_REMEMBER_ME))

  const requestToken: string = isRememberMe
    ? window.localStorage.getItem(EAuthStorageKey.REQUEST_TOKEN_V2)
    : window.sessionStorage.getItem(EAuthStorageKey.REQUEST_TOKEN_V2)

  return {
    Authorization: 'Bearer ' + requestToken,
  }
}

function handleApiV2Error(errorResponse: AxiosResponse) {
  const status: number = errorResponse?.status

  if (status === 401) {
    handleLogout()
  }
}

export default apiV2
