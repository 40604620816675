import { login, loginV2 } from 'API/auth'
import { ILoginResponse } from 'API/auth/constants'
import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import omit from 'lodash/omit'
import { makeAutoObservable } from 'mobx'
import { ILoginForm, IAuthUser } from '../types/user'
import RootStore from './rootStore'

class AuthStore {
  rootStore: RootStore
  userProfile: IAuthUser

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async login(data: ILoginForm): Promise<void> {
    try {
      const response: ISuccessResponse<ILoginResponse> = await login(omit(data, 'isRememberMe'))
      const responseV2: ILoginResponse = await loginV2(omit(data, 'isRememberMe'))

      const user: IAuthUser = response?.data?.user
      this.userProfile = user

      window.localStorage.setItem('userId', user?.id)
      window.localStorage.setItem('userRole', user?.role)
      window.localStorage.setItem('email', user?.email)
      window.localStorage.setItem('fullName', user?.fullName)
      window.localStorage.setItem('title', user?.title)
      window.localStorage.setItem('avatar', user?.avatar)
      window.localStorage.setItem('isRememberMe', JSON.stringify(data?.isRememberMe))

      if (data?.isRememberMe) {
        window.localStorage.setItem('aToken', user?.aToken)
        window.localStorage.setItem('rToken', user?.rToken)
        window.localStorage.setItem('aTokenV2', responseV2?.user?.aToken)
        window.localStorage.setItem('rTokenV2', responseV2?.user?.rToken)
      } else {
        window.sessionStorage.setItem('aToken', user?.aToken)
        window.sessionStorage.setItem('rToken', user?.rToken)
        window.sessionStorage.setItem('aTokenV2', responseV2?.user?.aToken)
        window.sessionStorage.setItem('rTokenV2', responseV2?.user?.rToken)
      }
    } catch (error) {
      handleError(error, 'src/store/authstore.ts', 'login')
    }
  }
}

export default AuthStore
