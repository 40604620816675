import { ITableHeader } from 'components/Table'

export enum EContractTabs {
  CONTRACT_CREATE = 'Contract Create',
  LABOR_CONTRACT = 'Labor Contract',
  FREELANCER_CONTRACT = 'Freelancer Contract',
  CONFIDENTIALITY_AGREEMENT = 'Confidentiality Agreement',
}
export function getHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Full name',
      accessor: 'fullNameVI',
    },
    {
      Header: 'Contract No.',
      accessor: 'number',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Deleted',
      accessor: 'isDeleted',
    },
    {
      Header: 'Languages',
      accessor: 'language',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export function getHeaderLaborContractList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Full name',
      accessor: 'fullNameVI',
    },
    {
      Header: 'Contract No.',
      accessor: 'number',
    },
    {
      Header: 'Category',
      accessor: 'laborContractCategory',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Deleted',
      accessor: 'isDeleted',
    },
    {
      Header: 'Languages',
      accessor: 'language',
    },

    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}
