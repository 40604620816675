import { VStack, Image, Text, Container } from '@chakra-ui/react'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import Helper from 'Helper'
import { useFormContext } from 'react-hook-form'
import styles from '../../index.module.scss'
interface IUserAvatarProps {
  name?: string
}

const UserAvatar = (props: IUserAvatarProps) => {
  const { getValues } = useFormContext()
  const { name } = props

  const avatarURL = getValues(name)
  const fullAvatarURL = Helper.generateImageURL(avatarURL, 'avatar')
  return (
    <Container background="white" width="full" maxWidth={{ base: 'unset', xl: '300px' }} padding={0} borderRadius={8}>
      <VStack className={styles.avatarSection}>
        <Text fontSize={{ xl: '16px', lg: '14px', md: '14px', sm: '14px' }}>User Profile Picture</Text>
        {fullAvatarURL ? (
          <Image
            width="216px"
            height="216px"
            objectFit="cover"
            src={fullAvatarURL}
            alt="profile avatar"
            className={styles.avatarImage}
          />
        ) : (
          <EmptyContentBox text="There is no avatar here" />
        )}
      </VStack>
    </Container>
  )
}

export default UserAvatar
