import { Box, HTMLChakraProps, Image } from '@chakra-ui/react'
import loginBackgroundURL from 'assets/images/background/login-background.png'

const LoginBackground = (props: HTMLChakraProps<'div'>) => {
  return (
    <Box>
      <Image src={loginBackgroundURL} alt="login-background" objectFit="contain" h="full" {...props} />
    </Box>
  )
}

export default LoginBackground
