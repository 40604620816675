import { RoutesConstants } from '../../../../constants'
import { IMenuList, IRouteName, timesheetSubmenu, timesheetSubmenuSupperVisor, timesheetSubmenuUser } from './constant'

export function getRouteName(isAdmin: boolean): IRouteName {
  const route: IRouteName = {
    userRoute: isAdmin ? RoutesConstants.USER_LIST : '',
    partner: isAdmin ? RoutesConstants.PARTNER : '',
    timesheetRoute: isAdmin ? RoutesConstants.TIMESHEET_ADMIN : RoutesConstants.TIMESHEET,
    projectRoute: isAdmin ? RoutesConstants.PROJECTS_ADMIN_LIST : RoutesConstants.PROJECT_LIST,
    commitTimeRoute: isAdmin ? RoutesConstants.COMMIT_TIME_ADMIN : RoutesConstants.COMMIT_TIME,
    internalDocument: isAdmin ? RoutesConstants.INTERNAL_DOCUMENT : '',
    paymentRoute: isAdmin ? RoutesConstants.PAYMENTS_ADMIN : RoutesConstants.PAYMENTS,
    policyRoute: isAdmin ? RoutesConstants.POLICIES_ADMIN : RoutesConstants.POLICIES,
    infoRoute: isAdmin ? RoutesConstants.INFOS_ADMIN : RoutesConstants.INFOS,
    feedbackRoute: isAdmin ? RoutesConstants.FEEDBACK_ADMIN : RoutesConstants.FEEDBACK,
    diagramRoute: isAdmin ? RoutesConstants.DIAGRAM : '',
    linkToHome: isAdmin ? RoutesConstants.PARTNER : RoutesConstants.PROJECT_LIST,
    qrCodeRoute: isAdmin ? RoutesConstants.QR_CODE : '',
    dashboardRoute: isAdmin ? RoutesConstants.PARTNER : RoutesConstants.USER_DASHBOARD,
  }
  return route
}

export function getTimesheetSubmenu(isAdmin: boolean, isSupervisor: boolean): IMenuList[] {
  if (isAdmin) {
    return timesheetSubmenu
  }
  if (isSupervisor) {
    return timesheetSubmenuSupperVisor
  }
  return timesheetSubmenuUser
}
