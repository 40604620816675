import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, HStack } from '@chakra-ui/react'
import ActionButton from 'componentsCharka/ActionButton'
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form'
import { textGrey700, borderColor, submitButtonBackground } from 'theme/globalStyles'
import EditProjectRatingForm from './components/ProjectRatingRequestForm'
import { ModalFooter, ModalHeader } from './editProjectRatingModal.style'
import { IProjectRatingRequestModalProps, IEditProjectRatingForm } from './types'

const EditProjectRatingModal = (props: IProjectRatingRequestModalProps) => {
  const currentProjectHourlyRate: number = 3.5
  const userName: string = 'User Name'
  const { onClose, isOpen } = props
  const methods: UseFormReturn<IEditProjectRatingForm> = useForm<IEditProjectRatingForm>({
    defaultValues: {
      userName: userName,
      currentProjectHourlyRate: currentProjectHourlyRate,
      project: 'Option 1',
    },
  })
  const { handleSubmit } = methods

  //TODO: transform data format when integrate
  function onSubmit(data: IEditProjectRatingForm): void {
    console.log(data)
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent maxHeight={{ base: '740px', md: '640px' }} maxWidth="600px">
            <ModalHeader>Edit User Project Rating</ModalHeader>
            <ModalCloseButton background="white" color="black" marginTop={1} />
            <ModalBody paddingY={0}>
              <EditProjectRatingForm />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4} paddingY={4}>
                <ActionButton
                  title="Cancel"
                  buttonStyles={{
                    color: textGrey700,
                    background: 'white',
                    border: `1px solid ${borderColor}`,
                  }}
                  onClick={onClose}
                />
                <ActionButton
                  title="Save"
                  type="submit"
                  buttonStyles={{
                    color: 'white',
                    background: submitButtonBackground,
                    borderRadius: '6px',
                    _hover: {
                      background: submitButtonBackground,
                    },
                  }}
                />
              </HStack>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default EditProjectRatingModal
