import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { getProjectDetailOfUserV2, getProjectsOfUserV2 } from 'API/project'
import { IProjectDetailResponse, IProjectsOfUserResponse } from 'API/project/constants'
import { getDetailTimeSheetForUserV2, getTimeSheetListOfProjectV2, getTimeSheetListV2 } from 'API/timesheet'
import {
  IDetailTimesheetResponseData,
  IGroupTimeSheetToFE,
  IResponseTimeSheetWithTags,
  ITimeSheetList,
  ITimeSheetToFE,
} from 'API/timesheet/constants'
import { ICalendarTimeData } from 'components/Calendar/constants'
import { makeAutoObservable } from 'mobx'
import RootStore from 'store/rootStore'
import { IProject } from 'types/project'

class UserTimesheetStore {
  rootStore: RootStore
  project: IProject
  timesheetList: ITimeSheetToFE[]
  groups: IGroupTimeSheetToFE[]
  projects: IProject[]
  currentTimesheet: IResponseTimeSheetWithTags
  isDuplicate: boolean
  calendarTimeData: ICalendarTimeData

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async fetchTimesheets(projectId?: string): Promise<void> {
    try {
      let response: ISuccessResponse<ITimeSheetList>

      if (projectId) {
        response = await getTimeSheetListOfProjectV2(projectId)
      } else {
        response = await getTimeSheetListV2()
      }

      this.timesheetList = response?.data?.timesheets
      this.groups = response?.data?.groups
    } catch (error) {
      handleError(error as Error, 'src/store/user/timesheetStore.ts', 'fetchTimesheets')
    }
  }

  public async fetchProjectDetail(projectId: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectDetailResponse> = await getProjectDetailOfUserV2(projectId)

      this.project = response?.data?.project
    } catch (error) {
      handleError(error as Error, 'src/store/user/timesheetStore.ts', 'fetchProjectDetail')
    }
  }

  public async fetchProjectsOfUser(): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectsOfUserResponse> = await getProjectsOfUserV2()
      this.projects = response?.data?.projects
    } catch (error) {
      handleError(error as Error, 'src/store/user/timesheetStore.ts', 'fetchProjectsOfUser')
    }
  }

  public async fetchTimesheetDetail(timesheetId: string): Promise<void> {
    try {
      const response: ISuccessResponse<IDetailTimesheetResponseData> = await getDetailTimeSheetForUserV2(timesheetId)

      this.currentTimesheet = response?.data?.timesheet
    } catch (error) {
      handleError(error as Error, 'src/store/user/timesheetStore.ts', 'fetchProjectsOfUser')
    }
  }

  public resetTimesheetDetail(): void {
    this.currentTimesheet = undefined
  }

  public setIsDuplicate(currentIsDuplicate: boolean): void {
    this.isDuplicate = currentIsDuplicate
  }

  public setCalendarTimeData(currentTimeData: ICalendarTimeData): void {
    this.calendarTimeData = currentTimeData
  }
}

export default UserTimesheetStore
