import React, { useEffect } from 'react'
import { Stack, HStack, VStack, Container, Text, Button } from '@chakra-ui/react'
import Helper from 'Helper'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { IPartner } from 'types/partner'
import { getValidArray } from 'utils'
import { PartnerCategory, PartnerType } from 'constants/defaultOptionList'
import { ECreatableOptionTypes } from 'constants/enum'
import { RoutesConstants } from 'constants/index'
import { ICreatableOption } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import PartnerCreateForm from '../PartnerCreate/components/PartnerCreateForm'
import styles from '../PartnerDetail/index.module.scss'
const PartnerCreate = () => {
  const { adminPartnerStore } = useStores()
  const { creatableOptionList } = adminPartnerStore
  const history = useHistory()
  const methods = useForm()
  const { handleSubmit, reset, setValue } = methods
  const options = getValidArray(creatableOptionList)
  const titleOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.TITLE_OF_USER)
  )
  const levelOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.LEVEL_OF_USER)
  )
  const paymentCategoryOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER)
  )
  const partnerCategoryOptions = Helper.createOptionsOfSelect(PartnerCategory)
  useEffect(() => {
    adminPartnerStore.getOptionList()
  }, [])
  const submitHandler = async (data: IPartner) => {
    await adminPartnerStore.createPartner(data, history)
  }
  function resetForm() {
    reset()
    history.push(RoutesConstants.PARTNER)
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data: IPartner) => submitHandler(data))}>
        <VStack>
          <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
            <Stack
              direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'column' }}
              margin={{ base: 6, lg: 6 }}
              height="full"
              background="white"
              justifyContent="space-between">
              <Text fontSize="lg" color="gray.700" margin={0}>
                New Partner
              </Text>
              <HStack>
                <Button
                  colorScheme="blue"
                  variant="outline"
                  isFullWidth
                  type="button"
                  onClick={resetForm}
                  _hover={{
                    cursor: 'pointer',
                  }}>
                  Cancel
                </Button>
                <Button
                  bg={'#4D5DFB'}
                  minWidth={'none'}
                  isFullWidth
                  className={styles.editButton}
                  variant="solid"
                  type="submit">
                  Save
                </Button>
              </HStack>
            </Stack>
          </Container>
          <HStack width="full" justifyContent="space-between" alignItems="flex-start">
            <VStack width="100%">
              <PartnerCreateForm
                partnersType={PartnerType}
                patnerCategoryOptions={partnerCategoryOptions}
                titleOptions={titleOptions}
                levelOptions={levelOptions}
                paymentCategoryOptions={paymentCategoryOptions}
                methods={methods}
              />
            </VStack>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  )
}

export default observer(PartnerCreate)
