import { useEffect, useState } from 'react'
import { chakra, VStack } from '@chakra-ui/react'
import { handleError } from 'API/error'
import ConfirmArchiveModal from 'componentsCharka/ConfirmArchiveModal'
import ConfirmDeleteModal from 'componentsCharka/ConfirmDeleteModal'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getParsedProjectValueBeforeRenderFE } from 'utils'
import { RoutesConstants, actionOnDataTable } from 'constants/index'
import { IProject } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import { IRowActionItem } from '../../constant'
import ActionButtonGroup from './components/ActionButtonGroup'
import CustomerInformationSection from './components/CustomerInformationSection'
import GeneralInformationSection from './components/GeneralInformationSection'
import LogoAndSettingSection from './components/LogoAndSettingSection'
import PaymentSection from './components/PaymentSection'
import WorkingInformationSection from './components/WorkingInformationSection'
import { ContentWrapper, FormHeaderTitle, FormHeaderWrapper } from './projectDetailAdmin.styles'

// *INFO: This component will be reuse and rename to use for all device(Desktop, Tablet, Mobile)
const ProjectDetailAdminMobile = () => {
  window.sessionStorage.setItem('subPageTitle', 'Project Detail')
  window.localStorage.removeItem('pageTitle')
  window.localStorage.setItem('pageTitle', 'Project')
  window.dispatchEvent(new Event('storage'))
  const { adminProjectStore } = useStores()
  const { rawProjectDetailData } = adminProjectStore
  const methods: UseFormReturn = useForm<IProject>()
  const { handleSubmit, reset } = methods
  const history = useHistory()
  const params = useParams()
  const projectId: string = get(params, 'id', '')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)
  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState<boolean>(false)
  const [projectDetail, setProjectDetail] = useState<IProject>()
  const isArchived: boolean = Boolean(projectDetail?.isArchived)
  const actions: IRowActionItem[] = [
    {
      name: !!isArchived ? 'Unarchive' : 'Archive',
      handler: () => callOpenDialogConfirm(projectDetail, actionOnDataTable.ARCHIVE),
    },
    {
      name: 'Delete',
      customClassNames: ['isDanger'],
      handler: () => callOpenDialogConfirm(projectDetail, actionOnDataTable.DELETE),
    },
  ]

  function toggleOpenDeleteModal(): void {
    setIsOpenDeleteModal(!isOpenDeleteModal)
  }

  function toggleOpenArchiveModal(): void {
    setIsOpenArchiveModal(!isOpenArchiveModal)
  }

  function switchToEditForm(): void {
    setIsEditing(true)
  }

  function switchToDetailForm(): void {
    setIsEditing(false)
  }

  function callOpenDialogConfirm(project: IProject, nameOfEvent: string): void {
    set(project, 'shortName', project?.name ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      toggleOpenDeleteModal()
    } else {
      toggleOpenArchiveModal()
    }
  }

  async function handleDeleteProject(id: string): Promise<void> {
    try {
      await adminProjectStore.deleteProject(id)
      history.push(RoutesConstants.PROJECTS_ADMIN_LIST)
    } catch (error) {
      toast.error('Delete project failed')
      handleError(
        error as Error,
        'src/containers/ProjectsNew/V2/ProjectDetailAdminMobile/index.tsx',
        'handleDeleteProject'
      )
    }
  }

  async function handleUnArchiveProject(id: string): Promise<void> {
    try {
      await adminProjectStore.unArchiveProject(id)
      history.push(RoutesConstants.PROJECTS_ADMIN_LIST)
    } catch (error) {
      toast.error('Unarchive project failed')
      handleError(
        error as Error,
        'src/containers/ProjectsNew/V2/ProjectDetailAdminMobile/index.tsx',
        'handleUnArchiveProject'
      )
    }
  }

  async function handleArchiveProject(id: string): Promise<void> {
    try {
      await adminProjectStore.archiveProject(id)
      history.push(RoutesConstants.PROJECTS_ADMIN_LIST)
    } catch (error) {
      toast.error('Archive project failed')
      handleError(
        error as Error,
        'src/containers/ProjectsNew/V2/ProjectDetailAdminMobile/index.tsx',
        'handleArchiveProject'
      )
    }
  }

  async function onSubmit(data: IProject): Promise<void> {
    try {
      await adminProjectStore.updateProject(data)
      setIsEditing(false)
    } catch (error) {
      toast.error('Update project failed')
      handleError(error as Error, 'src/containers/ProjectsNew/V2/ProjectDetailAdminMobile/index.tsx', 'onSubmit')
    }
  }

  async function fetchData(): Promise<void> {
    try {
      await adminProjectStore.getProjectDetail(projectId)
    } catch (error) {
      toast.error('Fetch project detail failed')
      handleError(error as Error, 'src/containers/ProjectsNew/V2/ProjectDetailAdminMobile/index.tsx', 'fetchData')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const formattedData: IProject = getParsedProjectValueBeforeRenderFE(rawProjectDetailData, isEditing)
    setProjectDetail(formattedData)
  }, [isEditing, rawProjectDetailData])

  useEffect(() => {
    if (projectDetail) {
      reset(projectDetail)
    }
  }, [projectDetail])

  return (
    <FormProvider {...methods}>
      <chakra.form onSubmit={handleSubmit(onSubmit)}>
        <VStack>
          <FormHeaderWrapper>
            <FormHeaderTitle>{projectDetail ? projectDetail.name : ''}</FormHeaderTitle>
            <ActionButtonGroup
              isEditing={isEditing}
              actions={actions}
              switchToDetailForm={switchToDetailForm}
              switchToEditForm={switchToEditForm}
            />
          </FormHeaderWrapper>
          <ContentWrapper>
            <GeneralInformationSection isEditing={isEditing} />
            <CustomerInformationSection isEditing={isEditing} />
            <PaymentSection isEditing={isEditing} />
            <WorkingInformationSection isEditing={isEditing} />
            <LogoAndSettingSection type="logo" name="logo" isEditing={isEditing} />
          </ContentWrapper>
        </VStack>
      </chakra.form>
      <ConfirmDeleteModal
        data={projectDetail}
        isOpen={isOpenDeleteModal}
        closeHandler={toggleOpenDeleteModal}
        OKClickHandler={handleDeleteProject}
        title="Project"
        content="Project name"
      />
      <ConfirmArchiveModal
        data={projectDetail}
        isOpen={isOpenArchiveModal}
        closeHandler={toggleOpenArchiveModal}
        OKClickHandler={isArchived ? handleUnArchiveProject : handleArchiveProject}
        title="Project"
        content="Project name"
      />
    </FormProvider>
  )
}

export default observer(ProjectDetailAdminMobile)
