import * as Yup from 'yup'
import { Messages } from '../../../../constants'

export const initialValues = {
  typeOfFilter: '',
  user: '',
  project: '',
  groupBy: '',
}
export const validationFilterUserSchema = Yup.object().shape({
  typeOfFilter: Yup.string().required(Messages.fieldRequired),
  user: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
})
export const validationFilterProjectSchema = Yup.object().shape({
  typeOfFilter: Yup.string().required(Messages.fieldRequired),
  project: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
})
