import React from 'react'
import { HStack, FormLabel, FormControl, Wrap, WrapItem } from '@chakra-ui/react'
import { getValidArray } from 'utils'
import { IOption } from 'constants/common'
import TagItem from './TagItem'

interface IInputTagProps {
  label?: string
  data?: IOption[]
  className?: string
  handleBackgroundColor?: (value: string) => string
  handleTextColor?: (value: string) => string
  handleOnClick?: (value: string) => void
}

const InputTag = (props: IInputTagProps) => {
  const { data, label, handleBackgroundColor, handleTextColor, handleOnClick } = props
  const isCanClick: boolean = typeof handleOnClick === 'function'
  const isBackgroundColor: boolean = typeof handleBackgroundColor === 'function'

  return (
    <FormControl padding={{ lg: '0px', md: '10px', sm: '10px' }}>
      <FormLabel fontWeight="normal" color="gray.700" lineHeight={6} marginBottom={3} marginInlineEnd={0}>
        {label}
      </FormLabel>

      <HStack>
        <Wrap>
          {getValidArray(data).map((item: IOption, index: number) => (
            <WrapItem
              key={index}
              onClick={isCanClick ? () => handleOnClick(item?.value) : undefined}
              cursor={isCanClick ? 'pointer' : 'default'}
              backgroundColor={isBackgroundColor ? handleBackgroundColor(item?.label ?? '') : undefined}>
              <TagItem key={index} value={item?.label ?? ''} handleTextColor={handleTextColor} />
            </WrapItem>
          ))}
        </Wrap>
      </HStack>
    </FormControl>
  )
}

export default InputTag
