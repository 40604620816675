import { ICreatableOption } from 'types/creatableOption'
import { IProject } from 'types/project'
import { EYesNoOptionWithCapitalization } from 'constants/enum'
import { IPartner } from 'constants/schema'

export const isSupervisor = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]
export const isDowloadCV = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]
export const isActive = [EYesNoOptionWithCapitalization.YES, EYesNoOptionWithCapitalization.NO]

export interface ICreateUserFormProps {
  partnerList: IPartner[]
  projectList: IProject[]
  creatableOptionList: ICreatableOption[]
  submitHandler
}
