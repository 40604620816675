import { IProject } from 'types/project'
import { IUser } from 'types/user'
import { EPaymentCategoryOfProject, EProjectTypes, EWorkingHourPeriods } from 'constants/enum'

export const DIAGRAM_NODE_BASE_URL: string = '/diagram-nodes'

export interface IDiagramOverview {
  projects: IProjectNodeData[]
  users: IDeveloperNodeData[]
}

export interface IDeveloperNodeData extends Partial<IUser> {
  id: string
  commitTime: number
  totalWorkingTime: number
  averageWorkingTime: number
}

export interface IProjectNodeData extends Partial<IProject> {
  id: string
  developers: IDeveloperNodeData[]
  totalWorkingTime: number
  paymentCategory: EPaymentCategoryOfProject
}

export interface IAssignment {
  project: string
  user: string
  projectHourlyRate: number
  projectClientRate?: number
  commitTime: number
  projectType: EProjectTypes
  isSupervisor: boolean
  projectPaymentCategory: EPaymentCategoryOfProject
}

export interface IAssignmentDetailQuery extends Pick<IAssignment, 'project' | 'user' | 'projectPaymentCategory'> {}

export interface IDiagramQuery {
  period?: EWorkingHourPeriods
  date?: string
  partners?: string[]
  technologies?: string[]
  isInactive?: boolean
  search?: string
}
