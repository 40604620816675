import apiV2 from 'API/apiV2'
import { getErrorMessage } from 'API/error'
import { AxiosResponse } from 'axios'
import { IFilter } from 'types/query'
import { IDiagramNode } from 'constants/schema'
import {
  DIAGRAM_NODE_BASE_URL,
  IAssignment,
  IAssignmentDetailQuery,
  IDiagramOverview,
  IDiagramQuery,
} from './constants'

export async function getDiagramOverview(filter: IFilter<IDiagramQuery>): Promise<IDiagramOverview> {
  try {
    const url: string = `${DIAGRAM_NODE_BASE_URL}/overview?filter=${JSON.stringify(filter)}`
    const response: AxiosResponse = await apiV2.get(url)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function updateDiagramNode(diagramNodeId: string, payload: IDiagramNode): Promise<void> {
  try {
    const url: string = `${DIAGRAM_NODE_BASE_URL}/${diagramNodeId}`
    await apiV2.patch(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function reInitializeNodesCoordinates(): Promise<void> {
  try {
    const url: string = `${DIAGRAM_NODE_BASE_URL}/re-initialize`
    await apiV2.get(url)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function createNewAssignment(payload: IAssignment): Promise<void> {
  try {
    const url: string = `${DIAGRAM_NODE_BASE_URL}/assign`
    await apiV2.post(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function getAssignments(payload: IAssignmentDetailQuery[]): Promise<IAssignment[]> {
  try {
    const url: string = `${DIAGRAM_NODE_BASE_URL}/assignments`
    const response: AxiosResponse = await apiV2.post(url, payload)
    return response.data
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function updateAssignments(payload: IAssignment[]): Promise<void> {
  try {
    const url: string = `${DIAGRAM_NODE_BASE_URL}/assignments/update`
    await apiV2.patch(url, payload)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}

export async function removeAssignment(filter: IFilter<IAssignmentDetailQuery>): Promise<void> {
  try {
    const url: string = `${DIAGRAM_NODE_BASE_URL}/assignments?filter=${JSON.stringify(filter)}`
    await apiV2.delete(url)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
