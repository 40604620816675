export enum ECreatableOptionTypes {
  LEVEL_OF_USER = 'level',
  TITLE_OF_USER = 'title',
  PAYMENT_CATEGORY_OF_USER = 'payment category of user',
  DOCTYPE_OF_INTERNAL_DOCS = 'doctype',
}

export const EPaymentCategoryOfUser = {
  PAYROLL: 'Payroll',
  FREELANCER_FEE: 'Freelancer Fee',
  MIXED_PAYROLL_FREELANCER_FEE: 'Mix Payroll & Freelancer Fee',
}

export enum EPaymentCategoryOfUserEnum {
  PAYROLL = 'Payroll',
  FREELANCER_FEE = 'Freelancer Fee',
  MIXED_PAYROLL_FREELANCER_FEE = 'Mix Payroll & Freelancer Fee',
}

export enum EStatusOfPaymentRequest {
  PENDING = 'Pending',
  DENIED = 'Denied',
  APPROVED = 'Approved',
}

export enum EContractTypes {
  LABOR_CONTRACT = 'Labor Contract',
  FREELANCER_CONTRACT = 'Freelancer Contract',
  CONFIDENTIALITY_AGREEMENT = 'Confidentiality Agreement',
}

export enum EAbbreviatedContractTypesVI {
  LABOR_CONTRACT = 'HDLD-UDT',
  FREELANCER_CONTRACT = 'HDDV-UDT',
  CONFIDENTIALITY_AGREEMENT = 'TTBM-UDT',
}

export enum EContractLanguages {
  VIETNAMESE = 'VIETNAMESE',
  BILINGUAL = 'BILINGUAL',
}

export enum ELaborContractCategories {
  OFFICIAL = 'OFFICIAL',
  PROBATION = 'PROBATION',
}

export enum ELaborContractTerm {
  INDEFINITE_TERM = 'Indefinite term',
  DEFINITE_TERM = 'Definite term',
}

export enum EPaymentCategoryOfProject {
  HOURLY = 'Hourly',
  FIXED_PRICE = 'Fixed Price',
}

export enum ERoleOfUser {
  USER = 'user',
  ADMIN = 'admin',
}

export enum EPartnerType {
  ORGANIZATIONS = 'organizations',
  PERSONAL = 'personal',
}
export enum EYesNoOption {
  YES = 'yes',
  NO = 'no',
}
export enum EYesNoOptionWithCapitalization {
  YES = 'Yes',
  NO = 'No',
}

export enum EPartnerCategory {
  EMPLOYEE = 'employee',
  FREELANCER = 'freelancer',
  OWNER = 'owner',
  CLIENT = 'client',
  SUPPLIER = 'supplier',
  BANK = 'bank',
}

export enum EFeatureBlockOfAccounting {
  EXPENSE = 'Expense',
  INCOME = 'Income',
}

export enum ECommitTimeRequestTableStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum EPlatformVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export enum EActivityObjectType {
  TIME_SHEET = 'timesheet',
  USER = 'user',
  PROJECT = 'project',
  INFORMATION = 'information',
  POLICY = 'policy',
  SETTING = 'setting',
  FEEDBACK = 'feedback',
}

export enum EFeatureFlags {
  BACKEND_V2 = 'backend_v2',
  DIAGRAM = 'diagram',
  TIMESHEET_V2 = 'timesheet_v2',
  NODE_DETAIL_OPTIONS = 'diagram_node_detail_options',
}

export enum EStorageKeys {
  BACKEND_URL = 'backendUrl',
}

export enum EEnvironment {
  DEVELOP = 'develop',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum EUserEducation {
  UNDERGRADUATE = 'Undergraduate',
  BACHELOR = 'Bachelor',
  MASTER = 'Master',
  DOCTOR = 'Doctor',
  CERTIFICATE = 'Certificate',
}

export enum EUserEducationVI {
  UNDERGRADUATE = 'Đại học',
  BACHELOR = 'Cử nhân',
  MASTER = 'Thạc sĩ',
  DOCTOR = 'Tiến sĩ',
  CERTIFICATE = 'Chứng chỉ',
}

export enum EGender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum EGenderVI {
  MALE = 'Nam',
  FEMALE = 'Nữ',
}

export enum EViewModeTimesheet {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum ECreatableOptionScope {
  USER = 'user',
  INTERNAL_DOCUMENT = 'internal-document',
}

export enum ETypeOfFilterOfHeadMap {
  FILTER_BY_PROJECT = 'filter by project',
  FILTER_BY_USER = 'filter by user',
  FILTER_ALL = 'filter all',
}

export enum ECommitTimeGroupBy {
  PROJECT = 'projects',
  USER = 'users',
}

export enum ECommitTimeTypeOfFilter {
  BY_PROJECT = 'filter by project',
  BY_USER = 'filter by user',
  ALL = 'filter all',
}

export enum EDiagramNodeTypes {
  PROJECT = 'project',
  USER = 'user',
}

export enum EProjectTypes {
  NORMAL_PROJECT = 'normal-project',
  EXTRA_PROJECT = 'extra-project',
  EXTERNAL_PROJECT = 'external-project',
}

export enum EWorkingHourPeriods {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum EPerformanceReportTypes {
  SALARY = 'salary',
  LOGGED_TIME = 'logged-time',
}

export enum EUserStatusLabel {
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export enum EPathName {
  DETAIL = 'detail',
  PROJECT = 'project',
  NEW = 'new',
  INDEX = 'index',
}
