import api from 'API'
import { handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import {
  COMMIT_TIME_BASE_URL,
  ICommitTimeRequestList,
  ICommitTimeFilterPayload,
  ICommitTimeReportsResponse,
  ICommitTimeRequestRejectPayload,
  ICommitTimeListAdminResponse,
  ICommitTimeList,
  ICommitTimeSendRequestChange,
} from './constants'

export async function getCommitTimeRequestList(): Promise<ICommitTimeRequestList> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/request-list`
    const response: AxiosResponse = await api.get(url)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'getCommitTimeRequestList')
    return {} as ICommitTimeRequestList
  }
}

export async function closeCommitTimeRequest(id: string): Promise<ICommitTimeRequestList> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/request-close/${id}`
    const response: AxiosResponse = await api.get(url)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'closeCommitTimeRequest')
    return {} as ICommitTimeRequestList
  }
}

export async function getCommitTimeReportsAdmin(
  payload: ICommitTimeFilterPayload
): Promise<ICommitTimeReportsResponse> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/report-list-admin`
    const response: AxiosResponse = await api.post(url, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'getCommitTimeReportsAdmin')
    return {} as ICommitTimeReportsResponse
  }
}

export async function getCommitTimeRequestListAdmin(): Promise<ICommitTimeRequestList> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/request-list-admin`
    const response: AxiosResponse = await api.get(url)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'getCommitTimeRequestsAdmin')
    return {} as ICommitTimeRequestList
  }
}

export async function rejectCommitTimeRequest(
  payload: ICommitTimeRequestRejectPayload
): Promise<ICommitTimeRequestList> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/reject-request`
    const response: AxiosResponse = await api.post(url, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'rejectCommitTimeRequest')
    return {} as ICommitTimeRequestList
  }
}

export async function approveCommitTimeRequest(commitTimeRequestId: string): Promise<ICommitTimeRequestList> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/approve-request/${commitTimeRequestId}`
    const response: AxiosResponse = await api.get(url)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'approveCommitTimeRequest')
    return {} as ICommitTimeRequestList
  }
}

export async function getCommitTimeList(payload: ICommitTimeFilterPayload): Promise<ICommitTimeListAdminResponse> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/list-admin`
    const response: AxiosResponse = await api.post(url, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'getCommitTimeList')
    return {} as ICommitTimeListAdminResponse
  }
}

export async function editCommitTime(payload: ICommitTimeFilterPayload): Promise<ICommitTimeListAdminResponse> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/edit-by-admin`
    const response: AxiosResponse = await api.post(url, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'editCommitTime')
    return {} as ICommitTimeListAdminResponse
  }
}

export async function getCommitTimeReports(): Promise<ICommitTimeReportsResponse> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/report-list`
    const response: AxiosResponse = await api.get(url)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'getCommitTimeReports')
    return {} as ICommitTimeReportsResponse
  }
}

export async function getListCommitTime(): Promise<ICommitTimeList> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/list`
    const response: AxiosResponse = await api.get(url)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'getListCommitTime')
    return {} as ICommitTimeList
  }
}

export async function sendCommitTimeRequestChange(payload: ICommitTimeSendRequestChange): Promise<object> {
  try {
    const url: string = `${COMMIT_TIME_BASE_URL}/send-request-change`
    const response: AxiosResponse = await api.post(url, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/commit-time', 'sendCommitTimeRequestChange')
    return {}
  }
}
