import React from 'react'
import RadioButton from '../../../../components/RadioButton/RadioButtonWithoutFormik'
import { NotificationId } from '../../../../constants/defaultOptionList'
import { firebaseCloudMessaging } from '../../../../utils/fcm'
import ApiHelper from '../../../../Helper/api'
import { AppConfig } from '../../../../constants'

class PushNotification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notificationId: '',
      enableNotification: false,
    }
  }

  componentDidMount() {
    const notificationId = localStorage.getItem(NotificationId)
    if (!!notificationId) {
      this.setState({
        notificationId,
        enableNotification: true,
      })
    }
    if (!notificationId) {
      this.setState({
        enableNotification: false,
      })
    }
  }

  enableNotification = async () => {
    await firebaseCloudMessaging.init()
    const token = firebaseCloudMessaging.tokenInLocalStorage()
    if (token) {
      this.setState({
        notificationId: token,
        enableNotification: true,
      })
      await ApiHelper.post({
        url: `/fcm/create`,
        data: {
          userId: window.localStorage.getItem('userId'),
          fcmToken: token,
        },
      })
    }
  }

  disableNotification = async () => {
    const token = firebaseCloudMessaging.tokenInLocalStorage()
    this.setState({
      notificationId: '',
      enableNotification: false,
    })
    localStorage.removeItem(NotificationId)
    await ApiHelper.post({
      url: `/fcm/delete`,
      data: {
        userId: window.localStorage.getItem('userId'),
        fcmToken: token,
      },
    })
  }

  onClickSwitch = async () => {
    if (!this.state.enableNotification) {
      this.enableNotification()
    } else {
      this.disableNotification()
    }
  }

  render() {
    const { enableNotification } = this.state

    return (
      <div className="container-fluid setting-item">
        <div className="box box-default">
          <div className="box-header">Push notification</div>
          <div className="box-divider" />
          <div className={`box-body`}>
            <div className="container-fluid">
              <div className="options-group">
                <RadioButton
                  label="Enable notification in this browser"
                  value={enableNotification}
                  clickHandler={this.onClickSwitch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PushNotification
