import { useEffect } from 'react'
import {
  Button,
  SimpleGrid,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  HStack,
  Box,
} from '@chakra-ui/react'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import GroupRadio from 'componentsCharka/GroupRadio'
import SwitchControl from 'componentsCharka/SwitchControl'
import Helper from 'Helper'
import get from 'lodash/get'
import { FormProvider, useForm } from 'react-hook-form'
import { textSecondary } from 'theme/globalStyles'
import { ICreatableOption } from 'types/creatableOption'
import { getValidArray } from 'utils'
import { ECreatableOptionTypes } from 'constants/enum'
import styles from './index.module.scss'
export interface IFilterForm {
  openModalFilterForm?: boolean
  setOpenFilterForm: (status: boolean) => void
  creatableOptionList?
  filterSubmit?
}

const FilterForm = (props: IFilterForm) => {
  const { openModalFilterForm, setOpenFilterForm, creatableOptionList, filterSubmit } = props

  const methods = useForm()
  const { register, handleSubmit, reset } = methods

  const filterValuesOfUsers = window.localStorage.getItem('filterValuesOfUsers')
  const filterValuesOfUsersJSON = JSON.parse(filterValuesOfUsers)
  const selectedRole = get(filterValuesOfUsersJSON, 'role', 'user')
  const selectedLevels = get(filterValuesOfUsersJSON, 'levelId', [])
  const selectedTitles = get(filterValuesOfUsersJSON, 'titleId', [])
  const selectedPaymentCategories = get(filterValuesOfUsersJSON, 'paymentCategoryId', [])
  const isActive = get(filterValuesOfUsersJSON, 'isActive', true)
  const isArchived = get(filterValuesOfUsersJSON, 'isArchived', false)
  const isDeleted = get(filterValuesOfUsersJSON, 'isDeleted', false)

  const userRole = ['all', 'admin', 'user']

  const options = getValidArray(creatableOptionList)
  const titleOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.TITLE_OF_USER)
  )
  const levelOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.LEVEL_OF_USER)
  )
  const paymentCategoryOptions = Helper.createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER)
  )

  function closeModal(): void {
    setOpenFilterForm(false)
  }

  function resetForm() {
    reset({
      titleId: [],
      levelId: [],
      paymentCategoryId: [],
      isActive: true,
      isArchived: false,
      isDeleted: false,
    })
  }
  useEffect(() => {
    if (creatableOptionList) {
      reset({
        role: selectedRole,
        titleId: selectedTitles,
        levelId: selectedLevels,
        paymentCategoryId: selectedPaymentCategories,
        isActive: isActive,
        isArchived: isArchived,
        isDeleted: isDeleted,
      })
    }
  }, [creatableOptionList])
  return (
    <HStack spacing={4} alignSelf="flex-start" marginBottom={6} display={{ base: 'none', md: 'flex' }}>
      <Drawer isOpen={openModalFilterForm} placement="right" onClose={closeModal} size={'lg'}>
        <DrawerOverlay />
        <FormProvider {...methods}>
          <form className="full-form" onSubmit={handleSubmit((data) => filterSubmit(data))}>
            <DrawerContent>
              <DrawerCloseButton _hover={{ cursor: 'pointer' }} color={'gray'} onClick={closeModal} />
              <DrawerHeader>Filter</DrawerHeader>
              <div className="box-divider" />
              <DrawerBody paddingTop={6}>
                <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full" marginBottom={5}>
                  <SwitchControl name="isActive" label="Is Active?" />
                  <SwitchControl name="isArchived" label="Is Archived?" />
                  <SwitchControl name="isDeleted" label="Is Deleted?" />
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <GroupRadio name="role" data={userRole} label="Role" defaultValue={selectedRole} />
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <ChakraInputDropdown isMulti name="titleId" label="User Title" optionsData={titleOptions} />
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <ChakraInputDropdown isMulti name="levelId" label="User Level" optionsData={levelOptions} />
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <ChakraInputDropdown
                    isMulti
                    name="paymentCategoryId"
                    label="Payment Category"
                    optionsData={paymentCategoryOptions}
                  />
                </SimpleGrid>
              </DrawerBody>
              <div className="box-divider" />
              <DrawerFooter justifyContent={'space-between'}>
                <Button
                  _hover={{ cursor: 'pointer' }}
                  colorScheme="blue"
                  variant="ghost"
                  margin={3}
                  onClick={resetForm}>
                  Reset
                </Button>
                <Box>
                  <Button _hover={{ cursor: 'pointer' }} variant="outline" margin={3} onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button background={textSecondary} className={styles.filterButton} type="submit">
                    Apply Filter
                  </Button>
                </Box>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </FormProvider>
      </Drawer>
    </HStack>
  )
}

export default FilterForm
