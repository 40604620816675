import React, { useEffect } from 'react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { Container, Table as CkTable, Thead, Tbody, Tr, Th, Skeleton, Td, Box, Stack } from '@chakra-ui/react'
import cx from 'classnames'
import get from 'lodash/get'
import { useTable, useExpanded, useSortBy, Column } from 'react-table'
import Cell from './components/Cell'
import Pagination from './components/Pagination'
import { defaultExcludedList } from './constant'
import { getExportFileBlob } from './utils'
import styles from './table.module.scss'

export interface IPagination {
  includePagination?: boolean
  tableLength: number
  pageIndex: number
  pageSize?: number
  gotoPage: (pageIndex: number) => void
}

interface ITableProps {
  tableData?: object[]
  headerList?: any[]
  tableSize?: string
  subComponent?: any
  pagination?: IPagination
  isLoading?: boolean
  hasNoSort?: boolean
  columnWidth?: number
  isHightLightExpanded?: boolean
  isManualSort?: boolean
  isStriped?: boolean
  hasBottomHeader?: boolean
  excludedExport?: string[]
  name?: string
  pageSize?: number
  setPageSize?: (page: number) => void
  exportNow?: boolean
  toggleExport?: (isExport: boolean) => void
}

export declare interface ITableHeader {
  Header: string | React.ReactNode
  accessor?: string
  Cell?: React.ReactNode
  columns?: ITableHeader[]
}

export interface IRowActionItem {
  name: string
  customClassNames?: string[]
  handler: () => void
}

const Table = (props: ITableProps) => {
  const {
    tableData,
    headerList,
    tableSize,
    subComponent,
    pagination = { includePagination: false, pageIndex: 1 },
    isLoading = false,
    hasNoSort,
    hasBottomHeader = true,
    excludedExport = defaultExcludedList,
    exportNow,
    toggleExport,
  } = props
  const pageTitle = window.localStorage.getItem('pageTitle')
  const columns: Column<object>[] = React.useMemo(() => headerList, [headerList]) || []
  const paginationComponent = pagination.includePagination ? <Pagination pagination={pagination} /> : null
  const loadingPaginationComponent = isLoading ? null : paginationComponent
  //@ts-ignore-next-line
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable(
    {
      columns,
      data: tableData,
      //@ts-ignore-next-line
      getExportFileBlob,
    },
    !hasNoSort && useSortBy,
    useExpanded
  )

  useEffect(() => {
    if (exportNow) {
      getExportFileBlob(tableData, excludedExport, pageTitle)
      toggleExport(false)
    }
  }, [exportNow])

  return (
    <Stack width="full">
      <Container maxWidth="100%" padding="0px" margin="0px !important">
        <Box className={styles.scrollAble}>
          <CkTable
            size={tableSize ? tableSize : 'md'}
            variant="striped"
            colorScheme="gray"
            outline="none"
            {...getTableProps()}>
            <Thead className={styles.thead}>
              {headerGroups.map((headerGroup) => {
                const { key, ...restHeaderGroup } = headerGroup.getHeaderGroupProps()
                return (
                  <Tr key={`tr-${key}`} {...restHeaderGroup}>
                    {headerGroup.headers.map((column: any) => {
                      const { key } = column.getHeaderProps()
                      const formattedClassName: string = (column?.Header ?? '').replace(' ', '-').toLowerCase()
                      const columnHeaderProps = column.Header
                        ? column.getHeaderProps(column.getSortByToggleProps)
                        : column.getHeaderProps()
                      return (
                        <Th
                          key={`th-${key}`}
                          {...columnHeaderProps}
                          whiteSpace="nowrap"
                          paddingY={4}
                          color={column.render('Header') === 'LOGO' ? 'white' : 'gray.600'}
                          className={styles[formattedClassName]}>
                          {column.render('Header')}
                          &nbsp;
                          {hasNoSort ? (
                            ''
                          ) : (
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              ) : null}
                            </span>
                          )}
                        </Th>
                      )
                    })}
                  </Tr>
                )
              })}
            </Thead>
            <Tbody {...getTableBodyProps()} color="gray.700" fontWeight="400" fontSize="sm" className={styles.tbody}>
              {rows.map((row, index: number) => {
                prepareRow(row)
                return (
                  <React.Fragment key={`row-${index}`}>
                    {isLoading && <Skeleton className={styles.loadingBar} height={80} />}
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        const { key, ...restCell } = cell.getCellProps()
                        return <Cell key={`cell-${key}`} {...restCell} cell={cell} />
                      })}
                    </Tr>
                    {subComponent && get(row, 'isExpanded', false) && (
                      <Tr>
                        <Td colSpan={visibleColumns.length} className={styles.subComponentContainer}>
                          {subComponent(row)}
                        </Td>
                      </Tr>
                    )}
                  </React.Fragment>
                )
              })}
            </Tbody>
            {hasBottomHeader && rows.length > 4 && (
              <Thead className={cx(styles.thead, styles.bottomHeader)} whiteSpace="nowrap">
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderGroup } = headerGroup.getHeaderGroupProps()
                  return (
                    <Tr key={`tr-${key}`} {...restHeaderGroup}>
                      {headerGroup.headers.map((column: any) => {
                        const { key } = column.getHeaderProps()
                        const columnHeaderProps = column.Header
                          ? column.getHeaderProps(column.getSortByToggleProps)
                          : column.getHeaderProps()
                        return (
                          <Th key={`th-${key}`} {...columnHeaderProps} py={4}>
                            {column.render('Header')}
                            &nbsp;
                            {hasNoSort ? (
                              ''
                            ) : (
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TriangleDownIcon aria-label="sorted descending" />
                                  ) : (
                                    <TriangleUpIcon aria-label="sorted ascending" />
                                  )
                                ) : null}
                              </span>
                            )}
                          </Th>
                        )
                      })}
                    </Tr>
                  )
                })}
              </Thead>
            )}
          </CkTable>
        </Box>
      </Container>
      {loadingPaginationComponent}
    </Stack>
  )
}

export default Table
