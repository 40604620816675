import { saveAs } from 'file-saver'
import Helper from 'Helper'
import get from 'lodash/get'
import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'
import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { generatePath } from 'react-router'
import { toast } from 'react-toastify'
import { IClientBill } from 'types/clientBill'
import {
  getParsedClientBillDataBeforeSendBE,
  getParsedClientBillDataBeforeRenderFE,
  toBase64,
  getInQuery,
  convertToSlug,
} from 'utils'
import { RoutesConstants } from '../../constants'
import ApiHelper from '../../Helper/api'
import RootStore from '../rootStore'

class AdminClientBillStore {
  rootStore: RootStore
  clientBillData
  clientBillList: IClientBill[]
  count
  totalBill: string

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public createClientBill(value, history?) {
    const filterValues = getParsedClientBillDataBeforeSendBE(value)
    ApiHelper.post(
      {
        url: `/client-bill/create`,
        data: filterValues,
      },
      (response) => {
        const pathnameIncomeList = generatePath(RoutesConstants.ACCOUNTING_INCOME_LIST)
        toast.success(response.data.message)
        history.push(pathnameIncomeList)
      }
    )
  }

  public updateClientBill(clientBillID, value, history?) {
    const filterValues = getParsedClientBillDataBeforeSendBE(value)
    ApiHelper.post(
      {
        url: `/client-bill/update/${clientBillID}`,
        data: filterValues,
      },
      (response) => {
        const pathnameIncomeList = generatePath(RoutesConstants.ACCOUNTING_INCOME_LIST)
        toast.success(response.data.message)
        history.push(pathnameIncomeList)
      }
    )
  }

  public deleteClientBill(clientBillID, history?) {
    ApiHelper.get(
      {
        url: `/client-bill/delete/${clientBillID}`,
      },
      (response) => {
        const pathnameIncomeList = generatePath(RoutesConstants.ACCOUNTING_INCOME_LIST)
        toast.success(response.data.message)
        history.push(pathnameIncomeList)
      }
    )
  }

  public getClientBillList(isReset?: boolean, history?: any, filter?: any) {
    const currentPage: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
    const truthyFilter = {
      ...pickBy(filter, identity),
      partnerId: getInQuery(filter.partnerId),
    }
    truthyFilter?.partnerId === null && delete truthyFilter?.partnerId
    ApiHelper.post(
      {
        url: `/client-bill/list`,
        data: { currentPage, perPage: 8, filter: truthyFilter },
      },
      (response) => {
        this.clientBillList = response.data.clientBillList
        this.count = response.data.count
      }
    )
  }

  public getDefaultDataOfTable(value) {
    const filterValues = getParsedClientBillDataBeforeSendBE(value)
    ApiHelper.post(
      {
        url: `/client-bill/generate-default-data`,
        data: filterValues,
      },
      (response) => {
        const data = response.data
        const temporaryData = {
          ...value,
          clientBillItemList: data.defaultData,
        }
        const finalData = getParsedClientBillDataBeforeRenderFE(temporaryData)
        const { parsedValue, totalBill } = finalData
        this.clientBillData = parsedValue
        this.totalBill = totalBill
      }
    )
  }

  public updateDefaultDataOfTable(field: string, indexEl: number, value) {
    const parsedValue = {
      ...value,
    }
    console.log('indexEl', indexEl)
    const clientBillItemListData = value.clientBillItemList
    let totalBill = 0
    const parsedClientBillItemListData = clientBillItemListData.map((clientBillItem, index) => {
      console.log('index', index)

      const clientRate = clientBillItem.hourlyRate
      const quantity = clientBillItem.quantity
      const totalPriceOfItem = clientBillItem.totalPrice
      const convertedTotalPrice = Helper.convertStringToNumber(totalPriceOfItem)
      let totalPrice = convertedTotalPrice ? Number(convertedTotalPrice) : 0
      if (index === indexEl) {
        if (field === 'element') {
          totalPrice = Number(clientRate) * Number(quantity)
        } else {
          totalPrice = Number(convertedTotalPrice)
        }
      }
      if (totalPrice > 0) {
        totalBill += totalPrice
      }
      const parsedData = {
        ...clientBillItem,
        totalPrice: Helper.getFormattedNumber(totalPrice, 1),
      }
      return parsedData
    })
    parsedValue.clientBillItemList = parsedClientBillItemListData
    this.clientBillData = parsedValue
    this.totalBill = Helper.getFormattedNumber(totalBill, 1)
  }

  public getClientBillDetail(clientBillID: string) {
    ApiHelper.get(
      {
        url: `/client-bill/detail/${clientBillID}`,
      },
      (response) => {
        const data = response.data
        const { finalData } = data
        const parsedData = getParsedClientBillDataBeforeRenderFE(finalData, true)
        const { parsedValue, totalBill } = parsedData
        this.clientBillData = parsedValue
        this.totalBill = totalBill
      }
    )
  }

  public exportData = async (value) => {
    const partnerName = get(value, 'partnerId.label')
    const publishedDate = get(value, 'publishedDate')
    const year = moment(publishedDate) ? moment(publishedDate).year() : 0
    const month = moment(publishedDate) ? moment(publishedDate).month() : 0
    const fileName = `${convertToSlug(partnerName)}-${year}-${month + 1}`
    const filterValues = getParsedClientBillDataBeforeSendBE(value)
    ApiHelper.uploadDataAnddownloadFile(
      {
        dataType: 'pdf',
        url: `/client-bill/export-data`,
        data: filterValues,
      },
      async (response) => {
        const clientBillData = response.data
        const result = await toBase64(new Blob([clientBillData], { type: 'application/pdf' }))
        saveAs(result, fileName)
      }
    )
  }
}

export default AdminClientBillStore
