import { ITableHeader } from 'components/Table'
import moment from 'moment'
import * as Yup from 'yup'
import { IUser, IPartner, ICreatableOption, IProject } from 'constants/schema'
export interface IRowActionItem {
  name: string
  customClassNames?: string[]
  handler: () => void
}

export const headerListTabletTable = {
  fullName: 'Full Name',
  role: 'Role',
  email: 'Email',
  phone: 'Phone Number',
  titleId: 'Title',
  status: 'Status',
  actionsTablet: '',
}

export function getHeaderList(isMobile: boolean): ITableHeader[] {
  const additionalHeaders: ITableHeader[] = isMobile
    ? []
    : [
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'Phone Number',
          accessor: 'phone',
        },
        {
          Header: 'Partner',
          accessor: 'partnerInfo', //type + category
        },
        {
          Header: 'Title',
          accessor: 'titleId',
        },
        {
          Header: 'Status',
          accessor: 'status',
        },
      ]
  const headers: ITableHeader[] = [
    {
      Header: 'Full name',
      accessor: 'fullName',
    },
    {
      Header: 'Role',
      accessor: 'role',
    },
    ...additionalHeaders,
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export const today = moment().format('YYYY-MM-DD')
export const initialValues = { isActive: true, isDeleted: false, isArchived: false }
export const validation = Yup.object().shape({
  levelId: Yup.object().nullable(),
  titleId: Yup.object().nullable(),
  paymentCategoryId: Yup.object().nullable(),
  role: Yup.object().nullable(),
  isActive: Yup.boolean().nullable(),
  isDeleted: Yup.boolean().nullable(),
  isArchived: Yup.boolean().nullable(),
})
export interface Props {
  history: any
  match: any
  handleCloseConfirmDialog?: () => void
  handleOpenSnackbar?: ({ variant, message }) => void
}

export interface IOption {
  label: string
  value: string
}
export interface IUserFilter extends Omit<IUser, 'levelId' | 'titleId' | 'role' | 'paymentCategoryId' | 'fullName'> {
  titleId?: string[]
  role?: string
  levelId?: string[]
  fullName?: any
  paymentCategoryId?: string[]
  project?: string
}
export interface States {
  users: IUser[]
  count: number
  filter?: IUserFilter
  exportNow?: boolean
  partners?: IPartner[]
  projects?: IProject[]
  creatableOption?: ICreatableOption[]
  open?: boolean
  openDeleteModal?: boolean
  openArchiveModal?: boolean
  openSummaryModal?: boolean
  selectedUser?: IUser
  isCorrectName?: boolean
}

export interface IFilterProps {
  classes?: Record<string, unknown>
  creatableOption?: ICreatableOption[]
  dirty?: boolean
  isSubmitting?: boolean
  handleSubmit?: any
  values?: IUserFilter
  projects?: IProject[]
  clearForm?: () => void
}

export enum ERoleFilter {
  ALL = 'all',
  ADMIN = 'admin',
  USER = 'user',
}
