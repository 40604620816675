import * as React from 'react'
import { FormControl, FormLabel, Switch } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
interface IRememberMeProps {
  label: string
}

export const RememberMe = React.forwardRef<HTMLInputElement, IRememberMeProps>((props, ref) => {
  const { label, ...rest } = props
  const { register } = useFormContext()

  return (
    <FormControl display="flex" alignItems="center">
      <Switch ref={ref} {...rest} name="isRememberMe" {...register('isRememberMe')} defaultChecked />
      <FormLabel mb="0" ml="2" size="sm" fontSize={'sm'} fontWeight="400" color={'gray.700'}>
        {label}
      </FormLabel>
    </FormControl>
  )
})
