import { useEffect, useState } from 'react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Button, Stack, Container, HStack, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import { handleError } from 'API/error'
import MoreDropBox from 'components/Table/components/MoreDropBox'
import ConfirmArchiveModal from 'componentsCharka/ConfirmArchiveModal'
import ConfirmDeleteModal from 'componentsCharka/ConfirmDeleteModal'
import Helper from 'Helper'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaSave } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IProfileDetailOfDetailForm } from 'types/user'
import { getValidArray } from 'utils'
import { PartnerCategory, PartnerType } from 'constants/defaultOptionList'
import { actionOnDataTable } from 'constants/index'
import { IPartner } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import { ReactComponent as Edit } from '../../../assets/images/svg/edit-white.svg'
import { RoutesConstants } from '../../../constants'
import DetailPartnerForm from './components/DetailPartnerForm'
import EditPartnerForm from './components/EditPartnerForm'
import { IRowActionItem } from './constant'
import styles from './index.module.scss'

const PartnerDetail = () => {
  const title: string = 'Partner Detail'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))
  const methods = useForm()
  const { handleSubmit, reset } = methods
  const params = useParams()
  const history = useHistory()
  const partnerId = get(params, 'id', '')
  const { adminPartnerStore } = useStores()
  const { partnerDetail, creatableOptionList } = adminPartnerStore
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false)
  const [isTablet] = useMediaQuery('(max-width: 820px)')

  const options = getValidArray(creatableOptionList)

  const partnerCategoryOptions = Helper.createOptionsOfSelect(PartnerCategory)

  useEffect(() => {
    adminPartnerStore.getPartnerDetail(partnerId, isEdit)
    adminPartnerStore.getOptionList()
  }, [isEdit])

  useEffect(() => {
    if (partnerDetail) {
      reset(partnerDetail)
    }
  }, [partnerDetail])

  async function submitHandler(data: IPartner): Promise<void> {
    try {
      const messages: string = await adminPartnerStore.updatePartner(data, partnerId)
      toast.success(messages)
      setIsEdit(false)
      await adminPartnerStore.getPartnerDetail(partnerId, false)
    } catch (error) {
      toast.error('Update partner failed!')
      handleError(error, 'src/containers/Partner/PartnerDetail/index.tsx', 'submitHandler')
    }
  }
  function toggleOpenDeleteModal() {
    setOpenDeleteModal(!openDeleteModal)
  }
  function toggleOpenArchiveModal() {
    setOpenArchiveModal(!openArchiveModal)
  }
  function callOpenDialogConfirm(user: IProfileDetailOfDetailForm, nameOfEvent: string) {
    set(user, 'shortName', user?.fullName ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      toggleOpenDeleteModal()
    } else {
      toggleOpenArchiveModal()
    }
  }
  async function handleClickOKConfirmedDelete(id: string): Promise<void> {
    try {
      const messages: string = await adminPartnerStore.deletePartner(partnerId)
      toast.success(messages)
      history.push(RoutesConstants.PARTNER)
    } catch (error) {
      toast.error('Delete partner failed!')
      handleError(error, 'src/containers/Partner/PartnerDetail/index.tsx', 'handleClickOKConfirmedDelete')
    }
  }
  async function handleClickOKOfUnarchiveConfirm(id: string): Promise<void> {
    try {
      const messages: string = await adminPartnerStore.unArchivePartner(partnerId)
      toast.success(messages)
      history.push(RoutesConstants.PARTNER)
    } catch (error) {
      toast.error('Unarchive partner failed!')
      handleError(error, 'src/containers/Partner/PartnerDetail/index.tsx', 'handleClickOKOfUnarchiveConfirm')
    }
  }
  async function handleClickOKOfArchiveConfirm(id: string): Promise<void> {
    try {
      const messages: string = await adminPartnerStore.archivePartner(partnerId)
      toast.success(messages)
      history.push(RoutesConstants.PARTNER)
    } catch (error) {
      toast.error('Archive partner failed!')
      handleError(error, 'src/containers/Partner/PartnerDetail/index.tsx', 'handleClickOKOfArchiveConfirm')
    }
  }
  function editHandler(event) {
    event.preventDefault()
    setIsEdit(true)
  }
  function cancelHandler() {
    setIsEdit(false)
  }
  const actions: IRowActionItem[] = [
    {
      name: !!partnerDetail?.isArchived ? 'Unarchive' : 'Archive',
      handler: () => callOpenDialogConfirm(partnerDetail, actionOnDataTable.ARCHIVE),
    },
  ]
  actions.push({
    name: 'Delete',
    customClassNames: ['isDanger'],
    handler: () => callOpenDialogConfirm(partnerDetail, actionOnDataTable.DELETE),
  })

  return (
    <VStack>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit((data) => submitHandler(data))}>
          <VStack>
            <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
              <Stack
                direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'row' }}
                margin={{ base: 6, lg: 6 }}
                height="full"
                background="white"
                justifyContent="space-between">
                <Text fontSize={{ xl: 'lg', lg: 'lg', md: 'lg', sm: 'sm' }} color="gray.700" margin={0}>
                  {partnerDetail?.fullName}
                </Text>
                <HStack justifyContent={{ sm: 'flex-end' }}>
                  <MoreDropBox isTablet isBackground actions={actions} />
                  {!isEdit ? (
                    <Button
                      fontWeight={'500'}
                      variant="solid"
                      onClick={editHandler}
                      type="button"
                      leftIcon={<Edit />}
                      bg={'#4D5DFB'}
                      className={styles.editButton}>
                      {!isTablet ? 'Edit Partner' : ''}
                    </Button>
                  ) : (
                    <>
                      <Button
                        fontWeight={'500'}
                        colorScheme="blue"
                        variant="outline"
                        isFullWidth
                        type="button"
                        onClick={cancelHandler}
                        _hover={{
                          cursor: 'pointer',
                        }}>
                        {!isTablet ? 'Cancel' : <ArrowBackIcon />}
                      </Button>
                      <Button
                        fontWeight={'500'}
                        bg="#4D5DFB"
                        className={styles.editButton}
                        variant="solid"
                        isFullWidth
                        type="submit"
                        _hover={{
                          cursor: 'pointer',
                        }}>
                        {!isTablet ? 'Save' : <FaSave />}
                      </Button>
                    </>
                  )}
                </HStack>
              </Stack>
            </Container>

            <HStack width="100%" marginTop="24px !important" justifyContent="space-between" alignItems="flex-start">
              <VStack width="75%">
                {isEdit ? (
                  <EditPartnerForm
                    methods={methods}
                    partnersType={PartnerType}
                    patnerCategoryOptions={partnerCategoryOptions}
                  />
                ) : (
                  <DetailPartnerForm methods={methods} />
                )}
              </VStack>
            </HStack>
          </VStack>
        </form>
        <ConfirmDeleteModal
          data={partnerDetail}
          isOpen={openDeleteModal}
          closeHandler={toggleOpenDeleteModal}
          OKClickHandler={handleClickOKConfirmedDelete}
          title={'Partner'}
          content={'Full Name'}
        />
        <ConfirmArchiveModal
          data={partnerDetail}
          isOpen={openArchiveModal}
          closeHandler={toggleOpenArchiveModal}
          OKClickHandler={partnerDetail?.isArchived ? handleClickOKOfUnarchiveConfirm : handleClickOKOfArchiveConfirm}
          title={'partner'}
          content={'Full name'}
        />
      </FormProvider>
    </VStack>
  )
}
export default observer(PartnerDetail)
