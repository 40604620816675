import { useRef, ChangeEvent } from 'react'
import { HStack, Input, Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { FaEllipsisV, FaFileUpload, FaTrashAlt } from 'react-icons/fa'
import style from './index.module.scss'

interface IButtonGroupUploadCV {
  isDisable?: boolean
  file?: any
  handleChangeFile?: (event: ChangeEvent<HTMLInputElement>) => void
  handleDeleteCV?: () => void
}

const ButtonGroupUploadCV = (props: IButtonGroupUploadCV) => {
  const { handleChangeFile, handleDeleteCV } = props
  const CVRef = useRef<HTMLInputElement | null>(null)

  function handleChooseCV(): void {
    CVRef.current?.click()
  }

  return (
    <HStack justifyContent={'flex-end'}>
      <Button
        onClick={handleChooseCV}
        className={style.uploadCVButton}
        bg={'#4D5DFB'}
        leftIcon={<FaFileUpload />}
        _hover={{
          cursor: 'pointer',
        }}>
        Upload CV
      </Button>
      <Input
        id="my-cv-file"
        type="file"
        ref={CVRef}
        accept={'.pdf'}
        display="none"
        onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeFile(event)}
      />
      <Menu>
        <MenuButton bg={'white'} color="gray.700" _hover={{ cursor: 'pointer' }}>
          <FaEllipsisV />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={handleDeleteCV} color={'red.500'} bg={'white'} _hover={{ cursor: 'pointer' }}>
            <FaTrashAlt className={style.icon} /> Delete CV
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  )
}

export default ButtonGroupUploadCV
