import { Td } from '@chakra-ui/react'
import ImageCell from '../ImageCell'
import StatusCell from '../StatusCell'
import TagListCell from '../TagListCell'

interface ICellProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: any
}

export enum RENDER {
  CELL = 'Cell',
}

export enum HEADER {
  STATUS = 'Status',
  BOOLEAN = 'ed',
  ROLE = 'Role',
  SCOPE = 'scope',
  TECHNOLOGY = 'TECHNOLOGY',
  LOGO = 'LOGO',
}

const TagHeader: string[] = ['Status', 'Role', 'Scope', 'ed', 'is', 'Archived', 'Deleted']

const Cell = (props: ICellProps) => {
  const { cell } = props
  let cellContent = cell.render(RENDER.CELL)
  const header: string = cell?.column?.Header ?? ''
  if (TagHeader.includes(header)) {
    cellContent = <StatusCell status={cell?.value}>{cell.render(RENDER.CELL)}</StatusCell>
  }
  if (header === HEADER.TECHNOLOGY) {
    cellContent = <TagListCell values={cell?.value} />
  }
  if (header === HEADER.LOGO) {
    cellContent = <ImageCell avatar={cell.value} />
  }
  return <Td>{cellContent}</Td>
}

export default Cell
