export const ThemeColor = {
  white: '#ffffff',
  orange: '#fcae02',
  primaryVsmColor: '#0a8080',
  pattensBlue: '#deebff',
  shark: '#212529',
  heliotrope: '#cb43fe',
  java: '#2abeb3',
  atomicTangerine: '#ff955a',
  lochmara: '#008cc2',
  goldenTainoi: '#ffcc4e',
  azureRadiance: '#0073ff',
  boulder: '#757575',
  funBlue: '#1D53B7',
  dodgerBlue: '#276EF1',
  hawkesBlue: '#D4E2FC',
  ragoonGreen: '#11110F',
  fairPink: '#FFECEA',
  resolutionBlue: '#002f79',
  mercury: '#e2e2e2',
  silverChalice: '#afafaf',
  jordyBlue: '#9FBFF8',
  valencia: '#D44333',
  pacificBlue: '#08B3B3',
  yellowOrange: '#FFC043',
  baliHai: '#8EA3AD',
  fuchsiaBlue: '#7356BF',
}

export const ReactButtonThemeColor = {
  primary: 'primary',
  link: 'link',
}

export const ThemeSpace = {
  spaceVeryTiny: 4,
  spaceTiny: 6,
  spaceXXSmall: 8,
  spaceExtraSmall: 12,
  spaceSmall: 16,
  spaceMediumSmall: 20,
  spaceMedium: 24,
  spaceMediumLarge: 30,
  spaceMediumExtraLarge: 54,
  spaceLarge: 40,
  spaceExtraLarge: 48,
}
