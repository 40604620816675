import { IPaymentList } from 'types/payment'
import { EStatusOfPaymentRequest } from 'constants/enum'

export const PAYMENT_BASE_URL = '/payment'

export interface IPaymentPayload {
  id?: string
  field?: string
  value?: EStatusOfPaymentRequest | boolean
  reason?: string
  date?: Date
}

export interface IFilterPaymentsPayload {
  category?: string
  date?: string
  includeRequest?: boolean
  project?: string
  user?: string
}

export interface IPaymentListResponse {
  paymentsList: IPaymentList[]
}
