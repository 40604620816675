import React from 'react'
import { chakra, Box, Stack } from '@chakra-ui/react'
import { Table as CkTable, Thead, Tbody, Tr, Th } from '@chakra-ui/react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import ClientBillItem from 'containers/Accounting/Income/components/ClientBillItemList/components/ClientBillItem'
import { getHourlyProjectHeaderList } from '../../../constant'
import styles from '../../../index.module.scss'

interface IClientBillItemListProp {
  isEdit?: boolean
  projectsInBillItem
  userList
  deleteRowHandler
  updateDefaultDataHandler
}
const ClientBillItemList = (props: IClientBillItemListProp) => {
  const { projectsInBillItem, userList, deleteRowHandler, updateDefaultDataHandler } = props
  const nameOfData = `clientBillItemList`
  const headerGroupData = getHourlyProjectHeaderList()
  const { control, register } = useFormContext()
  const { fields } = useFieldArray({
    control,
    name: nameOfData, // unique name for your Field Array
  })

  return (
    <chakra.form width="full">
      <React.Fragment>
        {fields && fields.length > 0 && (
          <Stack>
            <Box className={styles.scrollAble}>
              <CkTable size="sm" variant="striped" colorScheme="gray" outline="none">
                <Thead>
                  <Tr>
                    {headerGroupData.map((header, index) => {
                      return (
                        <Th key={index} py={4}>
                          {header.Header}
                        </Th>
                      )
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {fields.map((field, index) => {
                    return (
                      <ClientBillItem
                        projectsInBillItem={projectsInBillItem}
                        userList={userList}
                        deleteRowHandler={deleteRowHandler}
                        index={index}
                        field={field}
                        updateDefaultDataHandler={updateDefaultDataHandler}
                      />
                    )
                  })}
                </Tbody>
              </CkTable>
            </Box>
          </Stack>
        )}
      </React.Fragment>
    </chakra.form>
  )
}

export default ClientBillItemList
