import { ReactNode } from 'react'
import { Table as CkTable, Thead, Tbody, Tr, Th, Box, Stack } from '@chakra-ui/react'
import { ITableHeader } from 'components/Table'
import styles from './table.module.scss'

interface ITableWithInputCellProps {
  headersGroup: ITableHeader[]
  children?: ReactNode
}

const TableWithInputCell = (props: ITableWithInputCellProps) => {
  const { headersGroup, children } = props
  return (
    <Stack>
      <Box className={styles.scrollAble}>
        <CkTable variant="striped" colorScheme="gray" outline="none">
          <Thead>
            <Tr>
              {headersGroup.map((header, index) => {
                return (
                  <Th key={index} whiteSpace="nowrap" py={4}>
                    {header.Header}
                  </Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>{children}</Tbody>
        </CkTable>
      </Box>
    </Stack>
  )
}

export default TableWithInputCell
