import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'
import { Tr, Td, Tooltip, HStack } from '@chakra-ui/react'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import Helper from 'Helper'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'
import DeleteItemModal from 'containers/Accounting/components/ModalDeletedIItem'
import { ReactComponent as CancelIcon } from '../../../../../../../assets/images/svg/close-line.svg'
import { ReactComponent as DeleteIcon } from '../../../../../../../assets/images/svg/delete-bin-fill.svg'
import { ReactComponent as EditIcon } from '../../../../../../../assets/images/svg/edit-fill.svg'
import { ReactComponent as SaveIcon } from '../../../../../../../assets/images/svg/save.svg'
import styles from '../../../../../index.module.scss'

interface IClientBillItemProp {
  projectsInBillItem
  userList
  deleteRowHandler
  index?
  field?
  updateDefaultDataHandler?
}

const ClientBillItem = (props: IClientBillItemProp) => {
  const { projectsInBillItem, userList, deleteRowHandler, index, field, updateDefaultDataHandler } = props
  const nameOfData = `clientBillItemList`
  const { register } = useFormContext()
  const projectsOptions = Helper.createOptionsOfSelectFromDropdown(projectsInBillItem)
  const developerOptions = Helper.createOptionsOfSelectFromDropdown(userList)
  const defaultValueOfEndedDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
  const defaultValueOfStartedDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
  const [editMode, setEditMode] = useState(false)
  const [cancelMode, setCancelMode] = useState(false)
  const [saveMode, setSaveMode] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [itemSelected, setItemSelected] = useState()
  const [deleteCount, setDeleteCount] = useState(0)
  const deleteCountBillItem = window.localStorage.getItem('deleteCountBillItem')

  function editRowHandler() {
    setEditMode(true)
    setCancelMode(false)
    setSaveMode(false)
  }

  function cancelRowHandler() {
    setEditMode(false)
    setSaveMode(false)
    setCancelMode(true)
  }

  function saveOnRowHandler() {
    setEditMode(false)
    setCancelMode(false)
    setSaveMode(true)
  }

  function onChange1() {
    console.log('onChange1')
  }

  function onBlureTest() {
    console.log('onBlureTest')
  }

  function onChange2() {
    console.log('onChange2')
  }

  const openDeleteModal = (index) => {
    setDeleteCount(deleteCountBillItem ? Number(deleteCountBillItem) : 0)

    if (deleteCount < 2) {
      setItemSelected(index)
      setOpenModal(!openModal)
    } else {
      deleteRowHandler(index)
    }
  }
  function closeDeleteModal() {
    setOpenModal(!openModal)
  }
  return (
    <>
      <Tr key={index}>
        <Td className="can-hover">
          {!cancelMode && editMode && !saveMode && (
            <ChakraInputDropdown
              name={`${nameOfData}.${index}.developerId`}
              optionsData={developerOptions}
              widthCustom="180px"
            />
          )}
          {cancelMode && !editMode && !saveMode && (
            <textarea
              readOnly={!editMode}
              className={styles.projectRatingInputFieldTextArea}
              key={field.id} // important to include key with field's id
              value={field?.developerData?.fullName}
            />
          )}
          {!cancelMode && !editMode && saveMode && (
            <textarea
              readOnly={!editMode}
              className={styles.projectRatingInputFieldTextArea}
              key={field.id} // important to include key with field's id
              {...register(`${nameOfData}.${index}.developerId.label`)}
            />
          )}
          {!cancelMode && !editMode && !saveMode && (
            <textarea
              readOnly={!editMode}
              className={styles.projectRatingInputFieldTextArea}
              key={field.id} // important to include key with field's id
              {...register(`${nameOfData}.${index}.developerData.fullName`)}
            />
          )}
        </Td>
        <Td className="can-hover">
          {!cancelMode && editMode && !saveMode && (
            <ChakraInputDropdown
              name={`${nameOfData}.${index}.projectId`}
              optionsData={projectsOptions}
              widthCustom="180px"
            />
          )}
          {cancelMode && !editMode && !saveMode && (
            <textarea
              readOnly={!editMode}
              className={styles.projectRatingInputFieldTextArea}
              key={field.id} // important to include key with field's id
              value={field?.projectData?.name}
            />
          )}
          {!cancelMode && !editMode && saveMode && (
            <textarea
              readOnly={!editMode}
              className={styles.projectRatingInputFieldTextArea}
              key={field.id} // important to include key with field's id
              {...register(`${nameOfData}.${index}.projectId.label`)}
            />
          )}
          {!cancelMode && !editMode && !saveMode && (
            <textarea
              readOnly={!editMode}
              className={styles.projectRatingInputFieldTextArea}
              key={field.id} // important to include key with field's id
              {...register(`${nameOfData}.${index}.projectData.name`)}
            />
          )}
        </Td>
        <Td>
          {!cancelMode ? (
            <textarea
              readOnly={!editMode}
              className={styles.projectRatingInputFieldTextArea}
              key={field.id} // important to include key with field's id
              {...register(`${nameOfData}.${index}.description`)}
            />
          ) : (
            <textarea
              readOnly={!editMode}
              className={styles.projectRatingInputFieldTextArea}
              key={field.id} // important to include key with field's id
              value={field?.description}
            />
          )}
        </Td>
        <Td>
          {!cancelMode ? (
            <input
              readOnly={!editMode}
              className={styles.projectRatingInputFieldDate}
              type="date"
              defaultValue={defaultValueOfStartedDate}
              key={field.id} // important to include key with field's id
              {...register(`${nameOfData}.${index}.startedDate`)}
            />
          ) : (
            <input
              readOnly={!editMode}
              className={styles.projectRatingInputFieldDate}
              type="date"
              defaultValue={defaultValueOfEndedDate}
              key={field.id} // important to include key with field's id
              value={field?.startedDate}
            />
          )}
        </Td>
        <Td>
          {!cancelMode ? (
            <input
              readOnly={!editMode}
              className={styles.projectRatingInputFieldDate}
              type="date"
              defaultValue={defaultValueOfEndedDate}
              key={field.id} // important to include key with field's id
              {...register(`${nameOfData}.${index}.endedDate`)}
            />
          ) : (
            <input
              readOnly={!editMode}
              className={styles.projectRatingInputFieldDate}
              type="date"
              defaultValue={defaultValueOfEndedDate}
              key={field.id} // important to include key with field's id
              value={field?.endedDate}
            />
          )}
        </Td>
        <Td>
          {!cancelMode ? (
            <input
              readOnly={!editMode}
              className={styles.projectRatingInputFieldNumber}
              key={field.id} // important to include key with field's id
              {...register(`${nameOfData}.${index}.quantity`)}
              onBlur={() => updateDefaultDataHandler('element', index)}
            />
          ) : (
            <input
              readOnly={!editMode}
              // onChange={onChange2}

              className={styles.projectRatingInputFieldNumber}
              key={field.id} // important to include key with field's id
              value={field?.quantity}
            />
          )}
        </Td>
        <Td>
          {!cancelMode ? (
            <input
              readOnly={!editMode}
              className={styles.projectRatingInputFieldHourlyRate}
              key={field.id} // important to include key with field's id
              {...register(`${nameOfData}.${index}.hourlyRate`)}
              onBlur={() => updateDefaultDataHandler('element', index)}
            />
          ) : (
            <input
              readOnly={!editMode}
              className={styles.projectRatingInputFieldHourlyRate}
              key={field.id} // important to include key with field's id
              value={field?.hourlyRate}
            />
          )}
        </Td>
        <Td>
          {!cancelMode ? (
            <input
              readOnly={!editMode}
              className={styles.projectRatingInputFieldMoney}
              key={field.id} // important to include key with field's id
              {...register(`${nameOfData}.${index}.totalPrice`)}
              onBlur={() => updateDefaultDataHandler('total', index)}
            />
          ) : (
            <input
              readOnly={!editMode}
              className={styles.projectRatingInputFieldMoney}
              key={field.id} // important to include key with field's id
              value={field?.totalPrice}
            />
          )}
        </Td>
        <Td>
          <HStack>
            <Tooltip label={editMode ? 'Cancel' : 'Edit'}>
              <Button
                paddingRight={'5px'}
                background={'transparent'}
                height={'1em'}
                width={'1em'}
                leftIcon={
                  editMode ? <CancelIcon style={{ marginRight: '8px' }} /> : <EditIcon style={{ marginRight: '8px' }} />
                }
                onClick={!editMode ? editRowHandler : cancelRowHandler}
                _hover={{
                  cursor: 'pointer',
                  bg: 'transparent',
                }}
                _active={{
                  bg: 'lightgrey',
                  outline: 'none',
                  color: 'unset',
                }}></Button>
            </Tooltip>
            {editMode && (
              <Tooltip label={'Save template on row'}>
                <Button
                  paddingRight={'5px'}
                  background={'transparent'}
                  height={'1em'}
                  width={'1em'}
                  leftIcon={<SaveIcon style={{ marginRight: '8px' }} />}
                  onClick={saveOnRowHandler}
                  _hover={{
                    cursor: 'pointer',
                    bg: 'transparent',
                  }}
                  _active={{
                    bg: 'lightgrey',
                    outline: 'none',
                    color: 'unset',
                  }}></Button>
              </Tooltip>
            )}
            <Tooltip label={'Delete'}>
              <Button
                paddingRight={'5px'}
                background={'transparent'}
                height={'1em'}
                width={'1em'}
                leftIcon={<DeleteIcon style={{ marginRight: '8px' }} />}
                onClick={() => openDeleteModal(index)}
                _hover={{
                  cursor: 'pointer',
                  bg: 'transparent',
                }}
                _active={{
                  bg: 'lightgrey',
                  outline: 'none',
                  color: 'unset',
                }}></Button>
            </Tooltip>
          </HStack>
        </Td>
      </Tr>

      <DeleteItemModal
        data={itemSelected}
        isOpen={openModal}
        closeHandler={closeDeleteModal}
        OKClickHandler={deleteRowHandler}
        countDelete={deleteCount}
      />
    </>
  )
}

export default ClientBillItem
