import { chakra, ModalHeader as CkModalHeader, ModalFooter as CkModalFooter } from '@chakra-ui/react'
import { borderColor } from 'theme/globalStyles'

export const ModalHeader = chakra(CkModalHeader, {
  baseStyle: () => ({
    fontWeight: 500,
    fontSize: 18,
    borderBottom: `1px solid ${borderColor}`,
  }),
})

export const ModalFooter = chakra(CkModalFooter, {
  baseStyle: () => ({
    paddingX: 4,
    paddingY: 0,
    borderTop: `1px solid ${borderColor}`,
    overflow: 'hidden',
  }),
})
