import React from 'react'
import FormModel from '../../../../components/FormModel/index'
import Helper from '../../../../Helper'
import { Messages } from '../../../../constants'
import CommitTimeTable from '../../components/CommitTimeTable'
import FilterForm from '../../components/FilterForm/FilterFormOfCommitTime'
import EditCommitTimeDialog from '../../components/EditCommitTimeDialog'
import {
  initialValues,
  validationFilterUserSchema,
  validationFilterProjectSchema,
  validationGroupBySchema,
} from './constants'
import { getProjectList } from 'API/project'
import { getUserList } from 'API/user'
import { handleError } from 'API/error'
import { getCommitTimeList, editCommitTime } from 'API/commit-time'
import { toast } from 'react-toastify'

class CommitTimeOverview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      projects: [],
      selectedUser: undefined,
      commitTimeListData: {},
      validationSchema: validationFilterUserSchema,
      isOpenEditCommitTimeDialog: false,
      selectedCommitTime: undefined,
      selectedTypeOfFiler: undefined,
      selectedProject: undefined,
      selectedGroupBy: undefined,
      isShowProjectList: false,
      isShowUserList: false,
      isShowGroupSwitch: false,
      summarizeData: undefined,
    }
  }
  componentDidMount() {
    this.getListUsersHandler()
    this.getListProjectsHandler()
    window.sessionStorage.setItem('subPageTitle', 'Overview')
    window.dispatchEvent(new Event('storage'))
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem('subPageTitle')
    this.setState = () => {
      return
    }
  }
  selectedTypeOfFilerHandler = (value) => {
    let validationSchema
    let isShowProjectList = false
    let isShowUserList = false
    let isShowGroupSwitch = false
    switch (value) {
      case 'filter by project': {
        isShowProjectList = true
        validationSchema = validationFilterProjectSchema
        break
      }
      case 'filter by user': {
        isShowUserList = true
        validationSchema = validationFilterUserSchema

        break
      }
      case 'filter all': {
        isShowGroupSwitch = true
        validationSchema = validationGroupBySchema
        break
      }
      default: {
        return null
      }
    }
    this.setState({
      isShowProjectList,
      isShowUserList,
      isShowGroupSwitch,
      validationSchema,
    })
  }

  getListProjectsHandler = async () => {
    try {
      const response = await getProjectList()
      this.setState({
        projects: response.data.projects,
      })
    } catch (error) {
      handleError(
        error,
        'src/containers/CommitTime/CommitTimeAdmin/CommitTimeOverview/index.js',
        'getListProjectsHandler'
      )
    }
  }
  getListUsersHandler = async () => {
    try {
      const response = await getUserList()
      this.setState({
        users: response.data.users,
      })
    } catch (error) {
      handleError(error, 'src/containers/CommitTime/CommitTimeAdmin/CommitTimeOverview/index.js', 'getListUsersHandler')
    }
  }

  filterSubmitHandler = async (values) => {
    try {
      const { typeOfFilter, project, user, groupBy } = values
      const payload = {
        ...values,
        user: user.value,
        project: project.value,
      }
      const { commitTimeListData } = await getCommitTimeList(payload)
      const summarizeData = Helper.getFormattedSummarizeDataOfCommitTime(commitTimeListData)
      this.setState({
        commitTimeListData,
        summarizeData,
        selectedUser: user,
        selectedTypeOfFiler: typeOfFilter,
        selectedProject: project,
        selectedGroupBy: groupBy,
      })
    } catch (error) {
      toast.error('Get commit time list failed!')
      handleError(error, 'src/containers/CommitTime/CommitTimeAdmin/CommitTimeOverview/index.js', 'filterSubmitHandler')
    }
  }

  editCommitTimeHandler = (commitTimeId) => {
    const { commitTimeListData, selectedTypeOfFiler } = this.state
    const { commitTimeOfPerProject, commitTimeOfAllProject, commitTimeOfGroupBy } = commitTimeListData
    let selectedCommitTime
    switch (selectedTypeOfFiler) {
      case 'filter by project': {
        const checkInPerProj = commitTimeOfPerProject.find((item) => {
          return item._id === commitTimeId
        })
        const checkInAllProj = commitTimeOfAllProject.find((item) => {
          return item._id === commitTimeId
        })
        if (checkInAllProj) {
          selectedCommitTime = checkInAllProj
        } else {
          selectedCommitTime = checkInPerProj
        }
        break
      }
      case 'filter by user': {
        const checkInPerProj = commitTimeOfPerProject.find((item) => {
          return item._id === commitTimeId
        })
        const checkInAllProj = commitTimeOfAllProject.find((item) => {
          return item._id === commitTimeId
        })
        if (checkInAllProj) {
          selectedCommitTime = checkInAllProj
        } else {
          selectedCommitTime = checkInPerProj
        }
        break
      }
      case 'filter all': {
        commitTimeOfGroupBy.forEach((group) => {
          const { commitTimeDataOfGroup } = group
          const { commitTimeDataOfGroupAllProject, commitTimeDataOfGroupPerProject } = commitTimeDataOfGroup
          const checkInAll = commitTimeDataOfGroupAllProject.find((item) => {
            return item._id === commitTimeId
          })
          const checkInPer = commitTimeDataOfGroupPerProject.find((item) => {
            return item._id === commitTimeId
          })
          if (checkInAll) {
            selectedCommitTime = checkInAll
          }
          if (checkInPer) {
            selectedCommitTime = checkInPer
          }
        })
        break
      }
      default: {
        return null
      }
    }

    this.setState({
      isOpenEditCommitTimeDialog: true,
      selectedCommitTime,
    })
  }

  closeEditCommitTimeDialog = () => {
    this.setState({
      isOpenEditCommitTimeDialog: false,
    })
  }

  OKClickHandlerOfEditCommitTimeDialog = async (data) => {
    try {
      const { selectedUser, selectedTypeOfFiler, selectedProject, selectedGroupBy } = this.state
      const payload = {
        ...data,
        user: selectedUser.value,
        project: selectedProject.value,
        typeOfFilter: selectedTypeOfFiler,
        groupBy: selectedGroupBy,
      }
      const { commitTimeListData } = await editCommitTime(payload)
      const summarizeData = Helper.getFormattedSummarizeDataOfCommitTime(commitTimeListData)
      this.closeEditCommitTimeDialog()
      this.setState({
        commitTimeListData,
        summarizeData,
      })
      toast.success(Messages.editCommitTimeSuccessfully)
    } catch (error) {
      toast.error('Edit commit time failed!')
      handleError(
        error,
        'src/containers/CommitTime/CommitTimeAdmin/CommitTimeOverview/index.js',
        'OKClickHandlerOfEditCommitTimeDialog'
      )
    }
  }

  render() {
    const {
      users,
      commitTimeListData,
      isOpenEditCommitTimeDialog,
      selectedCommitTime,
      summarizeData,
      projects,
      validationSchema,
      isShowProjectList,
      isShowUserList,
      isShowGroupSwitch,
    } = this.state
    return (
      <div className="timesheet-page container">
        <div>
          <FormModel
            initialValues={initialValues}
            schema={validationSchema}
            handleSubmit={this.filterSubmitHandler}
            component={
              <FilterForm
                users={users}
                projects={projects}
                selectedTypeOfFilerHandler={this.selectedTypeOfFilerHandler}
                isShowProjectList={isShowProjectList}
                isShowUserList={isShowUserList}
                isShowGroupSwitch={isShowGroupSwitch}
                {...this.props}
              />
            }
          />
        </div>
        <div className="timesheet-content">
          <div className="container-fluid">
            <div className="box box-default">
              <div className="box-header">Commit Time List</div>
              <div className="box-divider" />
              <div className={`box-body`}>
                {commitTimeListData && (
                  <CommitTimeTable
                    summarizeData={summarizeData}
                    data={commitTimeListData}
                    editHandler={this.editCommitTimeHandler}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <EditCommitTimeDialog
          isOpen={isOpenEditCommitTimeDialog}
          closeHandler={this.closeEditCommitTimeDialog}
          OKClickHandler={this.OKClickHandlerOfEditCommitTimeDialog}
          item={selectedCommitTime}
        />
      </div>
    )
  }
}

export default CommitTimeOverview
