import { Checkbox, Box } from '@chakra-ui/react'
import { useFormContext, Controller } from 'react-hook-form'

export interface ICheckbox {
  name?: string
  label?: any
}

const CheckboxField = (props: ICheckbox) => {
  const { name, label } = props
  const { control } = useFormContext()

  return (
    <Box cursor="pointer" display="flex" alignItems="center">
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            size="md"
            colorScheme="blue"
            onChange={onChange}
            isChecked={value}
            sx={{
              '.chakra-checkbox__control': {
                '&:not([data-checked])': { bg: 'white' },
                rounded: 'base',
                borderWidth: '1px',
              },
              '.chakra-checkbox__label': { fontSize: 'sm', color: 'gray.700' },
            }}>
            {label?.label}
          </Checkbox>
        )}
      />
    </Box>
  )
}

export default CheckboxField
