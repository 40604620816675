import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'formik'

import Input from '../../../../../components/Input'
import RadioButton from '../../../../../components/RadioButton'
import MultipleSelect from '../../../../../components/Select/MultipleSelect'
import Select from '../../../../../components/Select'
import Helper from '../../../../../Helper'
import ToggleEditSaveDeleteSwitchInline from '../../../../../components/ToggleEditSaveDeleteSwitchInline'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
  menu: {
    width: 200,
  },
})

class FeedbackDetail extends React.Component {
  render() {
    const {
      admins,
      users,
      classes,
      viewMode,
      handleToggleActiveEdit,
      switchIsReadHandler,
      cancelForm,
      handleDelete,
      handleSubmit,
      dirty,
      isSubmitting,
      errors,
      values,
    } = this.props
    let activeEdit
    switch (viewMode) {
      case 'show':
        activeEdit = false
        break
      case 'edit':
        activeEdit = true
        break
      default:
        return null
    }
    const adminsOptions = Helper.createOptionsOfSelectFromDB(admins)
    const usersOptions = Helper.createOptionsOfSelectFromDB(users)
    return (
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="box box-default">
            <div className="box-header">
              Feedback
              <ToggleEditSaveDeleteSwitchInline
                title="Feedback"
                viewMode={viewMode}
                cancelClickHandler={() => cancelForm(values._id)}
                editClickHandler={() => handleToggleActiveEdit && handleToggleActiveEdit(values._id)}
                deleteClickHandler={() => handleDelete(values)}
                switchHandler={(event) => switchIsReadHandler(event, values._id)}
                saveDisabled={isSubmitting || !dirty}
                switchValue={values.isRead}
                switchButtonLabel="Is Read"
              />
            </div>
            <div className="box-divider" />
            <div className={`box-body`}>
              <div className="container-fluid">
                <div className="fields">
                  <div className="field">
                    <Field
                      name="name"
                      label="Name*"
                      className={classes.textField}
                      margin="normal"
                      component={Input}
                      disabled
                    />
                  </div>
                </div>
                <div className="fields">
                  <div className="field">
                    <Field
                      name="receiver"
                      label="Receiver*"
                      className={classes.textField + '98width'}
                      margin="normal"
                      options={adminsOptions}
                      component={MultipleSelect}
                      data={admins}
                      disabled
                    />
                  </div>
                  {!values.anonymous && (
                    <div className="field">
                      <Field
                        name="sender"
                        label="Sender"
                        className={classes.textField + '98width'}
                        margin="normal"
                        options={usersOptions}
                        component={Select}
                        disabled
                      />
                    </div>
                  )}
                  <div className="field radio-button">
                    <Field
                      name="anonymous"
                      label="Anonymous"
                      className={classes.textField}
                      margin="normal"
                      component={RadioButton}
                      disabled
                    />
                  </div>
                </div>
                <div className="fields">
                  <div className="field">
                    <Field
                      name="question"
                      label="Question*"
                      className={classes.textField}
                      multiline
                      row={3}
                      margin="normal"
                      component={Input}
                      disabled
                    />
                  </div>
                </div>
                <div className="fields">
                  <div className="field">
                    <Field
                      name="answer"
                      label="Answer"
                      multiline
                      row={3}
                      className={classes.textField}
                      margin="normal"
                      component={Input}
                      disabled={!activeEdit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className="error-message">{errors['form']}</span>
      </form>
    )
  }
}

FeedbackDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FeedbackDetail)
