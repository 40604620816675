import { ISuccessResponse } from 'API/constants'
import { getCountries } from 'API/country'
import { ICountryListResponse } from 'API/country/constants'
import { getCurrencies } from 'API/currency'
import { ICurrencyListResponse } from 'API/currency/constants'
import { handleError } from 'API/error'
import { getPartners } from 'API/partner'
import { IPartnerListRequest, IPartnerListResponse } from 'API/partner/constants'
import {
  archive,
  createProject,
  deleteProject,
  getProjectDetail,
  getProjectList,
  getProjectListWithPagination,
  unarchive,
  updateProject,
} from 'API/project'
import {
  IProjectDetailResponse,
  IProjectFilter,
  IProjectListWithPaginationResponse,
  IProjectMessageResponse,
  IProjectsOfUserResponse,
} from 'API/project/constants'
import { getProjectTypes } from 'API/projectType'
import { IProjectTypeListResponse } from 'API/projectType/constants'
import { getTechnologies } from 'API/technology'
import { ITechnologyListResponse } from 'API/technology/constants'
import { getUserList } from 'API/user'
import { IUserListResponse } from 'API/user/constants'
import get from 'lodash/get'
import set from 'lodash/set'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { THistory } from 'types/common'
import { getInQuery, getParsedProjectValueBeforeSendBE } from 'utils'
import { EPaymentCategoryOfProject } from 'constants/enum'
import { ICountry, ICurrency, IPartner, IProject, IProjectType, ITechnology, IUser } from 'constants/schema'
import RootStore from '../rootStore'

class AdminProjectStore {
  rootStore: RootStore
  currentPage: number = 1
  count: number = 0
  projectsList: IProject[] = []
  projectsListWithoutFilter: IProject[] = []
  projectDetail
  partnerListAll: IPartner[] = []
  partnerList: IPartner[] = []
  countriesList: ICountry[] = []
  technologiesList: ITechnology[] = []
  projectTypes: IProjectType[] = []
  currencyList: ICurrency[] = []
  usersList: IUser[] = []
  rawProjectDetailData

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getProjectListWithoutFilter(): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectsOfUserResponse> = await getProjectList()
      this.projectsListWithoutFilter = response?.data?.projects
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getProjectListWithoutFilter')
    }
  }

  public async getProjectList(isReset?: boolean, history?: THistory, filter?: IProjectFilter): Promise<void> {
    try {
      const currentPage: number = isReset ? 1 : Number(get(history, 'location.state.page') || 1)
      const response: ISuccessResponse<IProjectListWithPaginationResponse> = await getProjectListWithPagination({
        currentPage,
        perPage: 8,
        filter,
      })
      this.projectsList = response?.data?.projects
      this.count = response?.data?.count
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getProjectList')
    }
  }

  public async getProjectDetail(projectId: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectDetailResponse> = await getProjectDetail(projectId)
      this.rawProjectDetailData = response?.data?.project
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getProjectDetail')
    }
  }

  public async getPartnerList(partnerCategory: string[]): Promise<void> {
    try {
      const data: IPartnerListRequest = {
        filter: {
          isDeleted: false,
          isArchived: false,
          category: getInQuery(partnerCategory),
        },
      }
      const response: ISuccessResponse<IPartnerListResponse> = await getPartners(data)
      this.partnerList = response?.data?.partners
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getPartnerList')
    }
  }

  public async getCountryList(): Promise<void> {
    try {
      const response: ISuccessResponse<ICountryListResponse> = await getCountries()
      this.countriesList = response?.data?.countries
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getCountryList')
    }
  }

  public async getTechnologyList(): Promise<void> {
    try {
      const response: ISuccessResponse<ITechnologyListResponse> = await getTechnologies()
      this.technologiesList = response?.data?.technologies
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getTechnologyList')
    }
  }

  public async getProjectTypes(): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectTypeListResponse> = await getProjectTypes()
      this.projectTypes = response?.data?.projectTypes
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getProjectTypes')
    }
  }

  public async getCurrencyList(): Promise<void> {
    try {
      const response: ISuccessResponse<ICurrencyListResponse> = await getCurrencies()
      this.currencyList = response?.data?.currencies
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getCurrencyList')
    }
  }

  public async getUserList(): Promise<void> {
    try {
      const response: ISuccessResponse<IUserListResponse> = await getUserList()
      this.usersList = response?.data?.users
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'getUserList')
    }
  }

  public async createProject(data: IProject): Promise<void> {
    try {
      if (data?.paymentCategory === EPaymentCategoryOfProject.HOURLY) {
        set(data, 'price', 0)
        delete data?.margin
      }
      const isUntilNow: boolean = data?.untilNow
      if (isUntilNow) {
        delete data?.endedDate
      }
      const parseValues: IProject = getParsedProjectValueBeforeSendBE(data)
      const createProjectResponse: IProjectMessageResponse = await createProject(parseValues)
      toast.success(createProjectResponse?.message)
    } catch (error) {
      const errorMessage: string = error?.message ?? ''
      toast.error(errorMessage)
      handleError(error, 'src/store/admin/projectStore.ts', 'createProject')
    }
  }

  public async updateProject(data: IProject): Promise<void> {
    try {
      if (data.paymentCategory === EPaymentCategoryOfProject.HOURLY) {
        delete data?.margin
      }
      const isUntilNow: boolean = data?.untilNow
      if (isUntilNow) {
        delete data?.endedDate
      }
      const projectId: string = data?._id
      const parseValues: IProject = getParsedProjectValueBeforeSendBE(data)
      const response: ISuccessResponse<IProjectDetailResponse> = await updateProject(projectId, parseValues)
      this.getProjectDetail(projectId)
      toast.success(response?.data?.message)
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'updateProject')
    }
  }

  public async deleteProject(id: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectMessageResponse> = await deleteProject(id)
      toast.success(response?.data?.message)
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'deleteProject')
    }
  }

  public async archiveProject(id: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectMessageResponse> = await archive(id)
      toast.success(response?.data?.message)
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'archiveProject')
    }
  }

  public async unArchiveProject(id: string): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectMessageResponse> = await unarchive(id)
      toast.success(response?.data?.message)
    } catch (error) {
      handleError(error, 'src/store/admin/projectStore.ts', 'unArchiveProject')
    }
  }
}

export default AdminProjectStore
