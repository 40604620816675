import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  VStack,
} from '@chakra-ui/react'
import get from 'lodash/get'
import moment from 'moment'
import { backgroundGrey200 } from 'theme/globalStyles'
import { IFixedProjectRating } from 'constants/schema'
import FormView from '../../../../../../FormView'

interface IFixedProjectDetailProps {
  data: IFixedProjectRating
  isOpen: boolean
  onClose: () => void
}

const FixedProjectDetail = (props: IFixedProjectDetailProps) => {
  const { data, isOpen, onClose } = props

  return (
    <Drawer placement="bottom" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent borderTopRadius={6}>
        <DrawerHeader
          fontSize="lg"
          fontWeight={500}
          lineHeight={7}
          padding={4}
          borderBottom={`1px solid ${backgroundGrey200}`}>
          Rating Detail
        </DrawerHeader>
        <DrawerCloseButton cursor="pointer" color="gray" background="none" />
        <DrawerBody padding="16px 16px 40px">
          <VStack spacing={6} alignItems="flex-start">
            <FormView label="Project" value={get(data, 'project.label')} />
            <FormView label="Fixed Rate " value={String(data?.fixedRate)} />
            <FormView label="Apply Date" value={moment(get(data, 'applyDate')).format('DD/MM/YYYY')} />
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default FixedProjectDetail
