import React from 'react'
import Close from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import Helper from '../../../../Helper'
import { getValidArray } from 'utils'
import { ECommitTimeRequestTableStatus } from 'constants/enum'
import { formatRow } from './utils'

class CommitTimeRequestTable extends React.Component {
  renderOptions = (item) => {
    const { closeRequestHandler } = this.props
    let isRender = true
    const notRenderOptions = [1, 2, 4]
    if (notRenderOptions.includes(item.status.value)) {
      isRender = false
    }
    if (isRender) {
      return (
        <td className="options">
          <Tooltip title={'Close'}>
            <IconButton data-cy={`close`} aria-label={'Close'} onClick={() => closeRequestHandler(item)}>
              <Close />
            </IconButton>
          </Tooltip>
        </td>
      )
    }
    return null
  }

  renderClassNameOfStatus = (value) => {
    switch (value) {
      case 1: {
        return ECommitTimeRequestTableStatus.APPROVED
      }
      case 2: {
        return ECommitTimeRequestTableStatus.REJECTED
      }
      default:
        return ''
    }
  }

  render() {
    const { data } = this.props
    return (
      <div className="table-content">
        <table className="report-table">
          <thead>
            <tr>
              <th>Project</th>
              <th>Commit Time Per Week (Hours)</th>
              <th>Reason</th>
              <th>Status</th>
              <th>Rejected Reason</th>
              <th>Created At</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {getValidArray(data).map((item, index) => (
              <tr key={item._id} className={formatRow(index)}>
                <td>{item.projectName}</td>
                <td>{Helper.getFormattedNumber(item.commitTime, 1)}</td>
                <td>{item.reason}</td>
                <td className={`commit-time-status ${this.renderClassNameOfStatus(item.status.value)}`}>
                  {item.status.label}
                </td>
                <td>{item.rejectedReason}</td>
                <td>{Helper.getFormattedDateInEnglish(item.createdAt)}</td>
                {this.renderOptions(item)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default CommitTimeRequestTable
