import { HTMLInputTypeAttribute } from 'react'
import { FormControl, FormLabel, FormErrorMessage, HStack, Stack, InputGroup, Input } from '@chakra-ui/react'
import get from 'lodash/get'
import { useFormContext } from 'react-hook-form'

interface IFormInputProps {
  name: string
  label?: string
  type?: HTMLInputTypeAttribute
  placeholder?: string
  isRequired?: boolean
  isDisabled?: boolean
}

const FormInput = (props: IFormInputProps) => {
  const { name, label, type, placeholder, isRequired, isDisabled } = props
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <FormControl padding={0} isInvalid={!!get(errors, name, false)}>
      <HStack spacing="14px" maxHeight={6} marginBottom={2} position="relative">
        {label && (
          <FormLabel fontWeight={'nomal'} color="gray.700" lineHeight={6} marginBottom={0} marginInlineEnd={0}>
            {label}
          </FormLabel>
        )}
      </HStack>
      <Stack>
        <InputGroup width="full">
          <Input
            type={type}
            autoComplete={name}
            placeholder={placeholder}
            disabled={isDisabled}
            focusBorderColor={errors?.name ? 'red.500' : 'gray.200'}
            {...register(name, {
              required: isRequired ? `This field is required` : false,
            })}
            color="gray.700"
          />
        </InputGroup>
      </Stack>
      <FormErrorMessage>{get(errors, `${name}.message`, '')}</FormErrorMessage>
    </FormControl>
  )
}

export default FormInput
