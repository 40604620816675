import api from 'API'
import { handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import {
  HOURLY_RATING_BASE_URL,
  FIXED_RATING_BASE_URL,
  IHourlyProjectRatingList,
  IFixedProjectRatingList,
  IUpdateHourlyPayload,
  IUpdateFixedPayload,
} from './constants'

export async function getHourlyProjectRating(): Promise<IHourlyProjectRatingList> {
  try {
    const response: AxiosResponse = await api.get(`${HOURLY_RATING_BASE_URL}/list`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/projectRating', 'getHourlyProjectRating')
    return {} as IHourlyProjectRatingList
  }
}

export async function getFixedProjectRating(): Promise<IFixedProjectRatingList> {
  try {
    const response: AxiosResponse = await api.get(`${FIXED_RATING_BASE_URL}/list`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/projectRating', 'getFixedProjectRating')
    return {} as IFixedProjectRatingList
  }
}

export async function getHourlyProjectRatingAdmin(userId: string): Promise<IHourlyProjectRatingList> {
  try {
    const response: AxiosResponse = await api.get(`${HOURLY_RATING_BASE_URL}/list-admin/${userId}`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/projectRating', 'getHourlyProjectRatingAdmin')
    return {} as IHourlyProjectRatingList
  }
}

export async function getFixedProjectRatingAdmin(userId: string): Promise<IFixedProjectRatingList> {
  try {
    const response: AxiosResponse = await api.get(`${FIXED_RATING_BASE_URL}/list-admin/${userId}`)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/projectRating', 'getFixedProjectRatingAdmin')
    return {} as IFixedProjectRatingList
  }
}

export async function updateHourlyProjectRating(userId: string, payload: IUpdateHourlyPayload): Promise<void> {
  try {
    await api.post(`${HOURLY_RATING_BASE_URL}/update/${userId}`, payload)
  } catch (error) {
    handleError(error as Error, 'API/projectRating', 'updateHourlyProjectRating')
  }
}

export async function updateFixedProjectRating(userId: string, payload: IUpdateFixedPayload): Promise<void> {
  try {
    await api.post(`${FIXED_RATING_BASE_URL}/update/${userId}`, payload)
  } catch (error) {
    handleError(error as Error, 'API/projectRating', 'updateFixedProjectRating')
  }
}
