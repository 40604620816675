export enum EProfileTab {
  USER_DETAIL = 'Profile Detail',
  LABOR_CONTRACT = 'Labor Contract',
  CHANGE_PASSWORD = 'Change Password',
  PROJECT_RATING = 'Project Ratings',
  CURRICULUMN_VITAE = 'Curriculum Vitae',
}

export enum EContractProjectRatingSubTab {
  ACTIVE_CONTRACT = 'Active contract',
  ARCHIVED_CONTRACT = 'Archived Contract',
  HOURLY_PROJECT_RATING = 'Hourly Project Rating',
  FIXED_PROJECT_RATING = 'Fixed Project Rating',
}
