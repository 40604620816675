import { IProjectFilter as IFormattedFilter } from 'API/project/constants'
import Helper from 'Helper'
import get from 'lodash/get'
import moment from 'moment'
import { IOption } from 'types/creatableOption'
import { ITechnology } from 'types/project'
import { getInQuery, getValidArray } from 'utils'
import { ETechnologyItem } from 'containers/UserNew/UserDetail/constant'
import { EStoredFilterValue, IProjectFilter, IStoredProjectFilterAdmin } from './constants'

export function formatProjectFilter(filter: object): IFormattedFilter {
  const truthyFilter: IFormattedFilter = {
    ...filter,
    partnerId: getInQuery(get(filter, 'partnerId', [])),
    userId: getInQuery(get(filter, 'userId', [])),
    country: getInQuery(get(filter, 'country', [])),
    technology: getInQuery(get(filter, 'technology', [])),
  }
  truthyFilter?.partnerId === null && delete truthyFilter?.partnerId
  truthyFilter?.userId === null && delete truthyFilter?.userId
  truthyFilter?.country === null && delete truthyFilter?.country
  truthyFilter?.technology === null && delete truthyFilter?.technology

  return truthyFilter
}

export function getInitialFilterValue(): IFormattedFilter {
  const filterValuesOfProjectsJSON: string = window.localStorage.getItem('filterValuesOfProjects')
  const filterValuesOfProjects: IStoredProjectFilterAdmin = JSON.parse(filterValuesOfProjectsJSON)
  const isActive: boolean = get(filterValuesOfProjects, EStoredFilterValue.IS_ACTIVE, true)
  const isArchived: boolean = get(filterValuesOfProjects, EStoredFilterValue.IS_ARCHIVED, false)
  const technology: string[] = getStoredFilterValue(filterValuesOfProjects, EStoredFilterValue.SELECTED_TECHNOLOGIES)
  const country: string[] = getStoredFilterValue(filterValuesOfProjects, EStoredFilterValue.SELECTED_COUNTRIES)
  const partnerId: string[] = getStoredFilterValue(filterValuesOfProjects, EStoredFilterValue.SELECTED_PARTNER_ID)
  const userId: string[] = getStoredFilterValue(filterValuesOfProjects, EStoredFilterValue.SELECTED_USER_ID)
  const isGetAllData: boolean = !isActive && !isArchived

  const filter: IProjectFilter = {
    isActive: isGetAllData ? undefined : isActive,
    isArchived: isGetAllData ? undefined : isArchived,
    technology,
    country,
    partnerId,
    userId,
  }

  return formatProjectFilter(filter)
}

function getStoredFilterValue(
  filterValuesOfProjects: Omit<IStoredProjectFilterAdmin, 'isActive' | 'isArchive'>,
  keyName: EStoredFilterValue
): string[] {
  const selectedOptions: IOption[] = get(filterValuesOfProjects, keyName, [])
  const values: string[] = getValidArray(selectedOptions).map((item: IOption) => item.value)

  return values
}

export function handleBackgroundColor(value: string): string {
  switch (value) {
    case ETechnologyItem.NODE_JS:
      return 'green.50'
    case ETechnologyItem.REACT_NATIVE:
      return 'blue.50'
    case ETechnologyItem.LOOPBACK_4:
      return 'orange.50'
    default:
      return 'none'
  }
}

export function handleTextColor(value: string): string {
  switch (value) {
    case ETechnologyItem.NODE_JS:
      return 'green.500'
    case ETechnologyItem.REACT_NATIVE:
      return 'blue.500'
    case ETechnologyItem.LOOPBACK_4:
      return 'orange.500'
    default:
      return 'gray.700'
  }
}

export function getProjectTechnologies(listTechnologyId: string[], technologiesList: ITechnology[]): string[] {
  const technologiesOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(technologiesList)
  const technologiesOfProject: string[] = getValidArray(listTechnologyId).map((technologyId: string) => {
    const validItem: IOption = technologiesOptions.find((technology) => {
      return technology.value === technologyId
    })
    return validItem?.label ?? null
  })
  return technologiesOfProject.filter((item) => item)
}

export function combineStartedDateEndedDate(startedDate: Date, endedDate: Date, isUntilNow: boolean): string {
  return isUntilNow === true
    ? moment(startedDate ?? '').format('DD/MM/YYYY') + ' - Now'
    : moment(startedDate ?? '').format('DD/MM/YYYY') + ' - ' + moment(endedDate ?? '').format('DD/MM/YYYY')
}
