import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

//*INFO: Our breakpoints (576px, 768px, 1200px, 1440px)
const breakpoints = createBreakpoints({
  base: '0px',
  sm: '319px',
  md: '767px',
  lg: '1199px',
  xl: '1439px',
})

const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      'html, body': {
        background: 'white',
      },
    },
  },
  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
    mono: 'Roboto',
  },
})

export default theme
