import { ITableHeader } from 'components/Table'
import moment from 'moment'
import * as Yup from 'yup'
import { IProject, IPartner, ICreatableOption } from 'constants/schema'

export interface IRowActionItem {
  name: string
  customClassNames?: string[]
  handler: () => void
}
export function getHeaderList(isMobile: boolean): ITableHeader[] {
  const headers: ITableHeader[] = isMobile
    ? [
        {
          Header: 'LOGO',
          accessor: 'logo',
        },
        {
          Header: 'PROJECT NAME',
          accessor: 'name',
        },
        {
          Header: '',
          accessor: 'actions',
        },
      ]
    : [
        {
          Header: 'LOGO',
          accessor: 'logo',
        },
        {
          Header: 'PROJECT NAME',
          accessor: 'name',
        },
        {
          Header: 'Status',
          accessor: 'status',
        },
        {
          Header: 'TECHNOLOGY',
          accessor: 'technologiesListFilter',
        },
        {
          Header: 'START DATE - END DATE',
          accessor: 'startedDateEndDate',
        },
        {
          Header: '',
          accessor: 'actions',
        },
      ]

  return headers
}

export const today = moment().format('YYYY-MM-DD')
export const initialValues = {}
export const validation = Yup.object().shape({
  year: Yup.number().min(1900).max(2100),
  month: Yup.number().min(1).max(12),
})
export interface Props {
  history: any
  match: any
  handleCloseConfirmDialog?: () => void
  handleOpenSnackbar?: ({ variant, message }) => void
}

export interface IOption {
  label: string
  value: string
}

export interface IProjectFilter extends Omit<IProject, 'partnerId' | 'countryId' | 'technology' | 'user'> {
  partnerId?: string
  countryId?: string[]
  technology?: string[]
  user?: string[]
}
export interface States {
  projects: IProject[]
  count: number
  filter?: IProjectFilter
  partners?: IPartner[]
  doctypes?: ICreatableOption[]
  open?: boolean
  exportNow?: boolean
}
