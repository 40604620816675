import { ISuccessResponse } from 'API/constants'
import { getListCreatableOptions } from 'API/creatableOption'
import { ICreatableOptionListWithFilterResponse } from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import { getPartnerDetail, updatePartner, createPartner } from 'API/partner'
import omit from 'lodash/omit'
import { makeAutoObservable } from 'mobx'
import { generatePath } from 'react-router'
import { toast } from 'react-toastify'
import { THistory } from 'types/common'
import { getParsedPartnerValueBeforeSendBE, getParsedPartnerValueBeforeRenderFE } from 'utils'
import { ECreatableOptionScope } from 'constants/enum'
import { IPartner } from 'constants/schema'
import { RoutesConstants } from '../../constants'
import RootStore from '../rootStore'

class AdminPartnerStore {
  rootStore: RootStore
  partnerDetail
  creatableOptionList

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async getPartnerDetail(partnerId: string, isEdit: boolean): Promise<void> {
    try {
      const { partner } = await getPartnerDetail(partnerId)
      this.partnerDetail = getParsedPartnerValueBeforeRenderFE(partner, isEdit)
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'getPartnerDetail')
    }
  }

  public async getOptionList(): Promise<void> {
    try {
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptions({
        filter: { scope: ECreatableOptionScope.USER },
      })
      this.creatableOptionList = response.data.creatableOptions
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'getOptionList')
    }
  }

  public async createPartner(data: IPartner, history?: THistory): Promise<void> {
    try {
      const formattedPartner: Partial<IPartner> = getParsedPartnerValueBeforeSendBE(data)
      const { userId, messages } = await createPartner(formattedPartner)
      if (userId) {
        const pathnameUserDetail: string = generatePath(RoutesConstants.USER_DETAIL, { id: userId })
        window.sessionStorage.setItem('needToUpdateUser', 'true')
        history.push(pathnameUserDetail)
        toast.success(messages?.createUser)
      } else {
        toast.success(messages?.createPartner)
        const pathnamePartnerList: string = generatePath(RoutesConstants.PARTNER)
        history.push(pathnamePartnerList)
      }
    } catch (error) {
      toast.error('Create partner failed!')
      handleError(error, 'src/store/admin/partnerStore.ts', 'createPartner')
    }
  }

  public async updatePartner(data: IPartner, partnerId: string): Promise<string> {
    try {
      const formattedPartner: Partial<IPartner> = getParsedPartnerValueBeforeSendBE(
        omit(data, ['id', 'createdAt', 'updatedAt', 'createdBy', 'updatedBy'])
      )
      const { messages } = await updatePartner(formattedPartner, partnerId)
      return messages
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'updatePartner')
      return ''
    }
  }

  public async deletePartner(partnerId: string): Promise<string> {
    try {
      const { messages } = await updatePartner({ isDeleted: true }, partnerId)
      return messages
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'deletePartner')
      return ''
    }
  }

  public async archivePartner(partnerId: string): Promise<string> {
    try {
      const { messages } = await updatePartner({ isArchived: true }, partnerId)
      return messages
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'archivePartner')
      return ''
    }
  }

  public async unArchivePartner(partnerId: string): Promise<string> {
    try {
      const { messages } = await updatePartner({ isArchived: false }, partnerId)
      return messages
    } catch (error) {
      handleError(error, 'src/store/admin/partnerStore.ts', 'unArchivePartner')
      return ''
    }
  }
}

export default AdminPartnerStore
