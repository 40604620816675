import Button from '@material-ui/core/Button'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'
import { backgroundRed500, textWhite } from 'theme/globalStyles'
import DialogTitle from './components/DialogTitle'

export const StyledDialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: '16px 20px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(DialogTitle)

export const DialogContent = withStyles(() => ({
  root: {
    margin: 0,
    padding: 20,
  },
}))(MuiDialogContent)

export const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions)

export const StyledOkButton = withStyles({
  root: { background: backgroundRed500, color: textWhite },
  label: { textTransform: 'capitalize' },
})(Button)
