import { Image, VStack } from '@chakra-ui/react'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import Helper from 'Helper'
import { useFormContext, useWatch } from 'react-hook-form'
import { EUserDetailTitle } from 'containers/UserNew/UserDetail/constant'
import Section from '../../../Section'

interface IUserProfilePictureProps {
  name?: string
}

const UserProfilePicture = (props: IUserProfilePictureProps) => {
  const { name } = props
  const { control } = useFormContext()
  const avatarUrl: string = useWatch({ control, name })
  const fullAvatarUrl: string = Helper.generateImageURL(avatarUrl, 'avatar')

  return (
    <Section title={EUserDetailTitle.USER_PROFILE_PICTURE}>
      <VStack>
        {fullAvatarUrl ? (
          <Image boxSize="343px" objectFit="cover" src={fullAvatarUrl} alt="profile avatar" borderRadius={8} />
        ) : (
          <EmptyContentBox text="There is no avatar here" />
        )}
      </VStack>
    </Section>
  )
}

export default UserProfilePicture
