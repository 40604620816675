import { useState } from 'react'
import { Flex, Box, VStack, Link } from '@chakra-ui/react'
import ButtonForm from 'components/ButtonForm'
import LoginBackground from 'components/LoginBackground'
import { TitleLogin } from 'components/TitleLogin'
import { RoutesConstants } from '../../constants'
import ResetPasswordForm from './components/ResetPasswordForm'

const ResetPassword = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  return (
    <Flex h="100vh" direction={{ base: 'column', md: 'row' }}>
      <LoginBackground />
      <Flex flex={1} align="center" justify="center" bg="white" px="6">
        <VStack spacing="6" w="full" maxW="400px">
          <TitleLogin
            title="Reset your password"
            subtitle={
              isSubmitted
                ? 'Your password has been updated successfully. Please sign in to your account again'
                : 'Please provide your new password'
            }
          />
          {isSubmitted ? (
            <Box w="full" margin={'0px !important'}>
              <Link href={RoutesConstants.LOGIN} style={{ textDecoration: 'none' }}>
                <ButtonForm label="Sign in now" type="button" />
              </Link>
            </Box>
          ) : (
            <Box w="full">
              <ResetPasswordForm setIsSubmitted={setIsSubmitted} />
            </Box>
          )}
        </VStack>
      </Flex>
    </Flex>
  )
}

export default ResetPassword
