export enum ESubMenuName {
  TIMESHEET = 'timesheet',
  COMMIT_TIME = 'commit-time',
  SETTINGS = 'settings',
  PAYMENTS = 'payments',
  ACCOUNTING = 'accounting',
}

// * INFO: This is the sub sidebar menu not have page
export const settingSubmenu = [
  //   {
  //     name: 'Global config'
  //   },
  //   {
  //     name: 'IAM'
  //   },
]

export const timesheetSubmenu = [
  {
    name: 'Overview',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: [],
  },
  {
    name: 'Your Timesheet',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['0', ''],
  },
  {
    name: 'Report',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['1'],
  },
  {
    name: 'Bill Report',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['2'],
  },
  {
    name: 'History',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['3'],
  },
]

export const timesheetSubmenuUser = [
  // * INFO: This is the sub sidebar menu not have page
  // {
  //   name: 'Overview',
  //   tabIndex: 4,
  // },
  {
    name: 'Your Timesheet',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['0', ''],
  },
  {
    name: 'Report',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['1'],
  },
  {
    name: 'History',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['2'],
  },
]

export const timesheetSubmenuSupperVisor = [
  {
    name: 'Your Timesheet',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['0', ''],
  },
  {
    name: 'Report',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['1'],
  },
  {
    name: 'History',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['2'],
  },
  {
    name: 'Supervisorment',
    menuName: ESubMenuName.TIMESHEET,
    tabIndex: ['3'],
  },
]

export const commitTimeSubmenu = [
  // * INFO: This is the sub sidebar menu not have page
  // {
  //   name: 'Dashboard',
  // },
  {
    name: 'Overview',
    menuName: ESubMenuName.COMMIT_TIME,
    tabIndex: ['0'],
  },
  {
    name: 'Request History',
    menuName: ESubMenuName.COMMIT_TIME,
    tabIndex: ['1'],
  },
  {
    name: 'Report',
    menuName: ESubMenuName.COMMIT_TIME,
    tabIndex: ['2'],
  },
]

export const paymentSubmenu = [
  // * INFO: This is the sub sidebar menu not have page
  // {
  //   name: 'Freelancer Fee'
  // },
  // {
  //   name: 'Payroll'
  // },
  // {
  //   name: 'Advanced Payment'
  // },
]

export const accountingSubmenu = [
  // * INFO: This is the sub sidebar menu not have page
  // {
  //   name: 'Revenue'
  // },
  // {
  //   name: 'Expense'
  // },
  // {
  //   name: 'Client Invoice'
  // },
]

export interface IRouteName {
  userRoute: string
  partner: string
  timesheetRoute: string
  projectRoute: string
  commitTimeRoute: string
  internalDocument: string
  paymentRoute: string
  policyRoute: string
  infoRoute: string
  feedbackRoute: string
  diagramRoute: string
  linkToHome: string
  qrCodeRoute: string
  dashboardRoute: string
}

export interface IMenuList {
  name: string
  menuName: ESubMenuName
  tabIndex?: string[]
}

export enum ESidebarHeaderTitle {
  DEVELOPMENT = 'DEVELOPMENT',
  MANAGEMENT = 'MANAGEMENT',
  GENERAL = 'GENERAL',
  FINANCIAL = 'FINANCIAL',
  INFORMATION = 'INFORMATION',
  EXTENSIONS = 'EXTENSIONS',
  OPERATING_MANAGEMENT = 'OPERATING MANAGEMENT',
}

export enum ESidebarMenuContent {
  DASHBOARD = 'Dashboard',
  PARTNER = 'Partner',
  USERS = 'Users',
  INTERNAL_DOCUMENT = 'Internal Document',
  SETTINGS = 'Settings',
  STRATEGY_DIAGRAM = 'Strategy Diagram',
  TIMESHEET = 'Timesheet',
  PROJECT = 'Project',
  COMMIT_TIME = 'Commit Time',
  POLICY = 'Policy',
  ANNOUNCEMENT = 'Announcement',
  PAYMENTS = 'Payments',
  ACCOUNTING = 'Accounting',
  FEEDBACK = 'Feedback',
  GUIDELINE = 'Guideline',
  QR_CODE = 'QR code',
  CONTRACTS = 'Contracts',
  BIRTHDAY_OF_STAFFS = 'Birthday of staffs',
}
