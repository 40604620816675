import { chakra, Box as CkBox, Text as CkText } from '@chakra-ui/react'

export const Container = chakra(CkBox, {
  baseStyle: () => ({
    position: 'relative',
    cursor: 'pointer',
  }),
})

export const ColorDot = chakra(CkBox, {
  baseStyle: () => ({
    height: '16px',
    width: '16px',
    borderRadius: '50%',
  }),
})

export const Label = chakra(CkText, {
  baseStyle: () => ({
    fontWeight: 400,
    fontSize: 'md',
    lineHeight: 6,
    color: 'gray.700',
  }),
})

export const Popover = chakra(CkBox, {
  baseStyle: () => ({
    position: 'absolute',
    zIndex: 2,
  }),
})

export const Cover = chakra(CkBox, {
  baseStyle: () => ({
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }),
})
