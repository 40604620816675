import React from 'react'
import { useMediaQuery } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import DesktopTable from './DesktopTable'
import { defaultExcludedList } from './DesktopTable/constant'
import TabletTable from './TabletTable'

export interface IPagination {
  includePagination?: boolean
  tableLength: number
  pageIndex: number
  pageSize?: number
  gotoPage: (pageIndex: number) => void
}

interface ITableProps {
  tableData?: object[]
  headerList?: any[]
  headerTabletList?
  isDetail?: boolean
  detailActionHandler?: (e?) => void
  tableSize?: string
  subComponent?: any
  pagination?
  isShowPagination?: boolean
  isLoading?: boolean
  hasNoSort?: boolean
  columnWidth?: number
  isHightLightExpanded?: boolean
  isManualSort?: boolean
  isStriped?: boolean
  hasBottomHeader?: boolean
  excludedExport?: string[]
  name?: string
  pageSize?: number
  setPageSize?: (page: number) => void
  exportNow?: boolean
  toggleExport?: (isExport: boolean) => void
}
const Table = (props: ITableProps) => {
  const {
    tableData,
    headerList,
    headerTabletList,
    isDetail,
    tableSize,
    subComponent,
    isShowPagination,
    pagination = { includePagination: false, pageIndex: 1 },
    isLoading = false,
    hasNoSort,
    hasBottomHeader = true,
    excludedExport = defaultExcludedList,
    exportNow,
    toggleExport,
  } = props
  const [isTablet] = useMediaQuery('(max-width: 767px)')
  return (
    <>
      {!isTablet ? (
        <DesktopTable
          tableData={tableData}
          headerList={headerList}
          subComponent={subComponent}
          pagination={pagination}
          isLoading={isLoading}
          hasNoSort={hasNoSort}
          hasBottomHeader={hasBottomHeader}
          excludedExport={excludedExport}
          exportNow={exportNow}
          toggleExport={toggleExport}
        />
      ) : (
        <TabletTable
          isShowPagination={isShowPagination}
          pagination={pagination}
          isDetail={isDetail}
          tableData={tableData}
          headerList={headerTabletList}
        />
      )}
    </>
  )
}
export default observer(Table)
