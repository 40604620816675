import React from 'react'
import { Text, Center } from '@chakra-ui/react'
import { version } from '../../../../../package.json'

interface IAppVersionPaneProps {
  isCollapsed: boolean
}

const AppVersionPane = (props: IAppVersionPaneProps) => {
  const { isCollapsed } = props
  return (
    <Center width="100%">
      <Text margin={0}>
        {isCollapsed ? '' : 'App version: '}
        {version}
      </Text>
    </Center>
  )
}

export default AppVersionPane
