export enum ESettingType {
  TECHNOLOGY = 'technology',
  PROJECT_TYPE = 'project-type',
  COUNTRY = 'country',
  CURRENCY = 'currency',
  RATE_EXCHANGE = 'rate-exchange',
  INTERNAL_DOCUMENT = 'internal document',
  DOCTYPE = 'doctype',
  TITLE = 'title',
  LEVEL = 'level',
}

export const CREATABLE_OPTION_BASE_URL = '/creatable-option'

export const creatableOptionTypes: string[] = [ESettingType.DOCTYPE, ESettingType.TITLE, ESettingType.LEVEL]

export interface ISettingList {
  technologies: ISettingData[]
  projectTypes: ISettingData[]
  currencies: ISettingData[]
  countries: ISettingData[]
  rateExchanges: ISettingData[]
  internalDocumentTypes: ISettingData[]
  titles: ISettingData[]
  levels: ISettingData[]
}

export interface ISettingData {
  id?: string
  value: string
  createdAt?: string
  updatedAt?: string
  createdBy?: string
  updatedBy?: string
}
