import React, { useEffect } from 'react'
import { HStack, VStack, Container, Text, Button } from '@chakra-ui/react'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useStores } from 'utils/hooks/useStores'
import CreateClientBillForm from '../components/CreateClientBillForm'

const IncomeCreate = () => {
  const title: string = 'Add New Client Bill'
  window.sessionStorage.setItem('subPageTitle', title)
  window.localStorage.removeItem('pageTitle')
  window.localStorage.setItem('pageTitle', 'Accounting')
  window.dispatchEvent(new Event('storage'))
  const { adminProjectStore, adminClientBillStore } = useStores()
  const { partnerList, usersList, projectsListWithoutFilter } = adminProjectStore
  const { clientBillData } = adminClientBillStore

  const methods = useForm({
    defaultValues: {
      clientBillItemList: [{}, {}],
    },
  })
  const history = useHistory()
  const { handleSubmit, reset, getValues } = methods
  const partnerCategory: string[] = ['owner', 'client']
  useEffect(() => {
    adminProjectStore.getUserList()
    adminProjectStore.getPartnerList(partnerCategory)
    adminProjectStore.getProjectListWithoutFilter()
  }, [])

  function resetForm() {
    reset()
  }
  useEffect(() => {
    if (clientBillData) {
      reset(clientBillData)
    }
  }, [clientBillData])

  function exportData() {
    const allValuesOfForm = getValues()
    adminClientBillStore.exportData(allValuesOfForm)
  }

  function submitHandler(data) {
    adminClientBillStore.createClientBill(data, history)
  }

  function deleteNewRowOfClientBillItemHandler(index) {
    const allValuesOfForm = getValues()
    const currentClientBillItemList = get(allValuesOfForm, 'clientBillItemList')
    const newClientBillItemList = [
      ...currentClientBillItemList.slice(0, index),
      ...currentClientBillItemList.slice(index + 1),
    ]
    const newClientBillData = {
      ...clientBillData,
      clientBillItemList: newClientBillItemList,
    }
    reset(newClientBillData)
  }

  function addNewRowOfClientBillItemHandler(index) {
    const allValuesOfForm = getValues()
    const currentClientBillItemList = get(allValuesOfForm, 'clientBillItemList')
    const newClientBillItemList = [...currentClientBillItemList, {}]
    const newClientBillData = {
      ...clientBillData,
      clientBillItemList: newClientBillItemList,
    }
    reset(newClientBillData)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => submitHandler(data))}>
        <VStack>
          <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
            <HStack margin={{ base: 6, lg: 6 }} height="full" background="white" justifyContent="space-between">
              <Text fontSize="lg" color="gray.700" margin={0}>
                New Client Bill
              </Text>
            </HStack>
          </Container>

          <HStack width="full" justifyContent="space-between" alignItems="flex-start">
            <VStack width="100%">
              <CreateClientBillForm
                partnerList={partnerList}
                userList={usersList}
                projectsList={projectsListWithoutFilter}
                clientBillList={clientBillData}
                methods={methods}
                deleteNewRowOfClientBillItemHandler={deleteNewRowOfClientBillItemHandler}
                addNewRowOfClientBillItemHandler={addNewRowOfClientBillItemHandler}
              />
              <HStack>
                <Button bg={'#38B2AC'} minWidth={'none'} isFullWidth variant="solid" onClick={exportData} type="button">
                  Export
                </Button>
                <Button bg={'#4D5DFB'} minWidth={'none'} isFullWidth variant="solid" type="submit">
                  Save
                </Button>
              </HStack>
            </VStack>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  )
}

export default observer(IncomeCreate)
