import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Edit from '@material-ui/icons/Edit'
import Save from '@material-ui/icons/Save'
import Cancel from '@material-ui/icons/Cancel'
import Delete from '@material-ui/icons/Delete'

const styles = {
  inlineli: {
    marginRight: '2.5rem',
    height: '0.7rem',
    width: '0.7rem',
  },
  inlinetoolbutton: {
    height: '35px',
    width: '35px',
    marginTop: '5px',
  },
  inlineIcon: {
    marginTop: '-7px',
  },
  inlineActiveIcon: {
    marginTop: '-7px',
    color: '#2D3748',
  },
}

class ToggleEditSaveDeleteInline extends React.Component {
  render() {
    const { viewMode, title, saveDisabled, editClickHandler, cancelClickHandler, deleteClickHandler } = this.props
    switch (viewMode) {
      case 'show':
        return (
          <ul id="toggle-edit-save-button" className="list-unstyled list-inline headericons float-right">
            <li style={styles.inlineli} className="list-inline-item">
              <Tooltip title={`Edit ${title}`}>
                <IconButton
                  data-cy={`edit`}
                  aria-label={`Edit ${title}`}
                  style={styles.inlinetoolbutton}
                  onClick={editClickHandler}>
                  <Edit style={styles.inlineIcon} />
                </IconButton>
              </Tooltip>
            </li>
            <li style={styles.inlineli} className="list-inline-item">
              <Tooltip title={`Delete`}>
                <IconButton
                  data-cy={`edit`}
                  aria-label={`Delete`}
                  style={styles.inlinetoolbutton}
                  onClick={deleteClickHandler}>
                  <Delete style={styles.inlineIcon} />
                </IconButton>
              </Tooltip>
            </li>
          </ul>
        )
      case 'edit':
        return (
          <ul id="toggle-edit-save-button" className="list-unstyled list-inline headericons float-right">
            <li style={styles.inlineli} className="list-inline-item">
              <Tooltip title={`Cancel ${title}`}>
                <IconButton
                  data-cy={`cancel`}
                  aria-label={`Cancel ${title}`}
                  style={styles.inlinetoolbutton}
                  onClick={cancelClickHandler}>
                  <Cancel style={styles.inlineIcon} />
                </IconButton>
              </Tooltip>
            </li>
            <li style={styles.inlineli} className="list-inline-item">
              <Tooltip title={`Save ${title}`}>
                <div>
                  <IconButton
                    data-cy="submit"
                    aria-label={`Save ${title}`}
                    style={styles.inlinetoolbutton}
                    type="submit"
                    disabled={saveDisabled}>
                    <Save style={saveDisabled ? styles.inlineIcon : styles.inlineActiveIcon} />
                  </IconButton>
                </div>
              </Tooltip>
            </li>
            <li style={styles.inlineli} className="list-inline-item">
              <Tooltip title={`Delete`}>
                <IconButton
                  data-cy={`edit`}
                  aria-label={`Delete`}
                  style={styles.inlinetoolbutton}
                  onClick={deleteClickHandler}>
                  <Delete style={styles.inlineIcon} />
                </IconButton>
              </Tooltip>
            </li>
          </ul>
        )
      default:
        return null
    }
  }
}

export default ToggleEditSaveDeleteInline
