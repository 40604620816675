import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'

export interface IConfirmArchiveModal {
  data?: any
  isOpen?: boolean
  title?: string
  content?: string
  closeHandler?
  OKClickHandler?: (id: string) => void
}

const ConfirmArchiveModal = (props: IConfirmArchiveModal) => {
  const { isOpen, title, content, closeHandler, OKClickHandler, data } = props
  const [isCorrectName, setCorrectName] = useState<boolean>(false)

  useEffect(() => {
    setCorrectName(false)
  }, [isOpen])

  function inputOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value
    setCorrectName(inputValue === data.shortName ? true : false)
  }

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent height={'fit-content !important'}>
        <ModalHeader>
          {data?.isArchived ? 'Unarchive' : 'Archive'} {title}
        </ModalHeader>
        <ModalCloseButton color={'gray'} />
        <ModalBody>
          <Text>
            Are you sure you want to {data?.isArchived ? 'unarchive' : 'archive'} this {title}?
          </Text>
          <FormControl>
            <FormLabel fontWeight={'normal'}>
              Please type <b>{data?.shortName}</b> in the <b>{content}</b> to confirm
            </FormLabel>
            <Input autoFocus={true} placeholder={content} onChange={inputOnChange} width={'90%'} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button background={'white'} variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button onClick={() => OKClickHandler(data._id)} colorScheme="blue" disabled={isCorrectName ? false : true}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmArchiveModal
