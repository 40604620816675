import React from 'react'
import { SimpleGrid, Text } from '@chakra-ui/react'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import FormInput from 'componentsCharka/FormInput'
import Helper from 'Helper'
import { TypeOfContract } from 'constants/defaultOptionList'
import styles from '../../../contract.module.scss'

const CommonInformation = (props) => {
  const { partnerList } = props
  const partnersOptions = Helper.createOptionsOfReactSelectFromDB(partnerList)
  const contractTypesOptions = Helper.createOptionsOfSelect(TypeOfContract)

  return (
    <React.Fragment>
      <Text alignSelf={'flex-start'} className={styles.headerBox}>
        Common Information
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
        <ChakraInputDropdown
          name="partnerId"
          label="Belongs To Partner *"
          optionsData={partnersOptions}
          placeHolder={'- Select Partner -'}
        />
        <ChakraInputDropdown
          name="type"
          label="Contract Type"
          optionsData={contractTypesOptions}
          placeHolder={'- Select Project Type -'}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="IDIssueDate" label="ID Issue Date" type="date" />
        <FormInput name="IDNumber" label="ID Number" />
        <FormInput name="publishedDate" label="Published Date" type="date" />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 4, lg: 4 }} gap={{ base: 6, lg: 8 }} width="full">
        <FormInput name="workingFromDate" label="Working From Date" type="date" />
        <FormInput name="workingEndDate" label="Working End Date" type="date" />
        <FormInput name="dateOfBirth" label="Birth Date" type="date" />
      </SimpleGrid>
    </React.Fragment>
  )
}

export default CommonInformation
