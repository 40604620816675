import { VStack, Select, FormControl, Stack } from '@chakra-ui/react'
import { useFormContext, Controller } from 'react-hook-form'
import { backgroundGrey100 } from 'theme/globalStyles'
import { IOption } from 'types/user'
import { getValidArray } from 'utils'
import DatePicker from 'containers/v2/TimeSheet/TimeSheetUser/components/DatePicker'
import { FormLabel, Input } from './projectRatingRequestForm.style'

const EditProjectRatingForm = () => {
  //TODO: integrate backend to get the real data instead of use mockup data
  const projectHourlyRate: number = 6
  const projectClientRate: number = 4
  const optionData: IOption[] = [
    { label: 'Option 1', value: '1' },
    { label: 'Option 2', value: '2' },
    { label: 'Option 3', value: '3' },
  ]
  const { control, setValue } = useFormContext()

  function pickApplyDate(date: Date): void {
    setValue('applyDate', date)
  }

  return (
    <VStack spacing={6} marginY={6}>
      <FormControl>
        <FormLabel>User</FormLabel>
        <Controller
          name="userName"
          control={control}
          render={({ field }) => <Input readOnly background={backgroundGrey100} {...field} />}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Project</FormLabel>
        <Controller
          name="project"
          control={control}
          render={({ field }) => (
            <Select {...field}>
              {getValidArray(optionData).map((option) => {
                return <option key={option?.value}>{option?.label}</option>
              })}
            </Select>
          )}
        />
      </FormControl>
      <Stack align="stretch" width="100%" spacing={6} direction={{ base: 'column', md: 'row' }}>
        <FormControl>
          <FormLabel>Current Project Hourly Rate</FormLabel>
          <Controller
            name="currentProjectHourlyRate"
            control={control}
            render={({ field }) => <Input readOnly background={backgroundGrey100} {...field} />}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Project Hourly Rate</FormLabel>
          <Controller
            name="projectHourlyRate"
            control={control}
            render={({ field }) => <Input value={projectHourlyRate} {...field} />}
          />
        </FormControl>
      </Stack>
      <FormControl>
        <FormLabel>Project Client Rate</FormLabel>
        <Controller
          name="projectClientRate"
          control={control}
          render={({ field }) => <Input value={projectClientRate} {...field} />}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Apply Date</FormLabel>
        <Controller
          name="applyDate"
          control={control}
          render={({ field: { value } }) => <DatePicker selectedDate={value} onChange={pickApplyDate} />}
        />
      </FormControl>
    </VStack>
  )
}

export default EditProjectRatingForm
