import MultipleSelect from './MultipleSelect'
import SingleSelect from './SingleSelect'
import { IChakraInputDropdownProps } from './types'

const ChakraInputDropdown = (props: IChakraInputDropdownProps) => {
  const {
    name,
    label,
    isMulti,
    optionsData,
    isDisabled,
    selectHandler,
    placeHolder,
    isRequire,
    helperText,
    widthCustom,
    isClearable,
    isCustomValue,
    optionStyle,
    placeholderStyle,
    isDisabledClearIndicator,
    isCreatable,
  } = props
  return (
    <>
      {isMulti ? (
        <MultipleSelect
          label={label}
          name={name}
          isDisabled={isDisabled}
          optionsData={optionsData}
          selectHandler={selectHandler}
          placeHolder={placeHolder}
          helperText={helperText}
          widthCustom={widthCustom}
          isClearable={isClearable}
          isCustomValue={isCustomValue}
          optionStyle={optionStyle}
          placeholderStyle={placeholderStyle}
        />
      ) : (
        <SingleSelect
          label={label}
          name={name}
          placeHolder={placeHolder}
          isDisabled={isDisabled}
          optionsData={optionsData}
          isRequire={isRequire}
          helperText={helperText}
          widthCustom={widthCustom}
          isClearable={isClearable}
          isDisabledClearIndicator={isDisabledClearIndicator}
          isCreatable={isCreatable}
        />
      )}
    </>
  )
}

export default ChakraInputDropdown
