import React from 'react'
import { Field } from 'formik'
import { withStyles } from '@material-ui/core/styles'

import Input from '../../../../../components/Input'
import Select from '../../../../../components/Select'
import Helper from '../../../../../Helper'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
})

class FormRateExchangeDetail extends React.Component {
  render() {
    const { classes, handleSubmit, dirty, isSubmitting, viewMode, cancelEditHandler, currencies } = this.props
    let buttonText = 'Edit'
    if (viewMode === 'create') {
      buttonText = 'Create'
    }
    const currenciesOptions = Helper.createOptionsOfSelectFromDB(currencies)
    return (
      <form onSubmit={handleSubmit} className="form-detail">
        <div className="fields">
          <div className="field">
            <Field
              name="name"
              label="Name"
              className={classes.textField + '98width'}
              margin="normal"
              options={currenciesOptions}
              component={Select}
            />
          </div>
          <div className="field">
            <Field name="value" label="Value" className={classes.textField} margin="normal" component={Input} />
          </div>
        </div>

        <div className="options-sub">
          <button className="project-setting-button primary-button" type="submit" disabled={!dirty && isSubmitting}>
            {buttonText}
          </button>
          {viewMode === 'edit' && (
            <button className="project-setting-button secondary-button" onClick={cancelEditHandler}>
              Cancel
            </button>
          )}
        </div>
      </form>
    )
  }
}

export default withStyles(styles)(FormRateExchangeDetail)
