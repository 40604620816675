import { useEffect, useState } from 'react'
import { Flex, HStack, Image, Input, InputGroup, InputLeftAddon, Text, VStack } from '@chakra-ui/react'
import ButtonWithIcon from 'componentsCharka/ButtonWithIcon'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { backgroundBlueSecondary, borderColor } from 'theme/globalStyles'
import QRBackGroundURL from '../../assets/images/background/qr-background.png'

const QRCodePage = () => {
  const [link, setLink] = useState<string>('')
  const methods = useForm()
  const { control, register } = methods
  const watchedWebLink: string = useWatch({ name: 'weblink', control })

  function generateQRCode(): void {
    let weblink: string = ''
    if (watchedWebLink?.includes('https')) {
      weblink = watchedWebLink
    } else {
      weblink = `https://${watchedWebLink}`
    }
    const qrLink: string = `https://chart.googleapis.com/chart?cht=qr&chl=${weblink}&chs=250x250&chld=L|0`
    setLink(qrLink)
  }

  function downloadQRCode(): void {
    if (watchedWebLink) {
      fetch(link)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'qrcode.png')
          document.body.appendChild(link)

          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        })
    }
  }

  useEffect(() => {
    generateQRCode()
  }, [watchedWebLink])

  return (
    <VStack alignItems={'center'} backgroundColor="white" borderRadius="8px" p={{ base: 6, lg: '69px' }} spacing={6}>
      <FormProvider {...methods}>
        <form>
          <VStack width={{ base: 'full', md: '520px' }} spacing={2}>
            <HStack justifyContent="space-between" width="full">
              <Text color="gray.700" lineHeight={6} margin={0}>
                URL
              </Text>
              <Text color="blue.600" lineHeight={6} textAlign="right">
                QR Code will be generated automatically
              </Text>
            </HStack>
            <InputGroup height={10} width={{ base: 'full', md: '520px' }}>
              <InputLeftAddon maxHeight="full" children="https://" />
              <Input
                type="text"
                {...register('weblink')}
                padding={0}
                border={`1px solid ${borderColor}`}
                maxHeight="full"
                placeholder="Enter URL"
                paddingLeft={4}
                _focus={{
                  border: `1px solid ${borderColor}`,
                }}
              />
            </InputGroup>
          </VStack>
        </form>
      </FormProvider>

      {watchedWebLink ? (
        <Flex padding="24px" maxHeight="300px" maxWidth="300px" borderRadius="8px" border={`1px solid ${borderColor}`}>
          <Image src={link} />
        </Flex>
      ) : (
        <Image width="300px" height="300px" src={QRBackGroundURL} />
      )}

      <ButtonWithIcon
        label="Download"
        iconName="download.svg"
        size={18}
        color="white"
        fontSize="lg"
        lineHeight={7}
        fontWeight={500}
        background={backgroundBlueSecondary}
        padding="10px 24px"
        height={12}
        isDisabled={!watchedWebLink}
        onClick={downloadQRCode}
      />
    </VStack>
  )
}

export default QRCodePage
