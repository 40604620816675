import { IInQuery } from 'API/constants'
import { IProjectRatings } from 'API/projectRating/constants'
import { IBirthday, IProfileDetailOfDetailForm, IUser } from 'types/user'
import { IUser as IUserSchema } from 'constants/schema'

export const USER_BASE_URL: string = '/users'

export const USER_PROFILE_BASE_URL: string = `${USER_BASE_URL}/profile`

export interface IUserProfileResponse {
  user: IUser
}

export interface IChangePasswordResponse {
  messages: string
  isSuccess: boolean
}

export interface IUserProfileResponse {
  user: IUser
}

export interface IUpdateUserProfileResponse {
  messages: string
}

export interface IUserListResponse {
  users: IUserSchema[]
  count?: number
}

export interface IUserFilterPagination {
  isActive: boolean
  isArchived: boolean
  isDeleted: boolean
  role?: string
  levelId?: IInQuery
  titleId?: IInQuery
  paymentCategoryId?: IInQuery
  fullName?: string
}

export interface IUserListWithPaginationRequest {
  currentPage: number
  perPage: number
  filter: IUserFilterPagination
}

export interface IUpdateUserRequest {
  newProjects?: string[]
  projectsNotAdded?: string[]
  isUpdateProjectRatings?: boolean
  updatedUserData?: IUserSchema
}

export interface ICreateUserRequest extends Omit<IUser, 'userData'> {
  userData: object
}

export interface IUserDetail {
  profileDetailData: IProfileDetailOfDetailForm
  projectRatings?: IProjectRatings
}

export interface IUserListAdminResponse {
  admins: IUser[]
}

export interface IBirthdayFilter {
  month: number
  category: string
}

export interface IBirthdayList {
  birthdayList: IBirthday[]
}
