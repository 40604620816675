import { Link } from '@chakra-ui/react'
import NoDataText from 'componentsCharka/NoDataText'
import { useFormContext, useWatch } from 'react-hook-form'

export interface IWebLinkFormProps {
  name: string
  isHighlighted?: boolean
}

// TODO: Use this component to replace the WebLinkForm component in componentsCharka when implement project detail desktop
const WebLinkForm = (props: IWebLinkFormProps) => {
  const { name, isHighlighted } = props
  const { control } = useFormContext()
  const value: string = useWatch({ name, control })
  const formattedValue: string = value?.includes('https://') ? value : 'https://' + value

  return (
    <>
      {value ? (
        <Link
          href={formattedValue}
          target="_blank"
          fontSize="md"
          color={isHighlighted ? 'blue.500' : 'gray.700'}
          fontWeight={'500'}
          width={'90%'}
          border={0}
          _hover={{
            bg: 'white',
          }}>
          {formattedValue}
        </Link>
      ) : (
        <NoDataText />
      )}
    </>
  )
}

export default WebLinkForm
