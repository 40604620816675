import React, { useEffect } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Button, Flex, Text, Box, Icon, useMediaQuery } from '@chakra-ui/react'
import { IPagination } from 'components/Table'
import isNaN from 'lodash/isNaN'
import min from 'lodash/min'
import { useForm, UseFormReturn, useWatch } from 'react-hook-form'
import { backgroundPrimary } from 'theme/globalStyles'
import { getValidArray } from 'utils'
import { maxMobileSize } from 'constants/common'
import Dropdown from 'containers/v2/TimeSheet/TimeSheetUser/components/Dropdown'
import { IProjectItem } from 'containers/v2/TimeSheet/TimeSheetUser/components/Dropdown/DropdownButton'
import { truncatePagination } from './utils'

export interface IPaginationProps {
  pagination: IPagination
}

const Pagination = (props: IPaginationProps) => {
  const { pagination } = props
  const { gotoPage, pageIndex, tableLength, pageSize = 8 } = pagination
  const startPointTable: number = pageSize * (pageIndex - 1) + 1
  const endPointTable: number = min([pageSize * pageIndex, tableLength])
  const numberOfPages: number = Math.ceil(tableLength / pageSize)
  const truncatedPagination: string[] = truncatePagination(Number(pageIndex), Number(numberOfPages))
  const method: UseFormReturn = useForm()
  const { setValue, control } = method
  const currentPage: IProjectItem = useWatch({ control, name: 'page' })
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)

  function getPageNumberItems(): IProjectItem[] {
    return getValidArray(Array.from(Array(numberOfPages).keys())).map((item: number) => {
      return {
        title: `Page ${item + 1}`,
        value: item + 1,
      }
    })
  }

  function goToNextPage(): void {
    const currentPageNumberItem: IProjectItem = { title: `Page ${pageIndex + 1}`, value: pageIndex + 1 }
    setValue('page', currentPageNumberItem)
    gotoPage(pageIndex + 1)
  }

  function goToPreviousPage(): void {
    const currentPageNumberItem: IProjectItem = { title: `Page ${pageIndex - 1}`, value: pageIndex - 1 }
    setValue('page', currentPageNumberItem)
    gotoPage(pageIndex - 1)
  }

  useEffect(() => {
    const pageNumber: number = Number(currentPage?.title?.split(' ')[1])
    gotoPage(pageNumber)
  }, [currentPage])

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      marginTop={{ base: '12px !important', md: 6 }}
      paddingX={{ base: 4, md: 'auto' }}>
      {!isMobile && (
        <Text alignSelf="center" color="gray.600">
          {tableLength === 0 ? `Show 0 of 0` : `Show ${startPointTable} - ${endPointTable} of ${tableLength}`}
        </Text>
      )}
      <Box gap={2}>
        <Button
          colorScheme="gray"
          variant="outline"
          background="gray.50"
          borderColor="gray.200"
          paddingX={2}
          marginRight={2}
          disabled={pageIndex === 1}
          onClick={goToPreviousPage}
          _hover={{ cursor: 'pointer' }}>
          <Icon width={6} height={6} as={ChevronLeftIcon} />
        </Button>
        {isMobile ? (
          <Button
            fontSize="16px !important"
            variant="solid"
            background={backgroundPrimary}
            color="white"
            borderColor="gray.200"
            padding={2}
            marginRight={2}
            lineHeight={1.5}
            _hover={{ cursor: 'pointer' }}>
            {pageIndex}
          </Button>
        ) : (
          <>
            {getValidArray(truncatedPagination).map((item: string, index: number) => {
              if (isNaN(Number(item))) {
                return (
                  <Button
                    variant="outline"
                    background="gray.50"
                    color="gray.600"
                    borderColor="gray.200"
                    padding={2}
                    marginRight={2}
                    lineHeight={1.5}
                    key={`pagination-${index}`}
                    onClick={() => {}}
                    _hover={{ cursor: 'pointer' }}>
                    {item}
                  </Button>
                )
              }
              const isActive: boolean = pageIndex === Number(item)
              return (
                <Button
                  fontSize="16px !important"
                  variant={isActive ? 'solid' : 'outline'}
                  background={!isActive ? 'gray.50' : backgroundPrimary}
                  color={isActive ? 'white' : 'gray.600'}
                  borderColor="gray.200"
                  padding={2}
                  marginRight={2}
                  lineHeight={1.5}
                  key={`pagination-${index}`}
                  onClick={() => (isActive ? {} : gotoPage(Number(item)))}
                  _hover={{ cursor: 'pointer' }}>
                  {item}
                </Button>
              )
            })}
          </>
        )}
        <Button
          colorScheme="gray"
          variant="outline"
          paddingX={2}
          background="gray.50"
          borderColor="gray.200"
          disabled={pageIndex === numberOfPages}
          onClick={goToNextPage}
          _hover={{ cursor: 'pointer' }}>
          <Icon width={6} height={6} as={ChevronRightIcon} />
        </Button>
      </Box>
      {isMobile && (
        <Dropdown
          options={getPageNumberItems()}
          name="page"
          text="Go to"
          item={currentPage}
          setValue={setValue}
          width={150}
          minWidth={150}
          hasIcon={false}
          isMatchWidth
        />
      )}
    </Flex>
  )
}

export default Pagination
