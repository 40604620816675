import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import UnderConstruction from 'containers/UnderConstruction'
const Diagram = () => {

  // *INFO: This will be use later when implement search filter
  // *INFO: If we add this useEffect, it can cause call api multiple times problem
  // useEffect(() => {
  //   const query: IDiagramQuery = {
  //     period,
  //     date,
  //     partners,
  //     technologies,
  //     isInactive,
  //     search: keyword,
  //   }
  //   fetchDiagramData(query)
  // }, [keyword])

  return (
    <div>
      <UnderConstruction />
    </div>
  )
}

export default observer(Diagram)
