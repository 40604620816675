import api from 'API'
import { ISuccessResponse } from 'API/constants'
import { getErrorMessage } from 'API/error'
import {
  CREATABLE_OPTION_BASE_URL,
  ICreatableOptionListWithFilterRequest,
  ICreatableOptionListWithFilterResponse,
} from './constants'

export async function getListCreatableOptions(
  data: ICreatableOptionListWithFilterRequest
): Promise<ISuccessResponse<ICreatableOptionListWithFilterResponse>> {
  try {
    return api.post(`${CREATABLE_OPTION_BASE_URL}/listWithFilter`, data)
  } catch (error) {
    const errorMessage: string = getErrorMessage(error)
    throw new Error(errorMessage)
  }
}
