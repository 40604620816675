import React from 'react'
import { useMediaQuery, chakra, Tr, Td } from '@chakra-ui/react'
import EmptyContentBox from 'componentsCharka/EmptyContentBox'
import ResponsiveTable from 'componentsCharka/ResponsiveTable'
import get from 'lodash/get'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { RoutesConstants, Messages } from 'constants/index'
import styles from '../../../index.module.scss'
import TableWithInputCell from '../components/TableWithInputCell'
import { getFixedProjectHeaderList } from '../constants'

interface IFixedProjectRatingProps {
  name: string
  isEdit?: boolean
}
const FixedProjectRating = (props: IFixedProjectRatingProps) => {
  const { name, isEdit } = props
  const history = useHistory()
  const headerGroupData = getFixedProjectHeaderList()
  const nameOfData = `${name}.fixedRatingPrices`
  const { control, register } = useFormContext()
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${nameOfData}`, // unique nameOfData for your Field Array
  })
  const [isTablet] = useMediaQuery('(max-width: 45em)')
  function goToProjectDetail(projectId: string) {
    if (projectId) {
      const pathname = generatePath(RoutesConstants.PROJECT_ADMIN_DETAIL, { id: projectId })
      history.push(pathname)
    }
  }
  return (
    <chakra.form width="full">
      {!isTablet ? (
        <React.Fragment>
          {fields.length === 0 && <EmptyContentBox text={Messages.noData} />}
          {fields && fields.length > 0 && (
            <TableWithInputCell headersGroup={headerGroupData}>
              {fields.map((field, index) => {
                const itemId = get(field, 'project.value', '')
                return (
                  <Tr key={index}>
                    <Td className="can-hover" onClick={() => goToProjectDetail(itemId)}>
                      <input
                        className={`${styles.projectRatingInputField} can-hover`}
                        {...register(`${nameOfData}.${index}.project.label`)}
                        disabled={true}
                      />
                    </Td>
                    <Td>
                      <input
                        className={styles.projectRatingInputField}
                        key={field.id} // important to include key with field's id
                        {...register(`${nameOfData}.${index}.fixedRate`)}
                        disabled={!isEdit}
                      />
                    </Td>
                    <Td>
                      <input
                        className={styles.projectRatingInputField}
                        type="date"
                        key={field.id} // important to include key with field's id
                        {...register(`${nameOfData}.${index}.applyDate`)}
                        disabled={!isEdit}
                      />
                    </Td>
                  </Tr>
                )
              })}
            </TableWithInputCell>
          )}
        </React.Fragment>
      ) : (
        <ResponsiveTable headersGroup={headerGroupData}>
          {fields.length === 0 && <EmptyContentBox text={Messages.noData} />}
          {fields &&
            fields.length > 0 &&
            fields.map((field, index) => {
              return (
                <Tr key={index}>
                  <Td aria-label="Project">
                    <input
                      className={styles.projectRatingInputField}
                      {...register(`${nameOfData}.${index}.project.label`)}
                      disabled={true}
                    />
                  </Td>
                  <Td aria-label="Hourly rate" bg={'white'}>
                    <input
                      className={styles.projectRatingInputField}
                      key={field.id} // important to include key with field's id
                      {...register(`${nameOfData}.${index}.fixedRate`)}
                      disabled={!isEdit}
                    />
                  </Td>
                  <Td aria-label="Apply date">
                    <input
                      className={styles.projectRatingInputField}
                      type="date"
                      key={field.id} // important to include key with field's id
                      {...register(`${nameOfData}.${index}.applyDate`)}
                      disabled={!isEdit}
                    />
                  </Td>
                </Tr>
              )
            })}
        </ResponsiveTable>
      )}
    </chakra.form>
  )
}

export default FixedProjectRating
