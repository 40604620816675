import { Box, Text, VStack } from '@chakra-ui/react'
import { ISuccessResponse } from 'API/constants'
import { handleError } from 'API/error'
import { uploadFileCv } from 'API/upload'
import { IUploadFileResponse } from 'API/upload/constants'
import { ReactComponent as CvFile } from 'assets/images/svg/uploadCV.svg'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { EUploadFileType } from 'types/upload'
import { useStores } from 'utils/hooks/useStores'
import CurriculumVitaeDetail from '../../../../../../../componentsCharka/CurriculumVitaeDetail'
import styles from './index.module.scss'

interface IEmptyCV {
  cvFileName?: string
}
const EmptyCV = (props: IEmptyCV) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: false, maxFiles: 1 })
  const { cvFileName } = props
  const [selectedFile, setSelectFile] = useState<File | null>(null)

  const { userProfileStore } = useStores()

  async function uploadCv(formData: FormData): Promise<void> {
    try {
      const response: ISuccessResponse<IUploadFileResponse> = await uploadFileCv(formData, EUploadFileType.CV)
      const messages: string = response.data.messages
      toast.success(messages)
    } catch (error) {
      handleError(
        error,
        'src/containers/ProfileNew/ProfileDetail/components/CurriculumVitae/components/EmptyCV/index.tsx',
        'uploadCv'
      )
    }
  }

  useEffect(() => {
    if (!acceptedFiles[0]) {
      return
    }

    setSelectFile(acceptedFiles[0])

    const formData = new FormData()
    formData.append('myFile', acceptedFiles[0])
    uploadCv(formData)
    const objectUrl = URL.createObjectURL(acceptedFiles[0])
    //* INFO: disable unnecessary eslint rule eslint-disable-next-line consistent-return
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl)
  }, [acceptedFiles[0]])

  return (
    <Box>
      {!selectedFile ? (
        <VStack>
          <Text as={'i'} color={'gray.600'}>
            Your CV is missing. Please upload your CV.
          </Text>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <VStack spacing={'24'}>
                <input {...getInputProps()} />
                <CvFile />
                <Text>
                  Drag and drop or <Text className={styles.textLink}>Browse your CV</Text>
                </Text>
              </VStack>
            </div>
          </section>
        </VStack>
      ) : (
        <CurriculumVitaeDetail isShowButton={true} cvFilePreview={acceptedFiles[0]} />
      )}
    </Box>
  )
}

export default EmptyCV
