import { Avatar, FormControl, FormLabel, HStack, Tooltip, Wrap, WrapItem, SimpleGrid } from '@chakra-ui/react'
import NoDataText from 'componentsCharka/NoDataText'
import Helper from 'Helper'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { IUser } from 'types/user'
import { getValidArray, checkValidArray } from 'utils'
import { RoutesConstants } from 'constants/index'

export interface IAvatarDevGroup {
  name?: string
  label?: string
  developers?: IUser[]
}

const AvatarDevGroup = (props: IAvatarDevGroup) => {
  const { name, label, developers } = props
  const history = useHistory()

  function goToDeveloperDetail(id: string): void {
    history.push(generatePath(RoutesConstants.USER_DETAIL, { id }))
  }

  return (
    <FormControl id={name} width="100%">
      {label && (
        <FormLabel fontWeight="normal" color="gray.500">
          {label}
        </FormLabel>
      )}
      {checkValidArray(developers) ? (
        <HStack justifyContent="space-between">
          <SimpleGrid columns={{ base: 1 }} gap={{ base: 6, lg: 8 }} width="full">
            <Wrap>
              {getValidArray(developers).map((developer) => (
                <WrapItem>
                  <Tooltip label={developer.fullName}>
                    <Avatar
                      onClick={() => goToDeveloperDetail(developer._id)}
                      height={6}
                      width={6}
                      key={developer._id}
                      src={Helper.generateImageURL(developer.avatar, 'avatar')}
                      _hover={{
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </WrapItem>
              ))}
            </Wrap>
          </SimpleGrid>
        </HStack>
      ) : (
        <NoDataText customStyle={{ margin: 0 }} />
      )}
    </FormControl>
  )
}

export default AvatarDevGroup
