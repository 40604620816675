// import ProfileImageURL from '../../assets/images/guideline/profile.png'
import ProfileImageURL from '../../assets/images/guideline/profile.png'
import TimesheetImageURL from '../../assets/images/guideline/timesheet.png'
import ProjectImageURL from '../../assets/images/guideline/project.png'
import PolicyImageURL from '../../assets/images/guideline/policy.png'
import InformationImageURL from '../../assets/images/guideline/information.png'
import FeedbackImageURL from '../../assets/images/guideline/feedback.png'

const GuideData = [
  {
    name: 'timesheet',
    title: 'Timesheet',
    purpose: 'Timekeeping',
    content:
      '\n Step 1: Choose the project\n Step 2: Fill the information of your task \n Step 3: \n - With the hourly project: the Wages are calculated based on total time \n - With the fixed project: you need to mention to the rest of budget of the project, and fill the remuneration',
    image: TimesheetImageURL,
  },
  {
    name: 'profile',
    title: 'Profile',
    purpose: 'Show your information',
    content:
      '\n - You should change your password as soon as your account is granted. \n - Fill in your personal information, bank account and upload the Curriculum Vitae \n ',
    image: ProfileImageURL,
  },
  {
    name: 'project',
    title: 'Projects',
    purpose: "Show the projects' information",
    content: 'You can get the information of all projects of the company',
    image: ProjectImageURL,
  },
  {
    name: 'policy',
    title: 'Policy',
    purpose:
      'A ‘Policy’ is a predetermined course of action, which is established to provide a guide toward accepted business strategies and objectives. In other words, it is a direct link between an organization’s ‘Vision’ and their day-to-day operations.',
    content: '\n - Please read carefully the content, published date. \n - Follow the company policy',
    image: PolicyImageURL,
  },
  {
    name: 'information',
    title: 'Information',
    purpose:
      'Information use is concerned with understanding what information sources people choose and the ways in which people apply information to make sense of their lives and situations.',
    content: '\n - Please read carefully the content, published date. \n - Follow the company information',
    image: InformationImageURL,
  },
  {
    name: 'feedback',
    title: 'Feedback',
    purpose: 'This is the place for you send your opinions, feedback to improve your work',
    content: '\n - Fill the information of feedback what you want to send to the admin',
    image: FeedbackImageURL,
  },
]

export default GuideData
