import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Button, Text, Box, Icon, Stack } from '@chakra-ui/react'
import { IPagination } from 'components/Table'
import isNaN from 'lodash/isNaN'
import min from 'lodash/min'
import { backgroundPrimary } from 'theme/globalStyles'
import { truncatePagination } from './utils'

export interface IPaginationProps {
  pagination: IPagination
}

const Pagination = (props: IPaginationProps) => {
  const { pagination } = props
  const { gotoPage, pageIndex, tableLength, pageSize = 8 } = pagination
  const startPointTable: number = pageSize * (pageIndex - 1) + 1
  const endPointTable: number = min([pageSize * pageIndex, tableLength])
  const numberOfPages: number = Math.ceil(tableLength / pageSize)
  const truncatedPagination: string[] = truncatePagination(Number(pageIndex), Number(numberOfPages))
  return (
    <Stack direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'column' }} justifyContent="space-between">
      <Text alignSelf="center" color="gray.600">
        {tableLength === 0 ? `Show 0 of 0` : `Show ${startPointTable} - ${endPointTable} of ${tableLength}`}
      </Text>
      <Box gap={2} alignSelf={{ sm: 'center' }}>
        <Button
          colorScheme="gray"
          variant="outline"
          background="gray.50"
          borderColor="gray.200"
          paddingX={{ md: 2, sm: 0 }}
          margin={{ xl: 1, md: 2, sm: 0 }}
          disabled={pageIndex === 1}
          minWidth={{ sm: 6 }}
          onClick={() => gotoPage(pageIndex - 1)}
          _hover={{ cursor: 'pointer' }}>
          <Icon width={6} height={6} as={ChevronLeftIcon} />
        </Button>
        {Array.isArray(truncatedPagination) &&
          truncatedPagination.map((item: string, index: number) => {
            if (isNaN(Number(item))) {
              return (
                <Button
                  variant={'outline'}
                  background={'gray.50'}
                  color={'gray.600'}
                  borderColor="gray.200"
                  padding={{ md: 2, sm: 0 }}
                  margin={{ xl: 1, md: 2, sm: 0 }}
                  width={{ sm: 6 }}
                  lineHeight={1.5}
                  key={`pagination-${index}`}
                  onClick={() => {}}
                  _hover={{ cursor: 'pointer' }}>
                  {item}
                </Button>
              )
            }
            const isActive = pageIndex === Number(item)
            return (
              <Button
                variant={isActive ? 'solid' : 'outline'}
                background={!isActive ? 'gray.50' : backgroundPrimary}
                color={isActive ? 'white' : 'gray.600'}
                borderColor="gray.200"
                padding={{ md: 2, sm: 0 }}
                margin={{ xl: 1, md: 2, sm: 0 }}
                width={{ sm: 6 }}
                lineHeight={1.5}
                key={`pagination-${index}`}
                onClick={() => (isActive ? {} : gotoPage(Number(item)))}
                _hover={{ cursor: 'pointer' }}>
                {item}
              </Button>
            )
          })}
        <Button
          colorScheme="gray"
          variant="outline"
          paddingX={{ md: 2, sm: 0 }}
          margin={{ xl: 1, md: 2, sm: 0 }}
          width={{ sm: 6 }}
          background="gray.50"
          borderColor="gray.200"
          disabled={pageIndex === numberOfPages}
          onClick={() => gotoPage(pageIndex + 1)}
          _hover={{ cursor: 'pointer' }}>
          <Icon width={6} height={6} as={ChevronRightIcon} />
        </Button>
      </Box>
    </Stack>
  )
}

export default Pagination
