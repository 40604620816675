import React from 'react'
import axios from 'axios'
import { saveAs } from 'file-saver'

import { Document, Page, pdfjs } from 'react-pdf'
import { AppConfig, Messages } from '../../../constants'
import DownloadCVInline from '../../../components/DownloadCVInline'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

class UserCV extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      numPages: null,
      pageNumber: 1,
      disabledPre: false,
      disabledNext: false,
      cvFile: undefined,
      showCV: true,
    }
  }

  componentDidMount() {
    this.getCVFileHandler()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.cvFileName !== this.props.cvFileName) {
      this.getCVFileHandler()
    }
  }

  checkDisabledNavBtn = () => {
    const { numPages, pageNumber } = this.state
    let isDisabledPre
    let isDisabledNext
    if (numPages === 1) {
      isDisabledPre = true
      isDisabledNext = true
    }
    if (pageNumber === numPages) {
      isDisabledNext = true
    }
    if (pageNumber === 1) {
      isDisabledPre = true
    }

    if (pageNumber < numPages && pageNumber > 1) {
      isDisabledPre = false
      isDisabledNext = false
    }
    this.setState({
      disabledPre: isDisabledPre,
      disabledNext: isDisabledNext,
    })
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState(
      {
        numPages,
      },
      () => {
        this.checkDisabledNavBtn()
      }
    )
  }
  clickNextPageHandler = () => {
    const { pageNumber } = this.state
    this.setState(
      {
        pageNumber: pageNumber + 1,
      },
      () => {
        this.checkDisabledNavBtn()
      }
    )
  }

  clickPrePageHandler = () => {
    const { pageNumber } = this.state
    this.setState(
      {
        pageNumber: pageNumber - 1,
      },
      () => {
        this.checkDisabledNavBtn()
      }
    )
  }
  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  getCVFileHandler = () => {
    const { cvFileName } = this.props
    if (cvFileName) {
      callLoginAPI(cvFileName)
        .then(async (response) => {
          const cv = response.data
          const result = await this.toBase64(new Blob([cv], { type: 'application/pdf' }))
          this.setState({
            cvFile: result,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
  downloadCVHandler = () => {
    const { cvFileName, userId } = this.props
    callDownloadCVFileAPI(cvFileName, userId)
      .then(async (response) => {
        const cv = response.data
        const result = await this.toBase64(new Blob([cv], { type: 'application/pdf' }))
        saveAs(result, cvFileName)
      })
      .catch((error) => {
        alert(Messages.notAllowToDownloadCV)
      })
  }
  render() {
    const { pageNumber, numPages, disabledPre, disabledNext, cvFile } = this.state
    const { enabledShareCV } = this.props
    return (
      <div className="container-fluid">
        <div className="box box-default">
          <div className="box-header">
            The Curriculum Vitae Of User
            <DownloadCVInline
              cvFileName={cvFile}
              enabledDownload={enabledShareCV}
              downloadCVHandler={this.downloadCVHandler}
              {...this.props}
            />
          </div>
          <div className="box-divider" />
          {cvFile && (
            <div className={`box-body`}>
              <Document
                file={cvFile}
                onLoadSuccess={this.onDocumentLoadSuccess}
                options={{
                  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                  cMapPacked: true,
                }}>
                <Page pageNumber={pageNumber} />
              </Document>
              {numPages && (
                <div className="pagination-cv-page">
                  <div className="pagination-sub">
                    <span className={`nav-button ${disabledPre ? 'disabled' : ''}`} onClick={this.clickPrePageHandler}>
                      <span className="double-arrow">«</span>
                    </span>
                    <p>
                      Page {pageNumber} of {numPages}
                    </p>
                    <span
                      className={`nav-button ${disabledNext ? 'disabled' : ''}`}
                      onClick={this.clickNextPageHandler}>
                      <span className="double-arrow">»</span>
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default UserCV

export function callLoginAPI(cvFileName) {
  return axios.get(`/users/get-cv-file/${cvFileName}`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  })
}

export function callDownloadCVFileAPI(cvFileName, currentUserId) {
  return axios.get(`/users/download-cv/${currentUserId}/0/${cvFileName}`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  })
}
