import { AxiosError } from 'axios'
import { get } from 'lodash'

export function getErrorMessage(errorResponse: AxiosError): string {
  const errorMessage: string = get(
    errorResponse,
    'response.data.error.message',
    JSON.stringify(errorResponse?.response?.data?.error)
  )

  return errorMessage
}

export function handleError(error: Error, filePath: string, functionName: string, keepThrowing: boolean = true): void {
  const errorPath: string = `Error: ${filePath} -> ${functionName} -> error: ${error}`

  console.error(errorPath, JSON.stringify(error))

  if (keepThrowing) {
    throw error
  }
}
