import { Search2Icon } from '@chakra-ui/icons'
import { Flex, HStack, Input, InputGroup, InputLeftElement, useMediaQuery } from '@chakra-ui/react'
import ButtonWithIcon from 'componentsCharka/ButtonWithIcon'
import { backgroundBlueSecondary, backgroundGrey200 } from 'theme/globalStyles'
import { maxMobileSize, maxTabletSize } from 'constants/common'
import { ThemeColor } from 'constants/theme'

export interface IHeaderSectionProps {
  openFilterForm: () => void
  handleCreate: () => void
  changeName: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const { openFilterForm, handleCreate, changeName } = props
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const [isMobileTablet]: boolean[] = useMediaQuery(maxTabletSize)

  return (
    <Flex justifyContent="space-between" flexWrap="wrap" paddingX={isMobile ? 4 : 0}>
      <HStack marginBottom={isMobile ? 4 : 6}>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          background="white"
          width={isMobile ? '160px' : 'auto'}
          minWidth={isMobile ? 0 : '300px'}>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input
            type="search"
            placeholder={isMobileTablet ? 'Search' : 'Search Projects by name...'}
            onChange={changeName}
            border="none"
          />
        </InputGroup>
        <ButtonWithIcon
          label={!isMobile && 'Filter'}
          background="white"
          color="gray.700"
          iconName="filter.svg"
          size={isMobile ? 20 : 16}
          fontSize="md"
          lineHeight={6}
          fontWeight={500}
          onClick={openFilterForm}
        />
      </HStack>
      <HStack marginBottom={isMobile ? 4 : 6}>
        <ButtonWithIcon
          label={!isMobile && 'Export'}
          background="white"
          color="gray.700"
          iconName="export.svg"
          size={isMobile ? 20 : 16}
          fontSize="md"
          lineHeight={6}
          fontWeight={500}
        />
        <ButtonWithIcon
          label={!isMobile && 'Add Project'}
          background={backgroundBlueSecondary}
          color={ThemeColor.white}
          iconName="add-white.svg"
          size={isMobile ? 20 : 16}
          fontSize="md"
          lineHeight={6}
          fontWeight={500}
          onClick={handleCreate}
        />
      </HStack>
    </Flex>
  )
}

export default HeaderSection
