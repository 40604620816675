import { Dispatch, SetStateAction } from 'react'
import { Button } from '@chakra-ui/button'
import { useMediaQuery } from '@chakra-ui/react'
import { EContractProjectRatingSubTab } from '../../constants'
import { IconWithTextWrapper } from './subTabItem.styles'

interface ISubTabItemProps {
  isActive: boolean
  tab: EContractProjectRatingSubTab
  setCurrentTab: Dispatch<SetStateAction<EContractProjectRatingSubTab>>
}

const SubTabItem = (props: ISubTabItemProps) => {
  const { isActive, tab, setCurrentTab } = props
  const [isTablet] = useMediaQuery('(max-width: 1200px)')

  return (
    <Button
      fontWeight="600"
      textAlign={'center'}
      fontSize="md"
      lineHeight="24px"
      paddingLeft={0}
      color={isActive ? '#FFFFFF' : 'gray.500'}
      bg={isActive ? '#08C8F6 !important' : 'white'}
      borderRadius={'9999px'}
      _focus={{ boxShadow: 'none' }}
      onClick={() => {
        setCurrentTab(tab)
      }}>
      <IconWithTextWrapper
        className="noMarginBottom"
        iconClassName={!isActive ? 'icon' : 'active-icon'}
        label={isTablet ? String(tab).split(' ')[0] : String(tab)}
        size={20}
        color={isActive ? 'white' : 'gray.500'}
      />
    </Button>
  )
}

export default SubTabItem
