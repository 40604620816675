import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { withStyles } from '@material-ui/core/styles'
import UnderConstruction from 'containers/UnderConstruction'
import './index.scss'


const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
})

class FullWidthTabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: undefined,
      isSwipeable: false
    }
  }

  render() {
    const { classes } = this.props
    let { value } = this.state
    if (!value) {
      value = 0
    }
    const title = ''
    window.sessionStorage.setItem('subPageTitle', title)
    window.dispatchEvent(new Event('storage'))
    return (
      <div className={classes.root}>
        <UnderConstruction/>
      </div>
    )
  }
}

FullWidthTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(FullWidthTabs)
