import { Menu, MenuList } from '@chakra-ui/react'
import { backgroundBlack100, backgroundGrey600 } from 'theme/globalStyles'
import { getValidArray } from 'utils'
import { ResultWrapper } from './dropdown.styles'
import DropdownButton, { IProjectItem } from './DropdownButton'
import DropdownSelection from './DropdownSelection'
interface IDropdownProps {
  options: IProjectItem[]
  name: string
  item: IProjectItem
  setValue: (name: string, value: IProjectItem) => void
  width?: number
  minWidth?: number
  maxWidth?: number | string
  text?: string
  hasIcon?: boolean
  isMatchWidth?: boolean
}

const Dropdown = (props: IDropdownProps) => {
  const { options, name, setValue, item, width, minWidth, maxWidth, text, isMatchWidth, hasIcon = true } = props
  return (
    <Menu closeOnSelect={true} autoSelect={false} computePositionOnMount matchWidth={isMatchWidth}>
      {({ isOpen }) => (
        <ResultWrapper width={width ?? '100%'}>
          <DropdownButton
            minWidth={minWidth}
            item={item}
            placeHolder={text}
            isOpen={isOpen}
            width={width ?? '100%'}
            hasIcon={hasIcon}
          />
          <MenuList
            zIndex="1001"
            minWidth={minWidth ?? '200px'}
            maxWidth={maxWidth}
            width={width ?? '100%'}
            boxShadow={`0px 1px 3px ${backgroundBlack100}, 0px 1px 2px ${backgroundGrey600}`}>
            {getValidArray(options).map((option: IProjectItem, index: number) => (
              <DropdownSelection
                key={`${name}-${index}`}
                onClick={() => setValue(name, option)}
                label={option.title}
                isSelected={option.value === item?.value}
                width={width}
                maxWidth={maxWidth}
                borderColor={option.borderColor}
                color={option.color}
                hasIcon={hasIcon}
              />
            ))}
          </MenuList>
        </ResultWrapper>
      )}
    </Menu>
  )
}

export default Dropdown
