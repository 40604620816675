import React from 'react'
import Edit from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import SummarizeTable from '../../components/SummarizeTable'
import Helper from '../../../../Helper'

function formatRow(index) {
  let result = 'even'
  if (index % 2 === 0) {
    result = 'odd'
  }
  return result
}
class CommitTimeTable extends React.Component {
  renderProjectName = (item) => {
    let className = 'user'
    let taskName = <p>{item.projectName}</p>
    let updatedInfo
    if (item.isAdminUpdated) {
      className = 'admin'
    }
    if (item.isActive && item.isAdminUpdated && item.updatedBy) {
      updatedInfo = <p className={`editted-info ${className}`}>{`Edited by ${item.updatedBy}`}</p>
    }
    return (
      <td className="project-name">
        {taskName}
        {updatedInfo}
      </td>
    )
  }

  renderStatus = (item) => {
    let className = 'status'
    let text = ''
    if (item.isActive) {
      className += ' active'
      text = 'Actived'
    } else {
      className += ' disable'
      text = 'Disabled'
    }
    return <td className={className}>{text}</td>
  }

  render() {
    const { data, editHandler, summarizeData } = this.props
    const { commitTimeOfPerProject, commitTimeOfAllProject, commitTimeOfGroupBy } = data
    return (
      <div className="table-content">
        <div className="container-fluid">
          <div className="box box-default">
            <div className="box-header">Summary</div>
            <div className="box-divider" />
            <div className={`box-body`}>{summarizeData && <SummarizeTable data={summarizeData} />}</div>
          </div>
        </div>
        {commitTimeOfAllProject && commitTimeOfAllProject.length > 0 && (
          <div>
            <h4 className="table-name">Commit Time All Project</h4>
            <table className="report-table">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Developer</th>
                  <th>Commit Time Per Week (Hours)</th>
                  <th>Status</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {commitTimeOfAllProject &&
                  commitTimeOfAllProject.map((item, index) => (
                    <tr key={item._id} className={formatRow(index)}>
                      {this.renderProjectName(item)}
                      <td>{item.developerName}</td>
                      <td>{Helper.getFormattedNumber(item.commitTime, 1)}</td>
                      {this.renderStatus(item)}
                      <td className="options">
                        <Tooltip title={'Edit'}>
                          <IconButton aria-label={'Edit'} onClick={() => editHandler(item._id)}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {commitTimeOfPerProject && commitTimeOfPerProject.length > 0 && (
          <div>
            <h4 className="table-name">Commit Time Per Project</h4>
            <table className="report-table">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Developer</th>
                  <th>Commit Time Per Week (Hours)</th>
                  <th>Status</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {commitTimeOfPerProject &&
                  commitTimeOfPerProject.map((item, index) => (
                    <tr key={item._id} className={formatRow(index)}>
                      {this.renderProjectName(item)}
                      <td>{item.developerName}</td>
                      <td>{Helper.getFormattedNumber(item.commitTime, 1)}</td>
                      {this.renderStatus(item)}
                      <td className="options">
                        <Tooltip title={'Edit'}>
                          <IconButton aria-label={'Edit'} onClick={() => editHandler(item._id)}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {commitTimeOfGroupBy &&
          commitTimeOfGroupBy.length > 0 &&
          commitTimeOfGroupBy.map((group, indexGroup) => {
            const { groupByTitle, commitTimeDataOfGroup } = group
            const { commitTimeDataOfGroupAllProject, commitTimeDataOfGroupPerProject } = commitTimeDataOfGroup
            return (
              <div key={indexGroup}>
                <h4 className="table-name">{groupByTitle}</h4>
                {commitTimeDataOfGroupPerProject && commitTimeDataOfGroupPerProject.length > 0 && (
                  <div>
                    <h5 className="table-name">Commit Time Per Project</h5>
                    <table className="report-table">
                      <thead>
                        <tr>
                          <th>Project</th>
                          <th>Developer</th>
                          <th>Commit Time Per Week (Hours)</th>
                          <th>Status</th>
                          <th>Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commitTimeDataOfGroupPerProject.map((perProject) => (
                          <tr key={perProject._id} className={formatRow(perProject._id)}>
                            {this.renderProjectName(perProject)}
                            <td>{perProject.developerName}</td>
                            <td>{Helper.getFormattedNumber(perProject.commitTime, 1)}</td>
                            {this.renderStatus(perProject)}
                            <td className="options">
                              <Tooltip title={'Edit'}>
                                <IconButton aria-label={'Edit'} onClick={() => editHandler(perProject._id)}>
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {commitTimeDataOfGroupAllProject && commitTimeDataOfGroupAllProject.length > 0 && (
                  <div>
                    <h5 className="table-name">Commit Time All Project</h5>
                    <table className="report-table">
                      <thead>
                        <tr>
                          <th>Project</th>
                          <th>Developer</th>
                          <th>Commit Time Per Week (Hours)</th>
                          <th>Status</th>
                          <th>Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(commitTimeDataOfGroupAllProject) &&
                          commitTimeDataOfGroupAllProject.map((allProject) => (
                            <tr key={allProject._id} className={formatRow(allProject._id)}>
                              {this.renderProjectName(allProject)}
                              <td>{allProject.developerName}</td>
                              <td>{Helper.getFormattedNumber(allProject.commitTime, 1)}</td>
                              {this.renderStatus(allProject)}
                              <td className="options">
                                <Tooltip title={'Edit'}>
                                  <IconButton aria-label={'Edit'} onClick={() => editHandler(allProject._id)}>
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="break-line"></div>
              </div>
            )
          })}
      </div>
    )
  }
}

export default CommitTimeTable
