import { Dispatch, SetStateAction } from 'react'
import { Stack } from '@chakra-ui/react'
import ButtonForm from 'components/ButtonForm'
import InputField from 'components/InputField'

interface IResetPasswordFormProps {
  setIsSubmitted: Dispatch<SetStateAction<boolean>>
}

const ResetPasswordForm = (props: IResetPasswordFormProps) => {
  function handleOnSubmit(): void {
    props.setIsSubmitted(true)
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack spacing="6">
        <InputField label="New password" name="password" isPassword placeholder="Enter your new password" />
        <InputField label="Confirm password" name="confirmPassword" isPassword placeholder="Confirm new password" />
      </Stack>
      <ButtonForm label={'Reset password'} />
    </form>
  )
}

export default ResetPasswordForm
