import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import Helper from '../../../../Helper'
import { getValidArray } from 'utils'

const styles = {
  inlineli: {
    marginRight: '2.5rem',
    height: '0.7rem',
    width: '0.7rem',
  },
  inlinetoolbutton: {
    height: '35px',
    width: '35px',
    marginTop: '5px',
  },
  inlineIcon: {
    marginTop: '-7px',
  },
  inlineActiveIcon: {
    marginTop: '-7px',
    color: '#2D3748',
  },
  inlineReportItem: {
    cursor: 'default',
  },
}

function formatRow(index) {
  let result = 'even'
  if (index % 2 === 0) {
    result = 'odd'
  }
  return result
}
class CommitTimeReportTableOfUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showTableList: [],
    }
  }

  componentDidMount() {
    const { data } = this.props
    const showTableList = Array(getValidArray(data).length).fill(true)
    this.setState({ showTableList })
  }

  renderStatus = (item) => {
    let className = 'status'
    if (item.status === 'Fulfill') {
      className += ' active'
    } else {
      className += ' disable'
    }
    return <td className={className}>{item.status}</td>
  }

  toggleShowTableHandler = (index) => {
    const { showTableList } = this.state
    showTableList[index] = !showTableList[index]
    this.setState({ showTableList })
  }
  render() {
    const { data } = this.props
    const { showTableList } = this.state
    return (
      <div className="commit-time-report">
        {getValidArray(data).map((item, index) => {
          const titleShowTable = showTableList[index] ? 'Hide detail' : 'Show detail'
          return (
            <div className="commit-time-container" key={index}>
              <div className="container-fluid">
                <div className="box box-default">
                  <div className="box-header highlight">
                    {item.period}
                    <Tooltip title={titleShowTable}>
                      <IconButton
                        aria-label={titleShowTable}
                        style={styles.inlinetoolbutton}
                        onClick={() => this.toggleShowTableHandler(index)}>
                        {showTableList[index] ? (
                          <ArrowDropDown style={styles.inlineIcon} />
                        ) : (
                          <ArrowDropUp style={styles.inlineIcon} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="box-divider" />
                  <div className={`box-body`}>
                    <div className="table-content">
                      {showTableList[index] && (
                        <table className="report-table">
                          <thead>
                            <tr>
                              <th>Project</th>
                              <th>Commit Time Per Week (Hours)</th>
                              <th>Working time total (Hours)</th>
                              <th>Status</th>
                              <th>Time to send report</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getValidArray(item.data).map((reportItem, index) => (
                              <tr key={reportItem._id} className={formatRow(index)} style={styles.inlineReportItem}>
                                <td>{reportItem.projectName}</td>
                                <td>{Helper.getFormattedNumber(reportItem.commitTime, 1)}</td>
                                <td>{Helper.getFormattedNumber(reportItem.workingTimeTotal, 1)}</td>
                                <td>{this.renderStatus(reportItem)}</td>
                                <td>{reportItem.timeToSendReport}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default CommitTimeReportTableOfUser
