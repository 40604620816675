import React, { useEffect, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, HStack } from '@chakra-ui/react'
import Table from 'components/Table'
import MoreDropBox from 'components/Table/components/MoreDropBox'
import Helper from 'Helper'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import moment from 'moment'
import { FiFilter } from 'react-icons/fi'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { getValidArray } from 'utils'
import { PartnerCategory } from 'constants/defaultOptionList'
import { RoutesConstants } from 'constants/index'
import { IProject } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import FilterForm from '../../components/FilterForm'
import { getHeaderList, IRowActionItem, initialValues } from '../../constant'

const ClientBillList = () => {
  const [filter, setFilter] = useState(initialValues)
  const [openModalFilterForm, setOpenFilterForm] = useState<boolean>(false)
  const { adminProjectStore, adminClientBillStore } = useStores()
  const { partnerList } = adminProjectStore
  const { clientBillList, count } = adminClientBillStore
  window.localStorage.removeItem('pageTitle')
  window.localStorage.setItem('pageTitle', 'Accounting')
  const title: string = 'Income'
  window.sessionStorage.setItem('subPageTitle', title)
  window.dispatchEvent(new Event('storage'))
  const history = useHistory()
  const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
  const pagination = {
    includePagination: true,
    pageIndex,
    tableLength: count,
    gotoPage: (page: number) => {
      history.push({ pathname: RoutesConstants.ACCOUNTING_INCOME_LIST, state: { page, filter } })
      adminClientBillStore.getClientBillList(false, history, filter)
    },
  }

  const [selectedProject, setSelectedProject] = useState<IProject>()
  function handleCreate() {
    history.push(RoutesConstants.ACCOUNTING_INCOME_CREATE)
  }

  function openFilterForm(): void {
    setOpenFilterForm(true)
  }
  // const changeName = debounce((event: { target: { value: string } }) => {
  //   setFilter({ ...filter, name: { $regex: event?.target?.value ?? '', $options: 'i' } })
  // }, 1000)

  const partnerCategoryOfProject = PartnerCategory.slice(0, 2)

  useEffect(() => {
    adminProjectStore.getPartnerList(partnerCategoryOfProject)
  }, [])

  useEffect(() => {
    adminClientBillStore.getClientBillList(true, history, filter)
  }, [filter])

  const partnersOptions = Helper.createOptionsOfReactSelectFromDB(partnerList)
  const dataInTable = getValidArray(clientBillList).map((clientBill) => {
    const documentId: string = clientBill?._id ?? ''
    const pathname = generatePath(RoutesConstants.ACCOUNTING_INCOME_DETAIL, { id: documentId })
    const actionDetailHandler = () => history.push(pathname)
    const actions: IRowActionItem[] = []

    return {
      ...clientBill,
      publishedDate: moment(clientBill?.publishedDate ?? '').format('DD/MM/YYYY'),
      actions: <MoreDropBox isDetail={true} detailActionHandler={actionDetailHandler} actions={actions} />,
    }
  })

  const filterSubmitHandler = (data) => {
    const partnerId = Helper.getValueFromOption(data.partnerId)
    setFilter({
      ...filter,
      partnerId: partnerId,
    })
  }

  return (
    <Box bg="white" p={6} borderRadius="6px" border="1px solid #E2E8F0">
      <FilterForm
        partnersList={partnersOptions}
        openModalFilterForm={openModalFilterForm}
        setOpenFilterForm={setOpenFilterForm}
        filterSubmit={filterSubmitHandler}
      />
      <Flex justifyContent="space-between" flexWrap="wrap">
        <HStack mb={6} w={{ base: '100%', md: 'auto' }}>
          <Button
            leftIcon={<FiFilter />}
            colorScheme="blue"
            variant="outline"
            onClick={openFilterForm}
            display={{ base: 'none', md: 'inline-flex' }}>
            Filter
          </Button>
          <Button
            leftIcon={<FiFilter />}
            colorScheme="blue"
            variant="outline"
            onClick={openFilterForm}
            isFullWidth
            display={{ md: 'none' }}>
            Filter
          </Button>
          <Button
            fontWeight={'500'}
            leftIcon={<AddIcon />}
            bg="#4D5DFB"
            minWidth={'none'}
            variant="solid"
            isFullWidth
            onClick={handleCreate}>
            Add New Bill
          </Button>
        </HStack>
      </Flex>
      <Table
        headerList={getHeaderList()}
        tableData={dataInTable}
        isStriped
        pagination={pagination}
        // exportNow={exportNow}
        // toggleExport={this.toggleExport}
      />
    </Box>
  )
}

export default observer(ClientBillList)
