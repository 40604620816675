import React from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, ButtonGroup, Menu, MenuItem, MenuButton, MenuList } from '@chakra-ui/react'
import { getValidArray } from 'utils'

export interface IAction {
  name: string
  customClassNames?: string[]
  handler: (e?) => void
}

interface IMoreAction {
  isDetail?: boolean
  detailActionHandler?: (e?) => void
  actions?: IAction[]
}

const MoreAction = (props: IMoreAction) => {
  const { actions, isDetail, detailActionHandler } = props

  const viewDetail = isDetail ? actions[0] : actions.shift()
  return (
    <>
      {getValidArray(actions).length > 0 && (
        <React.Fragment>
          <ButtonGroup paddingBottom="10px" size="sm" isAttached variant="outline">
            <Menu isLazy>
              <Button colorScheme={'blue'} onClick={viewDetail.handler}>
                {viewDetail.name}
              </Button>
              {!isDetail && (
                <>
                  <MenuButton colorScheme="blue" as={Button} rightIcon={<ChevronDownIcon />}>
                    More
                  </MenuButton>
                  <MenuList paddingTop={{ sm: '0' }} paddingBottom={{ sm: 0 }} minWidth={{ sm: '6rem' }}>
                    {actions.map((action: IAction, index) => {
                      return (
                        <MenuItem
                          borderBottom="1px solid #fafafa"
                          key={index}
                          onClick={action.handler}
                          color={action.name === 'Delete' ? 'red' : 'black'}>
                          {action.name}
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </>
              )}
            </Menu>
          </ButtonGroup>
        </React.Fragment>
      )}
    </>
  )
}

export default MoreAction
