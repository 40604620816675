import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import ThemeSwitcher from 'react-css-vars'
import Smartlook from 'smartlook-client'
import { AppConfig, Messages, RoutesConstants } from '../../constants'
import { firebaseCloudMessaging } from '../../utils/fcm'
import { ERoleOfUser } from 'constants/enum'
import Routes from '../routes'
import ApiHelper from '../../Helper/api'
import Layout from '../../components/Layout'
import Login from '../Login'
import NotFound from '../NotFound'
import Helper from '../../Helper'
import ShareCV from '../ShareCV/index'
import ForgotPassword from 'containers/ForgotPassword'
import ResetPassword from 'containers/ResetPassword'
import { countUnreadFeedback } from 'API/feedback'
import { handleError } from 'API/error'

var eventInterval

const userTheme = {
  primary: '#1A365D',
  active: '#3F5676',
  hover: '#2C466A',
}

const adminTheme = {
  primary: '#2D3748',
  active: '#4F5765',
  hover: '#3D4756',
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notifications: [],
      unReadFeedbackNumber: undefined,
    }
  }
  async componentDidMount() {
    // *INFO: fix check Notification not support for iOS
    // *More details: https://stackoverflow.com/questions/41493866/firebase-on-ipads-safari-cant-find-variable-notification
    const isNotificationSupported = 'Notification' in window

    this.subcribeNotificationHandler()
    this.setIntervalCallSubcribeNotification()
    isNotificationSupported && (await Notification.requestPermission())
    const firebase = firebaseCloudMessaging.getFirebase()
    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage((payload) => {
        const title = payload.notification.title
        const icon = payload.notification.icon
        const body = payload.notification.body
        const options = {
          icon,
          body,
        }
        isNotificationSupported && new Notification(title, options)
      })
      window.addEventListener("beforeunload", this.handleRefresh);
    }

    //INFO: init smartlook
    Smartlook.init(AppConfig.SMART_LOOK_KEY);
  }

  async componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleRefresh);
  }

  handleRefresh() {
    sessionStorage.clear();
  }

  setIntervalCallSubcribeNotification = () => {
    eventInterval = setInterval(
      function (self) {
        self.subcribeNotificationHandler()
      },
      AppConfig.TIME_OUT_LONGPOLLING,
      this
    )
  }

  subcribeNotificationHandler = () => {
    ApiHelper.getWithLongPolling(
      {
        url: `/long-polling/subcribe-notificaiton`,
      },
      (response) => {
        const message = response.data
        if (message === Messages.longPollingSuccess) {
          this.getNotificationsList(true)
          // Helper.showNotificationOnBrowser(message)
          if (eventInterval) {
            clearInterval(eventInterval)
            this.setIntervalCallSubcribeNotification()
          }
        }
      },
      false
    )
  }

  getNotificationsList = (socket) => {
    let isLoading = socket ? false : true
    ApiHelper.get(
      {
        url: `/notification/list`,
      },
      (response) => {
        this.setState({
          notifications: response.data.notificationList,
        })
      },
      isLoading
    )
  }

  getUnreadFeedbackNumber = async () => {
    try {
      const response = await countUnreadFeedback()
      this.setState({
        unReadFeedbackNumber: response?.unReadFeedbackNumber,
      })
    } catch (error) {
      handleError(error, 'src/containers/App/index.js', 'getUnreadFeedbackNumber')
    }
  }

  isNotIncludedLogin(pathname) {
    const routes = [
      RoutesConstants.FORGOT_PASSWORD,
      RoutesConstants.RESET_PASSWORD,
      RoutesConstants.LOGIN,
      RoutesConstants.SHARE_CV,
    ]
    return routes.every((route) => !pathname.includes(route))
  }

  render() {
    const { history } = this.props
    const { notifications, unReadFeedbackNumber } = this.state
    window.localStorage.removeItem('pageTitle')
    window.localStorage.removeItem('subPageTitle')
    const isRememberMe = JSON.parse(window.localStorage.getItem('isRememberMe'))
    const aToken = isRememberMe ? window.localStorage.getItem('aToken') : window.sessionStorage.getItem('aToken')
    const rToken = isRememberMe ? window.localStorage.getItem('rToken') : window.sessionStorage.getItem('rToken')
    const role = window.localStorage.getItem('userRole')
    const { pathname, search } = window.location
    const isLoggedIn = Helper.checkAccessFE(aToken, rToken)
    if (isLoggedIn) {
      if (pathname === '/' || pathname === RoutesConstants.LOGIN) {
        if (!pathname.includes(RoutesConstants.DASHBOARD) && role === ERoleOfUser.ADMIN) {
          history.push(RoutesConstants.DASHBOARD)
        } else if (!pathname.includes(RoutesConstants.V2_TIMESHEET) && role === ERoleOfUser.USER) {
          history.push(RoutesConstants.V2_TIMESHEET)
        }
      }
    } else {
      // INFO: clear unnecessary information in the previous login
      window.localStorage.clear()
      if (this.isNotIncludedLogin(pathname)) {
        if (search) {
          history.push({ pathname: RoutesConstants.LOGIN, state: { from: pathname, search: search } })
        } else {
          history.push(RoutesConstants.LOGIN)
        }
      }
    }
    return (
      <ThemeSwitcher theme={role === ERoleOfUser.ADMIN ? adminTheme : userTheme}>
        <Switch>
          <Route exact path={RoutesConstants.LOGIN} component={Login} />
          <Route exact path={RoutesConstants.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route exact path={RoutesConstants.RESET_PASSWORD} component={ResetPassword} />
          <Route exact path={RoutesConstants.SHARE_CV_FULL} component={ShareCV} />
          {RoutesAuth(notifications, unReadFeedbackNumber, this.getNotificationsList, this.getUnreadFeedbackNumber)}
          <Route component={NotFound} />
        </Switch>
      </ThemeSwitcher>
    )
  }
}

const RoutesAuth = (notifications, unReadFeedbackNumber, getNotificationHandler, getUnreadFeedbackNumberHandler) => {
  const role = window.localStorage.getItem('userRole')
  window.sessionStorage.removeItem('subPageTitle')

  return (
    role &&
    Routes[role] &&
    Routes[role].map((item) => (
      <Route
        exact={item.exact}
        key={item.key}
        path={item.path}
        render={(props) => (
          <Layout
            component={item.component}
            getNotificationsList={getNotificationHandler}
            getUnreadFeedbackNumber={getUnreadFeedbackNumberHandler}
            notifications={notifications}
            unReadFeedbackNumber={unReadFeedbackNumber}
            {...props}
          />
        )}
      />
    ))
  )
}

export default withRouter(App)
