import { useState, useRef, MutableRefObject, useEffect } from 'react'
import { VStack } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import UnderConstruction from 'containers/UnderConstruction'

interface ITimesheetUserProps {
  handleCloseConfirmDialog: () => void
}

const TimeSheetUser = (props: ITimesheetUserProps) => {
  return (
    <VStack marginTop={6} spacing={6} width="full">
      <UnderConstruction />
    </VStack>
  )
}

export default observer(TimeSheetUser)
