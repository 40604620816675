import { useEffect } from 'react'
import { handleError } from 'API/error'
import ChakraInputDropdown from 'componentsCharka/Dropdown'
import FormFieldWithLabel from 'componentsCharka/FormFieldWithLabel'
import PhoneNumberInput from 'componentsCharka/PhoneNumberInputWithDialCode'
import WebLinkInput from 'componentsCharka/WebLinkInput'
import Helper from 'Helper'
import { IOption } from 'constants/common'
import { useStores } from 'utils/hooks/useStores'
import { IFormSectionBaseProps, ESectionHeaderValues } from '../../constants'
import { SectionWrapper, SectionHeader, GridLayout, SectionBody } from '../../projectDetailAdmin.styles'
import { getFormInputProps, getFormLabelProps } from '../../utils'
import FormInput from '../FormInput'
import WebLinkForm from './components/WebLinkForm'

const GeneralInformationSection = (props: IFormSectionBaseProps) => {
  const { isEditing } = props
  const { adminProjectStore } = useStores()
  const { countriesList } = adminProjectStore
  const countryOptions: IOption[] = Helper.createOptionsOfReactSelectFromDB(countriesList)

  async function fetchData(): Promise<void> {
    try {
      await adminProjectStore.getCountryList()
    } catch (error) {
      handleError(
        error as Error,
        'src/containers/ProjectsNew/V2/ProjectDetailAdminMobile/components/GeneralInformationSection/index.tsx',
        'fetchData'
      )
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <SectionWrapper spacing={{ base: 4 }}>
      <SectionHeader>{ESectionHeaderValues.GENERAL_INFORMATION}</SectionHeader>
      <SectionBody spacing={{ base: isEditing ? 6 : 4 }}>
        <GridLayout gap={{ base: !isEditing ? 4 : 6 }}>
          <FormFieldWithLabel {...getFormLabelProps('name', 'Project Name', isEditing)}>
            {isEditing && <FormInput {...getFormInputProps('name', 'Project Name', 'Enter Project name', true)} />}
          </FormFieldWithLabel>
          <FormFieldWithLabel {...getFormLabelProps('companyName', 'Company Name', isEditing)}>
            {isEditing && (
              <FormInput {...getFormInputProps('companyName', 'Company Name (Optional)', 'Enter Company name')} />
            )}
          </FormFieldWithLabel>
        </GridLayout>
        <GridLayout gap={{ base: !isEditing ? 4 : 6 }}>
          <FormFieldWithLabel {...getFormLabelProps('companyWebsite', 'Website', isEditing, true)}>
            {isEditing ? (
              <WebLinkInput {...getFormInputProps('companyWebsite', 'Company Website (Optional)', 'example.com')} />
            ) : (
              <WebLinkForm name="companyWebsite" isHighlighted />
            )}
          </FormFieldWithLabel>
          <FormFieldWithLabel {...getFormLabelProps('companyPhone', 'Phone Number', isEditing, true)}>
            {isEditing && (
              <PhoneNumberInput {...getFormInputProps('companyPhone', 'Company Phone Number (Optional)')} />
            )}
          </FormFieldWithLabel>
        </GridLayout>
        <GridLayout columns={{ base: !isEditing ? 2 : 1 }} gap={{ base: !isEditing ? 4 : 6 }}>
          <FormFieldWithLabel {...getFormLabelProps('country', 'Country', isEditing)}>
            {isEditing && (
              <ChakraInputDropdown
                {...getFormInputProps('country', 'Country', '- Select country -')}
                isRequire
                widthCustom="100%"
                optionsData={countryOptions}
              />
            )}
          </FormFieldWithLabel>
          {/* // *INFO: Maybe use later
          <FormFieldWithLabel {...getFormLabelProps('city', 'City', isEditing)}>
            {isEditing && (
              <ChakraInputDropdown
                {...getFormInputProps('city', 'City (Optional)', '- Select City -')}
                widthCustom="100%"
                optionsData={countryListOptions}
              />
            )}
          </FormFieldWithLabel> */}
        </GridLayout>
        <GridLayout columns={1}>
          <FormFieldWithLabel {...getFormLabelProps('address', 'Address', isEditing)}>
            {isEditing && (
              <FormInput {...getFormInputProps('address', 'Address Detail (Optional)', 'Enter Address detail')} />
            )}
          </FormFieldWithLabel>
        </GridLayout>
      </SectionBody>
    </SectionWrapper>
  )
}

export default GeneralInformationSection
