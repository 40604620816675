import { chakra, VStack } from '@chakra-ui/react'

export const ResultWrapper = chakra(VStack, {
  baseStyle: () => ({
    width: '100%',
    '& > div': {
      width: '100%',
    },
  }),
})
