import React from 'react'
import { Link } from 'react-router-dom'
import { RoutesConstants } from '../../constants'
import './index.scss'

import LogoURL from '../../assets/images/logo/logo.svg'
class NotFound extends React.Component {
  render() {
    const role = window.localStorage.getItem('userRole')
    let linkToHome = RoutesConstants.TIMESHEET
    if (role === 'admin') {
      linkToHome = RoutesConstants.DASHBOARD
    }
    return (
      <div className="not-found-page">
        <div className="logo">
          <img src={LogoURL} alt="company-logo" />
        </div>
        <div className="not-found-content">
          <h1>You are lost</h1>
          <p className="description">This page doesn't exit</p>
          <Link to={linkToHome}>Take me home</Link>
        </div>
      </div>
    )
  }
}

export default NotFound
