import { ReactNode } from 'react'
import { FormControl, FormLabel, FormErrorMessage, Tooltip, Box, HStack, Stack, Text } from '@chakra-ui/react'
import { get } from 'lodash'
import { useFormContext } from 'react-hook-form'
import Icon from '../Icon'
import FormInputField from './components/FormInputField'
export interface IFormItemProps {
  name: string
  label?: string
  type?: string
  placeholder?: string
  isRequired?: boolean
  children?: ReactNode
  helperText?: string
  helperTextShow?: boolean
  readonly?: boolean
  rightAddonText?: string
  disabled?: boolean
  isWebsite?: boolean
  defaultValue?
}

const FormItem = (props: IFormItemProps) => {
  const {
    name,
    label,
    type,
    placeholder,
    isRequired,
    children,
    helperText,
    helperTextShow,
    readonly,
    rightAddonText,
    disabled,
    isWebsite,
    defaultValue,
  } = props
  const {
    formState: { errors },
  } = useFormContext()
  return (
    <FormControl padding={{ lg: '0px', md: '10px', sm: '10px' }} isInvalid={!!get(errors, name, false)}>
      <HStack spacing="14px" maxHeight={6} marginBottom={2} position="relative">
        <FormLabel fontWeight={'nomal'} color="gray.700" lineHeight={6} marginBottom={0} marginInlineEnd={0}>
          {label}
        </FormLabel>
        {helperTextShow && <Text fontSize="xs">{helperText}</Text>}
        {helperText && !helperTextShow && (
          <Tooltip label={helperText} hasArrow placement="right" background="gray.900" color="white" fontSize="sm">
            <Box textAlign="center" height="20px">
              <Icon iconName="information.svg" size={20} />
            </Box>
          </Tooltip>
        )}
      </HStack>
      <Stack>
        {children ? (
          children
        ) : (
          <FormInputField
            name={name}
            type={type}
            placeholder={placeholder}
            isRequired={isRequired}
            helperText={helperText}
            helperTextShow={helperTextShow}
            readonly={readonly}
            rightAddonText={rightAddonText}
            disabled={disabled}
            isWebsite={isWebsite}
            defaultValue={defaultValue}
          />
        )}
      </Stack>
      <FormErrorMessage>{get(errors, `${name}.message`, '')}</FormErrorMessage>
    </FormControl>
  )
}

export default FormItem
