import { FormControl, InputGroup, FormLabel, Tooltip, Box, HStack } from '@chakra-ui/react'
import { GroupBase, MultiValue, Select, components } from 'chakra-react-select'
import { useFormContext } from 'react-hook-form'
import { backgroundGrey600 } from 'theme/globalStyles'
import Icon from '../../Icon'
import { IOption } from '../types'
import { IChakraInputDropdownProps } from '../types'

const MultiSelect = (props: IChakraInputDropdownProps) => {
  const {
    name,
    label,
    optionsData,
    isDisabled,
    selectHandler,
    placeHolder,
    helperText,
    isClearable = true,
    isCustomValue = false,
    optionStyle,
    placeholderStyle,
  } = props
  const { setValue, watch } = useFormContext()
  const formValueWatcher = watch(name)

  function handleMultiSelectChange(option: MultiValue<IOption>): void {
    const newOptions = []
    option.forEach((element) => {
      if (element) {
        newOptions.push(element)
      }
    })
    if (selectHandler) {
      selectHandler(option)
    }
    setValue(name, newOptions)
  }

  const CustomValueContainer = (props) => {
    const ValueContainer = components.ValueContainer
    const { hasValue } = props
    if (!hasValue) {
      return <ValueContainer {...props} />
    }
    return <ValueContainer {...props}>{`Selected ${formValueWatcher?.length ?? 0}`}</ValueContainer>
  }

  return (
    <FormControl id={name} width="full">
      {label && (
        <HStack spacing="14px" maxHeight={6} marginBottom={2} position="relative">
          <FormLabel fontWeight={'nomal'} mb={2} color="gray.700">
            {label}
          </FormLabel>

          {helperText && (
            <Tooltip label={helperText} hasArrow placement="right" background="gray.900" color="white" fontSize="sm">
              <Box textAlign="center" height="24px" mb={2}>
                <Icon iconName="information.svg" size={20} />
              </Box>
            </Tooltip>
          )}
        </HStack>
      )}

      <InputGroup borderRadius="6px" width="full" bg="white">
        <Select<IOption, true, GroupBase<IOption>>
          name={name}
          placeholder={placeHolder}
          isMulti
          isClearable={isClearable}
          isDisabled={isDisabled}
          colorScheme="gray.700"
          value={formValueWatcher}
          options={optionsData}
          closeMenuOnSelect={false}
          selectedOptionStyle="check"
          hideSelectedOptions={false}
          size="md"
          components={
            isCustomValue
              ? {
                  ValueContainer: isCustomValue ? CustomValueContainer : undefined,
                }
              : undefined
          }
          onChange={(option: MultiValue<IOption>) => {
            handleMultiSelectChange(option)
          }}
          chakraStyles={{
            container: (provided: Record<string, unknown>) => ({
              ...provided,
              width: 'full',
              cursor: 'pointer',
            }),
            noOptionsMessage: (provided: Record<string, unknown>) => ({
              ...provided,
              width: 'fit-content',
            }),
            option: (provided: Record<string, unknown>) => ({
              ...provided,
              width: 'auto',
              cursor: 'pointer',
              ...optionStyle,
            }),
            dropdownIndicator: (provided: Record<string, unknown>) => ({
              ...provided,
              bg: 'transparent',
              px: 2,
              cursor: 'pointer',
              display: isDisabled ? 'none' : undefined,
            }),
            indicatorSeparator: (provided: Record<string, unknown>) => ({
              ...provided,
              display: 'none',
            }),
            clearIndicator: (provided: Record<string, unknown>) => ({
              ...provided,
              color: 'gray.700',
            }),
            multiValueRemove: (provided: Record<string, unknown>) => ({
              ...provided,
              color: 'gray.700',
              padding: 0,
              display: isDisabled ? 'none' : undefined,
            }),
            placeholder: (provided: Record<string, unknown>) => ({
              ...provided,
              ...placeholderStyle,
            }),
            menu: (provided: Record<string, unknown>) => ({
              ...provided,
              boxShadow: `0 0 0 1px ${backgroundGrey600}`,
            }),
          }}
        />
      </InputGroup>
    </FormControl>
  )
}

export default MultiSelect
