import api from 'API'
import { handleError } from 'API/error'
import { AxiosResponse } from 'axios'
import { IInternalDocument } from 'constants/schema'
import {
  IInternalDocumentDetail,
  IInternalDocuments,
  IInternalDocumentsPayload,
  INTERNAL_DOCUMENT_BASE_URL,
} from './constants'

export async function getInternalDocuments(payload: IInternalDocumentsPayload): Promise<IInternalDocuments> {
  try {
    const url: string = `${INTERNAL_DOCUMENT_BASE_URL}/list`
    const response: AxiosResponse = await api.post(url, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/internalDocument', 'getInternalDocuments')
    return {} as IInternalDocuments
  }
}

export async function updateInternalDocument(id: string, payload: IInternalDocument): Promise<void> {
  try {
    const url: string = `${INTERNAL_DOCUMENT_BASE_URL}/update/${id}`
    await api.post(url, payload)
  } catch (error) {
    handleError(error as Error, 'API/internalDocument', 'updateInternalDocument')
  }
}

export async function createInternalDocument(payload: IInternalDocument): Promise<IInternalDocuments> {
  try {
    const url: string = `${INTERNAL_DOCUMENT_BASE_URL}/create`
    const response: AxiosResponse = await api.post(url, payload)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/internalDocument', 'createInternalDocument')
    return {} as IInternalDocuments
  }
}

export async function getInternalDocumentDetail(internalDocumentId: string): Promise<IInternalDocumentDetail> {
  try {
    const url: string = `${INTERNAL_DOCUMENT_BASE_URL}/detail/${internalDocumentId}`
    const response: AxiosResponse = await api.get(url)
    return response.data
  } catch (error) {
    handleError(error as Error, 'API/internalDocument', 'getInternalDocumentDetail')
    return {} as IInternalDocumentDetail
  }
}
