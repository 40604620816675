/* eslint-disable max-lines */
import { FC } from 'react'
import { chakra, shouldForwardProp } from '@chakra-ui/react'
import isValidHTMLProp from '@emotion/is-prop-valid'
import { IContractDetail } from 'API/contract/constant'
import { isFeatureEnabled } from 'API/featureFlag'
import Helper from 'Helper'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import { toJS } from 'mobx'
import moment from 'moment'
import { BaseStyle } from 'types/common'
import { IOption } from 'types/creatableOption'
import { IProjectsInfo, IUser } from 'types/user'
import {
  EUserEducation,
  EEnvironment,
  EFeatureFlags,
  EStorageKeys,
  EContractTypes,
  EAbbreviatedContractTypesVI,
  EGender,
} from 'constants/enum'
import { AppConfig } from 'constants/index'
import { ITechnology, IContract, IPartner, IProject } from 'constants/schema'
import { getProjectsInfo } from './user'

export function checkValidArray(array?: unknown[]): boolean {
  return array ? Array.isArray(array) && array.length > 0 : false
}

export function getValidArray<T>(array?: T[]): T[] {
  return checkValidArray(array) ? array || [] : []
}

export function getCapitalizeArray(array?: string[]): string[] {
  return getValidArray(array).map((e) => capitalize(e))
}

export function deleteFieldsInObject(object: { [x: string]: unknown }, fieldList: string[]) {
  fieldList.forEach((field) => {
    object[field] = undefined
  })
}

export function toCaseInsensitive(value: string) {
  return new RegExp(`^${value}$`, 'i')
}

export function toValue(value: string): string {
  return value.toLowerCase().replace(/\s/g, '-')
}

export function toUpperCaseWithUnderscore(value: string): string {
  return value.toUpperCase().replace(/\s/g, '_')
}

export function parseJson(inputJSON: string): object {
  let outputJSON: object
  try {
    outputJSON = JSON.parse(inputJSON)
  } catch (error) {
    outputJSON = {}
  }
  return outputJSON
}

export function getNotEqualQuery(value: boolean) {
  return {
    $ne: !value,
  }
}

export function getInQuery(array: any[]) {
  if (array && array.length > 0) {
    return {
      $in: array,
    }
  }
  return null
}

export function getParsedUserValueBeforeSendBE(data) {
  const parseValues = {
    ...data,
    userData: parseJson(data.userData as string),
  }
  if (!parseValues) {
    return null
  }
  if (data?.titleId?.value) {
    parseValues.titleId = get(data, 'titleId.value', '')
  } else {
    delete parseValues?.titleId
  }
  if (data?.levelId?.value) {
    parseValues.levelId = get(data, 'levelId.value', '')
  } else {
    delete parseValues?.levelId
  }
  if (data?.paymentCategoryId?.value) {
    parseValues.paymentCategoryId = get(data, 'paymentCategoryId.value', '')
  } else {
    delete parseValues?.paymentCategoryId
  }
  if (data?.partnerId) {
    parseValues.partnerId = get(data, 'partnerId.value', '')
  }
  if (data?.currentGender?.value) {
    parseValues.gender = data?.currentGender?.value ?? ''
  }
  if (data?.education?.value) {
    parseValues.education = get(data, 'education.value', '')
  } else {
    delete parseValues?.education
  }
  if (data?.role) {
    parseValues.role = get(data, 'role', '')
  }
  if (data?.isSupervisor) {
    const currentData = get(data, 'isSupervisor', '')
    if (capitalize(currentData) === 'Yes') {
      parseValues.isSupervisor = true
    } else if (capitalize(currentData) === 'No') {
      parseValues.isSupervisor = false
    } else {
      parseValues.isSupervisor = currentData
    }
  }
  if (data?.isActive) {
    const currentData = get(data, 'isActive', '')
    if (currentData === 'Yes') {
      parseValues.isActive = true
    } else if (currentData === 'No') {
      parseValues.isActive = false
    } else {
      parseValues.isActive = currentData
    }
  }
  if (data?.allowToDownloadCV) {
    const currentData = get(data, 'allowToDownloadCV', '')
    if (currentData === 'Yes') {
      parseValues.allowToDownloadCV = true
    } else if (currentData === 'No') {
      parseValues.allowToDownloadCV = false
    } else {
      parseValues.allowToDownloadCV = currentData
    }
  }
  if (data?.projects) {
    parseValues.projects = Helper.getValueFromOption(data.projects)
  }
  if (data?.extraProjects) {
    parseValues.extraProjects = Helper.getValueFromOption(data.extraProjects)
  }
  if (data?.projectsOfSupervisor) {
    parseValues.projectsOfSupervisor = Helper.getValueFromOption(data.projectsOfSupervisor)
  }
  if (data?.externalProjects) {
    parseValues.externalProjects = Helper.getValueFromOption(data.externalProjects)
  }
  if (!parseValues.partnerId) {
    delete parseValues.partnerId
  }
  if (!parseValues.gender) {
    delete parseValues.gender
  }
  if (data?.IDIssuePlace) {
    parseValues.IDIssuePlace = data?.IDIssuePlace?.value ?? data?.IDIssuePlace ?? ''
  }
  delete parseValues.IDInformation
  return parseValues
}

export function getParsedClientBillDataBeforeSendBE(data) {
  const parseValues = {
    ...data,
  }
  if (data?.includedProjects) {
    parseValues.includedProjects = Helper.getValueFromOption(data.includedProjects)
  }
  if (data?.partnerId) {
    parseValues.partnerId = get(data, 'partnerId.value', '')
  }
  return parseValues
}

export function getParsedProjectValueBeforeSendBE(data) {
  const parseValues = {
    ...data,
  }
  if (!parseValues) {
    return null
  }
  if (data?.country) {
    parseValues.country = get(data, 'country.value', '') || null
  }
  if (data?.currency) {
    parseValues.currency = get(data, 'currency.value', '') || null
  }
  if (data?.paymentPeriod) {
    parseValues.paymentPeriod = get(data, 'paymentPeriod', '')
  }
  if (data?.type) {
    parseValues.type = get(data, 'type.value', '') || null
  }
  if (data?.paymentCategoryId) {
    parseValues.paymentCategoryId = get(data, 'paymentCategoryId.value', '') || null
  }
  if (data?.partnerId) {
    parseValues.partnerId = get(data, 'partnerId.value', '') || null
  }
  if (data?.technology) {
    parseValues.technology = Helper.getValueFromOption(data.technology)
  }
  if (data?.userId) {
    parseValues.userId = Helper.getValueFromOption(data.userId)
  }

  const filterNullValue: IProject = omitBy(parseValues, isNil)

  return omit(filterNullValue, [
    'currencyId',
    'countryId',
    'projectTypeId',
    'partner',
    'technologyOnProjectData',
    'listDeveloper',
    'developerDetailOnProject',
    'untilNowProject',
    'workingDeveloperNumber',
    parseValues.budgetProject === 'N/A' ? 'budgetProject' : '',
  ])
}

export function getParsedClientBillDataBeforeRenderFE(data, isDetail?) {
  if (!data) {
    return null
  }
  let formattedClientBillData = {
    ...data,
  }
  let totalBill = 0
  delete formattedClientBillData.clientBillItemList

  if (isDetail) {
    const includedProjects = data.includedProjects
    const formattedIncludedProjects = includedProjects.map((includedProject) => {
      return {
        value: includedProject._id,
        label: includedProject.name,
      }
    })
    formattedClientBillData = {
      partnerId: { label: data.partnerData.fullName, value: data.partnerData._id },
      publishedDate: Helper.getFormattedDateWith_YYYYMMDD(data?.publishedDate),
      billNumber: data.billNumber,
      includedProjects: formattedIncludedProjects,
    }
  }
  const clientBillItemListData = data.clientBillItemList
  const parsedClientBillItemListData = clientBillItemListData.map((clientBillItem) => {
    const totalPrice = clientBillItem.totalPrice
    if (totalPrice > 0) {
      totalBill += totalPrice
    }
    const parsedData = {
      ...clientBillItem,
      developerId: { label: clientBillItem?.developerData?.fullName, value: clientBillItem?.developerData?._id },
      startedDate: Helper.getFormattedDateWith_YYYYMMDD(clientBillItem?.startedDate),
      endedDate: Helper.getFormattedDateWith_YYYYMMDD(clientBillItem?.endedDate),
      quantity: Helper.getFormattedNumber(clientBillItem.quantity, 1),
      hourlyRate: Helper.getFormattedNumber(clientBillItem.hourlyRate, 1),
      totalPrice: Helper.getFormattedNumber(totalPrice, 1),
      projectId: { label: clientBillItem?.projectData?.name, value: clientBillItem?.projectData?._id },
    }
    return parsedData
  })
  const finaldData = {
    ...formattedClientBillData,
    publishedDate: Helper.getFormattedDateWith_YYYYMMDD(data.publishedDate),
    clientBillItemList: parsedClientBillItemListData,
  }
  return {
    totalBill: Helper.getFormattedNumber(totalBill, 1),
    parsedValue: finaldData,
  }
}

export function getParsedProjectValueBeforeRenderFE(currentProjectData, isEdit) {
  if (!currentProjectData) {
    return null
  }
  const paymentCategory = currentProjectData?.paymentCategory
  const partner = currentProjectData?.partner
  const country = currentProjectData?.countryId
  const currency = currentProjectData?.currencyId
  const projectType = currentProjectData?.projectTypeId
  const developersList = []
  getValidArray(currentProjectData?.developerDetailOnProject).forEach((item: IUser) =>
    developersList.push({
      label: item.fullName,
      value: item._id,
    })
  )
  const technologiesList = []
  getValidArray(currentProjectData?.technologyOnProjectData).forEach((item: ITechnology) => {
    if (item) {
      technologiesList.push({
        label: item.value,
        value: item._id,
      })
    }
  })
  const partnerFieldValue = partner && partner?.fullName + ' | ' + capitalize(partner?.category)
  const currentProject = {
    ...currentProjectData,
    country: isEdit ? { label: country?.value ?? '', value: country?._id ?? '' } : country?.value,
    currency: isEdit ? { label: currency?.value ?? '', value: currency?._id ?? '' } : currency?.value,
    type: isEdit ? { label: projectType?.value ?? '', value: projectType?._id ?? '' } : projectType?.value,
    paymentCategory: isEdit ? paymentCategory : capitalize(paymentCategory),
    partnerId: isEdit ? { label: partner?.fullName ?? '', value: partner?._id ?? '' } : partnerFieldValue,
    startedDate: isEdit
      ? Helper.getFormattedDateWith_YYYYMMDD(currentProjectData.startedDate)
      : moment(currentProjectData.startedDate ?? '').format('DD/MM/YYYY'),
    endedDate: isEdit
      ? Helper.getFormattedDateWith_YYYYMMDD(currentProjectData.endedDate)
      : moment(currentProjectData.endedDate ?? '').format('DD/MM/YYYY'),
    userId: developersList,
    technology: isEdit ? technologiesList : technologiesList.map((item) => item.label).join(', '),
    listDeveloper: developersList.map((item) => item.avatar),
    untilNowProject: currentProjectData.untilNow ? 'Yes' : 'No',
    budgetProject: currentProjectData?.budgetRemain ?? 'N/A',
  }
  return currentProject
}
export function getParsedPartnerValueBeforeSendBE(data) {
  const parseValues = {
    ...data,
  }
  if (!parseValues) {
    return null
  }
  if (data?.category) {
    parseValues.category = get(data, 'category.value', '')
  }
  if (data?.type) {
    parseValues.type = get(data, 'type', '')
  }

  return parseValues
}
export function getParsedPartnerValueBeforeRenderFE(currentPartnerData, isEdit) {
  if (!currentPartnerData) {
    return null
  }
  const category = currentPartnerData?.category
  const currentPartner = {
    ...currentPartnerData,
    category: isEdit ? { label: category ?? '', value: category ?? '' } : category,
  }
  return currentPartner
}
function convertRawDataOfProject(array1, array2) {
  const newCommonItems = array1
    .map((item1) => {
      if (array2.includes(item1._id)) {
        return item1
      }
      return null
    })
    .filter((item) => item)
  const parsedArray = newCommonItems.map((commonItem) => {
    return {
      label: commonItem.name,
      value: commonItem._id,
    }
  })
  return parsedArray
}

export function formatIDInformation(fields: string[]): string {
  const IDInformation: string[] = getValidArray(fields).filter((field: string) => field)
  return IDInformation.join(' | ')
}

export function getParsedUserValueBeforeRenderFE(currentUserData, projectList, isEdit: boolean) {
  const title = currentUserData?.title
  const level = currentUserData?.level
  const partner = currentUserData?.partner
  const role = currentUserData?.role
  const gender: EGender = currentUserData?.gender
  const education: EUserEducation = currentUserData?.education
  const paymentCategory = currentUserData?.paymentCategory
  const projectsInfo: IProjectsInfo = getProjectsInfo(currentUserData)
  const projectsOfUser: string[] = projectsInfo?.projects ?? []
  const supervisorProjectsOfUser: string[] = projectsInfo?.supervisorProjects ?? []
  const extraProjectsOfUser: string[] = projectsInfo?.extraProjects ?? []
  const externalProjectsOfUser: string[] = projectsInfo?.externalProjects ?? []
  const isSupervisor = currentUserData?.isSupervisor
  const isActive = currentUserData?.isActive
  const allowToDownloadCV = currentUserData?.allowToDownloadCV
  const IDNumber: string = currentUserData?.IDNumber
  const IDIssueDate: string = Helper.getFormattedDateWith_DDMMYYYY(currentUserData?.IDIssueDate)
  const IDIssuePlace: string = currentUserData?.IDIssuePlace
  const IDInformation: string = formatIDInformation([IDNumber, IDIssueDate, IDIssuePlace])

  const partnerInfo: string = partner?.category
    ? `${partner?.fullName} | ${capitalize(partner?.category)}`
    : partner?.fullName

  const currentUser = {
    profileDetailData: {
      ...currentUserData,
      IDIssuePlace: IDIssuePlace,
      titleId: isEdit ? { label: title?.value ?? '', value: title?._id ?? '' } : title?.value,
      levelId: isEdit ? { label: level?.value ?? '', value: level?._id ?? '' } : level?.value,
      education: isEdit ? { label: education ?? '', value: education ?? '' } : education,
      currentGender: { label: gender ?? '', value: gender ?? '' },
      paymentCategoryId: isEdit
        ? { label: paymentCategory?.value ?? '', value: paymentCategory?._id ?? '' }
        : paymentCategory?.value,
      partner: isEdit ? { label: partner?.value ?? '', value: partner?._id ?? '' } : partner?.value,
      partnerId: isEdit
        ? { label: partner?.fullName ?? '', value: partner?._id ?? '' }
        : partner?.fullName
        ? partnerInfo
        : undefined,
      role: isEdit ? role : role,
      projects: projectsOfUser ? convertRawDataOfProject(projectList, projectsOfUser) : [],
      projectsOfUser: convertRawDataOfProject(projectList, projectsOfUser).map((item) => item.label),
      extraProjects: extraProjectsOfUser ? convertRawDataOfProject(projectList, toJS(extraProjectsOfUser)) : [],
      externalProjects: externalProjectsOfUser
        ? convertRawDataOfProject(projectList, toJS(externalProjectsOfUser))
        : [],
      dateOfBirth: isEdit
        ? Helper.getFormattedDateWith_YYYYMMDD(currentUserData?.dateOfBirth)
        : moment(currentUserData?.dateOfBirth).format('DD/MM/YYYY'),
      IDIssueDate: isEdit
        ? Helper.getFormattedDateWith_YYYYMMDD(currentUserData?.IDIssueDate)
        : moment(currentUserData?.IDIssueDate).format('DD/MM/YYYY'),
      joinDate: isEdit
        ? Helper.getFormattedDateWith_YYYYMMDD(currentUserData?.joinDate)
        : moment(currentUserData?.joinDate).format('DD/MM/YYYY'),
      projectsOfSupervisor: supervisorProjectsOfUser
        ? convertRawDataOfProject(projectList, toJS(supervisorProjectsOfUser))
        : [],
      partnerCategory: capitalize(partner?.category) ?? '',
      userData: currentUserData?.userData ? JSON.stringify(currentUserData.userData, null, '\t') : null,
      cv: currentUserData?.cv,
      avatar: currentUserData?.avatar,
      isSupervisor: isSupervisor ? 'Yes' : 'No',
      isActive: isActive ? 'Yes' : 'No',
      allowToDownloadCV: allowToDownloadCV ? 'Yes' : 'No',
      IDInformation,
    },
  }
  return currentUser
}

export function getContractDetailFormData(currentContract: IContractDetail): IContract {
  const partner: IPartner | null = currentContract.partnerData?.length > 0 ? currentContract.partnerData[0] : null
  const formData: IContract = {
    ...currentContract,
    partnerId: { label: partner?.fullName, value: partner?._id },
    type: { label: String(currentContract?.type), value: String(currentContract?.type) },
    laborContractCategory: {
      label: String(currentContract?.laborContractCategory),
      value: String(currentContract?.laborContractCategory),
    },
    laborContractTerm: {
      label: String(currentContract?.laborContractTerm),
      value: String(currentContract?.laborContractTerm),
    },
    publishedDate: Helper.getFormattedDateWith_YYYYMMDD(currentContract?.publishedDate),
    IDIssueDate: Helper.getFormattedDateWith_YYYYMMDD(currentContract?.IDIssueDate),
    workingFromDate: Helper.getFormattedDateWith_YYYYMMDD(currentContract?.workingFromDate),
    workingEndDate: Helper.getFormattedDateWith_YYYYMMDD(currentContract?.workingEndDate),
    dateOfBirth: Helper.getFormattedDateWith_YYYYMMDD(currentContract?.dateOfBirth),
  }
  return formData
}

export function formatContractNumber(number: string, type: EContractTypes): string {
  const formattedType: string = toUpperCaseWithUnderscore(type)
  return `${number}/${EAbbreviatedContractTypesVI[formattedType]}`
}

export function getBooleanValueFromText(text: string) {
  let result = false
  if (text === 'true') {
    result = true
  }
  return result
}

export function getOptionsOfCheckboxGroup(selectedData: IOption[], fullData: IOption[]) {
  const newFullData = getValidArray(fullData).map((item) => {
    const sameData = getValidArray(selectedData).find((selectedItem) => item.value === selectedItem.value)
    let isChecked = false
    if (sameData) {
      isChecked = true
    }
    return {
      ...item,
      checked: isChecked,
    }
  })
  return newFullData
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

/* Encode string to slug */
export function convertToSlug(str) {
  //replace all special characters | symbols with a space
  str = removeAccents(str)
    .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
    .toLowerCase()

  // trim spaces at start and end of string
  str = str.replace(/^\s+|\s+$/gm, '')

  // replace space with dash/hyphen
  str = str.replace(/\s+/g, '-')
  return str
}

function removeAccents(str) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}

export function chakraShouldForwardProp<AdditionalProps>(CkComponent: FC<AdditionalProps>, baseStyle: BaseStyle) {
  return chakra(CkComponent, {
    shouldForwardProp: (prop: string) => {
      // *INFO:  don't forward Chakra's props
      const isChakraProp = !shouldForwardProp(prop)
      if (isChakraProp) return false

      // *INFO: forward valid HTML props
      const isValidProp = isValidHTMLProp(prop)
      if (isValidProp) return true

      // *INFO: else, only forward `sample` prop
      return ['sample'].includes(prop)
    },
    baseStyle,
  })
}

export function thousandSeparatorByCommaAndDotDecimal(value: string): string {
  const seperatedParts: string[] = value.split('.')
  const intPart: string = seperatedParts[0]
  const formattedIntPartWithComas: string = Number(intPart).toLocaleString()
  let finalValue: string = formattedIntPartWithComas
  const hasContainedDot: boolean = value.includes('.')
  if (hasContainedDot) {
    const decimalPart: string = seperatedParts[1] ? seperatedParts[1] : ''
    finalValue = formattedIntPartWithComas.concat('.', decimalPart)
  }
  return finalValue
}

export async function getBackendUrl(): Promise<string> {
  let backendUrl: string = window.sessionStorage.getItem(EStorageKeys.BACKEND_URL)
  const environment: EEnvironment = AppConfig.ENVIROMENT as EEnvironment
  const isProduction: boolean = environment === EEnvironment.PRODUCTION

  if (isProduction) {
    return AppConfig.API_URL
  }

  if (backendUrl === null) {
    const flagName = `${environment}_${EFeatureFlags.BACKEND_V2}`
    const isBackendV2Enabled: boolean = await isFeatureEnabled(flagName)
    backendUrl = isBackendV2Enabled ? AppConfig.API_URL_V2 : AppConfig.API_URL
    window.sessionStorage.setItem(EStorageKeys.BACKEND_URL, backendUrl)
  }
  return backendUrl
}

export function roundNumberToFixedDigits(number: string | number, digits = 2): string {
  return Number.parseFloat(`${number}`).toFixed(digits)
}

export function handleLogout(): void {
  const isRememberMe: boolean | null = JSON.parse(window.localStorage.getItem('isRememberMe'))
  setTimeout(function () {
    if (!isRememberMe) {
      window.localStorage.clear()
      window.sessionStorage.clear()
    }
    window.localStorage.removeItem('userId')
    window.localStorage.removeItem('userRole')
    window.localStorage.removeItem('fullName')
    window.localStorage.removeItem('aToken')
    window.localStorage.removeItem('rToken')
    window.localStorage.removeItem('title')
    window.localStorage.removeItem('avatar')
    window.localStorage.removeItem('email')
    window.localStorage.removeItem('isRememberMe')
    window.location.reload()
  }, 300)
}
