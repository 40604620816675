import { chakra, Button } from '@chakra-ui/react'

export const LogoActionButton = chakra(Button, {
  baseStyle: () => ({
    background: 'blackAlpha.600',
    width: 10,
    height: 10,
    padding: 0,
    _hover: {
      cursor: 'pointer',
      background: 'blackAlpha.600',
    },
  }),
})
