import { Box, HStack, Text } from '@chakra-ui/react'
import { ERoleOfUser } from 'constants/enum'
import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useStores } from 'utils/hooks/useStores'
import { ReactComponent as LogoMini } from '../../../../assets/images/logo/logo-icon-mini.svg'
import { ReactComponent as Logo } from '../../../../assets/images/logo/logo-icon.svg'
import { ReactComponent as Arrow } from '../../../../assets/images/svg/arrow-left-white.svg'
import { ReactComponent as ArrowRight } from '../../../../assets/images/svg/arrow-right-s-line.svg'
import { ReactComponent as Birthday } from '../../../../assets/images/svg/birthday-cake.svg'
// TODO: Integrate later
// import { ReactComponent as Accounting } from '../../../../assets/images/svg/calculator.svg'
import { ReactComponent as Commit } from '../../../../assets/images/svg/commit.svg'
import { ReactComponent as Dashboard } from '../../../../assets/images/svg/dashboard.svg'
import { ReactComponent as Feedback } from '../../../../assets/images/svg/feedback.svg'
import { ReactComponent as Guideline } from '../../../../assets/images/svg/guideline.svg'
import { ReactComponent as Information } from '../../../../assets/images/svg/information.svg'
import { ReactComponent as Payment } from '../../../../assets/images/svg/payment.svg'
import { ReactComponent as Policy } from '../../../../assets/images/svg/policy.svg'
import { ReactComponent as Contract, ReactComponent as Project } from '../../../../assets/images/svg/project.svg'
import { ReactComponent as Extensions } from '../../../../assets/images/svg/qr-code.svg'
import { ReactComponent as Timesheet } from '../../../../assets/images/svg/timesheet.svg'
import { RoutesConstants } from '../../../../constants'
import SubMenu from './components/Submenu'
import SubmenuAdmin from './components/SubmenuAdmin'
import {
  // TODO: Integrate later
  // accountingSubmenu,
  commitTimeSubmenu,
  ESidebarHeaderTitle,
  ESidebarMenuContent,
  ESubMenuName,
  IMenuList,
  IRouteName,
  paymentSubmenu
} from './constant'
import { getRouteName, getTimesheetSubmenu } from './utils'

interface INormalSidebarV2Props {
  isCollapsed: boolean
  actionTab: string
  unReadFeedbackNumber: number
  setIsCollapsed: () => void
}

const NormalSidebarV2 = (props: INormalSidebarV2Props) => {
  const { isCollapsed, actionTab, unReadFeedbackNumber, setIsCollapsed } = props
  const role: string = window.localStorage.getItem('userRole')
  const isAdmin: boolean = role === ERoleOfUser.ADMIN
  const [subMenu, setSubmenu] = useState<string>('')
  const [isSupervisor, setIsSupervisor] = useState<boolean>(false)
  const route: IRouteName = getRouteName(isAdmin)
  const timeSheetList: IMenuList[] = getTimesheetSubmenu(isAdmin, isSupervisor)
  const { userProfileStore } = useStores()
  const { profileDetail } = userProfileStore

  function renderSidebarClassName(name: string): string {
    return actionTab?.includes(name) ? 'sidebar-item active' : 'sidebar-item'
  }

  function handleOpenSubmenu(subMenuName: string): void {
    setSubmenu(subMenuName === subMenu ? '' : subMenuName)
  }

  useEffect(
    function handleActionTab() {
      if (actionTab.includes(ESubMenuName.TIMESHEET)) {
        setSubmenu(ESubMenuName.TIMESHEET)
      }

      if (actionTab === ESubMenuName.COMMIT_TIME) {
        setSubmenu(actionTab)
      }
    },
    [actionTab]
  )

  useEffect(() => {
    userProfileStore.getProfileDetail()
  }, [])

  useEffect(() => {
    if (profileDetail?.isSupervisor) {
      setIsSupervisor(profileDetail.isSupervisor as boolean)
    }
  }, [profileDetail])

  return (
    <Box className="sidebar">
      <Link to={route.linkToHome}>
        {isCollapsed ? (
          <LogoMini />
        ) : (
          <HStack justifyContent="center" spacing={0} width="100%" height={20}>
            <Arrow onClick={setIsCollapsed} className="collapse-button" />
            <Logo />
          </HStack>
        )}
      </Link>
      <Box className="sidebar-menu" marginY="14px">
        {isAdmin && <SubmenuAdmin getClassName={renderSidebarClassName} />}

        <Box className="sidebar-header">{ESidebarHeaderTitle.DEVELOPMENT}</Box>

        <Box className={renderSidebarClassName('project')}>
          <Link to={route.projectRoute}>
            <Project className="icon" /> {ESidebarMenuContent.PROJECT}
          </Link>
        </Box>
        <Box className="sidebar-header">{ESidebarHeaderTitle.GENERAL}</Box>
        <Box className={renderSidebarClassName('policies')}>
          <Link to={route.policyRoute}>
            <Policy className="icon" /> {ESidebarMenuContent.POLICY}
          </Link>
        </Box>
        <Box className={renderSidebarClassName('infos')}>
          <Link to={route.infoRoute}>
            <Information className="icon" />
            {ESidebarMenuContent.ANNOUNCEMENT}
          </Link>
        </Box>
        <Box className="show-desktop sidebar-header">{ESidebarHeaderTitle.INFORMATION}</Box>
        <Box className={renderSidebarClassName('feedback')}>
          <Link to={route.feedbackRoute}>
            <Feedback className="icon" />
            {ESidebarMenuContent.FEEDBACK}
            {!!isCollapsed && unReadFeedbackNumber > 0 && (
              <span className="unRead-number-alert">{unReadFeedbackNumber}</span>
            )}
          </Link>
        </Box>
        <Box className={renderSidebarClassName('guideline')}>
          <Link to={RoutesConstants.GUIDELINE}>
            <Guideline className="icon" /> {ESidebarMenuContent.GUIDELINE}
          </Link>
        </Box>
        {isAdmin && (
          <>
            <Box className="show-desktop sidebar-header">{ESidebarHeaderTitle.EXTENSIONS}</Box>
            <Box className={renderSidebarClassName('qr-code-generator')}>
              <Link to={RoutesConstants.QR_CODE}>
                <Extensions className="icon" /> {ESidebarMenuContent.QR_CODE}
              </Link>
            </Box>
          </>
        )}
        {isAdmin && (
          <Fragment>
            <Box className="show-desktop sidebar-header">{ESidebarHeaderTitle.OPERATING_MANAGEMENT}</Box>
            <Box className={`show-desktop ${renderSidebarClassName('birthday')}`}>
              <Link to={RoutesConstants.BIRTHDAY}>
                <Birthday className="icon" /> {ESidebarMenuContent.BIRTHDAY_OF_STAFFS}
              </Link>
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  )
}
export default NormalSidebarV2
