import React, { useEffect, useState } from 'react'
import { Box, useMediaQuery } from '@chakra-ui/react'
import { IProjectFilter as IFormattedFilter } from 'API/project/constants'
import Table from 'components/Table'
import MoreDropBox from 'components/Table/components/MoreDropBox'
import ConfirmArchiveModal from 'componentsCharka/ConfirmArchiveModal'
import ConfirmDeleteModal from 'componentsCharka/ConfirmDeleteModal'
import Helper from 'Helper'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { getValidArray } from 'utils'
import { maxMobileSize } from 'constants/common'
import { PartnerCategory } from 'constants/defaultOptionList'
import { EPartnerCategory } from 'constants/enum'
import { actionOnDataTable, RoutesConstants } from 'constants/index'
import { IProject } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import { getHeaderList, IRowActionItem } from '../constant'
import FilterForm from './components/FilterForm'
import HeaderSection from './components/HeaderSection'
import ProjectSummary from './components/ProjectSummary'
import { IFilterValuesOfProjects, IFormLabel, IProjectFilter, IProjectFilterAdmin } from './constants'
import { formatProjectFilter, getInitialFilterValue } from './utils'
import { combineStartedDateEndedDate, getProjectTechnologies } from './utils'
import styles from './index.module.scss'

const ProjectListAdmin = () => {
  const [filter, setFilter] = useState<IFormattedFilter>(getInitialFilterValue())
  const [openModalFilterForm, setOpenFilterForm] = useState<boolean>(false)
  const { adminProjectStore } = useStores()
  const { projectsList, count, partnerList, countriesList, technologiesList, usersList } = adminProjectStore
  window.localStorage.removeItem('pageTitle')
  window.localStorage.setItem('pageTitle', 'Projects')
  const history = useHistory()
  const pageIndex: number = Number(get(history, 'location.state.page', 1)) || 1
  const pagination = {
    includePagination: true,
    pageIndex,
    tableLength: count,
    gotoPage: (page: number) => {
      history.push({ pathname: RoutesConstants.PROJECTS_ADMIN_LIST, state: { page, filter } })
      adminProjectStore.getProjectList(false, history, filter)
    },
  }
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false)
  const [selectedProject, setSelectedProject] = useState<IProject>()
  const [openSummaryModal, setOpenSummaryModal] = useState<boolean>(false)

  function toggleOpenDeleteModal(): void {
    setOpenDeleteModal(!openDeleteModal)
  }

  function toggleOpenArchiveModal(): void {
    setOpenArchiveModal(!openArchiveModal)
  }

  function toggleOpenSummaryModal(): void {
    setOpenSummaryModal(!openSummaryModal)
  }

  function toggleOpenSummaryModalHandler(project: IProject): void {
    setSelectedProject(project)
    setOpenSummaryModal(!openSummaryModal)
  }

  function callOpenDialogConfirm(project: IProject, nameOfEvent: string): void {
    set(project, 'shortName', project?.name ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      setSelectedProject(project)
      toggleOpenDeleteModal()
    } else if (nameOfEvent === actionOnDataTable.EDIT) {
      //TODO: Integrate edit modal later
      // setEditProject(project)
      // toggleOpenEditModal()
    } else {
      setSelectedProject(project)
      toggleOpenArchiveModal()
    }
  }
  async function handleClickOKConfirmedDelete(id: string): Promise<void> {
    await adminProjectStore.deleteProject(id)
    setOpenDeleteModal(false)
  }
  async function handleClickOKOfUnarchiveConfirm(id: string): Promise<void> {
    await adminProjectStore.unArchiveProject(id)
    setOpenArchiveModal(false)
  }
  async function handleClickOKOfArchiveConfirm(id: string): Promise<void> {
    await adminProjectStore.archiveProject(id)
    setOpenArchiveModal(false)
  }

  function handleCreate(): void {
    history.push(RoutesConstants.PROJECT_ADMIN_CREATE)
  }

  function openFilterForm(): void {
    setOpenFilterForm(true)
  }
  const changeName = debounce((event: { target: { value: string } }) => {
    setFilter({ ...filter, name: { $regex: event?.target?.value ?? '', $options: 'i' } })
  }, 1000)

  const partnerCategoryOfProject: EPartnerCategory[] = PartnerCategory.slice(0, 2)

  useEffect(() => {
    adminProjectStore.getPartnerList(partnerCategoryOfProject)
    adminProjectStore.getCountryList()
    adminProjectStore.getTechnologyList()
    adminProjectStore.getUserList()
  }, [])

  useEffect(() => {
    adminProjectStore.getProjectList(true, history, filter)
  }, [openDeleteModal, openArchiveModal, filter])

  const partnersOptions = Helper.createOptionsOfReactSelectFromDB(partnerList)
  const countriesOptions = Helper.createOptionsOfReactSelectFromDB(countriesList)
  const technologiesOptions = Helper.createOptionsOfReactSelectFromDB(technologiesList)
  const usersOptions = Helper.createOptionsOfReactSelectFromDB(usersList)

  const dataInTable = getValidArray(projectsList).map((project) => {
    const isArchived: boolean = project?.isArchived ?? false
    const isActive: boolean = project?.isActive ?? false
    const documentId: string = project?._id ?? ''
    const pathname = generatePath(RoutesConstants.PROJECT_ADMIN_DETAIL, { id: documentId, isArchived: isArchived })
    const actionDetailHandler = () => history.push(pathname)
    const actions: IRowActionItem[] = []
    // *TODO: Implement edit project later
    // actions.push({
    //   name: 'Edit Project',
    //   handler: () => callOpenDialogConfirm(project, actionOnDataTable.EDIT),
    // })
    if (isArchived) {
      actions.push({
        name: 'Unarchive Project',
        handler: () => callOpenDialogConfirm(project, actionOnDataTable.ARCHIVE),
      })
    } else {
      actions.push({
        name: 'Archive Project',
        handler: () => callOpenDialogConfirm(project, actionOnDataTable.ARCHIVE),
      })
    }
    actions.push({
      name: 'Delete Project',
      customClassNames: ['isDanger'],
      handler: () => callOpenDialogConfirm(project, actionOnDataTable.DELETE),
    })

    let statusText: string = 'Active'
    let archivedText: string = 'Not-Archived'
    if (!isActive) {
      statusText = 'Deleted'
    }
    if (isArchived) {
      statusText = 'Archived'
      archivedText = 'Archived'
    }
    const typeData = get(project, 'typeData[0].value', '')
    const technologiesOfProject: string[] = getProjectTechnologies(project?.technology, technologiesList)
    return {
      ...project,
      technologiesListFilter: technologiesOfProject,
      typeId: typeData,
      startedDateEndDate: combineStartedDateEndedDate(project?.startedDate, project?.endedDate, project?.untilNow),
      status: statusText,
      archived: archivedText,
      actions: (
        <MoreDropBox
          isDetail
          isSummary
          detailActionHandler={actionDetailHandler}
          summaryActionHandler={() => toggleOpenSummaryModalHandler(project)}
          actions={actions}
        />
      ),
    }
  })

  function filterSubmitHandler(data: IProjectFilterAdmin): void {
    const contriesFilter: IFormLabel[] = data?.countries?.filter((country) => country.checked === true)
    const isActive: boolean = data?.isActive
    const isArchived: boolean = data?.isArchived
    const technologiesFilter: IFormLabel[] = data?.technologies?.filter((technology) => technology.checked === true)
    const newFilter: IFilterValuesOfProjects = {
      selectedTechnologies: technologiesFilter,
      selectedCountries: contriesFilter,
      selectedPartnerId: data.partnerId,
      selectedUserId: data.userId,
      isActive,
      isArchived,
    }
    window.localStorage.setItem('filterValuesOfProjects', JSON.stringify(newFilter))
    const partnerId: string[] = Helper.getValueFromOption(data.partnerId)
    const userId: string[] = Helper.getValueFromOption(data.userId)
    const country: string[] = Helper.getValueFromOption(contriesFilter)
    const technology: string[] = Helper.getValueFromOption(technologiesFilter)
    const currentFilter: IProjectFilter = {
      partnerId,
      userId,
      country,
      technology,
    }
    if (isActive) {
      currentFilter.isActive = true
      currentFilter.isArchived = false
    }
    if (isArchived) {
      currentFilter.isArchived = true
    }
    const formattedFilter: IFormattedFilter = formatProjectFilter(currentFilter)
    setFilter(formattedFilter)
  }

  return (
    <Box>
      <HeaderSection openFilterForm={openFilterForm} changeName={changeName} handleCreate={handleCreate} />
      <Box
        className={styles.internalDocumentContainer}
        paddingY={isMobile ? 0 : 4}
        paddingX={isMobile ? 0 : 4}
        background={isMobile ? 'none' : 'white'}
        borderRadius="6px"
        border={isMobile ? 'none' : `1px solid ${backgroundGrey200}`}>
        <FilterForm
          partnersList={partnersOptions}
          countriesList={countriesOptions}
          technologiesList={technologiesOptions}
          usersList={usersOptions}
          openModalFilterForm={openModalFilterForm}
          setOpenFilterForm={setOpenFilterForm}
          filterSubmit={filterSubmitHandler}
        />
        <Table
          headerList={getHeaderList(isMobile)}
          tableData={dataInTable}
          isStriped
          pagination={pagination}
          hasBottomHeader={!isMobile}
          //*TODO: integrate later
          // exportNow={exportNow}
          // toggleExport={this.toggleExport}
        />
        <ConfirmDeleteModal
          data={selectedProject}
          isOpen={openDeleteModal}
          closeHandler={toggleOpenDeleteModal}
          OKClickHandler={handleClickOKConfirmedDelete}
          title={'Project'}
          content={'Project Name'}
        />
        <ConfirmArchiveModal
          data={selectedProject}
          isOpen={openArchiveModal}
          closeHandler={toggleOpenArchiveModal}
          OKClickHandler={selectedProject?.isArchived ? handleClickOKOfUnarchiveConfirm : handleClickOKOfArchiveConfirm}
          title={'Project'}
          content={'Project Name'}
        />
        <ProjectSummary isOpen={openSummaryModal} closeHandler={toggleOpenSummaryModal} projectData={selectedProject} />
      </Box>
    </Box>
  )
}

export default observer(ProjectListAdmin)
